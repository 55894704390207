import {Control} from "react-hook-form/dist/types";
import {JobPlanForm} from "../../../../../../store/data/entities/job-plan";
import {FlatList, StyleSheet, TouchableOpacity, View} from "react-native";
import {useFieldArray} from "react-hook-form";
import {Button} from "react-native-paper";
import {TextFieldController} from "../../../../../web/components/forms/TextFieldController";
import * as React from "react";
import ImageListFormControl from "../../../../components/ImageListFormControl";
import {Feather} from "@expo/vector-icons";

interface JobPlanItemDescriptionsFormProps {
  control: Control<JobPlanForm>;
  isApproval?: boolean;
}

export default function JobPlanItemDescriptionsForm({control, isApproval}: JobPlanItemDescriptionsFormProps) {
  const { fields, append, swap, remove } = useFieldArray({
    control,
    name: "descriptions",
  });

  const handleOnAddEntry = () => {
    append({
      description: "",
      images: [],
      imagesBase64: []
    });
  }

  const handleOnMoveUp = (index: number) => {
    if (index === 0) return;
    swap(index, index - 1);
  }

  const handleOnMoveDown = (index: number) => {
    if (index === fields.length - 1) return;
    swap(index, index + 1);
  }

  return (
    <View>
      <FlatList
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        data={fields}
        keyExtractor={(item) => item.id}
        renderItem={({index}) => {
          const first = index === 0;
          const last = index === fields.length - 1;

          return (
            <View style={styles.sectionContainer}>
              <View style={styles.innerSectionContainer}>
                <View style={styles.textBoxContainer}>
                  <View style={{flex: 1}}>
                    <TextFieldController
                      disabled={isApproval}
                      control={control}
                      controlName={`descriptions.${index}.description`}
                      title={`Job Description ${index + 1}`}
                      required
                      multiline={5}
                    />
                  </View>
                  <View style={styles.sectionButtons}>
                    <TouchableOpacity
                      disabled={isApproval}
                      onPress={() => remove(index)}
                    >
                      <Feather name='trash' size={24} color="red" />
                    </TouchableOpacity>
                    <View>
                      <TouchableOpacity
                        disabled={first || isApproval}
                        onPress={() => handleOnMoveUp(index)}
                      >
                        <Feather name='arrow-up' size={18} color={first ? '#9F9F9F' : '#333'} />
                      </TouchableOpacity>
                      <TouchableOpacity
                        disabled={last || isApproval}
                        onPress={() => handleOnMoveDown(index)}
                      >
                        <Feather name='arrow-down' size={18} color={last ? '#9F9F9F' : '#333'} />
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                <View style={styles.imageContainer}>
                  <ImageListFormControl
                    isApproval={isApproval}
                    control={control}
                    name={`descriptions.${index}.images`}
                    maxImages={5}
                  />
                </View>
              </View>
            </View>
          )
        }}
      />
      <View style={styles.buttonContainer}>
        <Button
          disabled={isApproval}
          icon='plus'
          style={styles.formButton}
          mode="elevated"
          onPress={handleOnAddEntry}>
          Add Job Description
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  formButton: {
    flexBasis: 254
  },
  buttonContainer: {
    marginVertical: 12,
    marginHorizontal: 4,
    flexDirection: 'row',
  },
  imageContainer: {
    marginVertical: 4
  },
  innerSectionContainer: {
    padding: 12,
    marginVertical: 8,
    flex: 1
  },
  sectionContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  sectionButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 30
  },
  textBoxContainer: {
    flexDirection: 'row',
    gap: 4
  },
  separator: {
    height: 1,
    width: '95%',
    marginHorizontal: 'auto',
    backgroundColor: "#d9d9d9",
  }
})
