import React from "react";
import {View, Text, ScrollView, StyleSheet, TouchableOpacity} from "react-native";
import { Button } from "react-native-paper";
import {PRIMARY_COLOUR} from "../../constants/GlobalStyle";

export interface FileUploadProps {
  onRemove: (file: File) => void;
  onUploadFilesPress: () => void;
  onFilePress?: (file: File) => void;
  fileList: File[];
  buttonText?: string;
  isLoading?: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = ({ onRemove, onUploadFilesPress, onFilePress, fileList, buttonText = 'Upload Files', isLoading = false }) => {
  const itemWrapper = (element: React.ReactNode, file: File, key: string) => {
    if (onFilePress) {
      return (
        <TouchableOpacity key={key} onPress={() => onFilePress(file)} style={styles.fileListItem}>
          {element}
        </TouchableOpacity>
      );
    }
    return <View key={key} style={styles.fileListItem}>{element}</View>;
  };

  return (
    <View style={styles.container}>
      <Button
        disabled={isLoading}
        style={styles.button}
        compact
        icon='upload'
        mode='elevated'
        textColor={PRIMARY_COLOUR}
        buttonColor='#fff'
        onPress={onUploadFilesPress}>{buttonText}</Button>
      <ScrollView style={styles.fileList}>
        {fileList.map((file, index) =>
          itemWrapper(
            <>
              <Text style={styles.fileName}>{file.name}</Text>
              <Button
                disabled={isLoading}
                icon='delete'
                textColor={PRIMARY_COLOUR}
                onPress={() => onRemove(file)}
              >
                Remove
              </Button>
            </>,
            file,
            file.size.toString() + index
          )
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
  },
  fileList: {
    marginVertical: 6,
    borderRadius: 4,
    backgroundColor: '#fff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5
  },
  fileListItem: {
    marginBottom: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 4,
    paddingHorizontal: 8
  },
  button: {
    maxWidth: 150,
    borderRadius: 4,
  },
  fileName: {
    paddingHorizontal: 8,
  }
})
