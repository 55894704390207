import {CoreModal, CoreModalProps} from "../../../../hooks/useModal";
import {StyleSheet, Text, View} from "react-native";
import * as React from "react";
import {TicketViewModel} from "../../../../store/data/models/ticket-view-model";
import {TextInput} from "react-native-paper";
import {useState} from "react";
import {useAddUpdateMutation} from "../../../../store/services/api/ticket-api";

export default function UpdateTicketModal({ticket, onClose}: {ticket: TicketViewModel, onClose: () => void}) {
  const [updateText, setUpdateText] = useState("");
  const [addUpdate] = useAddUpdateMutation();
  const [error, setError] = useState(null);

  const clearError = () => setError(null);

  const handleSave =  async () => {
    if (!updateText) {
      setError("Update can't be empty");
      return;
    }

    const result = await addUpdate({text: updateText, ticketId: ticket.id}) as any;
    if (result.error) {
      return;
    }
    onClose();
  };

  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Cancel', onPress: onClose, mode: 'text'},
    ],
    rightButtons: [
      {label: 'Save', onPress: handleSave, mode: 'contained'},
    ]
  }

  return (
    <CoreModal {...modalProps}>
      <View style={{minWidth: 500}}>
        <TextInput
          mode="outlined"
          label="Update"
          multiline={true}
          numberOfLines={15}
          onChangeText={text => { setUpdateText(text); clearError(); }}
          value={updateText}
          style={styles.textInput}
        />
        {error && <Text style={styles.errorText}>{error}</Text>}
      </View>
    </CoreModal>
  )
}

const styles = StyleSheet.create({
  textInput: {
    marginBottom: 20,
    backgroundColor: "white",
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
    textAlign: 'center'
  }
});
