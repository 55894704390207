import {useSetWorkOrderApprovalStatusMutation} from "../../../../../../store/services/api/work-order-api";
import {Button, Form, Modal, Input, Typography} from "antd";
const {TextArea} = Input;
const {Text} = Typography;

interface WorkOrderApprovalModalProps {
  id: string;
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
}

export default function WorkOrderApprovalModal(props: WorkOrderApprovalModalProps) {
  const [setStatus, {isLoading}] = useSetWorkOrderApprovalStatusMutation();
  const [form] = Form.useForm();

  const handleOnSubmit = async (isApproved: boolean) => {
    try {
      if (!isApproved) {
        await form.validateFields(['rejectionReason']);
      }

      const formData = form.getFieldsValue();

      await setStatus({
        entityId: props.id,
        isApproved,
        rejectionReason: isApproved ? undefined : formData?.rejectionReason,
      }).unwrap();

      props.onSuccess();
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Modal
      title='Approve Work Order'
      open={props.open}
      onClose={props.onClose}
      onCancel={props.onClose}
      loading={isLoading}
      destroyOnClose
      footer={(_, { CancelBtn }) => (
        <>
          <CancelBtn />
          <Button
            loading={isLoading}
            variant='outlined'
            color='danger'
            onClick={() => handleOnSubmit(false)}
          >
            Reject
          </Button>
          <Button
            loading={isLoading}
            variant='solid'
            color='primary'
            onClick={() => handleOnSubmit(true)}
          >
            Approve
          </Button>
        </>
      )}
    >
      <Text>Would you like to approve or reject this work order?</Text>
      <br/>
      <Text>Please provide a reason if you wish to reject.</Text>
      <Form
        style={{marginTop: 16}}
        form={form}
      >
        <Form.Item
          name="rejectionReason"
          label="Rejection Reason"
          rules={[
            {
              required: true,
              message: 'Please provide a reason for rejection.',
              validator: (_, value) => {
                if (value === undefined || value.trim() === '') {
                  return Promise.reject(Error('Please provide a reason for rejection.'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}
