import { baseApi } from "./base-api";
import {CorrectiveJob, CorrectiveJobForm} from "../../data/entities/corrective-job";

export const correctiveJobApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    updateCorrectiveJob: build.mutation<void, CorrectiveJobForm>({
      query: (dto) => ({
        url: `correctiveJobs/updateCorrectiveJob`,
        method: 'POST',
        body: dto
      })
    }),
    getCorrectiveJob: build.query<CorrectiveJob, string>({
      query: (jobId) => ({
        url: `correctiveJobs/getCorrectiveJob/${jobId}`
      })
    }),
  }),
  overrideExisting: true,
});

export const {
  useUpdateCorrectiveJobMutation,
  useGetCorrectiveJobQuery,
  useLazyGetCorrectiveJobQuery
} = correctiveJobApi;
