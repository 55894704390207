import {Form, Input, Modal} from "antd";
import {WorkOrderLogForm} from "../../../store/data/entities/work-order-log";
import {useAddWorkOrderLogMutation} from "../../../store/services/api/work-order-api";
import UserSelect from "../../forms/controls/UserSelect";
import {UserRole} from "../../../store/data/users/user-roles";
import {useEffect} from "react";

interface AddWorkLogModalProps {
  workOrderId: string;
  defaultUserId?: string;
  userRoles?: UserRole[];
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export default function AddWorkLogModal(props: AddWorkLogModalProps) {
  const [form] = Form.useForm<WorkOrderLogForm>();
  const [addWorkLog, {isLoading}] = useAddWorkOrderLogMutation();

  const handleOnFinish = (data: WorkOrderLogForm) => {
    addWorkLog({
      ...data,
      workOrderId: props.workOrderId,
    })
      .unwrap()
      .then(() => {
        props.onSuccess?.();
      });
  }

  useEffect(() => {
    form.resetFields();
  }, [props.open]);

  return (
    <Modal
      title='Add Work Log'
      open={props.open}
      onClose={props.onClose}
      onCancel={props.onClose}
      onOk={() => form.submit()}
      loading={isLoading}
      destroyOnClose
    >
      <Form<WorkOrderLogForm>
        form={form}
        layout='vertical'
        onFinish={handleOnFinish}
        initialValues={{
          userId: props.defaultUserId
        }}
      >
        <Form.Item name='userId' label='User' rules={[{required: true}]}>
          <UserSelect userRoles={props.userRoles ?? ['Technician', 'BuildingLead']} />
        </Form.Item>

        <Form.Item name='comments' label='Comments'>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}
