import React, {useEffect, useState} from "react";
import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {TechnicianWorkOrderAssetsStackParamList} from "./TechnicianWorkOrderAssetsStack";
import {
  useLazyFindWorkOrderAssetQuery,
  useUpdateWorkOrderAssetMutation
} from "../../../../../../store/services/api/work-order-api";
import Skeleton from "../../../../../../components/skeletons/Skeleton";
import {useWorkOrder} from "../../WorkOrdersStack";
import {useForm} from "react-hook-form";
import {Button, Card} from "react-native-paper"
import {CheckboxControl} from "../../../../../../components/form-controls/CheckboxControl";
import {WorkOrderAssetForm} from "../../../../../../store/data/entities/work-order-asset";
import LoadingCard from "../../../../../../components/LoadingCard";
import {useCamera} from "../../../../hooks/useCamera";
import {useToast} from "../../../../../../components/toast/ToastProvider";
import {TextControl} from "../../../../../../components/form-controls/TextControl";
import {getUniqueReferenceFromUrl} from "../../../../../../utils/qr-utils";
import FollowUpWorkOrderModal from "../../../../modals/FollowUpWorkOrderModal";

export default function TechnicianWorkOrderAssetDetails({navigation, route}: ScreenProps<TechnicianWorkOrderAssetsStackParamList, 'details'>) {
  const [findAsset, {data: asset, isFetching}] = useLazyFindWorkOrderAssetQuery();
  const [updateWorkOrderAsset, {isLoading}] = useUpdateWorkOrderAssetMutation();
  const [workOrderModalVisible, setWorkOrderModalVisible] = useState(false);
  const {id: workOrderId} = useWorkOrder();

  const id = route.params.id;
  const qrScanned = route.params?.qrScanned;

  const {showCamera} = useCamera();
  const {show} = useToast();

  useEffect(() => {
    if (workOrderId && id) {
      findAsset({
        workOrderId,
        workOrderAssetId: id
      });
    }
  }, [workOrderId, id]);

  useEffect(() => {
    if (qrScanned) {
      form.setValue('isQRScanned', true);
    }
  }, [qrScanned]);

  useEffect(() => {
    if (asset) {
      form.setValue('id', asset.id);
      form.setValue('isFollowUpRequired', asset.isFollowUpRequired);
      form.setValue('followUpWorkOrderId', asset.followUpWorkOrderId);
      form.setValue('isChecksCompleted', asset.isChecksCompleted);
      form.setValue('isQRScanned', qrScanned || asset.isQRScanned);
      form.setValue('comments', asset.comments);
    }
  }, [asset, qrScanned]);

  const form = useForm<WorkOrderAssetForm>({
    defaultValues: {
      isChecksCompleted: false,
      isQRScanned: false,
      isFollowUpRequired: false,
      followUpWorkOrderId: null,
      comments: null
    },
  });
  const isQrScanned = form.watch('isQRScanned');
  const isFollowUpRequired = form.watch('isFollowUpRequired');

  const handleOnSubmit = () => {
    if (!asset?.id) return;

    form.handleSubmit((data) => {
      if (isFollowUpRequired && !data.followUpWorkOrderId) {
        setWorkOrderModalVisible(true);
        return;
      }

      updateWorkOrderAsset({
        ...data,
        isQRScanned: isQrScanned, // unsure why this is necessary, but it is. If there is a bug, try here,
        isFollowUpRequired: isFollowUpRequired,
        id: asset.id,
      }).unwrap()
        .then(() => navigation.navigate('list'))
    })();
  }

  const handleWorkOrderCreation = (workOrderId: string) => {
    if (!asset?.id) return;

    form.handleSubmit((data) => {
      updateWorkOrderAsset({
        ...data,
        id: asset.id,
        isQRScanned: isQrScanned,
        followUpWorkOrderId: workOrderId,
        isFollowUpRequired: isFollowUpRequired,
      }).unwrap()
        .then(() => navigation.navigate('list'))
    })();
  }

  const handleOnQrScannedChecked = () => {
    showCamera({
      mode: 'qr',
      onResult: (url: string) => {
        const uniqueRef = getUniqueReferenceFromUrl(url);
        const match = uniqueRef === asset.assetQRCode;
        if (!match) {
          show("Couldn't Match QR", `The QR code ${uniqueRef} does not match the asset.`, 'warn');
        }
        form.setValue('isQRScanned', match);
      }
    })
  }

  return (
    <Skeleton
      placeholder={<Skeleton.Card />}
      loading={isFetching}
    >
      <LoadingCard loading={isLoading}>
        <Card.Title title={asset?.assetCode} subtitle={asset?.assetDescription} />
        <Card.Content>
          <CheckboxControl
            control={form.control}
            title='Have all checks been completed?'
            name='isChecksCompleted'
          />
          <CheckboxControl
            disabled={isQrScanned || !asset?.assetQRCode}
            control={form.control}
            onChange={handleOnQrScannedChecked}
            title='Has the asset been scanned for QR code?'
            name='isQRScanned'
          />
          <CheckboxControl
            control={form.control}
            disabled={asset?.isFollowUpRequired ?? false}
            title='Will this asset require a follow up?'
            name='isFollowUpRequired'
          />
          <TextControl
            control={form.control}
            title='Log Book Entry'
            name='comments'
          />
        </Card.Content>
        <Card.Actions>
          <Button mode='elevated' onPress={handleOnSubmit}>Save</Button>
        </Card.Actions>
      </LoadingCard>

      <FollowUpWorkOrderModal
        parentWorkOrderId={workOrderId}
        onSuccess={handleWorkOrderCreation}
        assetId={asset?.assetId}
        assetLabel={asset?.assetDescription}
        visible={workOrderModalVisible}
        onClose={() => setWorkOrderModalVisible(false)}
      />
    </Skeleton>
  );
}
