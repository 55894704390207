import { useIsFocused } from "@react-navigation/core";
import React, { useEffect, useState } from "react";
import { ScrollView, View, StyleSheet, Text, TextInput, TouchableOpacity } from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import { Ionicons, SimpleLineIcons, Entypo, MaterialCommunityIcons } from "@expo/vector-icons";
import { DataTable } from 'react-native-paper';
import Spinner from 'react-native-loading-spinner-overlay';
import { PagedTableDto } from "../../../../store/services/api/base-api";
import { assetTypeApi, useDeleteAssetTypeMutation } from "../../../../store/services/api/asset-type-api";
import PaginationComponent from "../../components/PaginationComponent";
import { DataTableHeader } from "../../components/DataTableHeader";
import { SubAssetTypesList } from "../SubAssetTypes/SubAssetTypesList";
import DataTableCell from "../../components/DataTableCell";

export default function AssetsListScreen({ navigation }: { navigation: any }) {
    const isFocused = useIsFocused();
    const [loading, setLoading] = useState<any>(false);
    const [trigger, assetTypesResult] = assetTypeApi.useLazyGetAssetTypesQuery();
    const [page, setPage] = React.useState<number>(0);
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
    const [sortedColumn, setSortedColumn] = React.useState<string>("name");
    const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
    const [deleteAssetType, deleteAssetTypeResult] = useDeleteAssetTypeMutation();

    const [selectedAssetType, setSelectedAssetType] = useState<any>(null);
    const [name, setName] = useState<any>("");

    useEffect(() => {
        loadTable();
    }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage])

    const resetFilters = () => {
        setSelectedAssetType(null);
        setName("");
    }

    const loadTable = () => {
        var dto = new PagedTableDto(page, {
            name: name
        }, sortedColumn, sortedColumnAscending);
        trigger(dto).then(() => {
            setLoading(false);
        });
    }

    const markAssetTypeDeleted = async (assetType) => {
        if (confirm("Are you sure you want to delete this asset type?")) {
            if (assetType.assetCount !== 0) {
                alert(`Can't delete asset type, there are ${assetType.assetCount} assets associated with it`)
                return;
            }
            var result = await deleteAssetType(assetType.id) as any;

            if (result.error) {
                alert("Error deleting asset type. Please try again.")
                return;
            }

            loadTable();
        }
    }

    return (
        <ScrollView style={GlobalStyle.container}>
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                textStyle={GlobalStyle.spinnerTextStyle}
            />
            <View style={GlobalStyle.filters}>
                <View style={GlobalStyle.filters__header}>
                    <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
                </View>
                <View style={GlobalStyle.filter__columns}>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Name</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChangeText={setName}
                            value={name}
                        />
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                                <SimpleLineIcons name="magnifier" size={16} color="white" />
                            </TouchableOpacity>

                            <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                                <SimpleLineIcons name="ban" size={16} color="black" />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
            <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
                    <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Asset Type</Text>
                </View>
            </TouchableOpacity>

            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                    <DataTable style={GlobalStyle.table}>
                        <DataTableHeader
                            sortedColumn={sortedColumn}
                            sortedColumnAscending={sortedColumnAscending}
                            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
                            headers={[
                                { text: 'Name', sortedName: "name", visible: true },
                                { text: 'Options', visible: true },
                                { text: 'Sub Asset Type', visible: true },
                            ]}
                        />

                        {assetTypesResult.data?.list.map((element: any) => {
                            return (
                                <DataTable.Row key={element.id}>
                                    <DataTableCell>{element.name}</DataTableCell>
                                    <DataTableCell>
                                        <TouchableOpacity onPress={() => navigation.navigate("manage", { assetTypeId: element.id })}>
                                            <Entypo name="pencil" size={24} color="black" />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => markAssetTypeDeleted(element)}>
                                            <Ionicons name="ios-remove-circle" size={24} color="red" />
                                        </TouchableOpacity>
                                    </DataTableCell>
                                    <DataTableCell>
                                        <TouchableOpacity onPress={() => setSelectedAssetType(element)}>
                                            <MaterialCommunityIcons name="view-list-outline" size={24} color="black" />
                                        </TouchableOpacity>
                                    </DataTableCell>
                                </DataTable.Row>
                            )
                        })}
                    </DataTable>

                    <PaginationComponent
                        numberOfItemsPerPage={numberOfItemsPerPage}
                        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
                        page={page}
                        setPage={setPage}
                        fetchResult={assetTypesResult}
                        setLoading={setLoading}
                    />
                </View>
                {selectedAssetType &&
                    <View style={{ width: 450, marginLeft: 20 }}>
                        <SubAssetTypesList assetType={selectedAssetType} onClear={() => setSelectedAssetType(null)} />
                    </View>
                }
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    }

});
