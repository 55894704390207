import {FlatList, ListRenderItem, ListRenderItemInfo, StyleSheet, TouchableOpacity, View} from "react-native";
import React, {ReactElement} from "react";
import {EmptyList} from "../../../../components/flatlist/EmptyList";


interface CoreListProps<T> {
  data: T[];
  renderItem: ListRenderItem<T>;
  isLoading?: boolean;
  onItemPress?: (item: ListRenderItemInfo<T>) => void;
  itemWrapper?: (children: ReactElement) => ReactElement;
  emptyListText?: string;
  keyExtractor?: (item: T, index: number) => string;
  horizontal?: boolean;
}

const defaultWrapper = (children: ReactElement) => (
  <View style={styles.row}>{children}</View>
);

export function CoreList<T>({renderItem, data, isLoading = false, onItemPress, emptyListText, keyExtractor, itemWrapper = defaultWrapper, horizontal = false}: CoreListProps<T>) {
  const customRenderItem = (info: ListRenderItemInfo<T>) => {
    const marginStyle = {
      [horizontal ? 'marginHorizontal' : 'marginVertical']: 4
    };

    const itemContent = (
      <TouchableOpacity onPress={() => onItemPress?.(info)} style={marginStyle}>
        {renderItem ? renderItem(info) : null}
      </TouchableOpacity>
    );

    if (itemWrapper) return itemWrapper(itemContent)
    return itemContent;
  };

  const previewData = isLoading ? [] : data;

  return (
    <FlatList
      horizontal={horizontal}
      keyExtractor={keyExtractor}
      data={previewData}
      renderItem={customRenderItem}
      ListEmptyComponent={
        <EmptyList
          isLoading={isLoading}
          emptyText={emptyListText ?? 'There are no results'} />
      }
    />
  );
}

const styles = StyleSheet.create({
  row: {
    margin: 4,
    borderRadius: 4,
    backgroundColor: '#fff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5
  }
})
