import useFilters, {BaseFilters} from "../../hooks/useFilters";
import {useLazyExportAssetsListQuery, useLazyGetAssetsListQuery} from "../../../../store/services/api/asset-api";
import {WebScreen} from "../../components/WebScreen";
import {
  DropdownFilter,
  SearchFilterRow,
  SearchFilters,
  TextInputFilter
} from "../../components/search-filters/SearchFilters";
import {Picker} from "@react-native-picker/picker";
import React, {useEffect, useState} from "react";
import {useGetAllBuildingsQuery, useGetAllFloorsQuery} from "../../../../store/services/api/building-api";
import {useGetAllAssetTypesQuery, useGetAllSubAssetTypesQuery} from "../../../../store/services/api/asset-type-api";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import {Entypo, FontAwesome, Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";
import {CoreTable} from "../../components/core-table/CoreTable";
import {DataTable} from "react-native-paper";
import AssetImageModal from "../../components/AssetImageModal";
import AssetTicketModal from "../../components/AssetTicketModal";
import {useToast} from "../../../../components/toast/ToastProvider";
import {convertBase64toBlob} from "../../../../utils/helpers";
import {useSetLoading} from "../../../../hooks/useSetLoading";

interface AssetListFilters extends BaseFilters {
  buildingId: string;
  floorId: string;
  assetTypeId: string;
  subAssetTypeId: string;
  description: string;
  code: string;
  uniqueReference: string;
  ticketStatus: number;
}

export function AssetListScreen({navigation}) {
  const [getAssetsList, {data: assetsListResult, isFetching: assetsFetching, isError: listError}] = useLazyGetAssetsListQuery();
  const [exportAssetsList, {data: exportResult, isFetching: exportFetching, isError: exportError}] = useLazyExportAssetsListQuery();
  const loadTable = (filters?: Record<string, any>) => getAssetsList(filters ?? getFilterValues());
  const exportTable = (filters?: Record<string, any>) => exportAssetsList(filters ?? getFilterValues());

  const { data: buildings, isFetching: buildingsFetching } = useGetAllBuildingsQuery(null);
  const { data: floors, isFetching: floorsFetching } = useGetAllFloorsQuery(null);
  const { data: assetTypes, isFetching: assetTypesFetching } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes, isFetching: subAssetTypesFetching } = useGetAllSubAssetTypesQuery(null);
  const filterDataFetching = buildingsFetching || floorsFetching || assetTypesFetching || subAssetTypesFetching

  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [selectedImageUri, setSelectedImageUri] = useState<string | null>(null);
  const [isTicketModalVisible, setIsTicketModalVisible] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);

  const {show: showToast} = useToast();
  useSetLoading([filterDataFetching, exportFetching]);

  const filter = useFilters<AssetListFilters>({
    buildingId: "",
    floorId: "",
    assetTypeId: "",
    subAssetTypeId: "",
    description: "",
    code: "",
    uniqueReference: "",
    ticketStatus: -1,
    sortedColumn: "",
    sortedColumnAscending: false,
    pageNumber: 1,
    pageSize: 10
  }, loadTable);
  const {getFilterValues, filters, setFilter} = filter;

  useEffect(() => {
    loadTable();
  }, []);

  useEffect(() => {
    if (exportResult) {
      let blob = convertBase64toBlob(exportResult.base64, "application/vnd.ms-excel");
      const fileSaver = require('file-saver');
      fileSaver(blob, "report.xlsx");
    }
  }, [exportResult]);

  useEffect(() => {
    if (listError) {
      showToast('Error getting table', 'There was an error getting asset data', 'error');
    }
    if (exportError) {
      showToast('Error exporting table', 'There was an error getting asset export data', 'error');
    }
  }, [listError, exportError]);

  const handleImageClick = (imageUri: string) => {
    setSelectedImageUri(imageUri);
    setIsImageModalVisible(true);
  };

  const handleTicketClick = (assetId) => {
    setSelectedAssetId(assetId);
    setIsTicketModalVisible(true);
  }

  return (
    <WebScreen>
      <SearchFilters filter={filter} onSubmit={() => loadTable()}>
        <SearchFilterRow>
          <DropdownFilter title='Building' name='buildingId' >
            <Picker.Item label='All' value='' />
            {(buildings ?? []).map(item =>
              <Picker.Item key={item.id} label={item.name} value={item.id} />
            )}
          </DropdownFilter>
          <DropdownFilter title='Floor' name='floorId' >
            <Picker.Item label='All' value='' />
            {(floors ?? []).map(item =>
              <Picker.Item key={item.id} label={item.name} value={item.id} />
            )}
          </DropdownFilter>
          <DropdownFilter title='Asset Type' name='assetTypeId' >
            <Picker.Item label='All' value='' />
            {(assetTypes ?? []).map(item =>
              <Picker.Item key={item.id} label={item.name} value={item.id} />
            )}
          </DropdownFilter>
          <DropdownFilter title='Sub Asset Type' name='subAssetTypeId' >
            <Picker.Item label='All' value='' />
            {(subAssetTypes ?? []).map(item =>
              <Picker.Item key={item.id} label={item.name} value={item.id} />
            )}
          </DropdownFilter>
        </SearchFilterRow>
        <SearchFilterRow>
          <DropdownFilter title='Ticket Status' name='ticketStatus'>
            <Picker.Item label="All" value={-1}/>
            <Picker.Item label="Outstanding Ticket" value={1}/>
          </DropdownFilter>
          <TextInputFilter title='Code' name='code' />
          <TextInputFilter title='Description' name='description' />
          <TextInputFilter title='CoreRFID QR' name='uniqueReference' />
        </SearchFilterRow>
      </SearchFilters>

      {assetsListResult?.list.length > 0 &&
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity style={{ marginRight: 20 }} onPress={() => exportTable()}>
            <Text style={GlobalStyle.add__new__link}>Export</Text>
          </TouchableOpacity>
        </View>
      }

      <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
          <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Asset</Text>
        </View>
      </TouchableOpacity>

      <CoreTable
        filters={filters}
        setFilter={setFilter}
        isLoading={assetsFetching}
        data={assetsListResult}
        headers={[
          { text: 'Building', sortedName: "buildingName", visible: true },
          { text: 'Floor', sortedName: "floorName", visible: true, flex: 0.5 },
          { text: 'Specific Location', sortedName: "locationName", visible: true },
          { text: 'Asset Type', sortedName: "assetTypeName", visible: true, flex: 0.5 },
          { text: 'Sub Asset Type', sortedName: "subAssetTypeName", visible: true, flex: 0.5 },
          { text: 'Description', sortedName: "description", visible: true },
          { text: 'Code', sortedName: "code", visible: true },
          { text: 'CoreRFID QR', sortedName: "uniqueReference", visible: true },
          { text: 'Tickets', visible: true, flex: 0.5 },
          { text: 'Photo', visible: true, flex: 0.5 },
          { text: 'Options', visible: true, flex: 0.5 },
        ]}
        renderItem={({item}) => (
          <DataTable.Row key={item.id}>
            <DataTable.Cell>{item.buildingName}</DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>{item.floorName}</DataTable.Cell>
            <DataTable.Cell>{item.locationName}</DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>{item.assetTypeName}</DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>{item.subAssetTypeName}</DataTable.Cell>
            <DataTable.Cell>{item.description}</DataTable.Cell>
            <DataTable.Cell>{item.code}</DataTable.Cell>
            <DataTable.Cell>{item.uniqueReference}</DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>
              <TouchableOpacity style={styles.ticketButton} onPress={() => handleTicketClick(item.id)}>
                <MaterialCommunityIcons name="ticket-outline" size={18} color="white" />
                <Text style={styles.ticketButtonText}>{item.ticketCount}</Text>
              </TouchableOpacity>
            </DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>
              {item.imageUri != null &&
                <TouchableOpacity onPress={() => handleImageClick(item.imageUri)}>
                  <FontAwesome name="camera" size={24} color="black" />
                </TouchableOpacity>
              }
            </DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>
              <TouchableOpacity onPress={() => navigation.navigate("manage", { assetId: item.id })}>
                <Entypo name="pencil" size={24} color="black" />
              </TouchableOpacity>
            </DataTable.Cell>
          </DataTable.Row>
        )}
      />

      {isImageModalVisible &&
        <AssetImageModal
          imageUri={selectedImageUri}
          onClose={() => setIsImageModalVisible(false)}
        />
      }

      {isTicketModalVisible &&
        <AssetTicketModal
          assetId={selectedAssetId}
          onClose={() => setIsTicketModalVisible(false)}
        />
      }
    </WebScreen>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  ticketButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#007AFF',
    borderRadius: 15,
    paddingVertical: 5,
    paddingHorizontal: 10,
    justifyContent: 'center',
  },

  ticketButtonText: {
    color: 'white',
    marginLeft: 5,
    fontWeight: 'bold',
  },
});
