import React, {ReactNode, useContext} from "react";
import {View, Text, StyleSheet, ScrollView} from "react-native";
import {Button} from "react-native-paper";
import {FilterContext} from "../../web/components/search-filters/SearchFilters";

interface FilterContextProps {
  getFilter: (name: string) => any;
  setFilter: (name: string, value: any) => void;
  resetFilters: () => void;
}

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterContext must be used within a FilterProvider");
  }
  return context;
};


export interface CoreFiltersBaseProps {
  filter: FilterContextProps;
  onSubmit: () => void;
}

interface CoreFiltersProps extends CoreFiltersBaseProps {
  onResetFilters?: () => void;
  children: ReactNode;
}

export function CoreFilters({filter, onSubmit, onResetFilters, children}: CoreFiltersProps) {
  const handleOnResetFilters = () => {
    onResetFilters?.();
    filter.resetFilters();
  }

  return (
    <FilterContext.Provider value={filter}>
      <View style={styles.container}>
        <Text style={{fontSize: 18, fontWeight: '500'}}>Filters</Text>
        <ScrollView style={styles.filtersContainer}>
          {children}
        </ScrollView>
        <View style={styles.actions}>
          <Button mode="elevated" onPress={handleOnResetFilters} buttonColor='#fff' textColor='#0f0f0f'>
            Reset
          </Button>
          <Button mode="elevated" onPress={onSubmit} buttonColor='#fff' textColor='#0f0f0f'>
            Submit
          </Button>
        </View>
      </View>
    </FilterContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: '#fff',
    padding: 18,
    borderWidth: 1,
    borderColor: '#CECECE',
    borderRadius: 6,
    flexShrink: 1,
    flex: 1
  },
  filtersContainer: {
    flex: 1,
    minWidth: 248
  },
  filterActions: {
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  filterDropdown: {
    flex: 1,
  },
  actions: {
    display: "flex",
    gap: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 10,
  },
  filterRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
  },
});
