import * as ImageManipulator from 'expo-image-manipulator';

export enum PlatformType {
  Web = 'web',
  Mobile = 'mobile'
}

const compressionConfig = {
  [PlatformType.Web]: { compress: 0.5 },
  [PlatformType.Mobile]: { compress: 0.2 }
};

export const compressImage = async (base64: string, platform: PlatformType = PlatformType.Mobile) => {
  const { compress } = compressionConfig[platform];
  const compressedImage = await ImageManipulator.manipulateAsync(
    base64,
    [],
    { compress, format: ImageManipulator.SaveFormat.JPEG, base64: true }
  );

  return compressedImage.uri;
};

export const base64toFile = (base64String: string, fileName: string): File => {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1] || '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, {type: mime});
};
