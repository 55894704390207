import React from "react";
import TicketsListScreen from "./screens/list/TicketsListScreen";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";

const Stack = createNativeStackNavigator<TicketsStackParamList>();

export default function TicketsStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='list'
        component={TicketsListScreen}
        options={{headerShown: false}}
      />
    </Stack.Navigator>
  );
};

export type TicketsStackParamList = ParamListBase & {
  list: undefined;
};
