import { useEffect } from 'react';
import { usePage } from './usePage';

/**
 * A custom hook to automatically manage loading state.
 * @param watchValues Array of boolean values to monitor.
 */
export function useSetLoading(watchValues: boolean[]) {
  const { setIsLoading } = usePage();

  useEffect(() => {
    const loading = watchValues.some((val) => val);
    setIsLoading(loading);

    return () => {
      setIsLoading(false);
    };
  }, [watchValues, setIsLoading]);
}
