import MobilePageLayout from "../../../layouts/MobilePageLayout";
import {useSetLoading} from "../../../../../hooks/useSetLoading";
import {useGetOpenTicketsByAssetQuery} from "../../../../../store/services/api/ticket-api";
import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AssetStackParamList} from "../AssetStack";
import {FlatList, View} from "react-native";
import Descriptions from "../../../../web-v2/components/Descriptions";

export default function AssetOpenTicketsScreen({route, navigation}: ScreenProps<AssetStackParamList, 'openTickets'>) {
  const assetId = route?.params?.assetId;
  const { data: tickets, isFetching: ticketsFetching } = useGetOpenTicketsByAssetQuery(assetId);

  useSetLoading([ticketsFetching]);

  return (
    <MobilePageLayout>
      <FlatList
        ItemSeparatorComponent={() => <View style={{height: 4}}/>}
        data={tickets ?? []}
        keyExtractor={(item) => item.id}
        renderItem={({item}) => (
          <Descriptions
            data={item}
            columns={[
              {title: 'Ticket Number', key: 'number'},
              {title: 'Issue', key: 'description'},
              {title: 'Latest Update', key: 'latestUpdate'},
            ]}
          />
        )}
      />
    </MobilePageLayout>
  )
}
