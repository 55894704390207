import {useSelector} from "react-redux";
import {RootState} from "../../store";
import React, {useEffect} from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import TechnicianWorkOrdersStack from "./screens/work-orders/WorkOrdersStack";
import {TechnicianWorkOrdersList} from "./screens/work-orders-list/TechnicianWorkOrdersList";
import {ParamListBase} from "@react-navigation/native";

const Stack = createNativeStackNavigator<TechnicianNavigatorParamList>();

export default function TechnicianNavigator({ navigation }: { navigation: any }) {
  const {user} = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user == null || user.role !== 'Technician') {
      navigation.navigate("login")
    }
  }, [user]);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="workOrdersList"
        component={TechnicianWorkOrdersList}
        options={{
          headerShown: true,
          title: 'Work Orders'
        }}
      />
      <Stack.Screen
        name="workOrders"
        component={TechnicianWorkOrdersStack}
        options={{
          headerShown: false
        }}
      />
    </Stack.Navigator>
  )
}

export type TechnicianNavigatorParamList = ParamListBase & {
  workOrdersList: undefined;
  workOrders: { id: string; };
};

