import React from "react";
import {ScreenProps} from "../../../../../../../utils/screen-props-helper";
import WebPageLayout from "../../../../../layouts/WebPageLayout";
import TablePageLayout from "../../../../../layouts/TablePageLayout";
import {Button, DataTable} from "react-native-paper";
import {Entypo, Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";
import {BaseFilters} from "../../../../../../web/hooks/useFilters";
import {CoreTable} from "../../../../../components/CoreTable";
import {TouchableOpacity} from "react-native";
import SystemBuildingsScreenFilter from "./components/SystemBuildingsScreenFilter";
import {getSystemScreenHeaders} from "../../../system-screen-headers";
import {
  useDeleteBuildingMutation,
  useLazyGetBuildingsQuery
} from "../../../../../../../store/services/api/building-api";
import useEntityListPage from "../../../../../hooks/useEntityListPage";
import {SystemBuildingsStackParamList} from "../../SystemBuildingsStack";

interface BuildingListFilters extends BaseFilters {
  name: string;
}

export default function SystemBuildingsListScreen({navigation}: ScreenProps<SystemBuildingsStackParamList, 'list'>) {
  const {
    navigateToCreate,
    navigateToEdit,
    deleteEntity,
    onSubmitFilter,
    filter,
    tableData,
    isLoading
  } = useEntityListPage<BuildingListFilters>({
    entityName: "Building",
    lazyTableQuery: useLazyGetBuildingsQuery,
    deleteEntityMutation: useDeleteBuildingMutation,
    filterDefinition: {
      name: "",
      sortedColumn: "",
      sortedColumnAscending: false,
      pageNumber: 1,
      pageSize: 10
    },
  });

  const handleOnDeleteEntity = async (data: any) => {
    await deleteEntity(
      data,
      (building) => building.assetCount === 0,
      `Can't delete Building, there are ${data.assetCount} assets associated with it`)
  }

  const navigateToFloors = (buildingId: string) => navigation.navigate('floors', {buildingId})

  return (
    <WebPageLayout header={getSystemScreenHeaders()}>
      <TablePageLayout
        filters={<SystemBuildingsScreenFilter filter={filter} onSubmit={onSubmitFilter} />}
        actions={(
          <>
            <Button icon='plus' mode="elevated" onPress={navigateToCreate} buttonColor='#fff' textColor='#0f0f0f'>
              Add Building
            </Button>
          </>
        )}
      >
        <CoreTable<any>
          isLoading={isLoading}
          headers={[
            { text: 'Name', sortedName: "name" },
            { text: 'Actions', flex: 0.5 },
            { text: 'Floors', flex: 0.5 },
          ]}
          data={tableData}
          keyExtractor={(item) => item.id}
          renderItem={({item}) => (
            <DataTable.Row key={item.id}>
              <DataTable.Cell>{item.name}</DataTable.Cell>
              <DataTable.Cell style={{flex: 0.5}}>
                <TouchableOpacity onPress={() => navigateToEdit(item.id)}>
                  <Entypo name="pencil" size={24} color="black" />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleOnDeleteEntity(item)}>
                  <Ionicons name="ios-remove-circle" size={24} color="red" />
                </TouchableOpacity>
              </DataTable.Cell>
              <DataTable.Cell style={{flex: 0.5}}>
                <TouchableOpacity onPress={() => navigateToFloors(item.id)}>
                  <MaterialCommunityIcons name="view-list-outline" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )}
          filters={filter.filters}
          setFilter={filter.setFilter}
        />
      </TablePageLayout>
    </WebPageLayout>
  )
}
