import React, {createContext, useContext, useState, ReactNode} from 'react';

interface PageContextProps {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

const PageContext = createContext<PageContextProps | undefined>(undefined);

interface PageProviderProps {
  children: ReactNode;
}

export const PageProvider: React.FC<PageProviderProps> = ({children}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSetLoading = (value: boolean) => {
    setIsLoading(value);
  }

  return (
    <PageContext.Provider value = {{isLoading, setIsLoading: handleSetLoading}}>
      {children}
    </PageContext.Provider>
  );
};

export const usePage = (): PageContextProps => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePage must be used within a PageProvider');
  }
  return context;
};
