import {getSystemScreenHeaders} from "../../../system-screen-headers";
import WebPageLayout from "../../../../../layouts/WebPageLayout";
import React from "react";
import ManagePageLayout from "../../../../../layouts/ManagePageLayout";
import SystemSettingsPinManageControl from "./components/SystemSettingsPinManageControl";
import {
  useCreatePinMutation, useGetPinQuery,
  useUpdatePinMutation
} from "../../../../../../../store/services/api/systemSettings-api";
import {usePage} from "../../../../../../../hooks/usePage";
import {useToast} from "../../../../../../../components/toast/ToastProvider";

export default function SystemSettingsPinScreen() {
  const {data: entityResult} = useGetPinQuery();
  const [createEntity] = useCreatePinMutation();
  const [updateEntity] = useUpdatePinMutation();

  const {setIsLoading} = usePage();
  const {show: showToast} = useToast();

  const onSubmit = async (data: any) => {
    const performAction = async () => {
      if (entityResult?.id != null) {
        return updateEntity(data).unwrap();
      }
      return createEntity(data).unwrap();
    }

    setIsLoading(true);
    try {
      const result = await performAction();

      if (result?.error) {
        showToast('Error', `There was an issue updating the Pin`, 'error');
        setIsLoading(false);
        return;
      }

      showToast(`Updated Pin`, `Successfully updated Pin`, 'success');
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <WebPageLayout header={getSystemScreenHeaders()}>
      <ManagePageLayout>
        <SystemSettingsPinManageControl pin={entityResult} onSubmit={onSubmit} />
      </ManagePageLayout>
    </WebPageLayout>
  )
}
