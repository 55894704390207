import React, {ReactNode} from "react";
import {StyleSheet, TouchableOpacity, View} from "react-native";

function HeaderButton({onPress, children}: {onPress: () => void, children: ReactNode}) {
  return (
    <TouchableOpacity
      style={style.button}
      onPress={onPress} >
      {children}
    </TouchableOpacity>
  )
}

function Container({children}: {children: ReactNode}) {
  return (
    <View style={style.container}>
      {children}
    </View>
  )
}

HeaderButton.Container = Container;
export {HeaderButton};

const style = StyleSheet.create({
  button: {
    display: 'flex',
    minWidth: 64,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4
  }
})
