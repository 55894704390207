import { baseApi } from './base-api'

export const systemSettingsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
      getPin: build.query<any, void>({
        query: () => ({
          url: `systemsettings/getpin`,
        }),
      }),
      createPin: build.mutation<any, any>({
        query: (body) => ({
          url: 'systemsettings/addpin',
          method: 'post',
          body: body
        }),
      }),
      updatePin: build.mutation<any, any>({
        query: (body) => ({
          url: `systemsettings/updatepin/${body.id}`,
          method: 'put',
          body: body
        }),
      }),
      validatePin: build.mutation<boolean, string>({
        query: (pin) => ({
          url: 'systemsettings/validatePin',
          method: 'POST',
          body: {
            pin
          }
        })
      })
    }),
    overrideExisting: true,
  })
  
  export const { 
    useGetPinQuery,
    useUpdatePinMutation,
    useCreatePinMutation,
    useValidatePinMutation
  } = systemSettingsApi
