import {HeaderButton} from "./header-button";
import {Feather} from "@expo/vector-icons";
import React from "react";


export function BackButton({onPress}: {onPress: () => void}) {
  return (
    <HeaderButton onPress={onPress}>
      <Feather name='chevron-left' size={24} />
    </HeaderButton>
  );
}
