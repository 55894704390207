import {Image, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import * as React from "react";
import {useForm} from "react-hook-form";
import {TextFieldController} from "../../components/forms/TextFieldController";
import CoreForm from "../../components/forms/CoreForm";
import {PickerFieldController} from "../../components/forms/PickerFieldController";
import {useEffect} from "react";
import {AssetManageForm} from "../../../../store/data/entities/asset";
import {
  useGetAllBuildingsQuery,
  useGetAllFloorsQuery,
  useGetAllLocationsQuery
} from "../../../../store/services/api/building-api";
import {useGetAllAssetTypesQuery, useGetAllSubAssetTypesQuery} from "../../../../store/services/api/asset-type-api";
import * as DocumentPicker from "expo-document-picker";
import {useSetLoading} from "../../../../hooks/useSetLoading";

interface AssetManageControlProps {
  onSubmit: (data: AssetManageForm) => void;
  initialValue?: any;
}

export default function AssetManageControl({onSubmit, initialValue}: AssetManageControlProps) {
  const { data: buildings, isFetching: buildingsFetching } = useGetAllBuildingsQuery(null);
  const { data: floors, isFetching: floorsFetching } = useGetAllFloorsQuery(null);
  const { data: locations, isFetching: locationsFetching } = useGetAllLocationsQuery(null);
  const { data: assetTypes, isFetching: assetTypesFetching } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes, isFetching: subAssetTypesFetching } = useGetAllSubAssetTypesQuery(null);
  const dataFetching = buildingsFetching || floorsFetching || locationsFetching || assetTypesFetching || subAssetTypesFetching;

  const form = useForm<AssetManageForm>({
    defaultValues: {
      id: undefined,
      buildingId: "",
      floorId: "",
      locationId: "",
      assetTypeId: "",
      subAssetTypeId: "",
      description: "",
      code: "",
      uniqueReference: "",
      imageBase64: "",
      troubleshootingGuide: "",
      priority: 1,
      supportingInformation: ""
    }
  });
  const {handleSubmit, setValue, watch} = form;
  let imageBase64 = watch("imageBase64");

  useEffect(() => {
    if (initialValue) {
      setValue("id", initialValue.id);
      setValue("buildingId", initialValue.buildingId);
      setValue("floorId", initialValue.floorId);
      setValue("locationId", initialValue.locationId);
      setValue("assetTypeId", initialValue.assetTypeId);
      setValue("subAssetTypeId", initialValue.subAssetTypeId);
      setValue("description", initialValue.description);
      setValue("code", initialValue.code);
      setValue("uniqueReference", initialValue.uniqueReference);
      setValue("troubleshootingGuide", initialValue.troubleshootingGuide);
      setValue("supportingInformation", initialValue.supportingInformation);
      setValue("priority", initialValue.priority);
    }
  }, [initialValue])

  useSetLoading([dataFetching]);

  const priorityOptions = [
    {label: "1", value: 1},
    {label: "2", value: 2},
    {label: "3", value: 3},
    {label: "4", value: 4},
    {label: "5", value: 5},
  ]

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const loadFile = async () => {
    var result = await DocumentPicker.getDocumentAsync({
      type: ".png"
    }) as any;

    if (result.assets.length == 0) return;
    const file = result.assets[0].file;

    var base64 = (await toBase64(file)) as string;

    setValue("imageBase64", base64);
  }

  return (
    <ScrollView>
      <CoreForm form={form} onSubmit={handleSubmit(onSubmit)}>
        <CoreForm.Row>
          <PickerFieldController
            controlName='buildingId'
            title='Building'
            required
            defaultFirst={false}
            items={(buildings ?? []).map(item => ({
              label: item.name,
              value: item.id
            }))} />
          <PickerFieldController
            controlName='floorId'
            title='Floor'
            required
            defaultFirst={false}
            items={(floors ?? []).map(item => ({
              label: item.name,
              value: item.id
            }))} />
          <PickerFieldController
            controlName='locationId'
            title='Location'
            required
            defaultFirst={false}
            items={(locations ?? []).map(item => ({
              label: item.name,
              value: item.id
            }))} />
        </CoreForm.Row>
        <CoreForm.Row>
          <PickerFieldController
            controlName='assetTypeId'
            title='Asset Type'
            required
            defaultFirst={false}
            items={(assetTypes ?? []).map(item => ({
              label: item.name,
              value: item.id
            }))} />
          <PickerFieldController
            controlName='subAssetTypeId'
            title='Sub Asset Type'
            required
            defaultFirst={false}
            items={(subAssetTypes ?? []).map(item => ({
              label: item.name,
              value: item.id
            }))} />
          <TextFieldController controlName='description' title='Description' />
        </CoreForm.Row>
        <CoreForm.Row>
          <TextFieldController required controlName='uniqueReference' title='CoreRFID QR' />
          <TextFieldController required controlName='code' title='Code' />
          <PickerFieldController
            controlName='priority'
            title='My Rating'
            required
            items={priorityOptions} />
        </CoreForm.Row>
        <CoreForm.Row>
          <TextFieldController
            style={{minHeight: 50}}
            multiline
            controlName='supportingInformation'
            title='Supporting Information' />
        </CoreForm.Row>
        <CoreForm.Row>
          <TextFieldController
            style={{minHeight: 100}}
            multiline
            controlName='troubleshootingGuide'
            title='Troubleshooting Guide' />
        </CoreForm.Row>
      </CoreForm>

      <View style={[GlobalStyle.form, styles.imageFormContainer]}>
        <View style={styles.imageContainer}>
          {imageBase64 ? (
            <Image
              style={{ width: 320, height: 320, marginBottom: 10, marginTop: 10 }}
              resizeMode={"contain"}
              source={{
                uri: imageBase64
              }}
            />
          ) : initialValue?.imageUri ? (
            <Image
              style={{ width: 320, height: 320, marginBottom: 10, marginTop: 10 }}
              resizeMode={"contain"}
              source={{
                uri: initialValue?.imageUri,
              }}
            />
          ) : null}
        </View>
        <TouchableOpacity style={[GlobalStyle.upload__button, { marginBottom: 0 }]} onPress={() => loadFile()}>
          <Text style={GlobalStyle.form__submit__button__text}>Upload Picture</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  imageFormContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: "column",
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: "row",
    minHeight: 250,
  }
})
