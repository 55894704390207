import {useForm} from "react-hook-form";
import {useEffect} from "react";
import * as React from "react";
import CoreForm from "../../../../../../../web/components/forms/CoreForm";
import {TextFieldController} from "../../../../../../../web/components/forms/TextFieldController";

export default function SystemSettingsPinManageControl({pin, onSubmit}) {
  const form = useForm({
    defaultValues: {
      id: null,
      value: ""
    }
  });

  useEffect(() => {
    if (pin) {
      form.setValue("id", pin.id);
      form.setValue("value", pin.value);
    }
  }, [pin]);

  const handleOnSubmit = () => {
    form.handleSubmit(onSubmit)();
  }

  return (
    <CoreForm form={form} onSubmit={handleOnSubmit}>
      <CoreForm.Row>
        <TextFieldController controlName='value' title='Admin Pin' required />
      </CoreForm.Row>
    </CoreForm>
  )
}
