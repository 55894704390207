import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import SystemUsersListScreen from "./screens/list/SystemUsersListScreen";
import SystemUsersManageScreen from "./screens/manage/SystemUsersManageScreen";

const Stack = createNativeStackNavigator<SystemUsersStackParamList>();

export default function SystemUsersStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='list'
        component={SystemUsersListScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='manage'
        component={SystemUsersManageScreen}
        options={{headerShown: false}}
      />
    </Stack.Navigator>
  );
};

export const systemUsersLinkingOptions = {
  path: 'users',
  screens: {
    list: 'list',
    manage: 'manage'
  },
}

export type SystemUsersStackParamList = ParamListBase & {
  list: undefined;
  manage: {id: string};
};
