import React, {useMemo} from "react";
import {List, Modal, Skeleton} from 'antd';
import {WorkOrder} from "../../../../../../store/data/entities/work-order";

interface WorkOrderAssetLocationsModalProps {
  onClose: () => void;
  visible: boolean;
  workOrder: WorkOrder | undefined;
}

export default function WorkOrderAssetLocationsModal(props: WorkOrderAssetLocationsModalProps) {

  const sortedLocations = useMemo(() => {
    if (!props?.workOrder?.assetLocations) return [];

    return [...props.workOrder.assetLocations].sort((a, b) => {
      const buildingComparison = a.building.localeCompare(b.building);
      if (buildingComparison !== 0) {
        return buildingComparison;
      }

      const floorComparison = a.floor.localeCompare(b.floor);
      if (floorComparison !== 0) {
        return floorComparison;
      }

      return a.location.localeCompare(b.location);
    });
  }, [props.workOrder])


  return (
    <Modal
      centered
      title="Locations"
      open={props.visible}
      onCancel={props.onClose}
    >
      <Skeleton
        active
        loading={!props.workOrder}
      >
        <List
          style={{maxHeight: '50dvh', overflowY: 'auto'}}
          dataSource={sortedLocations}
          renderItem={(item, index) => (
            <List.Item key={`${item.building}_${item.floor}_${item.location}_${index}`}>
              {`${item.building}, ${item.floor}, ${item.location}`}
            </List.Item>
          )}
        />
      </Skeleton>
    </Modal>
  )
}
