import {TechnicianWorkOrdersStackParamList, useWorkOrder} from "../../WorkOrdersStack";
import {useLazyGetWorkOrderQuery} from "../../../../../../store/services/api/work-order-api";
import React, {useEffect, useState} from "react";
import Skeleton from "../../../../../../components/skeletons/Skeleton";
import {Button, Card, Text} from "react-native-paper";
import {WorkOrderStatus, WorkOrderType} from "../../../../../../store/data/entities/work-order";
import WorkOrderSetStatusModal from "../../../../modals/WorkOrderSetStatusModal";
import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {Descriptions, Flex} from "antd";
import {humanizeDate, humanizeEnum} from "../../../../../../utils/humanizers";
import {ScrollView} from "react-native";
import {QuestionCircleOutlined} from "@ant-design/icons";
import WorkOrderAssetLocationsModal from "./WorkOrderAssetLocationsModal";

export default function TechnicianWorkOrderDetails({navigation}: ScreenProps<TechnicianWorkOrdersStackParamList, 'details'>) {
  const [getWorkOrder, {data, isFetching}] = useLazyGetWorkOrderQuery();
  const [completionModalVisible, setCompletionModalVisible] = useState(false);
  const [locationsModalVisible, setLocationsModalVisible] = useState(false);
  const {id, setWorkOrderType, setWorkOrderStatus} = useWorkOrder();

  useEffect(() => {
    if (id) {
      getWorkOrder(id)
        .unwrap()
        .then(data => {
          setWorkOrderType(data.type);
          setWorkOrderStatus(data.status);
        });
    }
  }, [id]);

  const nextStatus = data?.status === WorkOrderStatus.Approved ? WorkOrderStatus.InProgress
    : data?.status === WorkOrderStatus.InProgress ? WorkOrderStatus.Completed : undefined;

  const nextStatusOption = nextStatus === WorkOrderStatus.InProgress ? 'Begin Job'
    : nextStatus === WorkOrderStatus.Completed ? 'Complete Job' : undefined;

  return (
    <Skeleton
      loading={isFetching}
      placeholder={<Skeleton.Card />}
    >
      <ScrollView>
        <Card style={{backgroundColor: 'white', margin: 4}}>
          <Card.Content>
            <Descriptions layout="horizontal" bordered size="small">
              {data?.parentWorkOrderId && (
                <Descriptions.Item label="Parent WO Reference" span={'filled' as any}>{data?.parentWorkOrderReference}</Descriptions.Item>
              )}
              <Descriptions.Item span={'filled' as any} label="Work Order">{data?.reference}</Descriptions.Item>
              <Descriptions.Item span={'filled' as any} label="Description">{data?.description}</Descriptions.Item>
                <Descriptions.Item
                  span={'filled' as any}
                  label={(
                    <Flex justify='flex-start' align='center' gap={4} onClick={() => setLocationsModalVisible(true)}>
                      Location
                      <QuestionCircleOutlined />
                    </Flex>
                  )}
                >
                  {data?.locationLabel}
                </Descriptions.Item>
              <Descriptions.Item span={'filled' as any} label="Scheduled Start">{humanizeDate(data?.scheduledStartDate)}</Descriptions.Item>
              <Descriptions.Item span={'filled' as any} label="Scheduled Finish">{data?.scheduledFinishDate ? humanizeDate(data?.scheduledFinishDate) : 'N/A'}</Descriptions.Item>
              <Descriptions.Item span={'filled' as any} label="Assigned To">{data?.technician}</Descriptions.Item>
              <Descriptions.Item span={'filled' as any} label="Status">{humanizeEnum(WorkOrderStatus[data?.status])}</Descriptions.Item>
              <Descriptions.Item span={'filled' as any} label="WO Type">{humanizeEnum(WorkOrderType[data?.type])}</Descriptions.Item>
            </Descriptions>
          </Card.Content>
        </Card>
        {data?.reopenedOn && (
          <Card style={{backgroundColor: 'white', margin: 4}}>
            <Card.Title title='Work Reopened' />
            <Card.Content>
              <Text variant='bodyMedium'>Reason: {data?.reopenedReason || 'No reason provided'}</Text>
            </Card.Content>
          </Card>
        )}
        {(nextStatus === WorkOrderStatus.InProgress || nextStatus == WorkOrderStatus.Completed) && (
          <Button
            style={{marginTop: 6, marginHorizontal: 4}}
            mode="elevated"
            onPress={() => setCompletionModalVisible(true)}
            buttonColor='#fff'
            textColor='#0f0f0f'
          >
            {nextStatusOption}
          </Button>
        )}
      </ScrollView>

      <WorkOrderSetStatusModal
        workOrderId={id}
        visible={completionModalVisible}
        targetStatus={nextStatus}
        onClose={() => setCompletionModalVisible(false)}
        onSuccess={(status) => {
          setWorkOrderStatus(status);
          if (status === WorkOrderStatus.Completed) navigation.navigate('workOrders')
        }}
      />

      <WorkOrderAssetLocationsModal
        onClose={() => setLocationsModalVisible(false)}
        visible={locationsModalVisible}
        workOrder={data}
      />
    </Skeleton>
  );
}
