import useEntityManagePage from "../../../../hooks/useEntityManagePage";
import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {JobPlansStackParamList} from "../../JobPlansStack";
import {
  useCreateJobPlanMutation,
  useLazyGetJobPlanQuery,
  useUpdateJobPlanMutation
} from "../../../../../../store/services/api/job-plan-api";
import WebPageLayout from "../../../../layouts/WebPageLayout";
import {JobPlan} from "../../../../../../store/data/entities/job-plan";
import JobPlanManageForm from "./JobPlanManageForm";
import {useModal} from "../../../../../../hooks/useModal";
import * as React from "react";
import JobPlanApprovalModal from "../../../../../../components/modals/JobPlanApprovalModal";

export default function JobPlanManageScreen({route, navigation}: ScreenProps<JobPlansStackParamList, 'manage'>) {
  const id = route.params?.id;
  const isApproval = route.params?.approval ?? false;

  const {
    entity,
    onSubmit
  } = useEntityManagePage<JobPlan>({
    entityName: "Job Plan",
    entityId: id,
    createMutation: useCreateJobPlanMutation,
    updateMutation: useUpdateJobPlanMutation,
    lazyGetEntityQuery: useLazyGetJobPlanQuery
  })

  const {showModal, hideModal} = useModal();

  const handleOnApprove = (approve: boolean) => {
    showModal(
      <JobPlanApprovalModal
        id={id}
        isApproved={approve}
        onClose={hideModal}
        onSuccess={() => {
          hideModal();
          navigation.navigate("list");
        }}
      />
    )
  }

  return (
    <WebPageLayout header={{
      previousTab: {title: 'Job Plans', routeName: 'list'},
      tabs: [
        {title: 'Manage', routeName: 'manage', params: {id}}
      ]
    }}>
      <JobPlanManageForm
        isApproval={isApproval}
        onSubmit={onSubmit}
        onApproval={handleOnApprove}
        jobPlan={entity}
      />
    </WebPageLayout>
  )
}
