import { baseApi } from './base-api'
import {UserManageForm} from "../../data/users/user-manage-form";
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {User} from "../../data/entities/user";
import {UserRole} from "../../data/users/user-roles";

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<PagedResponse<User>, Record<string, any>>({
      query: (params) => ({
        url: `users/getUsers`,
        params: params
      }),
      transformResponse: getPagedResponse
    }),
    getUsersOfRole: build.query<User[], UserRole[]>({
      query: (roles) => {
        const params = new URLSearchParams();
        params.append('pageNumber', '1');
        params.append('pageSize', '1000');
        params.append('sortedColumn', 'name');

        roles.forEach(role => {
          params.append('roles', role.toString());
        });

        return {
          url: `users/getUsers`,
          params: params
        }
      }
    }),
    getUser: build.query<User, string>({
      query: (userId) => ({
        url: `users/getuser/${userId}`,
      }),
    }),
    createUser: build.mutation<any, UserManageForm>({
      query: (body) => ({
        url: 'users/adduser',
        method: 'post',
        body: body
      }),
    }),
    updateUser: build.mutation<any, UserManageForm>({
      query: (body) => ({
        url: `users/updateuser/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    deleteUser: build.mutation<any, string>({
      query: (userId) => ({
        url: `users/deleteuser/${userId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: true,
})

export const { 
  useLazyGetUsersQuery,
  useLazyGetUserQuery,
  useCreateUserMutation, 
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUsersOfRoleQuery
} = userApi
