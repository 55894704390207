import {useWorkOrder} from "../../WorkOrdersStack";
import React, {useEffect, useState} from "react";
import {FlatList, ListRenderItemInfo, View} from "react-native";
import {Button, Card, Text} from "react-native-paper";
import Skeleton from "../../../../../../components/skeletons/Skeleton";
import {
  useLazyGetWorkOrderLabourQuery
} from "../../../../../../store/services/api/work-order-api";
import {WorkOrderLabour} from "../../../../../../store/data/entities/work-order-labour";
import moment from "moment";
import AddWorkOrderLabourModal from "../../../../modals/AddWorkOrderLabourModal";
import useUser from "../../../../../../hooks/useUser";

export default function TechnicianLabour() {
  const [getLabour, {data, isFetching}] = useLazyGetWorkOrderLabourQuery();
  const [labourModalVisible, setLabourModalVisible] = useState(false);
  const {id} = useWorkOrder();
  const {user} = useUser();

  useEffect(() => {
    if (id) {
      getLabour(id);
    }
  }, [id]);

  const renderWorkLabour = ({item}: ListRenderItemInfo<WorkOrderLabour>) => {
    const getDateFormat = (value: string) => moment(value).format('Do MMMM YYYY');
    const getTimeFormat = (value: string) => moment(value).format('HH:mm');

    return (
      <Card style={{backgroundColor: 'white'}}>
        <Card.Title title={item.user} />
        <Card.Content>
          <Text variant='bodyMedium'>Date: {getDateFormat(item.date)}</Text>
          <Text variant='bodyMedium'>Start Time: {getTimeFormat(item.startDateTime)}</Text>
          <Text variant='bodyMedium'>End Time: {getTimeFormat(item.endDateTime)}</Text>
          <Text variant='bodyMedium'>Hours: {item.hoursWorked}</Text>
        </Card.Content>
      </Card>
    )
  }

  return (
    <Skeleton
      loading={isFetching}
      placeholder={<Skeleton.Card />}
    >
      <FlatList
        style={{paddingHorizontal: 4, paddingVertical: 6}}
        ItemSeparatorComponent={() => <View style={{height: 6}} />}
        data={data ?? []}
        renderItem={renderWorkLabour}
        ListFooterComponent={(
          <Button
            style={{marginTop: 6}}
            icon='plus'
            mode="elevated"
            onPress={() => setLabourModalVisible(true)}
            buttonColor='#fff'
            textColor='#0f0f0f'
          >
            Add Labour
          </Button>
        )}
      />

      <AddWorkOrderLabourModal
        technicianId={user.id}
        workOrderId={id}
        onClose={() => setLabourModalVisible(false)}
        visible={labourModalVisible}
      />
    </Skeleton>
  );
}
