import Table from "../../../../../../components/Table";
import {TouchableOpacity} from "react-native";
import {Entypo, Ionicons} from "@expo/vector-icons";
import React from "react";
import {usePage} from "../../../../../../../../hooks/usePage";
import {useToast} from "../../../../../../../../components/toast/ToastProvider";
import {useDeleteSubAssetTypeMutation} from "../../../../../../../../store/services/api/asset-type-api";

interface SystemSubAssetTypesListProps {
  data: any[],
  onSelected: (floor: any) => void;
  onDeleted: () => void;
}

export default function SystemSubAssetTypesList({data, onSelected, onDeleted}: SystemSubAssetTypesListProps) {
  const [deleteSubAssetType] = useDeleteSubAssetTypeMutation();

  const {setIsLoading} = usePage();
  const {show: showToast} = useToast();

  const handleOnDelete = async (id: string) => {
    if (!confirm(`Are you sure you want to delete this Sub Asset Type?`)) return;

    setIsLoading(true);
    try {
      const result = await deleteSubAssetType(id).unwrap();

      if (result?.error) {
        showToast('Error', `There was an issue deleting the Sub Asset Type`, 'error');
        setIsLoading(false);
        return;
      }

      showToast(`Deleted Sub Asset Type`, `Successfully deleted Sub Asset Type`, 'success');
      onDeleted();
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <Table
      columns={[
        {title: 'Name', key: 'name'},
        {
          title: 'Actions',
          render: (item) => (
            <>
              <TouchableOpacity onPress={() => onSelected(item)}>
                <Entypo name="pencil" size={24} color="black" />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => handleOnDelete(item.id)}>
                <Ionicons name="ios-remove-circle" size={24} color="red" />
              </TouchableOpacity>
            </>
          )
        }
      ]}
      data={data ?? []}
      keyExtractor={(item) => item.id}
    />
  )
}
