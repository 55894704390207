import GlobalStyle from "../../../constants/GlobalStyle";
import React from "react";
import {ActivityIndicator, ScrollView, StyleSheet, View} from "react-native";
import {usePage} from "../../../hooks/usePage";

export function WebScreen({children}) {
  const {isLoading} = usePage();

  return (
    <View style={[GlobalStyle.container, styles.pageContainer]}>
      <ScrollView>
        {children}
      </ScrollView>
      {isLoading && <WebLoadingIndicator />}
    </View>
  )
}

function WebLoadingIndicator() {
  return (
    <View style={styles.indicatorContainer}>
      <ActivityIndicator size={48} />
    </View>
  )
}

const styles = StyleSheet.create({
  indicatorContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  pageContainer: {
    position: 'relative'
  }
})
