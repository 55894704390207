import React, {createContext, useContext, useState, FC, ReactNode} from 'react';
import {
  Text,
  StyleSheet,
  Animated,
  Easing,
  View,
  Platform,
  TouchableOpacity,
  ViewStyle
} from 'react-native';
import {Feather} from "@expo/vector-icons";

interface ToastContextType {
  show: (title: string, message: string, type: ToastType) => void;
  hide: () => void;
}

type ToastType = 'warn' | 'success' | 'error' | 'info';

interface ToastState {
  title: string;
  message: string;
  visible: boolean;
  type: ToastType;
}

const ToastContext = createContext<ToastContextType>(null);

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [toast, setToast] = useState<ToastState>({ title: '', message: '', visible: false, type: 'success' });
  const [animation] = useState(new Animated.Value(0));
  const isWeb = Platform.OS === 'web';

  const show = (title: string, message: string, type: ToastType) => {
    setToast({ title, message, visible: true, type });
    Animated.timing(animation, {
      toValue: 1,
      duration: 150,
      useNativeDriver: true,
      easing: Easing.out(Easing.ease),
    }).start(() => {
      setTimeout(() => hide(), 3000);
    });
  };

  const hide = () => {
    Animated.timing(animation, {
      toValue: 0,
      duration: 150,
      useNativeDriver: true,
      easing: Easing.in(Easing.ease),
    }).start(() => {
      setToast({ ...toast, visible: false });
    });
  };

  const backgroundColor =
    toast.type === 'success' ? '#f6ffed' :
      toast.type === 'warn' ? '#fffbe6' :
        toast.type === 'error' ? '#fff2f0' : '#e6f4ff'

  const borderColor =
    toast.type === 'success' ? 'rgb(183, 235, 143)' :
      toast.type === 'warn' ? 'rgb(255, 229, 143)' :
        toast.type === 'error' ? 'rgb(255, 204, 199)' : 'rgb(145, 202, 255)'


  const toastStyle = {
    backgroundColor: backgroundColor,
    borderColor: borderColor,
    opacity: animation,
    transform: [
      {
        translateX: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [50, 0],
        }),
      },
    ]
  };


  const toastContainerStyle: ViewStyle = {
    justifyContent: isWeb ? 'flex-end' : 'center'
  };

  return (
    <ToastContext.Provider value={{ show, hide }}>
      {children}
      {toast.visible && (
        <View style={[styles.toastContainer, toastContainerStyle]}>
          <Animated.View style={[styles.toast, toastStyle]}>
            <View style={styles.toastInner}>
              <View style={styles.toastBody}>
                <Text style={styles.titleText}>{toast.title}</Text>
                <Text style={styles.text}>{toast.message}</Text>
              </View>
              <TouchableOpacity style={styles.closeButton} onPress={hide} aria-label="Close">
                <Feather name="x-circle" size={24} color="black" />
              </TouchableOpacity>
            </View>
          </Animated.View>
        </View>
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

const styles = StyleSheet.create({
  toastContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    bottom: 20,
    left: 0,
    right: 0,
    marginHorizontal: 20,
    padding: 10,
    borderRadius: 5,
    zIndex: 1000,
    elevation: 1000,
    overflow: 'hidden',
  },
  toast: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    flexBasis: 300,
    borderWidth: 1,
    padding: 10,
    borderRadius: 8,
    borderStyle: 'solid'
  },
  titleText: {
    color: 'rgba(0, 0, 0, 0.88)',
    textAlign: 'left',
    fontSize: 18,
    marginBottom: 4
  },
  text: {
    color: 'rgba(0, 0, 0, 0.88)',
    textAlign: 'left',
    fontSize: 14
  },
  toastInner: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  toastBody: {
    flex: 1,
  },
  closeButton: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
  },
  closeButtonText: {
    color: 'white',
    fontSize: 18,
    paddingHorizontal: 10,
  },
});
