import {useEffect} from "react";
import {TechnicianJobPlansStackParamList} from "./TechnicianJobPlansStack";
import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {useLazyGetJobPlanQuery} from "../../../../../../store/services/api/job-plan-api";
import {FlatList, ListRenderItemInfo, View} from "react-native";
import {JobPlanDescription} from "../../../../../../store/data/entities/job-plan";
import { Card, Text } from 'react-native-paper';
import Skeleton from "../../../../../../components/skeletons/Skeleton";
import { Image} from "antd";

export default function TechnicianJobPlanDetails({route}: ScreenProps<TechnicianJobPlansStackParamList, 'details'>) {
  const [getWorkOrder, {data, isFetching}] = useLazyGetJobPlanQuery();
  const id = route.params.id;

  useEffect(() => {
    if (id) {
      getWorkOrder(id);
    }
  }, [id]);

  const renderJobDescription = ({item, index}: ListRenderItemInfo<JobPlanDescription>) => {
    return (
      <Card style={{backgroundColor: 'white'}}>
        <Card.Title title={`Description ${index + 1}`} />
        <Card.Content>
          <Text variant='bodyMedium'>{item.description}</Text>
          <Image.PreviewGroup>
            <FlatList
              style={{paddingHorizontal: 4, paddingVertical: 6}}
              horizontal
              data={[...item.imageURIs] ?? []}
              renderItem={({item}) => (
                <Image
                  style={{paddingInline: 2}}
                  key={item}
                  src={item}
                  width={96}
                />
              )}
            />
          </Image.PreviewGroup>
        </Card.Content>
      </Card>
    )
  }

  return (
    <Skeleton
      loading={isFetching}
      placeholder={<Skeleton.Card />}
    >
      <FlatList
        style={{paddingHorizontal: 4, paddingVertical: 6}}
        ItemSeparatorComponent={() => <View style={{height: 6}} />}
        keyExtractor={item => item.id}
        data={data?.descriptions ?? []}
        renderItem={renderJobDescription}
      />
    </Skeleton>
  );
}
