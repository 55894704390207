import {Text} from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import {Controller, RegisterOptions} from "react-hook-form";
import * as React from "react";
import {useCoreForm} from "./CoreForm";
import {ComboPicker} from "../inputs/ComboPicker";

interface ComboPickerFieldController {
  rules?: RegisterOptions;
  controlName: string;
  title: string;
  options?: { key: string, value: string, label: string }[];
  optionsLoading?: boolean;
  onSearch: (value: string) => void;
  disabled?: boolean;
  debounceTime?: number;
  required?: boolean;
}

export function ComboPickerFieldController(
  {rules, controlName, title, options, onSearch, optionsLoading, required = false}: ComboPickerFieldController) {
  const {form: {control}} = useCoreForm();

  if (!control) return null;

  const extendedRules = {
    ...(rules ?? {}),
    required: required ? 'This field is required' : false
  };

  return (
    <Controller
      control={control}
      name={controlName}
      rules={extendedRules}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <>
          <Text style={GlobalStyle.form__column__text}>{title}</Text>
          <ComboPicker
            value={value}
            optionsLoading={optionsLoading}
            options={options}
            onSearch={onSearch}
            onValueChange={onChange}
            debounceTime={600}
          />
          {error && <Text style={{color: 'red'}}>{error.message}</Text>}
        </>
      )}
    />
  )
}
