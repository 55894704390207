import {StyleSheet, Text, View} from "react-native";
import {CoreModal, CoreModalProps} from "../../hooks/useModal";
import {ApprovalStatusForm} from "../../store/data/models/approval-status-form";
import {useForm} from "react-hook-form";
import {TextFieldController} from "../../screens/web/components/forms/TextFieldController";
import * as React from "react";
import CoreForm from "../../screens/web/components/forms/CoreForm";

export interface ApprovalModalProps {
  entityName: string;
  entityId: string;
  isApproved: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (status: ApprovalStatusForm) => void;
}

export function ApprovalModal({onClose, entityName, entityId, isApproved, onSubmit, loading}: ApprovalModalProps) {
  const form = useForm<ApprovalStatusForm>({
    defaultValues: {
      entityId,
      isApproved,
      rejectionReason: '',
    }
  })

  const handleOnFormSubmit = () => {
    form.handleSubmit(onSubmit)();
  }

  const action = isApproved ? 'Approve' : 'Reject';

  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Cancel', onPress: onClose, mode: 'text', disabled: loading},
    ],
    rightButtons: [
      {label: action, onPress: handleOnFormSubmit, mode: 'contained', disabled: loading},
    ]
  }

  return (
    <CoreModal {...modalProps}>
      <View style={styles.container}>
        <Text style={styles.title}>{`${action} ${entityName}`}</Text>
        <Text style={styles.message}>{`Are you sure you want to ${action} this ${entityName}`}</Text>

        {!isApproved && (
          <CoreForm
            footer={null}
            form={form}>
            <CoreForm.Row>
              <TextFieldController
                disabled={loading}
                multiline
                controlName='rejectionReason'
                title='Rejection Reason'
              />
            </CoreForm.Row>
          </CoreForm>
        )}
      </View>
    </CoreModal>
  )
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
  },
  title: {
    fontSize: 18,
    marginBottom: 6
  },
  message: {
    fontSize: 14,
  }
})
