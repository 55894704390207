import React, {createContext, ReactNode, useCallback, useContext, useState} from "react";
import {Modal, StyleSheet, View} from "react-native";
import {PRIMARY_COLOUR} from "../constants/GlobalStyle";
import {Button as PaperButton} from 'react-native-paper';

type ModalContextType = {
  showModal: (content: ReactNode) => void;
  hideModal: (response?: any) => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);

  const showModal = useCallback((content: React.ReactNode) => {
    setModalContent(content);
    setVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setVisible(false);
    setModalContent(null);
  }, []);

  return (
    <ModalContext.Provider value={{showModal, hideModal}}>
      {children}
      <Modal transparent={true} visible={visible} animationType="fade">
        {modalContent}
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export interface CoreModalProps {
  children?: React.ReactNode;
  leftButtons?: ModalButtonProps[];
  rightButtons?: ModalButtonProps[];
}

const CoreModal = ({children, leftButtons = [], rightButtons = []}: CoreModalProps) => {
  return (
    <View style={styles.modalBackdrop}>
      <View style={styles.modalContent}>
        {children}
        <View style={styles.buttonContainer}>
          <View style={styles.buttonPosition}>
            {leftButtons?.map((buttonProps, index) => (
              <Button key={index} {...buttonProps} />
            ))}
          </View>
          <View style={styles.buttonPosition}>
            {rightButtons?.map((buttonProps, index) => (
              <Button key={index} {...buttonProps} />
            ))}
          </View>
        </View>
      </View>
    </View>
  )
}

const Button = ({label, onPress, mode = 'contained', disabled = false}: ModalButtonProps) => {
  const textColor = mode === 'contained' ? '#FFF' : PRIMARY_COLOUR;
  const buttonColor = mode === 'contained' ? PRIMARY_COLOUR : undefined;
  return (
    <View style={styles.buttonPosition}>
      <PaperButton
        style={styles.modalButton}
        mode={mode}
        textColor={textColor}
        buttonColor={buttonColor}
        disabled={disabled}
        onPress={onPress}>
        {label}
      </PaperButton>
    </View>
  );
};

type ModalButtonProps = {
  onPress: (value: any) => void;
  mode?: 'contained' | 'outlined' | 'text';
  label: string;
  disabled?: boolean;
};

export {CoreModal};


const styles = StyleSheet.create({
  modalBackdrop: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    maxHeight: '90%',
    minWidth: 300,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'flex-start',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
  buttonPosition: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  modalButton: {
    borderRadius: 4,
  },
});
