import {StyleSheet, Text, View} from "react-native";
import {useLazyGetJobsListQuery} from "../../../../../../store/services/api/job-api";
import {CoreList} from "../../../../components/core-list/CoreList";
import {useEffect} from "react";
import {Job, JobPriority, JobStatus, JobType} from "../../../../../../store/data/entities/job";
import {humanizeDate} from "../../../../../../utils/humanizers";
import MobileScreen from "../../../../components/MobileScreen";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {useToast} from "../../../../../../components/toast/ToastProvider";
import {useIsFocused} from "@react-navigation/native";
import {Feather} from "@expo/vector-icons";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../store";

export function TechnicianJobsList({navigation}: NativeStackScreenProps<any, any>) {
  const {user} = useSelector((state: RootState) => state.user);
  const [getJobsList, {data: jobsList, isLoading, isError}] = useLazyGetJobsListQuery();

  const {show: showToast} = useToast();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isError) {
      showToast('Error loading jobs list', 'There was an error loading the jobs list', 'error');
    }
  }, [isError]);

  useEffect(() => {
    if (isFocused) {
      getJobsList({
        pageNumber: 1,
        pageSize: 1000,
        technicianId: user.id,
        status: JobStatus.NotStarted,
        startDate: '2000-01-01',
        endDate: '3000-01-01',
        sortedColumn: 'priority',
        sortedColumnAscending: true
      })
    }
  }, [isFocused]);

  const handleOnItemPress = (job: Job) => {
    if (job.type === JobType.Maintenance) {
      navigation.navigate("maintenance-job", {jobId: job.id});
    }
    if (job.type === JobType.Corrective) {
      navigation.navigate("corrective-job", {jobId: job.id});
    }
  }

  const priorityIconMap = {
    [JobPriority.P1]: {icon: 'chevrons-up', color: '#fa5252'},
    [JobPriority.P2]: {icon: 'chevron-up', color: '#fd7e14'},
    [JobPriority.P3]: {icon: 'chevron-down', color: '#15aabf'},
    [JobPriority.P4]: {icon: 'chevrons-down', color: '#228be6'},
  }

  const renderItem = (job: Job) => {
    const icon = job.type === JobType.Corrective ? 'tool' : 'calendar';
    const priorityIcon = priorityIconMap[job.priority].icon;
    const priorityColor = priorityIconMap[job.priority].color;

    return (
      <View style={styles.listItemContainer}>
        <View style={styles.icon}>
          <Feather name={icon} size={20}/>
        </View>
        <View style={styles.icon}>
          <Feather name={priorityIcon as any} size={16} color={priorityColor}/>
        </View>
        <View style={styles.itemDetails}>
          <Text style={styles.itemReference}>{job.reference}</Text>
          <Text style={styles.listItemDateText}>{humanizeDate(job.plannedDate)}</Text>
        </View>
        <View style={styles.icon}>
          <Feather name='chevron-right' size={20}/>
        </View>
      </View>
    )
  }

  return (
    <MobileScreen>
      <CoreList
        onItemPress={(item) => handleOnItemPress(item.item)}
        isLoading={isLoading}
        data={jobsList?.list}
        renderItem={({item}) => renderItem(item)}
      />
    </MobileScreen>
  )
}

const styles = StyleSheet.create({
  listItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 18,
    gap: 12
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemDetails: {
    flex: 1,
  },
  itemReference: {
    fontSize: 16,
    fontWeight: '600',
    flexWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  listItemDateText: {
    fontWeight: '400',
  }
})
