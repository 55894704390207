import { createContext, useContext } from 'react';
import {UseFormReturn} from "react-hook-form/dist/types";

export interface JobControlContextType<T> {
  handleOnFinish: () => void;
  handleOnNext?: () => void;
  handleOnBack?: () => void;
  onError: (message?: string) => void;
  form:  UseFormReturn<T, any, T>;
  jobId: string;
  initialForm?: T;
  isFirstControl?: boolean;
  isLastControl?: boolean;
  isApproval: boolean;
}

export const JobControlContext = createContext<JobControlContextType<any> | undefined>(undefined);

export const useJobControl = <T,>(): JobControlContextType<T> => {
  const context = useContext(JobControlContext) as JobControlContextType<T> | undefined;
  if (!context) {
    throw new Error('useJobControl must be used within a JobControlProvider');
  }
  return context;
};
