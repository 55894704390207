import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import React, {useEffect} from "react";
import {MaintenanceJobNavigator} from "./pages/MaintenanceJob/MaintenanceJobNavigator";
import {TechnicianJobsNavigator} from "./pages/Jobs/TechnicianJobsNavigator";
import {CorrectiveJobNavigator} from "./pages/CorrectiveJob/CorrectiveJobNavigator";

const Stack = createNativeStackNavigator();

export default function TechnicianStackNavigator({ navigation }: { navigation: any }) {
  const {user} = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user == null || user.role !== 'Technician') {
      navigation.replace("login")
    }
  }, [user]);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="jobs"
        component={TechnicianJobsNavigator}
        options={{
          headerShown: false
        }} />
      <Stack.Screen
        name="maintenance-job"
        component={MaintenanceJobNavigator}
        options={{
          headerShown: false
        }} />
      <Stack.Screen
        name="corrective-job"
        component={CorrectiveJobNavigator}
        options={{
          headerShown: false
        }} />
    </Stack.Navigator>
  )
}
