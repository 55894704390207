import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import React from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {Feather} from "@expo/vector-icons";
import {PRIMARY_COLOUR} from "../../../../../../constants/GlobalStyle";
import {AssetInformation} from "./components/AssetInformationHelp";
import {AssetMediaHelp} from "./components/AssetMediaHelp";

const Tab = createBottomTabNavigator();

export function MaintenanceJobHelp({route}: NativeStackScreenProps<any, any>) {
  const jobId = route?.params?.jobId;

  const getIconColor = (focused: boolean): string => {
    return focused ? PRIMARY_COLOUR : 'rgb(142, 142, 143)'
  }

  return (
    <Tab.Navigator initialRouteName="AssetInformation">
      <Tab.Screen
        name="asset-information"
        component={AssetInformation}
        initialParams={{jobId}}
        options={{
          title: 'Information',
          headerShown: false,
          tabBarIcon: ({focused, size}) => <Feather name='info' color={getIconColor(focused)} size={size} />
      }}
      />
      <Tab.Screen
        name="asset-media"
        component={AssetMediaHelp}
        initialParams={{jobId}}
        options={{
          title: 'Media',
          headerShown: false,
          tabBarIcon: ({focused, size}) => <Feather name='play' color={getIconColor(focused)} size={size} />
        }}
      />
    </Tab.Navigator>
  );
}
