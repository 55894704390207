import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AssetScreen from './mobile/screens/Asset/AssetScreen';
import TicketScreen from './mobile/screens/Ticket/TicketScreen';
import TechnicianStackNavigator from "./mobile/screens/Technician/TechnicianStackNavigator";
import {ToastProvider} from "../components/toast/ToastProvider";
import {ModalProvider} from "../hooks/useModal";

const Stack = createNativeStackNavigator();

export function MobileNavigator() {
  return (
    <ToastProvider>
      <ModalProvider>
        <Stack.Navigator>
          <Stack.Screen name="technician" component={TechnicianStackNavigator} options={{ headerShown: false, title: 'Technician' }}/>
          <Stack.Screen name="asset-screen" component={AssetScreen} options={{ headerShown: false, title: 'Asset' }}/>
          <Stack.Screen name="ticket-screen" component={TicketScreen} options={{ headerShown: false, title: 'Asset' }}/>
        </Stack.Navigator>
      </ModalProvider>
    </ToastProvider>
  );
}
