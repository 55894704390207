import {StyleSheet, Text, View} from "react-native";
import {CoreModal, CoreModalProps} from "../../hooks/useModal";

interface ConfirmModalViewProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
}

export function ConfirmModal({title, message, onConfirm, onClose}: ConfirmModalViewProps) {
  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Cancel', onPress: onClose, mode: 'text'},
    ],
    rightButtons: [
      {label: 'No', onPress: onClose, mode: 'outlined'},
      {label: 'Yes', onPress: onConfirm, mode: 'contained'},
    ]
  }

  return (
    <CoreModal {...modalProps}>
      <View style={styles.container}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.message}>{message}</Text>
      </View>
    </CoreModal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingBottom: 12,
  },
  title: {
    fontSize: 18,
    marginBottom: 6
  },
  message: {
    fontSize: 14,
  }
})
