import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {extractEmail, extractPermissions} from "../../utils/jwt-helper";
import {UserPermission} from "../data/users/user-permission";
import {UserRole} from "../data/users/user-roles";

export class User {
  id: string;
  name: string;
  role: UserRole;
  email: string;
}

export interface UserState {
  user: User,
  token: string | null,
  permissions: UserPermission[],
  email: string
}

const initialState: UserState = {
  user: null,
  token: null,
  permissions: [],
  email: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      if (action.payload) {
        state.permissions = extractPermissions(action.payload.token);
        state.email = extractEmail(action.payload.token);
        state.token = action.payload.token;
      }
    },
    logout: (state) => {
      state.user = null;
      state.permissions = [];
      state.email = null;
      state.token = null;
    }
  }
})

export const { setUser, logout } = userSlice.actions;

export default userSlice.reducer;
