import React, {useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import WebPageLayout from "../../../../layouts/WebPageLayout";
import TablePageLayout from "../../../../layouts/TablePageLayout";
import {CoreTable} from "../../../../components/CoreTable";
import {Button, DataTable} from "react-native-paper";
import {Feather, MaterialIcons} from "@expo/vector-icons";
import {BaseFilters} from "../../../../../web/hooks/useFilters";
import useEntityListPage from "../../../../hooks/useEntityListPage";
import {
  useDeleteWorkOrderMutation,
  useLazyGetWorkOrdersQuery,
  useSetWorkOrderStatusMutation
} from "../../../../../../store/services/api/work-order-api";
import {
  WorkOrder,
  WorkOrderPriority,
  WorkOrderStatus,
  WorkOrderType
} from "../../../../../../store/data/entities/work-order";
import WorkOrdersListScreenFilters from "./WorkOrdersListScreenFilters";
import {
  humanizeDate,
  humanizeEnum,
  humanizePastDate,
  humanizeWorkOrderPriority
} from "../../../../../../utils/humanizers";
import useHasPermission from "../../../../../../hooks/useHasPermission";
import {Tooltip} from "antd";
import ConfirmModal from "../../../../../../components/modals/New/ConfirmModal";
import moment from "moment/moment";

interface WorkOrderListFilters extends BaseFilters {
  reference: string;
  parentReference: string;
  description: string;
  priority?: WorkOrderPriority | '';
  statuses: WorkOrderStatus[];
  startDate: string;
  endDate: string;
}

export default function WorkOrdersList() {
  const navigation = useNavigation<any>();
  const {hasPermission} = useHasPermission();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [setWorkOrderStatus, {isLoading: workOrderStatusLoading}] = useSetWorkOrderStatusMutation();
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState<string | undefined>();

  const reopenWorkOrder = () => {
    if (!selectedWorkOrderId) return;
    setWorkOrderStatus({
      workOrderId: selectedWorkOrderId,
      status: WorkOrderStatus.Approved
    }).unwrap()
      .then(() => {
        setSelectedWorkOrderId(undefined)
        setConfirmModalOpen(false);
      })
  }

  const handleOnWorkOrderReopen = (id: string) => {
    setSelectedWorkOrderId(id);
    setConfirmModalOpen(true);
  }

  const {
    navigateToEdit,
    navigateToCreate,
    navigateToApproval,
    onSubmitFilter,
    filter,
    tableData,
    isLoading
  } = useEntityListPage<WorkOrderListFilters>({
    entityName: "Work Order",
    lazyTableQuery: useLazyGetWorkOrdersQuery,
    deleteEntityMutation: useDeleteWorkOrderMutation,
    filterDefinition: {
      sortedColumn: '',
      sortedColumnAscending: false,
      pageNumber: 1,
      pageSize: 10,
      statuses: [],
      reference: '',
      parentReference: '',
      description: '',
      priority: '',
      startDate: moment().toISOString(),
      endDate: moment().add(1, 'week').toISOString(),
    },
  });

  const navigateToRecordView = (id: string) => navigation.navigate('recordView', {id});

  return (
    <WebPageLayout
      header={{
        previousTab: {title: 'Dashboard', routeName: 'dashboard', replaceStack: true},
        tabs: [
          {title: 'Work Orders', routeName: 'list'}
        ]
      }}
    >
      <TablePageLayout
        filters={<WorkOrdersListScreenFilters filter={filter} onSubmit={onSubmitFilter} />}
        actions={(
          <Button icon='plus' mode="elevated" onPress={navigateToCreate} buttonColor='#fff' textColor='#0f0f0f'>
            Create Work Order
          </Button>
        )}
      >
        <CoreTable<WorkOrder>
          isLoading={isLoading}
          headers={[
            {text: 'Reference', flex: 0.75, sortedName: 'referenceNumber'},
            {text: 'Parent WO', flex: 0.75, sortedName: 'parentWorkOrder.referenceNumber'},
            {text: 'Priority', flex: 0.5, sortedName: 'priority'},
            {text: 'Scheduled Date', sortedName: 'scheduledStartDate'},
            {text: 'WO Age'},
            {text: 'Type', sortedName: 'type'},
            {text: 'Location', flex: 1.25, sortedName: 'locationLabel'},
            {text: 'Description', flex: 1.25, sortedName: 'description'},
            {text: 'Status', sortedName: 'status'},
            {text: 'Options', flex: 1.25}
          ]}
          data={tableData}
          keyExtractor={(item) => item.id}
          renderItem={({item, index}) => {
            const showApprovalButton = hasPermission('CanApproveWorkOrders') && item.status === WorkOrderStatus.PendingApproval;
            const showReopenButton = hasPermission('CanReopenWorkOrders') && item.status === WorkOrderStatus.Closed;
            const showEditButton = hasPermission('CanCreateWorkOrders')
              && item.status === WorkOrderStatus.PendingApproval
              && !item.isApproved;

            const canViewRecord = item.isApproved;

            return (
              <DataTable.Row
                style={index % 2 === 0 ? styles.alternateRow : styles.row}
                key={index}
              >
                <CoreTable.Cell style={{flex: 0.75}}>{item.reference}</CoreTable.Cell>
                <CoreTable.Cell style={{flex: 0.75}}>{item?.parentWorkOrderReference ?? 'N/A'}</CoreTable.Cell>
                <CoreTable.Cell style={{flex: 0.5}}>{humanizeWorkOrderPriority(item.priority)}</CoreTable.Cell>
                <CoreTable.Cell>{humanizeDate(item?.scheduledStartDate)}</CoreTable.Cell>
                <CoreTable.Cell>{
                  item.status === WorkOrderStatus.Approved || item.status === WorkOrderStatus.InProgress
                    ? humanizePastDate(item?.scheduledStartDate)
                    : 'N/A'}
                </CoreTable.Cell>
                <CoreTable.Cell>{humanizeEnum(WorkOrderType[item?.type])}</CoreTable.Cell>
                <CoreTable.Cell style={{flex: 1.25}}>{item.locationLabel ?? 'N/A'}</CoreTable.Cell>
                <CoreTable.Cell style={{flex: 1.25}}>{item.description}</CoreTable.Cell>
                <DataTable.Cell>
                  {item.isRejected ? (
                    <Tooltip title={item.rejectedReason ?? 'No reason provided'}>
                      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 6 }}>
                        <Text>Rejected</Text>
                        <Feather name='alert-circle' size={16} color="#fab005" />
                      </View>
                    </Tooltip>
                  ) : humanizeEnum(WorkOrderStatus[item.status])}
                </DataTable.Cell>
                <DataTable.Cell style={{flex: 1.25}}>
                  {canViewRecord && (
                    <Tooltip title='View Record'>
                      <TouchableOpacity onPress={() => navigateToRecordView(item.id)} style={{marginRight: 4}}>
                        <MaterialIcons name="login" size={24} color="black" />
                      </TouchableOpacity>
                    </Tooltip>
                  )}
                  {showApprovalButton && (
                    <Tooltip title='View for Approval'>
                      <TouchableOpacity onPress={() => navigateToApproval(item.id)} style={{marginRight: 4}}>
                        <MaterialIcons name="approval" size={24} color="black" />
                      </TouchableOpacity>
                    </Tooltip>
                  )}
                  {showReopenButton && (
                    <Tooltip title='Reopen Work Order'>
                      <TouchableOpacity onPress={() => handleOnWorkOrderReopen(item.id)} style={{marginRight: 4}}>
                        <MaterialIcons name="undo" size={24} color="black" />
                      </TouchableOpacity>
                    </Tooltip>
                  )}
                  {showEditButton && (
                    <Tooltip title='Edit Work Order'>
                      <TouchableOpacity onPress={() => navigateToEdit(item.id)} style={{marginRight: 4}}>
                        <MaterialIcons name="edit" size={24} color="black" />
                      </TouchableOpacity>
                    </Tooltip>
                  )}
                </DataTable.Cell>
              </DataTable.Row>
            )
          }}
          filters={filter.filters}
          setFilter={filter.setFilter}
        >
        </CoreTable>

        <ConfirmModal
          title='Reopen Work Order'
          message='Are you sure you want to reopen this work order?'
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={reopenWorkOrder}
          isLoading={workOrderStatusLoading}
        />
      </TablePageLayout>
    </WebPageLayout>
  );
}

const styles = StyleSheet.create({
  row: {
    backgroundColor: 'rgba(255,255,255,1)'
  },
  alternateRow: {
    backgroundColor: 'rgba(245,245,245,1)'
  }
});
