import {StyleProp, StyleSheet, Text, TextInput, View} from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import {Controller, RegisterOptions} from "react-hook-form";
import * as React from "react";
import {useCoreForm} from "./CoreForm";

interface TextFieldControllerProps {
  rules?: RegisterOptions;
  controlName: string;
  title?: string;
  type?: 'default' | 'numeric';
  required?: boolean;
  multiline?: boolean | number;
  placeholder?: string;
  disabled?: boolean;
  style?: StyleProp<any>;
  maxLength?: number;
  control?: any;
  secureTextEntry?: boolean;
}

export function TextFieldController({rules, controlName, title, type = 'default', required = false, multiline = false, placeholder, disabled, style, maxLength, control, secureTextEntry = false}: TextFieldControllerProps) {
  const {form: {control: _control}, isLoading} = useCoreForm();
  if (!_control && !control) return null;

  const extendedRules = {
    ...(rules ?? {}),
    required: required ? 'This field is required' : false,
    ...(type === 'numeric' && {
      pattern: {
        value: /^\d*(\.\d+)?$/,
        message: 'Value must be a valid number',
      }
    })
  };

  const handleOnChange = (value: string, onChangeHandler: (value: any) => void) => {
    if (type === 'numeric') {
      onChangeHandler(Number(value));
      return;
    }
    onChangeHandler(value);
  }

  return (
    <Controller
      control={control ?? _control}
      rules={extendedRules}
      name={controlName}
      render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
        <View>
          {title && (<Text style={GlobalStyle.form__column__text}>{title} {required ? '*' : null}</Text>)}
          <TextInput
            placeholder={placeholder}
            multiline={!!multiline}
            numberOfLines={typeof multiline === 'number' ? multiline : undefined}
            editable={!isLoading && !disabled}
            style={[style, GlobalStyle.form__column__input, error && {borderColor: 'red'}, disabled && styles.disabled]}
            onBlur={onBlur}
            onChangeText={(val) => handleOnChange(val, onChange)}
            value={value}
            keyboardType={type}
            secureTextEntry={secureTextEntry}
            maxLength={maxLength}
          />
          {error && <Text style={{color: 'red'}}>{error.message}</Text>}
        </View>
      )}
    />
  );
}

const styles = StyleSheet.create({
  disabled: {
    borderColor: 'rgb(206, 206, 206)',
    color: 'rgb(109, 109, 109)'
  }
})
