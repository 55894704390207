import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {JobsList} from "./JobsList";
import JobManage from "./JobManage";
import {JobApproval} from "./JobApproval";

const Stack = createNativeStackNavigator();

export default function JobsNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={JobsList} options={{ headerShown: false, title: 'Jobs' }} />
      <Stack.Screen name="manage" component={JobManage} options={{ headerShown: false, title: 'Manage Job' }} />
      <Stack.Screen name="approval" component={JobApproval} options={{ headerShown: false, title: 'Job Approval' }} />
    </Stack.Navigator>
  )
}
