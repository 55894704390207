import { View, Text, StyleSheet } from 'react-native';
import { getHeaderTitle } from '@react-navigation/elements';
import { DefaultButton } from './controls/DefaultButton';
import { useDispatch } from 'react-redux';
import { logout as logoutUser } from '../../../../store/slices/user-slice';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const Header = (props, user) => {
  var title = getHeaderTitle(props.options, props.route.name);
  const dispatch = useDispatch();

  const logout = async () => {
    dispatch(logoutUser());
    
    try {
      await AsyncStorage.clear();
    } catch (error) {
      console.log("Error clearing AsyncStorage:", error);
    }
    
    props.navigation.replace("login");
  }

  return (
    <View style={styles.header}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.spacer}></View>
      { user != null && 
        <View style={styles.user_profile}>
          <Text style={styles.profile__text_name}>{user.name}</Text>
          <DefaultButton text="Logout" onPressed={() => logout()}/>
        </View>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#FFF',
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1
  },

  title: {
    fontFamily: 'OpenSans-ExtraBold'
  },

  spacer: {
    flex: 1,
  },

  user_profile: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  profile__text_name: {
    marginRight: 10,
  },

 
});
