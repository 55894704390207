export const flattenCascaderOptions = (values: string[][]): string[] => {
  return values.map(option => option[option.length - 1]);
};

export const findFullPathByLeafId = <T,>(
  options: CascaderOption<T>[],
  leafId: string
): string[] | null => {
  for (const option of options) {
    if (option.value === leafId) return [option.value];
    if (option.children) {
      const path = findFullPathByLeafId(option.children, leafId);
      if (path) return [option.value, ...path];
    }
  }
  return null;
};

export interface CascaderOption<T> {
  value: string;
  label: string;
  type: T;
  children?: CascaderOption<T>[];
  isLeaf?: boolean;
  selectable?: boolean;
}
