import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {
  useCreateSubAssetTypeMutation,
  useUpdateSubAssetTypeMutation
} from "../../../../../../../../store/services/api/asset-type-api";
import CoreForm from "../../../../../../../web/components/forms/CoreForm";
import {TextFieldController} from "../../../../../../../web/components/forms/TextFieldController";
import {FormButton} from "../../../../../../../web/components/forms/FormButton";

interface SystemSubAssetTypesFormProps {
  subAssetType: any;
  assetTypeId: string;
  onSubmit: () => void;
  onClearSelection: () => void;
}

export default function SystemSubAssetTypesForm({subAssetType, assetTypeId, onSubmit, onClearSelection}: SystemSubAssetTypesFormProps) {
  const [createSubAssetType] = useCreateSubAssetTypeMutation();
  const [updateSubAssetType] = useUpdateSubAssetTypeMutation();

  const form = useForm({
    defaultValues: {
      id: null,
      assetTypeId: assetTypeId,
      name: "",
    }
  });

  useEffect(() => {
    if (subAssetType) {
      form.setValue("id", subAssetType.id);
      form.setValue("assetTypeId", subAssetType.assetTypeId);
      form.setValue("name", subAssetType.name);
    }
  }, [subAssetType])

  const id = form.watch("id");

  const handleOnSubmit = async (data) => {
    data.assetTypeId = assetTypeId;

    const performAction = async () => {
      if (data.id != null) {
        return await updateSubAssetType(data).unwrap();
      }
      return await createSubAssetType(data).unwrap();
    }

    const result = await performAction();

    if (result?.error) {
      alert(result.error)
      return;
    }

    form.reset();
    onSubmit();
  }

  const handleOnClear = () => {
    onClearSelection();
    form.reset();
  }

  return (
    <CoreForm
      form={form}
      onSubmit={form.handleSubmit(handleOnSubmit)}
      footer={(
        <>
          <FormButton
            title={id == null ? 'Add' : 'Update'}
            onPress={form.handleSubmit(handleOnSubmit)}
          />
          {id && (
            <FormButton title='Cancel' onPress={handleOnClear}/>
          )}
        </>
      )}
    >
      <CoreForm.Row>
        <TextFieldController
          controlName='name'
          title='Sub AssetType Name'
          required
        />
      </CoreForm.Row>
    </CoreForm>
  )
}
