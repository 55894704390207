import {Text, TouchableOpacity, View} from "react-native";
import {DataTable} from "react-native-paper";
import React, {useEffect} from "react";
import {CoreTable} from "../../components/core-table/CoreTable";
import {
  DropdownFilter,
  SearchFilterRow,
  SearchFilters
} from "../../components/search-filters/SearchFilters";
import GlobalStyle from "../../../../constants/GlobalStyle";
import useFilters, {BaseFilters} from "../../hooks/useFilters";
import {Entypo, Ionicons} from "@expo/vector-icons";
import {WebScreen} from "../../components/WebScreen";
import {useToast} from "../../../../components/toast/ToastProvider";
import {useLazyGetChecklistsListQuery} from "../../../../store/services/api/maintenance-checklist-api";
import {useGetAllAssetTypesQuery} from "../../../../store/services/api/asset-type-api";
import {Picker} from "@react-native-picker/picker";
import {humanizeBoolean} from "../../../../utils/humanizers";
import {MaintenanceChecklist} from "../../../../store/data/entities/maintanence-checklist";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {useIsFocused} from "@react-navigation/native";
import {useSetLoading} from "../../../../hooks/useSetLoading";

interface MaintenanceChecklistListFilters extends BaseFilters {
  assetTypeId: string;
}

export function MaintenanceChecklistList({navigation}: NativeStackScreenProps<any, any>) {
  const [getChecklistsList, {data: checklistsListResult, isFetching: checklistsFetching, isError}] = useLazyGetChecklistsListQuery();
  const {data: assetTypes, isFetching: isAssetTypesFetching} = useGetAllAssetTypesQuery();
  const loadTable = (filters?: Record<string, any>) => getChecklistsList(filters ?? getFilterValues());

  const filter = useFilters<MaintenanceChecklistListFilters>({
    sortedColumn: "",
    sortedColumnAscending: false,
    pageNumber: 1,
    pageSize: 10,
    assetTypeId: ''
  }, loadTable);
  const {getFilterValues, setFilter, filters} = filter;

  const {show: showToast} = useToast();
  const isFocused = useIsFocused()

  useEffect(() => {
    if(isFocused){
      loadTable();
    }
  }, [isFocused]);

  useSetLoading([isAssetTypesFetching]);

  useEffect(() => {
    if (isError) {
      showToast('Error getting table', 'There was an error getting checklist data', 'error');
    }
  }, [isError]);

  const handleOnChecklistPress = (item: MaintenanceChecklist) => {
    navigation.navigate('manage', {checklistId: item.id})
  }

  return (
    <WebScreen>
      <SearchFilters filter={filter} onSubmit={() => loadTable()}>
        <SearchFilterRow>
          <DropdownFilter title='Asset Type' name='assetTypeId'>
            <Picker.Item label='All' value='' />
            {(assetTypes ?? []).map(item =>
              <Picker.Item key={item.id} label={item.name} value={item.id} />
            )}
          </DropdownFilter>
        </SearchFilterRow>
      </SearchFilters>

      <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
          <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add Job Plan</Text>
        </View>
      </TouchableOpacity>

      <CoreTable
        filters={filters}
        setFilter={setFilter}
        isLoading={checklistsFetching}
        data={checklistsListResult}
        headers={[
          { text: 'Edit', visible: true, flex: 0.2 },
          { text: 'Title', visible: true },
          { text: 'Asset Type', visible: true },
          { text: 'Approved', visible: true },
        ]}
        renderItem={({item}) => (
          <DataTable.Row key={item.id} style={{backgroundColor: item.isApproved ? '#fff' : '#ffc9c9'}}>
            <DataTable.Cell style={{flex: 0.2}}>
              <TouchableOpacity onPress={() => handleOnChecklistPress(item)}>
                <Entypo name="pencil" size={24} color="black"/>
              </TouchableOpacity>
            </DataTable.Cell>
            <DataTable.Cell>{item.title}</DataTable.Cell>
            <DataTable.Cell>{item.assetType?.name}</DataTable.Cell>
            <DataTable.Cell>{humanizeBoolean(item.isApproved)}</DataTable.Cell>
          </DataTable.Row>
        )}
      />
    </WebScreen>
  )
}
