import MobilePageLayout from "../../../layouts/MobilePageLayout";
import {Button, Card} from "react-native-paper";
import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AdminStackParamList} from "../AdminStack";

export default function AdminMenuScreen({navigation, route}: ScreenProps<AdminStackParamList, 'menu'>) {
  const assetId = route.params?.assetId;
  const navigateToDetails = () => navigation.navigate('assetDetails', {assetId})
  const navigateToStatus = () => navigation.navigate('assetStatus', {assetId})

  return (
    <MobilePageLayout>
      <Card style={{backgroundColor: 'white', height: '100%'}}>
        <Card.Content style={{flexDirection: 'column', gap: 12}}>
          <Button icon='alert-circle-outline' mode='elevated' onPress={navigateToDetails}>View Details</Button>
          <Button icon='help' mode='elevated' onPress={navigateToStatus}>Check Status</Button>
        </Card.Content>
      </Card>
    </MobilePageLayout>
  )
}
