import Table from "../../../../../../components/Table";
import {TouchableOpacity} from "react-native";
import {Entypo, Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";
import React from "react";
import {useDeleteFloorMutation} from "../../../../../../../../store/services/api/building-api";
import {usePage} from "../../../../../../../../hooks/usePage";
import {useToast} from "../../../../../../../../components/toast/ToastProvider";
import {useNavigation} from "@react-navigation/native";

interface SystemFloorsListProps {
  floors: any[],
  onFloorSelected: (floor: any) => void;
  onFloorDeleted: () => void;
}

export default function SystemFloorsList({floors, onFloorSelected, onFloorDeleted}: SystemFloorsListProps) {
  const navigation = useNavigation<any>();
  const navigateToLocations = (id: string) => navigation.navigate('locations', {floorId: id});
  const [deleteFloor] = useDeleteFloorMutation();

  const {setIsLoading} = usePage();
  const {show: showToast} = useToast();

  const handleOnFloorDelete = async (floorId: string) => {
    if (!confirm(`Are you sure you want to delete this Floor?`)) return;

    setIsLoading(true);
    try {
      const result = await deleteFloor(floorId).unwrap();

      if (result?.error) {
        showToast('Error', `There was an issue deleting the Floor`, 'error');
        setIsLoading(false);
        return;
      }

      showToast(`Deleted Floor`, `Successfully deleted Floor`, 'success');
      onFloorDeleted();
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <Table
      columns={[
        {title: 'Name', key: 'name'},
        {
          title: 'Actions',
          render: (item) => (
            <>
              <TouchableOpacity onPress={() => onFloorSelected(item)}>
                <Entypo name="pencil" size={24} color="black" />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => handleOnFloorDelete(item.id)}>
                <Ionicons name="ios-remove-circle" size={24} color="red" />
              </TouchableOpacity>
            </>
          )
        },
        {
          title: 'Locations',
          render: (item) => (
            <TouchableOpacity onPress={() => navigateToLocations(item.id)}>
              <MaterialCommunityIcons name="view-list-outline" size={24} color="black" />
            </TouchableOpacity>
          )
        }
      ]}
      data={floors ?? []}
      keyExtractor={(item) => item.id}
    />
  )
}
