import {ActivityIndicator, View, Text, StyleSheet} from "react-native";
import {PRIMARY_COLOUR} from "../../constants/GlobalStyle";

export function EmptyList({isLoading, emptyText}: {isLoading?: boolean, emptyText?: string}) {
  return (
    <View style={styles.emptyListIndicator}>
      {isLoading ? (
        <ActivityIndicator animating={true} color={PRIMARY_COLOUR} />
      ): (
        <Text>{emptyText ?? 'There are no results'}</Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  emptyListIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
  },
})
