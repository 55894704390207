import Table from "../../../../../../components/Table";
import {TouchableOpacity} from "react-native";
import {Entypo, Ionicons} from "@expo/vector-icons";
import React from "react";
import {
  useDeleteLocationMutation
} from "../../../../../../../../store/services/api/building-api";
import {usePage} from "../../../../../../../../hooks/usePage";
import {useToast} from "../../../../../../../../components/toast/ToastProvider";

interface SystemLocationsListProps {
  locations: any[],
  onLocationSelected: (floor: any) => void;
  onLocationDeleted: () => void;
}

export default function SystemLocationsList({locations, onLocationSelected, onLocationDeleted}: SystemLocationsListProps) {
  const [deleteLocation] = useDeleteLocationMutation();

  const {setIsLoading} = usePage();
  const {show: showToast} = useToast();

  const handleOnLocationDelete = async (locationId: string) => {
    if (!confirm(`Are you sure you want to delete this Location?`)) return;

    setIsLoading(true);
    try {
      const result = await deleteLocation(locationId).unwrap();

      if (result?.error) {
        showToast('Error', `There was an issue deleting the Location`, 'error');
        setIsLoading(false);
        return;
      }

      showToast(`Deleted Location`, `Successfully deleted Location`, 'success');
      onLocationDeleted();
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <Table
      columns={[
        {title: 'Name', key: 'name'},
        {
          title: 'Actions',
          render: (item) => (
            <>
              <TouchableOpacity onPress={() => onLocationSelected(item)}>
                <Entypo name="pencil" size={24} color="black" />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => handleOnLocationDelete(item.id)}>
                <Ionicons name="ios-remove-circle" size={24} color="red" />
              </TouchableOpacity>
            </>
          )
        }
      ]}
      data={locations ?? []}
      keyExtractor={(item) => item.id}
    />
  )
}
