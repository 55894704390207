import { baseApi } from './base-api'
import {AssetType} from "../../data/entities/asset-type";
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {CascaderOption} from "../../data/models/cascader-option";
import {buildPagedResponseTags, invalidateTags} from "../../../utils/api-utils";

export const assetTypeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllAssetTypes: build.query<AssetType[], void>({
      query: () => ({
        url: `assetTypes/getallassettypes`,
      })
    }),
    getAllSubAssetTypes: build.query<any, any>({
      query: () => ({
        url: `assetTypes/getallsubassettypes`,
      })
    }),
    getAssetTypes: build.query<PagedResponse<any>, Record<string, any>>({
      query: (params) => ({
        url: `assetTypes/getassetTypes`,
        params: params
      }),
      transformResponse: getPagedResponse,
      providesTags: result => buildPagedResponseTags(result, 'AssetTypes')
    }),
    deleteAssetType: build.mutation<any, string>({
      query: (id) => ({
        url: `assetTypes/deleteAssetType/${id}`,
        method: 'delete',
      }),
      invalidatesTags: (_result, _error, id) => invalidateTags(id, 'AssetTypes')
    }),
    createAssetType: build.mutation<any, any>({
      query: (body) => ({
        url: 'assetTypes/addAssetType',
        method: 'post',
        body: body
      }),
      invalidatesTags: () => invalidateTags('LIST', 'AssetTypes')
    }),
    updateAssetType: build.mutation<any, any>({
      query: (body) => ({
        url: `assetTypes/updateAssetType/${body.id}`,
        method: 'put',
        body: body
      }),
      invalidatesTags: (_result, _error, {id}) => invalidateTags(id, 'AssetTypes')
    }),
    getAssetType: build.query<any, any>({
      query: (assetTypeId) => ({
        url: `assetTypes/getAssetType/${assetTypeId}`,
      }),
    }),
    getSubAssetTypes: build.query<PagedResponse<any>, Record<string, any>>({
      query: (params) => ({
        url: `assetTypes/getSubAssetTypes`,
        params: params
      }),
      transformResponse: getPagedResponse,
      providesTags: result => buildPagedResponseTags(result, 'SubAssetTypes')
    }),
    deleteSubAssetType: build.mutation<any, any>({
      query: (id) => ({
        url: `assetTypes/deleteSubAssetType/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => invalidateTags('LIST', 'AssetTypes')
    }),
    createSubAssetType: build.mutation<any, any>({
      query: (body) => ({
        url: 'assetTypes/addSubAssetType',
        method: 'post',
        body: body
      }),
      invalidatesTags: () => invalidateTags('LIST', 'AssetTypes')
    }),
    updateSubAssetType: build.mutation<any, any>({
      query: (body) => ({
        url: `assetTypes/updateSubAssetType/${body.id}`,
        method: 'put',
        body: body
      }),
      invalidatesTags: (_result, _error, {id}) => invalidateTags(id, 'AssetTypes')
    }),
    getSubAssetType: build.query<any, any>({
      query: (assetTypeId) => ({
        url: `assetTypes/getSubAssetType/${assetTypeId}`,
      }),
    }),
    getAssetTypeCascaderOptions: build.query<CascaderOption[], void>({
      query: () => ({
        url: `assetTypes/getAssetTypeCascaderOptions`,
      }),
    }),
  })
})

export const {
  useGetAllAssetTypesQuery,
  useGetAllSubAssetTypesQuery,
  useGetAssetTypesQuery,
  useDeleteAssetTypeMutation,
  useCreateAssetTypeMutation,
  useUpdateAssetTypeMutation,
  useGetAssetTypeQuery,
  useCreateSubAssetTypeMutation,
  useDeleteSubAssetTypeMutation,
  useGetSubAssetTypeQuery,
  useGetSubAssetTypesQuery,
  useUpdateSubAssetTypeMutation,
  useLazyGetAssetTypeQuery,
  useLazyGetAssetTypesQuery,
  useGetAssetTypeCascaderOptionsQuery
} = assetTypeApi
