import {useSelector} from "react-redux";
import {RootState} from "../../store";
import React, {useEffect} from "react";
import {UserPermission} from "../../store/data/users/user-permission";
import DashboardView from "./DashboardView";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import WorkOrdersStack from "./screens/work-orders/WorkOrdersStack";
import SystemStack from "./screens/system/SystemStack";
import JobPlansStack from "./screens/job-plans/JobPlansStack";
import PreventativeMaintenancesStack from "./screens/preventative-maintenances/PreventativeMaintenancesStack";
import TicketsStack from "./screens/tickets/TicketsStack";

const Stack = createNativeStackNavigator();

export default function WebNavigator({ navigation }: { navigation: any }) {
  const {user, permissions} = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user == null) {
      navigation.replace("login")
    }
  }, [user]);

  const renderScreens = () => {
    const screens = protectedScreens.filter(s => !s.requiredPermission || permissions.includes(s.requiredPermission));
    return screens.map((s, i) => (
      <Stack.Screen
        key={`${s.name}-${i}`}
        name={s.name}
        component={s.component}
        options={{ title: s.title }}
      />
    ));
  }

  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      {renderScreens()}
    </Stack.Navigator>
  );
}

interface ProtectedDrawerScreen {
  component: any;
  name: string;
  title: string;
  requiredPermission?: UserPermission;
}

const protectedScreens: ProtectedDrawerScreen[] = [
  {name: 'dashboard', title: 'Dashboard', component: DashboardView},
  {name: 'workOrders', title: 'Work Orders', component: WorkOrdersStack},
  {name: 'tickets', title: 'Tickets', component: TicketsStack},
  {name: 'jobPlans', title: 'Job Plans', component: JobPlansStack},
  {name: 'preventativeMaintenances', title: 'Preventative Maintenances', component: PreventativeMaintenancesStack},
  {name: 'system', title: 'System', component: SystemStack},
]
