export interface Media {
  title: string;
  description: string;
  uri: string;
}

export interface NewMedia {
  title: string;
  description: string;
  path: string;
  file: File;
  extras: MediaExtras;
}

export type MediaExtras = {
  [key: string]: string;
};


export function getMediaType(uri: string): 'image' | 'video' {
  const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv'];
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
  const extension = uri.split('.').pop()?.toLowerCase() || '';
  if (videoExtensions.includes(`.${extension}`)) {
    return 'video';
  }
  if (imageExtensions.includes(`.${extension}`)) {
    return 'image';
  }
  return null;
}
