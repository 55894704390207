import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import SystemAssetTypesListScreen from "./screens/list/SystemAssetTypesListScreen";
import SystemAssetTypesManageScreen from "./screens/manage/SystemAssetTypesManageScreen";
import SystemSubAssetTypesScreen from "./screens/subAssetTypes/SystemSubAssetTypesScreen";

const Stack = createNativeStackNavigator<SystemAssetTypesStackParamList>();

export default function SystemAssetTypesStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='list'
        component={SystemAssetTypesListScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='manage'
        component={SystemAssetTypesManageScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='subAssetTypes'
        component={SystemSubAssetTypesScreen}
        options={{headerShown: false}}
      />
    </Stack.Navigator>
  );
};

export const systemAssetTypesLinkingOptions = {
  path: 'assetTypes',
  screens: {
    list: 'list',
    manage: 'manage'
  },
}

export type SystemAssetTypesStackParamList = ParamListBase & {
  list: undefined;
  manage: {id: string};
  subAssetType: {assetTypeId: string};
};
