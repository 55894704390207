import { baseApi } from './base-api'
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {CascaderOption} from "../../data/models/cascader-option";
import {buildPagedResponseTags, invalidateTags} from "../../../utils/api-utils";

export const buildingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllBuildings: build.query<any, any>({
      query: () => ({
        url: `buildings/getallbuildings`,
      })
    }),
    getAllFloors: build.query<any, any>({
      query: () => ({
        url: `buildings/getallfloors`,
      })
    }),
    getAllLocations: build.query<any, any>({
      query: () => ({
        url: `buildings/getalllocations`,
      })
    }),
    getBuildings: build.query<PagedResponse<any>, Record<string, any>>({
      query: (params) => ({
        url: `buildings/getBuildings`,
        params: params
      }),
      transformResponse: getPagedResponse,
      providesTags: result => buildPagedResponseTags(result, 'Building')
    }),
    deleteBuilding: build.mutation<any, any>({
      query: (id) => ({
        url: `buildings/deleteBuilding/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => invalidateTags('LIST', 'Building')
    }),
    createBuilding: build.mutation<any, any>({
      query: (body) => ({
        url: 'buildings/addBuilding',
        method: 'post',
        body: body
      }),
      invalidatesTags: () => invalidateTags('LIST', 'Building')
    }),
    updateBuilding: build.mutation<any, any>({
      query: (body) => ({
        url: `buildings/updateBuilding/${body.id}`,
        method: 'put',
        body: body
      }),
      invalidatesTags: (_result, _error, {id}) => invalidateTags(id, 'Building')
    }),
    getBuilding: build.query<any, any>({
      query: (buildingId) => ({
        url: `buildings/getBuilding/${buildingId}`,
      }),
    }),
    getFloors: build.query<PagedResponse<any>, Record<string, any>>({
      query: (params) => ({
        url: `buildings/getFloors`,
        params: params
      }),
      transformResponse: getPagedResponse
    }),
    deleteFloor: build.mutation<any, any>({
      query: (id) => ({
        url: `buildings/deleteFloor/${id}`,
        method: 'delete',
      }),
    }),
    createFloor: build.mutation<any, any>({
      query: (body) => ({
        url: 'buildings/addFloor',
        method: 'post',
        body: body
      }),
    }),
    updateFloor: build.mutation<any, any>({
      query: (body) => ({
        url: `buildings/updateFloor/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    getFloor: build.query<any, any>({
      query: (buildingId) => ({
        url: `buildings/getFloor/${buildingId}`,
      }),
    }),
    getLocations: build.query<PagedResponse<any>, Record<string, any>>({
      query: (params) => ({
        url: `buildings/getLocations`,
        params: params
      }),
      transformResponse: getPagedResponse
    }),
    deleteLocation: build.mutation<any, any>({
      query: (id) => ({
        url: `buildings/deleteLocation/${id}`,
        method: 'delete',
      }),
    }),
    createLocation: build.mutation<any, any>({
      query: (body) => ({
        url: 'buildings/addLocation',
        method: 'post',
        body: body
      }),
    }),
    updateLocation: build.mutation<any, any>({
      query: (body) => ({
        url: `buildings/updateLocation/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    getLocation: build.query<any, any>({
      query: (buildingId) => ({
        url: `buildings/getLocation/${buildingId}`,
      }),
    }),
    getFloorsListForAsset: build.query<any[], {buildingId: string, assetTypeId: string, subAssetTypeId?: string}>({
      query: (dto) => ({
        url: `buildings/getFloorsListForAssets`,
        params: {
          buildingId: dto.buildingId,
          assetTypeId: dto.assetTypeId,
          subAssetTypeId: dto.subAssetTypeId ? dto.subAssetTypeId : undefined
        }
      }),
    }),
    getBuildingCascaderOptions: build.query<CascaderOption[], void>({
      query: () => ({
        url: `buildings/getBuildingCascaderOptions`,
      }),
    }),
  })
})

export const {
  useGetAllBuildingsQuery,
  useGetAllFloorsQuery,
  useGetAllLocationsQuery,
  useCreateBuildingMutation,
  useDeleteBuildingMutation,
  useGetBuildingQuery,
  useGetBuildingsQuery,
  useUpdateBuildingMutation,
  useCreateFloorMutation,
  useDeleteFloorMutation,
  useGetFloorQuery,
  useGetFloorsQuery,
  useUpdateFloorMutation,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useGetLocationQuery,
  useGetLocationsQuery,
  useUpdateLocationMutation,
  useLazyGetBuildingQuery,
  useLazyGetBuildingsQuery,
  useLazyGetFloorsQuery,
  useLazyGetFloorsListForAssetQuery,
  useGetBuildingCascaderOptionsQuery
} = buildingApi
