import MobilePageLayout from "../../../layouts/MobilePageLayout";
import AssetSetupForm from "./components/AssetSetupForm";
import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AssetStackParamList} from "../AssetStack";
import {compressImage} from "../../../../../utils/imageUtils";
import {getImageBase64} from "../../../../../utils/media-utils";
import {useToast} from "../../../../../components/toast/ToastProvider";
import {useSetLoading} from "../../../../../hooks/useSetLoading";
import {useAddAssetMutation} from "../../../../../store/services/api/asset-api";

export default function AssetSetupScreen({route, navigation}: ScreenProps<AssetStackParamList, 'setup'>) {
  const uniqueRef = route.params?.uniqueRef;
  const [createAsset, {isLoading}] = useAddAssetMutation();

  const {show} = useToast();
  useSetLoading([isLoading]);

  const onSubmit = async (data) => {
    if (data.images?.length > 0) {
      const base64 = await getImageBase64(data.images[0]);
      data.ImageBase64 = await compressImage(base64);
      delete data.images;
    }

    createAsset(data)
      .unwrap()
      .then(() => {
        show('Success', 'Successfully created asset', 'success');
        navigation.navigate("asset", { uniqueRef: data.uniqueReference });
      });
  };
  
  return (
    <MobilePageLayout>
      <AssetSetupForm uniqueRef={uniqueRef} onSubmit={onSubmit} />
    </MobilePageLayout>
  )
}
