import WebPageLayout from "../../../../../layouts/WebPageLayout";
import ManagePageLayout from "../../../../../layouts/ManagePageLayout";
import React from "react";
import {ScreenProps} from "../../../../../../../utils/screen-props-helper";
import useEntityManagePage from "../../../../../hooks/useEntityManagePage";
import {
  useCreateUserMutation, useLazyGetUserQuery,
  useUpdateUserMutation
} from "../../../../../../../store/services/api/user-api";
import {SystemUsersStackParamList} from "../../SystemUsersStack";
import WebUsersManageForm from "./components/WebUsersManageForm";

export default function SystemUsersManageScreen({route}: ScreenProps<SystemUsersStackParamList, 'manage'>) {
  const {
    entity,
    onSubmit
  } = useEntityManagePage({
    entityName: "User",
    entityId: route?.params?.id,
    createMutation: useCreateUserMutation,
    updateMutation: useUpdateUserMutation,
    lazyGetEntityQuery: useLazyGetUserQuery
  })

  return (
    <WebPageLayout header={{
      previousTab: {title: 'Users', routeName: 'list'},
      tabs: [
        {title: 'Manage User', routeName: 'manage'}
      ]
    }}>
      <ManagePageLayout>
        <WebUsersManageForm user={entity} onSubmit={onSubmit}/>
      </ManagePageLayout>
    </WebPageLayout>
  )
}
