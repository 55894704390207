import MobileScreen from "../../../../../components/MobileScreen";
import {StyleSheet, Text, View} from "react-native";
import {CoreList} from "../../../../../components/core-list/CoreList";
import {TroubleshootingGuide} from "../../../../../../../store/data/entities/troubleshooting-guide";
import React, {useEffect} from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {useLazyGetGuideForJobQuery} from "../../../../../../../store/services/api/troubleshooting-guide-api";
import {useLazyGetAssetByJobIdQuery} from "../../../../../../../store/services/api/asset-api";
import {useSetLoading} from "../../../../../../../hooks/useSetLoading";

export function AssetInformation({navigation, route}: NativeStackScreenProps<any, any>) {
  const jobId = route?.params?.jobId;
  const [getGuides, {data: troubleshootingGuide, isFetching: guideFetching}] = useLazyGetGuideForJobQuery(jobId);
  const [getAsset, {data: asset, isFetching: assetFetching}] = useLazyGetAssetByJobIdQuery();
  const guides = troubleshootingGuide ? [troubleshootingGuide] : [];

  useEffect(() => {
    if (!jobId) {
      navigation.navigate("help");
      return;
    }
    
    getGuides(jobId);
    getAsset(jobId);
  }, [jobId]);

  useSetLoading([assetFetching, guideFetching]);

  return (
    <MobileScreen>
      {asset && (
        <View style={styles.itemContainer}>
          <Text style={styles.title}>Supporting Information</Text>
          <Text>{asset?.supportingInformation}</Text>
        </View>
      )}
      <View style={styles.listContainer}>
        <CoreList<TroubleshootingGuide>
          data={guides}
          itemWrapper={null}
          emptyListText='No guides have been created for this asset'
          renderItem={({item}) => (
            <View style={styles.itemContainer}>
              <Text style={styles.title}>Guide</Text>
              <Text>{item?.text}</Text>
            </View>
          )}
        />
      </View>
    </MobileScreen>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    flex: 1
  },
  itemContainer: {
    marginVertical: 4,
    borderRadius: 4,
    backgroundColor: '#fff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
    paddingVertical: 12,
    paddingHorizontal: 18
  },
  title: {
    fontSize: 18,
    marginBottom: 4
  }
});

