import {ActivityIndicator, StyleSheet, View} from "react-native";
import React, {ReactNode} from "react";
import {usePage} from "../../../hooks/usePage";
import PageHeader, {WebPageHeaderProps} from "../components/PageHeader";

interface WebPageLayoutProps {
  children: ReactNode;
  header: WebPageHeaderProps;
}

export default function WebPageLayout({ children, header }: WebPageLayoutProps) {
  const {isLoading} = usePage();

  return (
    <View style={styles.pageContainer}>
      <PageHeader header={header} />
      <View style={styles.contentContainer}>
        {children}
      </View>
      {isLoading && <WebLoadingIndicator />}
    </View>
  );
}

function WebLoadingIndicator() {
  return (
    <View style={styles.indicatorContainer}>
      <ActivityIndicator size={48} />
    </View>
  )
}

const styles = StyleSheet.create({
  pageContainer: {
    height: '100%',
    paddingHorizontal: 18,
    paddingVertical: 8,
    overflow: "hidden",
    position: 'relative'
  },
  contentContainer: {
    flex: 1
  },
  indicatorContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
})
