import {useGetUsersOfRoleQuery} from "../../../store/services/api/user-api";
import {Select, SelectProps} from "antd";
import {UserRole} from "../../../store/data/users/user-roles";

export type UserSelectProps = Omit<SelectProps, 'loading' | 'options'> & {
  userRoles?: UserRole[];
}

export default function UserSelect(props: UserSelectProps) {
  const {data, isLoading} = useGetUsersOfRoleQuery(props.userRoles ?? []);

  const options =  data?.map(user => ({
    value: user.id, label: user.name
  })) ?? [];

  return (
    <Select
      {...props}
      loading={isLoading}
      options={options}
    />
  )
}
