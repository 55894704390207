import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import SystemAssetsListScreen from "./screens/list/SystemAssetsListScreen";
import SystemAssetsManageScreen from "./screens/manage/SystemAssetsManageScreen";

const Stack = createNativeStackNavigator<SystemAssetsStackParamList>();

export default function SystemAssetsStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='list'
        component={SystemAssetsListScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='manage'
        component={SystemAssetsManageScreen}
        options={{headerShown: false}}
      />
    </Stack.Navigator>
  );
};

export const systemAssetsLinkingOptions = {
  path: 'assets',
  screens: {
    list: 'list',
    manage: 'manage'
  },
}

export type SystemAssetsStackParamList = ParamListBase & {
  list: undefined;
  manage: {id: string};
};
