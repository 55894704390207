import * as React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { EvilIcons } from '@expo/vector-icons';
import { assetApi, useAddAssetMutation, useUpdateAssetMutation } from '../../../../store/services/api/asset-api';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../constants/GlobalStyle';
import { PlatformType, compressImage } from '../../../../utils/imageUtils';
import AssetManageControl from "./AssetManageControl";
import {WebScreen} from "../../components/WebScreen";
import {usePage} from "../../../../hooks/usePage";
import {useToast} from "../../../../components/toast/ToastProvider";
import {useEffect} from "react";

export default function AssetManageScreen({ navigation, route }: { navigation: any, route: any }) {
    const [createAsset, createAssetResult] = useAddAssetMutation();
    const [updateAsset, updateAssetResult] = useUpdateAssetMutation();

    const [trigger, assetResult] = assetApi.useLazyGetAssetQuery();
    const {setIsLoading} = usePage();
    const {show: showToast} = useToast();

    React.useEffect(() => {
        if (route.params?.assetId != null) {
            trigger(route.params?.assetId);
        }
    }, [route.params?.assetId])

    const onSubmit = async (data) => {
        var result = null;
        setIsLoading(true);
        const platform = PlatformType.Web;
        if (data.imageBase64) {
            const compressedImage = await compressImage(data.imageBase64, platform);
            data.imageBase64 = compressedImage;
        }

        if (data.id != null) {
            result = await updateAsset(data) as any;
        } else {
            result = await createAsset(data) as any;
        }

        if (result.error) {
          showToast('Error', 'There was an issue updating the asset', 'error');
          setIsLoading(false);
          return;
        }

        showToast('Updated Asset', 'Successfully updated asset', 'success');
        setIsLoading(false);
        navigation.navigate("list");
    }

    return (
      <WebScreen>
        <View style={GlobalStyle.sub__title}>
          <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.navigate("list")}>
            <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
          </TouchableOpacity>
          <Text style={GlobalStyle.sub__title__text}>{"MANAGE ASSET"}</Text>
        </View>

        <AssetManageControl
          initialValue={assetResult?.data}
          onSubmit={onSubmit} />
      </WebScreen>
    );
}
