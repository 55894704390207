import {Form, Input, Modal} from "antd";
import {
  useAddWorkOrderAssetMutation,
  useCreateWorkOrderMutation,
  useGetWorkOrderChildQuery
} from "../../../store/services/api/work-order-api";
import {WorkOrderForm, WorkOrderType} from "../../../store/data/entities/work-order";
import {useEffect} from "react";
import UserSelect from "../../../components/forms/controls/UserSelect";

interface FollowUpWorkOrderModal {
  visible: boolean;
  onClose: () => void;
  onSuccess: (workOrderId: string) => void;
  assetLabel: string;
  assetId: string;
  parentWorkOrderId: string | undefined;
}

export default function FollowUpWorkOrderModal(props: FollowUpWorkOrderModal) {
  const [createWorkOrder, {isLoading: createLoading}] = useCreateWorkOrderMutation();
  const [addAsset, {isLoading: addLoading}] = useAddWorkOrderAssetMutation();
  const {data: childWorkOrder, isFetching: childWorkOrderFetching, isUninitialized} = useGetWorkOrderChildQuery(props?.parentWorkOrderId, {
    skip: !props?.parentWorkOrderId,
  });
  const followUpExists = !!childWorkOrder?.id;

  const [form] = Form.useForm<WorkOrderForm>();

  useEffect(() => {
    form.resetFields();

    if (childWorkOrder) {
      form.setFieldValue('description', childWorkOrder.description);
      form.setFieldValue('technicianId', childWorkOrder.technicianId);
      form.setFieldValue('buildingLeadId', childWorkOrder.buildingLeadId);
    }
  }, [props.visible, childWorkOrder]);

  const handleOnSubmit = (data: WorkOrderForm) => {
    if (!props.assetId || isUninitialized) return;

    if (followUpExists) {
      handleAddWorkOrderAsset();
      return;
    }

    handleCreateWorkOrder(data);
  }

  const handleCreateWorkOrder = (data: WorkOrderForm) => {
    if (!props.parentWorkOrderId) return;

    createWorkOrder({
      ...data,
      type: WorkOrderType.Corrective,
      assets: [props.assetId],
      parentWorkOrderId: props.parentWorkOrderId
    }).unwrap()
      .then((wo) => props.onSuccess(wo.id))
      .finally(props.onClose)
  }

  const handleAddWorkOrderAsset = () => {
    if (!childWorkOrder?.id) return;

    addAsset({
      assetId: props.assetId,
      workOrderId: childWorkOrder.id
    }).unwrap()
      .then(() => props.onSuccess(childWorkOrder.id))
      .finally(() => props.onClose())
  }

  return (
    <Modal
      destroyOnClose
      title="Follow-up Work Order"
      open={props.visible}
      onOk={() => form.submit()}
      onCancel={props.onClose}
      loading={createLoading || addLoading || childWorkOrderFetching}
    >
      <Form
        style={{
          maxHeight: '80vh',
          overflowY: 'auto'
        }}
        form={form}
        onFinish={handleOnSubmit}
        disabled={createLoading || addLoading || childWorkOrderFetching}
      >
        <Form.Item label='Asset'>
          <Input value={props?.assetLabel} disabled />
        </Form.Item>
        <Form.Item name='description' label='Description'>
          <Input disabled={followUpExists} />
        </Form.Item>
        <Form.Item name='technicianId' label='Technician'>
          <UserSelect userRoles={['Technician']} style={{ width: '100%' }} disabled={followUpExists} />
        </Form.Item>
        <Form.Item name='buildingLeadId' label='Building Lead'>
          <UserSelect userRoles={['BuildingLead']} style={{ width: '100%' }} disabled={followUpExists} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
