import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {useWorkOrder} from "../../WorkOrdersStack";
import React, {useEffect} from "react";
import {FlatList, ListRenderItemInfo, View} from "react-native";
import {useLazyGetWorkOrderJobPlansQuery} from "../../../../../../store/services/api/work-order-api";
import {JobPlan} from "../../../../../../store/data/entities/job-plan";
import {TechnicianJobPlansStackParamList} from "./TechnicianJobPlansStack";
import {Button, Card} from "react-native-paper";
import Skeleton from "../../../../../../components/skeletons/Skeleton";
import {EmptyList} from "../../../../../../components/flatlist/EmptyList";

export default function TechnicianJobPlansList({navigation}: ScreenProps<TechnicianJobPlansStackParamList, 'jobPlans'>) {
  const {id} = useWorkOrder();
  const [getJobPlans, {data, isFetching}] = useLazyGetWorkOrderJobPlansQuery();

  useEffect(() => {
    if (id) {
      getJobPlans(id);
    }
  }, [id]);

  const renderJobPlan = ({item}: ListRenderItemInfo<JobPlan>) => {
    const handleOnPress = () => navigation.navigate('details', {id: item.id})

    return (
      <Card style={{backgroundColor: 'white'}}>
        <Card.Title title={item.reference} subtitle={item.title} />
        <Card.Actions>
          <Button onPress={handleOnPress}>View</Button>
        </Card.Actions>
      </Card>
    )
  }

  return (
    <Skeleton
      loading={isFetching}
      placeholder={<Skeleton.Card />}
    >
      <FlatList
        style={{paddingHorizontal: 4, paddingVertical: 6}}
        ItemSeparatorComponent={() => <View style={{height: 6}} />}
        data={data ?? []}
        renderItem={renderJobPlan}
        ListEmptyComponent={
          <EmptyList
            isLoading={isFetching}
            emptyText={'There are no job plans assign to this work order.'} />
        }
      />
    </Skeleton>
  );
}
