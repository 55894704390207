import {TextInputFilter} from "../../../../../../../web/components/search-filters/SearchFilters";
import React from "react";
import {CoreFilters, CoreFiltersBaseProps} from "../../../../../../components/CoreFilters";

export default function SystemAssetsScreenFilters({filter, onSubmit}: CoreFiltersBaseProps) {
  return (
    <CoreFilters
      filter={filter}
      onSubmit={onSubmit}
    >
      <TextInputFilter title='Name' name='name' />
    </CoreFilters>
  )
}
