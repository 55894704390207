import {
  useSetWorkOrderStatusMutation
} from "../../../store/services/api/work-order-api";
import {WorkOrderStatus} from "../../../store/data/entities/work-order";
import {Form, Modal, Input, Typography} from "antd";
const {TextArea} = Input;
const {Text} = Typography;

interface CloseWorkOrderModalProps {
  id: string;
  rejectWork: boolean;
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
}

type CloseWorkForm = {
  rejectionReason: string;
}

export default function CloseWorkOrderModal(props: CloseWorkOrderModalProps) {
  const [setStatus, {isLoading}] = useSetWorkOrderStatusMutation();
  const [form] = Form.useForm<CloseWorkForm>();

  const handleOnSubmit = () => {
    if (props.rejectWork) {
      form.submit();
      return;
    }

    setStatus({
      workOrderId: props.id,
      status: WorkOrderStatus.Closed
    }).unwrap()
      .then(props.onSuccess)
      .finally(props.onClose);
  }

  const handleRejectWork = (data: CloseWorkForm) => {
    setStatus({
      workOrderId: props.id,
      status: WorkOrderStatus.Approved,
      reason: data.rejectionReason
    }).unwrap()
      .then(props.onSuccess)
      .finally(props.onClose);
  }

  const title = !props.rejectWork ? 'Close Work Order' : 'Return Work Order to Technician';
  const message = !props.rejectWork
    ? 'Are you should you want to close this Work Order?'
    : 'Are you should you want to return this Work Order the to technician?';

  return (
    <Modal
      title={title}
      open={props.open}
      onClose={props.onClose}
      onCancel={props.onClose}
      onOk={handleOnSubmit}
      loading={isLoading}
      destroyOnClose
    >
      <Text>{message}</Text>
      {props.rejectWork && (
        <Form
          form={form}
          onFinish={handleRejectWork}
        >
          <Form.Item name='rejectionReason' label='Reason'>
            <TextArea />
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}
