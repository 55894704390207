import {Text, TouchableOpacity, View} from "react-native";
import GlobalStyle, {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import {EvilIcons} from "@expo/vector-icons";
import * as React from "react";
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {TextFieldController} from "../../components/forms/TextFieldController";
import {
  Job,
  JobPriorities,
  JobType,
  JobTypes,
  NewMaintenanceJob,
  NewTicketJob
} from "../../../../store/data/entities/job";
import CoreForm from "../../components/forms/CoreForm";
import {PickerFieldController} from "../../components/forms/PickerFieldController";
import {
  useCreateMaintenanceJobMutation,
  useCreateTicketJobMutation,
  useLazyGetJobQuery, useUpdateJobMutation
} from "../../../../store/services/api/job-api";
import {useToast} from "../../../../components/toast/ToastProvider";
import {ComboTextFieldController} from "../../components/forms/ComboTextFieldController";
import {
  useLazyGetAssetQuery,
  useLazyGetChecklistsForAssetQuery,
  useLazySearchAssetQuery
} from "../../../../store/services/api/asset-api";
import {debounce} from "../../../../utils/debounce";
import {WebScreen} from "../../components/WebScreen";
import {DateFieldController} from "../../components/forms/DateFieldController";
import moment from "moment/moment";
import {useGetUsersOfRoleQuery} from "../../../../store/services/api/user-api";
import {useSetLoading} from "../../../../hooks/useSetLoading";
import {useLazyGetMaintenanceJobQuery} from "../../../../store/services/api/maintenance-job-api";
import {useLazyGetCorrectiveJobQuery} from "../../../../store/services/api/corrective-job-api";
import {MaintenanceJob} from "../../../../store/data/entities/maintenance-job";
import {CorrectiveJob} from "../../../../store/data/entities/corrective-job";

export default function JobManage({navigation, route}) {
  const jobId = route.params?.jobId;
  const [getJob, {data: initialJob, isFetching: initialJobFetching}] = useLazyGetJobQuery();
  const [getMaintenanceJob, {data: initialMaintenanceJob, isFetching: initialMaintenanceJobFetching}] = useLazyGetMaintenanceJobQuery();
  const [getCorrectiveJob, {data: initialCorrectiveJob, isFetching: initialCorrectiveFetching}] = useLazyGetCorrectiveJobQuery();

  const [searchAssets, {data: assets, isFetching: assetsFetching}] = useLazySearchAssetQuery();
  const [getAsset, {data: selectedAsset, isFetching: selectedAssetFetching}] = useLazyGetAssetQuery();
  const [getChecklists, {data: checklists, isFetching: checklistsFetching}] = useLazyGetChecklistsForAssetQuery();
  const {data: usersList, isLoading: usersListLoading} = useGetUsersOfRoleQuery('Technician');
  const [createMaintenanceJob, {
    isLoading: maintenanceJobLoading,
    isSuccess: maintenanceJobSuccess,
    isError: isMaintenanceJobError }] = useCreateMaintenanceJobMutation();

  const [createTicketJob, {
    isLoading: ticketJobLoading,
    isSuccess: ticketJobSuccess,
    isError: isTicketJobError }] = useCreateTicketJobMutation();

  const [updateJob, {
    isLoading: updateLoading,
    isSuccess: updateSuccess,
    isError: updateError }] = useUpdateJobMutation();

  const form = useForm<NewTicketJob | NewMaintenanceJob>({
    defaultValues: {
      id: '',
      reference: '',
      plannedDate: moment().toISOString(),
      type: undefined,
      priority: undefined,
      technicianId: '',
      issue: '',
      contactEmail: '',
      assetId: '',
      checklistId: ''
    }
  });

  const { watch, handleSubmit, reset } = form;
  const {show} = useToast();

  const selectedJobType = watch('type');
  const selectedAssetId = watch('assetId');

  useSetLoading([
    ticketJobLoading,
    maintenanceJobLoading,
    usersListLoading,
    checklistsFetching,
    initialJobFetching,
    updateLoading,
    initialMaintenanceJobFetching,
    initialCorrectiveFetching
  ]);

  useEffect(() => {
    if (jobId) {
      getJob(jobId);
    }
  }, [jobId]);

  useEffect(() => {
    if (initialJob && jobId) {
      const query = initialJob.type == JobType.Corrective ? getCorrectiveJob : getMaintenanceJob;
      query(initialJob.id);
    }
  }, [initialJob]);

  useEffect(() => {
    if (initialCorrectiveJob) {
      handleOnJobLoaded(initialJob, initialCorrectiveJob);
    }
    if (initialMaintenanceJob) {
      handleOnJobLoaded(initialJob, initialMaintenanceJob);
    }
  }, [initialCorrectiveJob, initialMaintenanceJob]);

  useEffect(() => {
    if (selectedAssetId) {
      getAsset(selectedAssetId);
      getChecklists(selectedAssetId);
    }
  }, [selectedAssetId]);

  const handleOnJobLoaded = (job: Job, jobExtras: Partial<MaintenanceJob> | Partial<CorrectiveJob>) => {
    reset({
      id: job.id,
      reference: job.reference,
      plannedDate: job.plannedDate,
      type: job.type,
      priority: job.priority,
      technicianId: job.technicianId,
      issue: (jobExtras as CorrectiveJob)?.ticket?.issue ?? '',
      contactEmail: (jobExtras as CorrectiveJob)?.ticket?.contactEmail ?? '',
      assetId: (jobExtras as MaintenanceJob)?.assetId ?? (jobExtras as CorrectiveJob)?.ticket?.assetId ?? '',
      checklistId: (jobExtras as MaintenanceJob)?.maintenanceChecklistId ?? '',
    });
  }

  const handleOnFormSubmit = () => {
    if (jobId) {
      handleSubmit((data) => {
        updateJob({
          id: data.id,
          reference: data.reference,
          plannedDate: data.plannedDate,
          technicianId: data.technicianId,
          priority: data.priority
        })
      })();
      return;
    }

    if (selectedJobType == JobType.Corrective) {
      handleSubmit(createTicketJob)();
      return;
    }

    if (selectedJobType == JobType.Maintenance) {
      handleSubmit(createMaintenanceJob)();
      return;
    }

    form.trigger();
  }

  useEffect(() => {
    if (ticketJobSuccess || maintenanceJobSuccess || updateSuccess) {
      navigateBack();
      show('Created Job', 'Successfully created job', 'success');
    }
  }, [ticketJobSuccess, maintenanceJobSuccess, updateSuccess]);

  useEffect(() => {
    if (isTicketJobError || isMaintenanceJobError || updateError) {
      show('Error', 'There was an issue creating the job', 'error');
    }
  }, [isTicketJobError, isMaintenanceJobError, updateError]);

  const navigateBack = () => navigation.navigate('list');

  const debouncedSearchAssets = React.useMemo(
    () => debounce((value) => {
      if (value?.length >= 3) {
        searchAssets(value)
      }
    }, 600),
    [searchAssets]
  );

  const mapAssetToOption = (asset: any) => {
    const label = asset?.uniqueReference ? `(${asset?.uniqueReference}) ${asset?.code}` : asset?.code

    return ({
      key: asset?.id,
      value: asset?.id,
      label: label
    })
  };

  const assetOptions = assets?.map(mapAssetToOption) ?? [];

  return (
    <WebScreen>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.navigate("list")}>
          <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{"MANAGE WORK ORDER"}</Text>
      </View>

      <CoreForm form={form} onSubmit={handleOnFormSubmit}>
        <CoreForm.Row>
          <TextFieldController
            required
            controlName='reference'
            title='Job Reference'
          />
          <PickerFieldController
            required
            controlName='technicianId'
            defaultFirst={false}
            title='Technician'
            items={(usersList ?? []).map(item => ({
              label: item.name,
              value: item.id
            }))}
          />
          <PickerFieldController
            disabled={jobId}
            required
            defaultFirst={false}
            controlName='type'
            title='Job Type'
            items={JobTypes}
          />
          <PickerFieldController
            required
            defaultFirst={false}
            controlName='priority'
            title='Job Priority'
            items={JobPriorities}
          />
        </CoreForm.Row>
        {selectedJobType == JobType.Corrective && (
          <CoreForm.Row>
            <DateFieldController
              required
              controlName='plannedDate'
              title='Planned Date'
            />
            <ComboTextFieldController
              disabled={jobId}
              required
              initialSelection={mapAssetToOption(selectedAsset)}
              optionsLoading={assetsFetching || selectedAssetFetching}
              onTextChanged={debouncedSearchAssets}
              options={assetOptions}
              controlName='assetId'
              title='Asset' />
            <TextFieldController disabled={jobId} required controlName='issue' title='Issue' />
            <TextFieldController disabled={jobId} required controlName='contactEmail' title='Contact Email' />
          </CoreForm.Row>
        )}

        {selectedJobType == JobType.Maintenance && (
          <CoreForm.Row>
            <ComboTextFieldController
              disabled={jobId}
              initialSelection={mapAssetToOption(selectedAsset)}
              optionsLoading={assetsFetching || selectedAssetFetching}
              onTextChanged={debouncedSearchAssets}
              options={assetOptions}
              controlName='assetId'
              title='Asset'
            />
            <PickerFieldController
              disabled={jobId}
              controlName='checklistId'
              title='Work Order' items={(checklists ?? []).map(item => ({
                key: item?.id,
                value: item?.id,
                label: item.title
              }))}
            />
          </CoreForm.Row>
        )}
      </CoreForm>
    </WebScreen>
  )
}
