import React, { ReactNode } from "react";
import { FlatList, StyleSheet } from "react-native";
import { DataTable } from "react-native-paper";
import {EmptyList} from "../../../components/flatlist/EmptyList";

type TableColumn<T> = {
  title?: string;
  key?: keyof T;
  render?: (item: T) => ReactNode;
  flex?: number;
};

interface TableProps<T> {
  columns: TableColumn<T>[];
  data: T[];
  keyExtractor: (data: T, index?: number) => string;
}

const Table = <T,>({ columns, data, keyExtractor }: TableProps<T>) => {
  const hideHeader = columns.some((c) => !c?.title);

  return (
    <DataTable style={styles.container}>
      {!hideHeader && (
        <DataTable.Header>
          {columns.map((column, i) => (
            <DataTable.Title
              key={`${column?.title}-${i}`}
              style={{ flex: column?.flex ?? 1 }}
            >
              {column?.title}
            </DataTable.Title>
          ))}
        </DataTable.Header>
      )}

      <FlatList
        data={data}
        ListEmptyComponent={<EmptyList />}
        renderItem={({ item, index }) => {
          const key = keyExtractor(item, index);
          return (
            <DataTable.Row key={key}>
              {columns.map((column, colIndex) => (
                <DataTable.Cell
                  key={`cell-${key}-${colIndex}`}
                  style={{ flex: column?.flex ?? 1 }}
                >
                  {column.render
                    ? column.render(item)
                    : (item as any)[column.key] ?? "N/A"}
                </DataTable.Cell>
              ))}
            </DataTable.Row>
          );
        }}
      />
    </DataTable>
  );
};

export default Table;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderColor: '#CECECE',
    borderRadius: 6,
    flexShrink: 1
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    padding: 10,
    backgroundColor: "#f5f5f5",
    borderBottomWidth: 1,
    borderBottomColor: "#d9d9d9",
  },
  table: {
    flexDirection: "column",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#d9d9d9",
  },
  cellLabel: {
    flex: 1,
    padding: 10,
    backgroundColor: "#f7f7f7",
    borderRightWidth: 1,
    borderRightColor: "#d9d9d9",
  },
  cellValue: {
    flex: 2,
    padding: 10,
    backgroundColor: "#fff",
  },
  label: {
    fontWeight: "bold",
    color: "#595959",
  },
  value: {
    color: "#000",
  }
});
