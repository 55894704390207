import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react'
import { RootState } from '../..';
import Constants from "expo-constants";
import {notification} from "antd";

export class PagedTableDto {
  page: number;
  sortedColumn: string;
  sortedColumnAscending: boolean;
  filters: any;

  constructor(page: number, filters: any, sortedColumn?: string, sortedColumnAscending?: boolean) {
    this.page = page;
    this.filters = filters;
    this.sortedColumn = sortedColumn;
    this.sortedColumnAscending = sortedColumnAscending;
  }


  getFilters(): string {
    var filterString = "";
    if (this.sortedColumn != null) { filterString += `sortedColumn=${this.sortedColumn}&` }
    if (this.sortedColumnAscending != null) { filterString += `sortedColumnAscending=${this.sortedColumnAscending}&` }

    for (let key in this.filters) {
      var value = this.filters[key];
      if (value != null && value != "") {
        filterString += `${key}=${value}&`
      }
    }

    return filterString;
  }
}

// @ts-ignore
export const baseUrl = Constants.expoConfig.baseUrl;

export type TagType = 'Ticket'
  | 'WorkOrder'
  | 'WorkOrderChild'
  | 'WorkOrderAsset'
  | 'WorkOrderLog'
  | 'WorkOrderLabour'
  | 'JobPlan'
  | 'PreventativeMaintenance'
  | 'Asset'
  | 'AssetTypes'
  | 'SubAssetTypes'
  | 'Building';

export const apiTagTypes: TagType[] =
  [
    'Ticket',
    'WorkOrder',
    'WorkOrderAsset',
    'WorkOrderLog',
    'WorkOrderLabour',
    'WorkOrderChild',
    'JobPlan',
    'PreventativeMaintenance',
    'Asset',
    'AssetTypes',
    'SubAssetTypes',
    'Building'
  ];

const baseQueryWithErrorNotification:
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${baseUrl}api/`,
    prepareHeaders: (headers, { getState }) => {
      var user = (getState() as RootState).user;
      if (user == null) {
        return headers;
      }

      const token = user.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  });

  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    const { data } = result.error;
    let message = 'An unexpected error occurred!';
    if (data) {
      message = data as string;
    }

    notification.error({
      message: `Error`,
      description: message,
      placement: 'bottomRight',
      duration: 3,
    });
  }

  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: baseQueryWithErrorNotification,
  tagTypes: apiTagTypes,
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
})
