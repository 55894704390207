import {DatePicker, Form, FormInstance, Input, InputNumber, Select} from "antd";
import {useGetUsersOfRoleQuery} from "../../../../../../store/services/api/user-api";
import React from "react";
import {PeriodicityOptions} from "../../../../../../store/data/models/periodicity";

interface PreventativeMaintenanceManageFormProps {
  form: FormInstance;
  isApproval: boolean;
}

export default function PreventativeMaintenanceDetailsForm(props: PreventativeMaintenanceManageFormProps) {
  const { data: technicians, isFetching: techniciansFetching } = useGetUsersOfRoleQuery(['Technician']);
  const { data: buildingLeads, isFetching: buildingLeadsFetching } = useGetUsersOfRoleQuery(['BuildingLead']);
  const {form} = props;

  const frequencyPeriod = Form.useWatch('frequencyPeriod', props.form);
  const autoGenerateOffsetPeriod = Form.useWatch('autoGenerateOffsetPeriod', props.form);

  const mapUserOptions = (usersList: any) =>
    (usersList ?? []).map(item => ({
      label: item.name,
      value: item.id
    }));

  return (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name='title'
        label='Title'
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='technicianId'
        label='Technician'
        rules={[{ required: true }]}
      >
        <Select
          options={mapUserOptions(technicians)}
          loading={techniciansFetching}
        />
      </Form.Item>
      <Form.Item
        name='buildingLeadId'
        label='Owner'
        rules={[{ required: true }]}
      >
        <Select
          options={mapUserOptions(buildingLeads)}
          loading={buildingLeadsFetching}
        />
      </Form.Item>
      <Form.Item
        name='startDate'
        label='Start Date'
        rules={[{ required: true }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Frequency"
        name='frequency'
        rules={[{ required: true }]}
      >
        <InputNumber
          addonAfter={
            <Select
              style={{ minWidth: 96 }}
              defaultValue={PeriodicityOptions[0].value}
              value={frequencyPeriod}
              onChange={(value) => form.setFieldValue("frequencyPeriod", value)}
              options={PeriodicityOptions}
            />
          }
        />
      </Form.Item>
      <Form.Item
        label="Notify Me"
        name='autoGenerateOffset'
        rules={[{ required: true }]}
      >
        <InputNumber
          addonAfter={
            <Select
              style={{ minWidth: 96 }}
              defaultValue={PeriodicityOptions[0].value}
              value={autoGenerateOffsetPeriod}
              onChange={(value) => form.setFieldValue("autoGenerateOffsetPeriod", value)}
              options={PeriodicityOptions}
            />
          }
        />
      </Form.Item>

      <Form.Item name='autoGenerateOffsetPeriod' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='frequencyPeriod' hidden>
        <Input />
      </Form.Item>
    </>
  )
}
