import {CoreModal, CoreModalProps} from "../../../hooks/useModal";
import {StyleSheet, View} from "react-native";
import {Controller, useForm} from "react-hook-form";
import {useValidatePinMutation} from "../../../store/services/api/systemSettings-api";
import React from "react";
import {TextInput, Text, HelperText} from "react-native-paper";

interface AdminPinModalProps {
  onSuccess: () => void;
  onClose: () => void;
}

export function AdminPinModal({onSuccess, onClose} : AdminPinModalProps) {
  const [validatePin, {isError, isLoading}] = useValidatePinMutation();

  const form = useForm({
    defaultValues:{
      pin: ""
    }
  });

  const handleOnValidatePin = () => {
    form.handleSubmit((data) => {
      validatePin(data.pin)
        .unwrap()
        .then(onSuccess)
    })();
  }

  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Cancel', mode: 'text', disabled: isLoading, onPress: onClose}
    ],
    rightButtons: [
      {label: 'Confirm', mode: 'outlined', disabled: isLoading, onPress: handleOnValidatePin},
    ]
  }

  return (
    <CoreModal {...modalProps}>
      <View style={styles.container}>
        <Text
          style={{marginBottom: 18}}
          variant='bodyLarge'
        >Administrators Only</Text>
        <Controller
          control={form.control}
          rules={{required: true}}
          name='pin'
          render={({field: {value, onChange}, fieldState: {error}}) => (
            <>
              <TextInput
                label='Pin'
                mode='outlined'
                value={value}
                onChange={onChange}
                error={(isError || !!error) && !isLoading}
              />
              <HelperText type="error" visible={(isError || !!error) && !isLoading}>
                {isError ? 'Incorrect Pin' : 'Pin Required'}
              </HelperText>
            </>
          )}
        />
      </View>
    </CoreModal>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingBottom: 12,
  }
});
