import {Form, Input, Modal} from "antd";
import {useAddTicketMutation} from "../../../../../../store/services/api/ticket-api";
import AssetFormControl from "../../../../../../components/form-controls/AssetFormControl";
import React, {useEffect} from "react";

interface TicketForm {
  issue: string;
  contactEmail: string;
  assetId: string;
}

interface CreateTicketModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export default function CreateTicketModal(props: CreateTicketModalProps) {
  const [form] = Form.useForm<TicketForm>();
  const [createTicket, {isLoading: createLoading}] = useAddTicketMutation();

  const convertFormData = (data): any => ({
    ...data,
    assetId: data.assets?.value,
    assets: undefined
  });

  const handleOnFinish = (data: TicketForm) => {
    createTicket(convertFormData(data))
      .unwrap()
      .then(() => {
        props.onSuccess?.();
        props.onClose();
      });
  }

  useEffect(() => {
    form.resetFields();
  }, [props.open])

  return (
    <Modal
      destroyOnClose
      title='Create Ticket'
      open={props.open}
      onClose={props.onClose}
      onCancel={props.onClose}
      onOk={() => form.submit()}
      loading={createLoading}
    >
      <Form<TicketForm>
        form={form}
        onFinish={handleOnFinish}
        layout='vertical'
      >
        <Form.Item name='issue' label='Issue' rules={[{required: true}]}>
          <Input />
        </Form.Item>

        <Form.Item name='contactEmail' label='Contact Email' rules={[{required: true}]}>
          <Input />
        </Form.Item>

        <Form.Item name='assets' label='Asset' rules={[{required: true}]}>
          <AssetFormControl />
        </Form.Item>
      </Form>
    </Modal>
  )
}
