import React, {useEffect, useState} from "react";
import { View, StyleSheet, Text } from "react-native";
import {
  TextInput
} from "react-native-paper";
import {useAddUpdateMutation} from "../../../../../store/services/api/ticket-api";
import {CoreModal, CoreModalProps} from "../../../../../hooks/useModal";
import {useToast} from "../../../../../components/toast/ToastProvider";

interface TicketUpdateModalProps {
  ticketId: string;
  onClose: () => void;
}

export function TicketUpdateModal({ticketId, onClose}: TicketUpdateModalProps) {
  const [updateText, setUpdateText] = useState("");
  const [addUpdate, {isSuccess, isError, isLoading}] = useAddUpdateMutation();
  const [error, setError] = useState(null);

  const {show: showToast} = useToast();

  const clearError = () => setError(null);

  const handleSave =  async () => {
    if (!updateText) {
      setError("Update can't be empty");
      return;
    }

    await addUpdate({text: updateText, ticketId: ticketId});
  };

  useEffect(() => {
    if (isSuccess) {
      showToast("Update Successful", "Successfully added update to ticket", "success");
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast("Update Error", "There was an error adding update to ticket", "error");
    }
  }, [isError]);

  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Cancel', onPress: onClose, mode: 'text', disabled: isLoading},
    ],
    rightButtons: [
      {label: 'Submit', onPress: handleSave, mode: 'contained', disabled: isLoading},
    ]
  }

  return (
    <CoreModal {...modalProps}>
      <View style={styles.container}>
        <TextInput
          mode="outlined"
          label="Update"
          multiline={true}
          numberOfLines={15}
          onChangeText={text => { setUpdateText(text); clearError(); }}
          value={updateText}
          style={styles.textInput}
        />
        {error && <Text style={styles.errorText}>{error}</Text>}
      </View>
    </CoreModal>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingBottom: 12,
    minWidth: 600,
  },
  textInput: {
    marginBottom: 20,
    backgroundColor: "white",
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
  }
});
