import WebPageLayout from "../../../../../layouts/WebPageLayout";
import {ScreenProps} from "../../../../../../../utils/screen-props-helper";
import {SystemBuildingsStackParamList} from "../../SystemBuildingsStack";
import {
  useGetBuildingQuery,
  useGetFloorsQuery
} from "../../../../../../../store/services/api/building-api";
import SystemFloorsList from "./components/SystemFloorsList";
import SubManagePageLayout from "../../../../../layouts/SubManagePageLayout";
import {useState} from "react";
import SystemFloorsForm from "./components/SystemFloorsForm";
import {useSetLoading} from "../../../../../../../hooks/useSetLoading";

export default function SystemFloorsScreen({route}: ScreenProps<SystemBuildingsStackParamList, 'floors'>) {
  const buildingId = route?.params?.buildingId;
  const {data: building, isLoading} = useGetBuildingQuery(buildingId);
  const {data: floors, refetch, isFetching} = useGetFloorsQuery({
    buildingId: buildingId,
    pageNumber: 1,
    pageSize: 1000
  })

  useSetLoading([isFetching, isLoading]);

  const [selectedFloor, setSelectedFloor] = useState();

  return (
    <WebPageLayout header={{
      previousTab: {title: 'Buildings', routeName: 'list'},
      tabs: [
        {title: building?.name, hidden: !building?.name, disabled: true},
        {title: 'Floors', routeName: 'floors'}
      ]
    }}>
      <SubManagePageLayout
        form={(
          <SystemFloorsForm
            floor={selectedFloor}
            buildingId={buildingId}
            onSubmit={refetch}
            onClearSelection={() => setSelectedFloor(null)}
          />
        )}
        table={(
          <SystemFloorsList
            floors={floors?.list}
            onFloorSelected={setSelectedFloor}
            onFloorDeleted={refetch}
          />
        )}
      />
    </WebPageLayout>
  )
}
