import * as React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { EvilIcons } from '@expo/vector-icons';
import { AssetTypeManageControl } from './AssetTypeManageControl';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../constants/GlobalStyle';
import { assetTypeApi, useCreateAssetTypeMutation, useUpdateAssetTypeMutation } from '../../../../store/services/api/asset-type-api';



export default function AssetTypeManageScreen({ navigation, route }: { navigation: any, route: any }) {

    const [createAssetType, createAssetTypeResult] = useCreateAssetTypeMutation();
    const [updateAssetType, updateAssetTypeResult] = useUpdateAssetTypeMutation();

    const [trigger, assetTypeResult] = assetTypeApi.useLazyGetAssetTypeQuery();

    React.useEffect(() => {
        if (route.params?.assetTypeId != null) {
            trigger(route.params?.assetTypeId);
        }
    }, [])

    const onSubmit = async (data) => {
        var result = null;

        if (data.id != null) {
            result = await updateAssetType(data) as any;
        } else {
            result = await createAssetType(data) as any;
        }

        if (result.error) {
            return;

        };
        navigation.goBack();
    }

    if (createAssetTypeResult.isLoading || updateAssetTypeResult.isLoading) {
        return <LoadingIndicator />
    }

    return (
        <View style={styles.container}>
            <View style={GlobalStyle.sub__title}>
                <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.navigate("list")}>
                    <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
                <Text style={GlobalStyle.sub__title__text}>{"MANAGE ASSET TYPE"}</Text>
            </View>

            <AssetTypeManageControl
                assetType={assetTypeResult?.data}
                onSubmit={onSubmit} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    },
});

