import {StyleSheet, Text, View} from "react-native";
import {CoreModal, CoreModalProps} from "../../hooks/useModal";

interface AlertModalViewProps {
  title: string;
  message: string;
  onClose: () => void;
}

export function AlertModal({title, message, onClose}: AlertModalViewProps) {
  const modalProps: CoreModalProps = {
    rightButtons: [
      {label: 'Ok', onPress: onClose, mode: 'contained'},
    ]
  }

  return (
    <CoreModal {...modalProps}>
      <View style={styles.container}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.message}>{message}</Text>
      </View>
    </CoreModal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingBottom: 12,
  },
  title: {
    fontSize: 18,
    marginBottom: 6
  },
  message: {
    fontSize: 14,
  }
})
