import {UserPermission} from "../store/data/users/user-permission";

interface JwtPayload {
  Permission: UserPermission[];
  [key: string]: any;
}

function decodeBase64(base64: string): string {
  return decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

function extractFromJwt<T>(jwtToken: string, property: string): T {
  try {
    const [header, payload, signature] = jwtToken.split('.');

    if (!payload) {
      console.error("Invalid JWT token");
      return null;
    }

    const decodedPayload = JSON.parse(decodeBase64(payload)) as JwtPayload;

    if (decodedPayload && decodedPayload[property] !== undefined) {
      return decodedPayload[property] as T;
    }

    return null;
  } catch (error) {
    console.error("Failed to decode JWT:", error);
    return null;
  }
}

export function extractPermissions(jwtToken: string): UserPermission[] {
  return extractFromJwt<UserPermission[]>(jwtToken, 'Permission') ?? [];
}

export function extractEmail(jwtToken: string): string {
  return extractFromJwt<string>(jwtToken, 'email') ?? null;
}
