import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import PreventativeMaintenancesListScreen from "./screens/list/PreventativeMaintenancesListScreen";
import PreventativeMaintenanceManageScreen from "./screens/manage/PreventativeMaintenanceManageScreen";

const Stack = createNativeStackNavigator<PreventativeMaintenanceStackParamList>();

export default function PreventativeMaintenancesStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='list'
        component={PreventativeMaintenancesListScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='manage'
        component={PreventativeMaintenanceManageScreen}
        options={{headerShown: false}}
      />
    </Stack.Navigator>
  );
};

export type PreventativeMaintenanceStackParamList = ParamListBase & {
  list: undefined;
  manage: { id: string, approval?: boolean };
};
