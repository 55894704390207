import {useState, useCallback} from 'react';

type Filters<T> = {
  [key in keyof T]: any;
};

export interface BaseFilters {
  sortedColumn: string;
  sortedColumnAscending: boolean;
  pageNumber: number;
  pageSize: number;
}

function useFilters<T extends BaseFilters>(
  defaultFilters: Filters<T>,
  onPaginationChange?: (filters: Filters<T>) => void,
) {
  const [filters, setFilters] = useState<Filters<T>>(defaultFilters);

  const setFilter = (key: keyof T, value: any) => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        [key]: value,
      };

      if (
        key === 'pageNumber' ||
        key === 'pageSize' ||
        key === 'sortedColumn' ||
        key === 'sortedColumnAscending'
      ) {
        onPaginationChange?.(getValidFilters(newFilters));
      }

      return newFilters;
    });
  };

  const resetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const getFilter = (key: keyof T): any => {
    return filters[key];
  };

  const getFilterValues = (): Record<string, any> => getValidFilters(filters);
  
  const getValidFilters = (filters: Filters<T>): Filters<T> => {
    return Object.keys(filters).reduce((acc, key) => {
      const filterValue = filters[key];

      if (typeof filterValue === 'boolean' || typeof filterValue === 'number') {
        acc[key] = filters[key];
      }

      if (filterValue) {
        acc[key] = filterValue;
      }
      return acc;
    }, {} as Filters<T>);
  }

  return {
    filters,
    resetFilters,
    setFilter,
    getFilter,
    getFilterValues,
  };
}

export default useFilters;
