import CoreForm from "../../../../../../../web/components/forms/CoreForm";
import {TextFieldController} from "../../../../../../../web/components/forms/TextFieldController";
import * as React from "react";
import {useForm} from "react-hook-form";
import {useEffect} from "react";

interface BuildingManageFormProps {
  building: any;
  onSubmit: (data: any) => void;
}

export default function BuildingManageForm({building, onSubmit}: BuildingManageFormProps) {
  const form = useForm({
    defaultValues: {
      id: null,
      name: "",
    }
  });

  useEffect(() => {
    if (building != null) {
      form.setValue("id", building.id);
      form.setValue("name", building.name);
    }
  }, [building])

  const handleOnSubmit = () => {
    form.handleSubmit(onSubmit)();
  }

  return (
    <CoreForm form={form} onSubmit={handleOnSubmit}>
      <CoreForm.Row>
        <TextFieldController controlName='name' title='Name' />
      </CoreForm.Row>
    </CoreForm>
  )
}
