import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useAddJobMediaForJobMutation} from "../../../store/services/api/job-api";
import {usePage} from "../../../hooks/usePage";
import {JobControlContext, JobControlContextType} from "../../../hooks/useJobControl";
import {
  useUpdateMaintenanceJobMutation
} from "../../../store/services/api/maintenance-job-api";
import {MaintenanceJobForm} from "../../../store/data/entities/maintenance-job";
import {MaintenanceJobChecklistControl} from "./components/MaintenanceJobChecklistControl";
import {MaintenanceJobPartsControl} from "./components/MaintenanceJobPartsControl";
import {ScrollView, StyleSheet, Text, View} from "react-native";
import {JobImagesControl} from "../shared/JobImagesControl";
import {JobControlProps} from "../shared/JobControlProps";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {CoreModal, CoreModalProps, useModal} from "../../../hooks/useModal";
import {ButtonSwitchFieldController} from "../../../screens/web/components/forms/ButtonSwitchFieldController";
import { CoreFormContext } from "../../../screens/web/components/forms/CoreForm";
import {TextFieldController} from "../../../screens/web/components/forms/TextFieldController";

export interface MaintenanceJobControlForm extends MaintenanceJobForm {
  images: File[]
}

const initialValues: MaintenanceJobControlForm = {
  jobId: '',
  checklistResponses: [],
  parts: [],
  images: [],
  reasonForCorrectiveWorks: '',
  requireCorrectiveWorks: false
}

export function MaintenanceJobControl({jobId, onSuccess, onError, children, initialForm}: JobControlProps<MaintenanceJobControlForm>) {
  const {permissions} = useSelector((state: RootState) => state.user);
  const isApproval = permissions.includes('CanApproveJobs');
  const [submitForm] = useUpdateMaintenanceJobMutation();
  const [uploadImages] = useAddJobMediaForJobMutation();
  const [currentPage, setCurrentPage] = useState(0);
  const {showModal, hideModal} = useModal();

  const form = useForm<MaintenanceJobControlForm>({
    defaultValues: initialValues
  });

  useEffect(() => {
    form.reset({
      ...initialValues,
      jobId: jobId
    })
    setCurrentPage(0);
  }, [jobId]);

  useEffect(() => {
    form.setValue('reasonForCorrectiveWorks', initialForm?.reasonForCorrectiveWorks)
    form.setValue('requireCorrectiveWorks', initialForm?.requireCorrectiveWorks)
  }, [initialForm]);

  const pageMap: { [key: number]: string } = {
    0: 'checklist',
    1: 'parts'
  };

  const getPreviousScreen = () => {
    return pageMap[currentPage - 1];
  };

  const getNextScreen = () => {
    return pageMap[currentPage + 1];
  };

  const {setIsLoading} = usePage();

  useEffect(() => {
    form.setValue('jobId', jobId);
  }, [jobId]);

  const handleOnFinish = async () => {
    setIsLoading(true);
    hideModal();
    try {
      const formValid = await form.trigger();
      if (!formValid) return;
      const formData = form.getValues();

      if (formData.images?.length) {
        const response = await uploadImages({
          jobId,
          images: formData.images
        });
        if ((response as any)?.error) {
          onError('There was an error sending images');
          return;
        }
      }
      const formResponse = await submitForm(formData);

      if ((formResponse as any)?.error) {
        onError('There was an error submitting job');
        return;
      }
      onSuccess();
    } catch (error) {
      onError('There was an unexpected error');
    } finally {
      setIsLoading(false);
    }
  }

  const handleOnBack = () => {
    const previousScreen = getPreviousScreen();
    if (!previousScreen) return;
    setCurrentPage(curr => curr - 1);
  }

  const handleOnNext = async () => {
    const formValid = await form.trigger();
    if (!formValid) return;

    const nextScreen = getNextScreen();
    if (!nextScreen) return;
    setCurrentPage(curr => curr + 1);
  }

  const ConfirmModal = () => {
    const modalProps: CoreModalProps = {
      leftButtons: [
        {label: 'Cancel', onPress: hideModal, mode: 'text'},
      ],
      rightButtons: [
        {label: 'Finish', onPress: handleOnFinish, mode: 'contained'}
      ]
    }

    return (
      <CoreModal {...modalProps}>
        <View style={styles.container}>
          <Text style={styles.title}>Finish Job</Text>
          <Text style={styles.message}>Does the asset required additional works?</Text>

          <CoreFormContext.Provider value={{form}}>
            <View style={styles.modalFormItem}>
              <ButtonSwitchFieldController
                onButtonText='Yes'
                offButtonText='No'
                controlName='requireCorrectiveWorks'
                title='Additonal Works Required?' />
            </View>
            <View style={styles.modalFormItem}>
              <TextFieldController
                multiline
                title='Reason for Works'
                controlName='reasonForCorrectiveWorks' />
            </View>
          </CoreFormContext.Provider>
        </View>
      </CoreModal>
    )
  }

  const showFinalizeModal = () => {
    showModal(
      <ConfirmModal />
    )
  }

  const isFirstControl = currentPage === 0;
  const isLastControl = currentPage === 1;

  const providerValue: JobControlContextType<MaintenanceJobControlForm> = {
    form,
    handleOnBack,
    handleOnFinish: showFinalizeModal,
    handleOnNext,
    isFirstControl,
    isLastControl,
    jobId,
    onError,
    initialForm,
    isApproval
  }

  return (
    <JobControlContext.Provider value={providerValue}>
      <ScrollView style={currentPage === 0 ? {} : styles.hidden}>
        <JobImagesControl />
        <MaintenanceJobChecklistControl />
      </ScrollView>
      <ScrollView style={currentPage === 1 ? {} : styles.hidden}>
        <MaintenanceJobPartsControl />
      </ScrollView>
      {children}
    </JobControlContext.Provider>
  )
}

const styles = StyleSheet.create({
  hidden: {
    display: 'none'
  },
  container: {
    paddingHorizontal: 8,
    paddingBottom: 12,
  },
  title: {
    fontSize: 18,
    marginBottom: 6
  },
  message: {
    fontSize: 14,
    marginBottom: 16
  },
  modalFormItem: {
    marginBottom: 18
  }
})
