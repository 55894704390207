import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AssetStackParamList} from "../AssetStack";
import {
  useLazyGetAssetByUniqueRefQuery
} from "../../../../../store/services/api/asset-api";
import React, {useEffect, useState} from "react";
import MobilePageLayout from "../../../layouts/MobilePageLayout";
import {Banner, Button, Text} from "react-native-paper";
import {StyleSheet, TouchableOpacity, View} from "react-native";
import {Feather, Ionicons} from "@expo/vector-icons";
import {STAR_COLOUR} from "../../../../../constants/GlobalStyle";
import {useLazyGetAverageRatingQuery} from "../../../../../store/services/api/feedback-api";
import {useSetLoading} from "../../../../../hooks/useSetLoading";
import BadgeButton from "../../../components/BadgeButton";
import {useModal} from "../../../../../hooks/useModal";
import {AdminPinModal} from "../../../modals/AdminPinModal";
import {Card, Dropdown, Flex, Image, MenuProps, Button as AntButton} from 'antd';
import {DashOutlined, MessageOutlined, BookOutlined} from '@ant-design/icons';
const { Meta } = Card;

export default function AssetViewScreen({route, navigation}: ScreenProps<AssetStackParamList, 'asset'>) {
  const uniqueRef = route.params?.uniqueRef;
  const [getAsset, { data: asset, isError, isFetching: assetFetching }] = useLazyGetAssetByUniqueRefQuery();
  const [getAssetRating, { data: assetRating, isFetching: ratingFetching }] = useLazyGetAverageRatingQuery();
  const [bannerVisible, setBannerVisible] = useState(false);

  const navigateToLogBook = () => navigation.navigate('logBook', {assetId: asset?.id});
  const navigateToFeedback = () => navigation.navigate('feedback', {assetId: asset?.id});
  const navigateToTroubleshooting = () => navigation.navigate('troubleshooting', {assetId: asset?.id});
  const navigateToCreateTicket = () => navigation.navigate('createTicket', {assetId: asset?.id});
  const navigateToOpenTickets = () => navigation.navigate('openTickets', {assetId: asset?.id});

  const navigateToReplaceQR = () =>
    showModal(
      <AdminPinModal
        onSuccess={() => {
          hideModal();
          navigation.navigate('replaceQr', {uniqueRef: uniqueRef});
        }}
        onClose={hideModal}
      />
    );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        const navigateToSystemMenu = () => {
          if (!asset?.id) return;

          showModal(
            <AdminPinModal
              onSuccess={() => {
                hideModal();
                navigation.navigate('admin', {assetId: asset.id});
              }}
              onClose={hideModal}
            />
          );
        }

        return (
          <TouchableOpacity style={{paddingHorizontal: 14}}>
            <Feather name='settings' size={24} onPress={navigateToSystemMenu} />
          </TouchableOpacity>
        )
      }
    })
  }, [asset]);

  const navigateToAssetSetup = () =>
    showModal(
      <AdminPinModal
        onSuccess={() => {
          hideModal();
          navigation.navigate('setup', {uniqueRef: uniqueRef});
        }}
        onClose={hideModal}
      />
    );

  useSetLoading([assetFetching, ratingFetching]);

  const {showModal, hideModal} = useModal();

  useEffect(() => {
    if (uniqueRef) {
      getAsset(uniqueRef)
        .unwrap()
        .then((asset) => getAssetRating(asset.id));
    }
  }, [uniqueRef]);

  const handleCreateTicketPress = () => {
    if (asset && asset.ticketsCount > 0) {
      setBannerVisible(!bannerVisible);
      return;
    }
    navigateToCreateTicket();
  };

  const assetNotFound = () => (
    <Banner
      icon='help-circle-outline'
      visible={true}
      actions={[
        {
          label: 'Setup Asset',
          onPress: navigateToAssetSetup
        },
        {
          label: 'Replace QR Code',
          onPress: navigateToReplaceQR
        }
      ]}
    >
      <Text variant='labelLarge'>Couldn't find any asset with the unique reference:<br/>{uniqueRef}</Text>
    </Banner>
  )

  const renderStarsView = () => {
    const rating = assetRating?.averageRating ?? 0;
    const createFullStar = (index: number) => (
      <Ionicons key={`full-${index}`} name="star" size={30} color={STAR_COLOUR}/>
    );

    const createHalfStar = (index: number) => (
      <Ionicons key={`half-${index}`} name="star-half-outline" size={30} color={STAR_COLOUR}/>
    );

    const createEmptyStar = (index: number) => (
      <Ionicons key={`empty-${index}`} name="star-outline" size={30} color={STAR_COLOUR}/>
    );

    return Array.from({length: 5}, (_, i) => i + 1).map(index =>
      index <= rating ? createFullStar(index)
        : index === Math.ceil(rating) && rating % 1 > 0 ? createHalfStar(index)
          : createEmptyStar(index)
    );
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      onClick: navigateToFeedback,
      label: (
        <AntButton
          type='text'
          icon={<MessageOutlined />}
        >
          Add Feedback
        </AntButton>
      ),
    },
    {
      key: '2',
      onClick: navigateToLogBook,
      label: (
        <AntButton
          type='text'
          icon={<BookOutlined />}
        >
          Log book
        </AntButton>
      ),
    }
  ];

  const assetView = () => (
    <Card
      styles={{
        body: {
          padding: 0
        }
      }}
    >
      <View style={styles.contentContainer}>
        <Flex justify='space-between'>
          <Meta title={asset?.assetType} description={asset?.code} />
          <Dropdown menu={{ items }} placement="bottomLeft">
            <AntButton icon={<DashOutlined />} />
          </Dropdown>
        </Flex>
      </View>
      <Image
        height={260}
        width={'100%'}
        src={asset?.imageUri}
        style={{ width: '100%', objectFit: 'contain', paddingInline: 12, paddingTop: 12 }}
      />
      <View style={styles.contentContainer}>
        {!!assetRating?.averageRating && (
          <View style={styles.ratingContainer}>
            {renderStarsView()}
          </View>
        )}
        <View style={styles.buttonContainer}>
          <BadgeButton
            badgeValue={(asset?.ticketsCount ?? 0) > 0 ? asset.ticketsCount : undefined}
            icon='plus'
            mode='elevated'
            onPress={handleCreateTicketPress}
          >
            Create Ticket
          </BadgeButton>
          <Button icon='magnify' mode='elevated' onPress={navigateToTroubleshooting}>Troubleshoot</Button>
        </View>
      </View>
    </Card>
  )

  return (
    <MobilePageLayout>
      <Banner
        style={styles.banner}
        visible={bannerVisible}
        actions={[
          {
            label: 'Dismiss',
            onPress: () => setBannerVisible(false)
          },
          {
            label: 'View Status',
            onPress: navigateToOpenTickets
          },
          {
            label: 'Create Ticket',
            onPress: navigateToCreateTicket
          },
        ]}
      >
        <Text>
          {`There are ${asset && asset?.ticketsCount} tickets currently open for this item.`}
        </Text>
      </Banner>
      {(!uniqueRef || isError) ? assetNotFound() : assetView()}
    </MobilePageLayout>
  )
}


const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    paddingTop: 12
  },
  contentContainer: {
    padding: 18
  },
  banner: {
    marginBottom: 4
  },
  ratingContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly"
  },
});
