import {Controller, ControllerProps} from "react-hook-form";

export interface BaseFormControlProps<TControl> extends ControllerProps<TControl> {
  title: string;
}

export function BaseFormControl<TControl>(props: BaseFormControlProps<TControl>) {
  return (
    <Controller
      {...props}
    />
  )
}
