import {Button, Cascader, CascaderProps, Divider, Flex, Select, Space, Tooltip} from "antd";
import {useLazySearchAssetQuery} from "../../store/services/api/asset-api";
import {
  useGetBuildingCascaderOptionsQuery
} from "../../store/services/api/building-api";
import {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {CascaderOption} from "../../store/data/models/cascader-option";
import useDebounce from "../../hooks/useDebounce";
import {useGetAssetTypeCascaderOptionsQuery} from "../../store/services/api/asset-type-api";
import {CloseOutlined, FilterOutlined, GlobalOutlined} from "@ant-design/icons";

export type LabelValue = {
  value: string;
  label: ReactNode;
}

interface AssetFormControlProps {
  onChange?: (value: LabelValue[] | LabelValue) => void;
  value?: LabelValue[] | LabelValue | undefined;
  disabled?: boolean;
  multiple?: boolean;
}

export default function AssetFormControl(props: AssetFormControlProps) {
  const {data: buildingOptions, isFetching: buildingOptionsFetching} = useGetBuildingCascaderOptionsQuery(null, {
    skip: props.disabled
  });
  const {data: assetTypeOptions, isFetching: assetTypeOptionsFetching} = useGetAssetTypeCascaderOptionsQuery(null, {
    skip: props.disabled
  });
  const [selectedBuildingId, setSelectedBuildingId] = useState<string | undefined>(undefined);
  const [selectedFloorId, setSelectedFloorId] = useState<string | undefined>(undefined);
  const [selectedAssetTypeId, setSelectedAssetTypeId] = useState<string | undefined>(undefined);
  const [selectedSubAssetTypeId, setSelectedSubAssetTypeId] = useState<string | undefined>(undefined);

  const [buildingCascaderValue, setBuildingCascaderValue] = useState<string[] | undefined>();
  const [assetTypeCascaderValue, setAssetTypeCascaderValue] = useState<string[] | undefined>();

  const [initialLoad, setInitialLoad] = useState(true);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const [searchAssets, {data: assetOptions, isFetching: assetsFetching}] = useLazySearchAssetQuery();

  useEffect(() => {
    if (initialLoad && !debouncedSearchQuery) {
      return;
    }
    handleOnSearchAssets(debouncedSearchQuery);
  }, [
    debouncedSearchQuery, selectedBuildingId,
    selectedFloorId, selectedAssetTypeId,
    selectedSubAssetTypeId, initialLoad
  ]);

  const handleOnSearchAssets = (value?: string | undefined) => {
    searchAssets({
      searchTerm: value === '' ? undefined : value,
      buildingId: selectedBuildingId,
      floorId: selectedFloorId,
      assetTypeId: selectedAssetTypeId,
      subAssetTypeId: selectedSubAssetTypeId,
    })
  }

  const onBuildingFilterChange: CascaderProps<CascaderOption>['onChange'] = (selectedItem: string[]) => {
    const isBuildingSelected = selectedItem.length === 1;
    const isFloorSelected = selectedItem.length === 2;
    setBuildingCascaderValue(selectedItem);

    if (isBuildingSelected) {
      setSelectedBuildingId(selectedItem[0]);
      setSelectedFloorId(undefined);
      return;
    }
    if (isFloorSelected) {
      setSelectedFloorId(selectedItem[1]);
      return;
    }
  };

  const onAssetTypeFilterChange: CascaderProps<CascaderOption>['onChange'] = (selectedItem: string[]) => {
    const isAssetTypeSelected = selectedItem.length === 1;
    const isSubAssetType = selectedItem.length === 2;
    setAssetTypeCascaderValue(selectedItem)

    if (isAssetTypeSelected) {
      setSelectedAssetTypeId(selectedItem[0]);
      setSelectedSubAssetTypeId(undefined);
      return;
    }
    if (isSubAssetType) {
      setSelectedSubAssetTypeId(selectedItem[1]);
      return;
    }
  };

  const onFilterClear = (type: 'building' | 'assetType') => {
    if (type === 'building') {
      setSelectedBuildingId(undefined);
      setSelectedFloorId(undefined);
      setBuildingCascaderValue(undefined);
      return;
    }
    setSelectedAssetTypeId(undefined);
    setSelectedSubAssetTypeId(undefined);
    setAssetTypeCascaderValue(undefined);
  }

  const handleOnChange = (value: LabelValue[] | LabelValue) => {
    props.onChange?.(value);
  }

  const handleOnSelectFocus = () => {
    if (initialLoad) {
      setInitialLoad(false);
      handleOnSearchAssets();
    }
  }
  
  const allSelectedInList = useMemo(() => {
    if (!assetOptions?.length || !props?.value) return false;

    return Array.isArray(props.value)
      ? assetOptions.every(option => (props.value as LabelValue[]).some(value => value?.value === option.value))
      : assetOptions.some(option => (props.value as LabelValue)?.value === option.value);
  }, [assetOptions, props.value]);

  const handleOnSelectAll = useCallback(() => {
    if (!assetOptions?.length) return false;

    const newOptions = assetOptions.filter(option => {
      if (Array.isArray(props?.value)) {
        return !(props.value as LabelValue[]).some(value => value.value === option.value);
      }

      return (props?.value as LabelValue)?.value !== option.value;
    }).map(option => ({
      value: option.value,
      label: option.label
    }));

    if (Array.isArray(props.value) && props.value.length > 0) {
      handleOnChange([
        ...props.value,
        ...newOptions
      ]);
      return;
    }

    if ((props.value as LabelValue)?.value) {
      handleOnChange([
        (props.value as LabelValue),
        ...newOptions
      ]);
      return;
    }

    handleOnChange([
      ...newOptions
    ]);

  }, [assetOptions, props.value, allSelectedInList, handleOnChange]);

  const selectedCount = useMemo(() => {
    return Array.isArray(props.value)
      ? props.value.length
      : props.value ? 1 : 0;
  }, [props.value]);

  const clearSelection = () => handleOnChange([]);

  return (
    <Flex>
      <Select
        disabled={props.disabled}
        filterOption={false}
        loading={assetsFetching}
        mode={props.multiple ? "multiple" : undefined}
        showSearch
        onFocus={handleOnSelectFocus}
        onSearch={setSearchQuery}
        onChange={handleOnChange}
        value={props.value}
        labelInValue
        style={{
          flex: 1,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        }}
        maxTagCount={'responsive'}
        options={
          (assetOptions ?? []).map((option) => ({
            ...option,
            label: (
              <Tooltip title={option.details || option.label}>
                {option.label}
              </Tooltip>
            ),
          }))
        }
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Flex justify='space-between' align='center'>
              <Space style={{ padding: '0 8px 4px' }} size='middle'>
                <Button onClick={handleOnSelectAll}>Select All</Button>
                {selectedCount}
              </Space>
              <Button onClick={clearSelection}>Clear</Button>
            </Flex>
          </>
        )}
      />

      <Cascader
        options={buildingOptions}
        disabled={props.disabled}
        onChange={onBuildingFilterChange}
        value={buildingCascaderValue}
        loading={buildingOptionsFetching}
        onClear={() => onFilterClear('building')}
        changeOnSelect
        placeholder={null}
        style={{ display: "none" }}
        dropdownRender={(menu) => menu}
      >
        <div>
          <Button
            disabled={props.disabled}
            icon={<GlobalOutlined />}
            style={{
              borderRadius: 0,
              borderRight: 0
            }}
          >
            Location
          </Button>
          <Tooltip title="Clear filter">
            <Button
              icon={<CloseOutlined />}
              disabled={props.disabled}
              onClick={(e) => {
                e.stopPropagation();
                onFilterClear("building")
              }}
              style={{
                borderRadius: 0,
                borderLeft: 0
              }}
            />
          </Tooltip>
        </div>
      </Cascader>

      <Cascader
        options={assetTypeOptions}
        disabled={props.disabled}
        onChange={onAssetTypeFilterChange}
        value={assetTypeCascaderValue}
        loading={assetTypeOptionsFetching}
        onClear={() => onFilterClear('assetType')}
        changeOnSelect
        placeholder={null}
        style={{ display: "none" }}
        dropdownRender={(menu) => menu}
      >
        <div>
          <Button
            disabled={props.disabled}
            icon={<FilterOutlined />}
            style={{
              borderRadius: 0,
              borderRight: 0
            }}
          >
            Asset Type
          </Button>
          <Tooltip title="Clear filter">
            <Button
              icon={<CloseOutlined />}
              disabled={props.disabled}
              onClick={(e) => {
                e.stopPropagation();
                onFilterClear("assetType")
              }}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: 0
              }}
            />
          </Tooltip>
        </div>
      </Cascader>
    </Flex>
  )
}
