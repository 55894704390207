import React, {ReactNode} from "react";
import { View, Text, StyleSheet } from "react-native";

type Column<T> = { title: string; key?: keyof T, render?: (item: T) => ReactNode };

interface DescriptionsProps<T> {
  data: T | undefined;
  columns: Column<T>[];
}

const Descriptions = <T,>({ data, columns }: DescriptionsProps<T>) => {
  const renderCellContent = (column: Column<T>) => {
    if (!data) return null;
    if (column?.render) {
      return column.render(data);
    }

    if (column.key) {
      return <Text style={styles.value}>{data[column.key as string] || "N/A"}</Text>
    }

    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.table}>
        {columns.map((column) => (
          <View style={styles.tableRow} key={column.key as string}>
            <View style={styles.cellLabel}>
              <Text style={styles.label}>{column.title}</Text>
            </View>

            <View style={styles.cellValue}>
              {renderCellContent(column)}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default Descriptions;

const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
    flex: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    padding: 10,
    backgroundColor: "#f5f5f5",
    borderBottomWidth: 1,
    borderBottomColor: "#d9d9d9",
  },
  table: {
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: "#d9d9d9",
    borderRadius: 6,
    backgroundColor: "#fff",
    flexDirection: "column",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#d9d9d9",
  },
  cellLabel: {
    flex: 1,
    padding: 10,
    backgroundColor: "#f7f7f7"
  },
  cellValue: {
    flex: 2,
    padding: 10,
    backgroundColor: "#fff",
    borderLeftWidth: 1,
    borderColor: "#d9d9d9",
  },
  label: {
    fontWeight: "bold",
    color: "#595959",
  },
  value: {
    color: "#000",
  },
});
