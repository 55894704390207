import { useWindowDimensions } from 'react-native';
import { DrawerContent } from './web/components/navigation/DrawerContent';
import { createDrawerNavigator } from '@react-navigation/drawer';
import AssetsScreen from './web/screens/Assets/AssetsScreen';
import TicketsScreen from './web/screens/Tickets/TicketsScreen';
import React, { useEffect } from 'react';
import WebUsers from './web/screens/Users/WebUsers';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Header } from './web/components/navigation/Header';
import SystemSettings from './web/screens/SystemSettings/SystemSettings';
import Feedback from './web/screens/Feedback/Feedback';
import BuildingsScreen from './web/screens/Buildings/BuildingsScreen';
import AssetTypesScreen from './web/screens/AssetTypes/AssetTypesScreen';
import JobsNavigator from "./web/screens/Jobs/JobsNavigator";
import {ToastProvider} from "../components/toast/ToastProvider";
import MaintenanceChecklistNavigator from "./web/screens/MaintenanceChecklists/MaintenanceChecklistNavigator";
import {ModalProvider} from "../hooks/useModal";
import MaintenancePartsNavigator from "./web/screens/MaintenanceParts/MaintenancePartsNavigator";
import {UserPermission} from "../store/data/users/user-permission";

const Drawer = createDrawerNavigator();

export function WebNavigator({ navigation }: { navigation: any }) {
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 768;
  const {user, permissions} = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user == null) {
      navigation.replace("login")
    }
  }, [user]);

  const renderScreens = () => {
    const screens = protectedScreens.filter(s => !s.requiredPermission || permissions.includes(s.requiredPermission));
    return screens.map((s, i) => (
      <Drawer.Screen
        key={`${s.name}-${i}`}
        name={s.name}
        component={s.component}
        options={{ title: s.title }}
      />
    ));
  }

  return (
    <ToastProvider>
      <ModalProvider>
        <Drawer.Navigator
          detachInactiveScreens={true}
          defaultStatus="open"
          screenOptions={{
            drawerType: isLargeScreen ? 'permanent' : 'back',
            drawerStyle: isLargeScreen ? { width: 250 } : { width: '100%' },
            overlayColor: 'transparent',
            header: (props) => Header(props, user),
            unmountOnBlur: true
          }}
          drawerContent={(props) => <DrawerContent permissions={permissions} props={props} />}
        >
          {renderScreens()}
        </Drawer.Navigator>
      </ModalProvider>
    </ToastProvider>
  );
}

interface ProtectedDrawerScreen {
  component: any;
  name: string;
  title: string;
  requiredPermission?: UserPermission;
}

const protectedScreens: ProtectedDrawerScreen[] = [
  {name: 'assets', title: 'Assets', component: AssetsScreen, requiredPermission: 'CanManageAssets'},
  {name: 'assetTypes', title: 'Asset Types', component: AssetTypesScreen, requiredPermission: 'CanManageAssetTypes'},
  {name: 'buildings', title: 'Buildings', component: BuildingsScreen, requiredPermission: 'CanManageBuildings'},
  {name: 'tickets', title: 'Tickets', component: TicketsScreen, requiredPermission: 'CanManageTickets'},
  {name: 'feedback', title: 'Feedback', component: Feedback},
  {name: 'jobs', title: 'Work Orders', component: JobsNavigator, requiredPermission: 'CanApproveJobs'},
  {name: 'maintenance-checklists', title: 'Job Plans', component: MaintenanceChecklistNavigator, requiredPermission: 'CanManageMaintenanceChecklists'},
  {name: 'maintenance-parts', title: 'Parts', component: MaintenancePartsNavigator, requiredPermission: 'CanManageMaintenanceParts'},
  {name: 'users', title: 'Users', component: WebUsers, requiredPermission: 'CanManageUsers'},
  {name: 'system-settings', title: 'System Settings', component: SystemSettings},
]
