import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import AdminMenuScreen from "./screens/AdminMenuScreen";
import AdminAssetDetailsScreen from "./screens/AdminAssetDetailsScreen";
import AdminAssetStatusScreen from "./screens/AdminAssetStatusScreen";

const Stack = createNativeStackNavigator<AdminStackParamList>();

export default function AdminStack({route}) {
  const assetId = route.params?.assetId;

  return (
    <Stack.Navigator>
      <Stack.Screen
        name='menu'
        component={AdminMenuScreen}
        initialParams={{assetId}}
        options={{title: 'Asset Administration'}}
      />
      <Stack.Screen
        name='assetDetails'
        component={AdminAssetDetailsScreen}
        initialParams={{assetId}}
        options={{title: 'Asset Details'}}
      />
      <Stack.Screen
        name='assetStatus'
        component={AdminAssetStatusScreen}
        initialParams={{assetId}}
        options={{title: 'Asset Status'}}
      />
    </Stack.Navigator>
  );
};

export type AdminStackParamList = ParamListBase & {
  menu: {assetId: string};
  assetDetails: {assetId: string};
  assetStatus: {assetId: string};
};
