import {useForm} from "react-hook-form";
import {useCreateFloorMutation, useUpdateFloorMutation} from "../../../../../../../../store/services/api/building-api";
import React, {useEffect} from "react";
import CoreForm from "../../../../../../../web/components/forms/CoreForm";
import {TextFieldController} from "../../../../../../../web/components/forms/TextFieldController";
import {FormButton} from "../../../../../../../web/components/forms/FormButton";

interface SystemFloorsFormProps {
  floor: any;
  buildingId: string;
  onSubmit: () => void;
  onClearSelection: () => void;
}

export default function SystemFloorsForm({floor, buildingId, onSubmit, onClearSelection}: SystemFloorsFormProps) {
  const [createFloor] = useCreateFloorMutation();
  const [updateFloor] = useUpdateFloorMutation();

  const form = useForm({
    defaultValues: {
      id: null,
      buildingId: buildingId,
      name: "",
    }
  });

  const id = form.watch("id");

  useEffect(() => {
    if (floor) {
      form.setValue("id", floor.id);
      form.setValue("buildingId", floor.buildingId);
      form.setValue("name", floor.name);
    }
  }, [floor]);

  const handleOnSubmit = async (data) => {
    data.buildingId = buildingId;

    const performAction = async () => {
      if (data.id != null) {
        return await updateFloor(data).unwrap();
      }
      return await createFloor(data).unwrap();
    }

    const result = await performAction();

    if (result?.error) {
      alert(result.error)
      return;
    }

    form.reset();
    onSubmit();
  }

  const handleOnClear = () => {
    onClearSelection();
    form.reset();
  }

  return (
    <CoreForm
      form={form}
      onSubmit={form.handleSubmit(handleOnSubmit)}
      footer={(
        <>
          <FormButton
            title={id == null ? 'Add' : 'Update'}
            onPress={form.handleSubmit(handleOnSubmit)}
          />
          {id && (
            <FormButton title='Cancel' onPress={handleOnClear}/>
          )}
        </>
      )}
    >
      <CoreForm.Row>
        <TextFieldController
          controlName='name'
          title='Floor Name'
          required
        />
      </CoreForm.Row>
    </CoreForm>
  )
}
