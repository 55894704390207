import {DropdownFilter, TextInputFilter} from "../../../../../../../web/components/search-filters/SearchFilters";
import {Picker} from "@react-native-picker/picker";
import React, {useMemo} from "react";
import {useGetAllBuildingsQuery, useGetAllFloorsQuery} from "../../../../../../../../store/services/api/building-api";
import {
  useGetAllAssetTypesQuery,
  useGetAllSubAssetTypesQuery
} from "../../../../../../../../store/services/api/asset-type-api";
import {CoreFilters, CoreFiltersBaseProps} from "../../../../../../components/CoreFilters";

export default function SystemAssetsScreenFilters({filter, onSubmit}: CoreFiltersBaseProps) {
  const { data: buildings } = useGetAllBuildingsQuery(null);
  const { data: floors } = useGetAllFloorsQuery(null);
  const { data: assetTypes } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes } = useGetAllSubAssetTypesQuery(null);

  const selectedBuildingId = filter.getFilter('buildingId');
  const selectedAssetTypeId = filter.getFilter('assetTypeId');

  const filteredFloors = useMemo(() => {
    if (!selectedBuildingId) return [];
    return floors?.filter(item => item.buildingId === selectedBuildingId) ?? [];
  }, [selectedBuildingId]);

  const filteredSubAssetTypes = useMemo(() => {
    if (!selectedAssetTypeId) return [];
    return subAssetTypes?.filter(item => item.assetTypeId === selectedAssetTypeId) ?? [];
  }, [selectedAssetTypeId]);

  const handleOnBuildingChange = (buildingId: string) => {
    filter.setFilter('buildingId', buildingId);
    filter.setFilter('floorId', '');
  }

  const handleOnAssetTypeChange = (assetTypeId: string) => {
    filter.setFilter('assetTypeId', assetTypeId);
    filter.setFilter('subAssetTypeId', '');
  }

  return (
    <CoreFilters
      filter={filter}
      onSubmit={onSubmit}
    >
      <DropdownFilter title='Building' name='buildingId' onValueChange={handleOnBuildingChange}>
        <Picker.Item label='All' value='' />
        {(buildings ?? []).map(item =>
          <Picker.Item key={item.id} label={item.name} value={item.id} />
        )}
      </DropdownFilter>
      <DropdownFilter title='Floor' name='floorId' >
        <Picker.Item label='All' value='' />
        {filteredFloors.map(item =>
          <Picker.Item key={item.id} label={item.name} value={item.id} />
        )}
      </DropdownFilter>
      <DropdownFilter title='Asset Type' name='assetTypeId' onValueChange={handleOnAssetTypeChange}>
        <Picker.Item label='All' value='' />
        {(assetTypes ?? []).map(item =>
          <Picker.Item key={item.id} label={item.name} value={item.id} />
        )}
      </DropdownFilter>
      <DropdownFilter title='Sub Asset Type' name='subAssetTypeId' >
        <Picker.Item label='All' value='' />
        {filteredSubAssetTypes.map(item =>
          <Picker.Item key={item.id} label={item.name} value={item.id} />
        )}
      </DropdownFilter>
      <DropdownFilter title='Ticket Status' name='ticketStatus'>
        <Picker.Item label="All" value={-1}/>
        <Picker.Item label="Outstanding Ticket" value={1}/>
      </DropdownFilter>
      <TextInputFilter title='Code' name='code' />
      <TextInputFilter title='Description' name='description' />
      <TextInputFilter title='CoreRFID QR' name='uniqueReference' />
    </CoreFilters>
  )
}
