import {CoreModal, CoreModalProps} from "../../../../hooks/useModal";
import * as React from "react";
import {useAddWorkOrderLabourMutation} from "../../../../store/services/api/work-order-api";
import {WorkOrderLabourForm} from "../../../../store/data/entities/work-order-labour";
import CoreForm from "../../../web/components/forms/CoreForm";
import {PickerFieldController} from "../../../web/components/forms/PickerFieldController";
import {useForm} from "react-hook-form";
import {useGetUsersOfRoleQuery} from "../../../../store/services/api/user-api";
import {UseFormReturn} from "react-hook-form/dist/types";
import {DateInputFieldController} from "../../../web/components/forms/DateInputFieldController";
import {TextFieldController} from "../../../web/components/forms/TextFieldController";
import moment from "moment";

interface AddLabourFormModalProps {
  workOrderId: string;
  onSuccess: () => void;
  onClose: () => void;
}

export default function AddLabourFormModal({workOrderId, onSuccess, onClose}: AddLabourFormModalProps) {
  const [addLabour, {isLoading}] = useAddWorkOrderLabourMutation();

  const form = useForm<WorkOrderLabourForm>({
    defaultValues: {
      id: undefined,
      date: '',
      startDateTime: '',
      endDateTime: '',
      hoursWorked: 0
    }
  });

  const onSubmit = async (data) => {
    addLabour({workOrderId, ...data})
      .unwrap()
      .then(onSuccess)
      .catch(console.error);
  };

  const handleOnSave = () => {
    form.handleSubmit(onSubmit)();
  }

  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Cancel', onPress: onClose, mode: 'text', disabled: isLoading},
    ],
    rightButtons: [
      {label: 'Save', onPress: handleOnSave, mode: 'contained', disabled: isLoading},
    ]
  }

  return (
    <CoreModal {...modalProps}>
      <AddLabourForm form={form} />
    </CoreModal>
  )
}

function AddLabourForm({form}: {form: UseFormReturn<WorkOrderLabourForm>}) {
  const {data: users} = useGetUsersOfRoleQuery(['Technician']);
  const selectedDate = form.watch('date');
  const minDate = moment(selectedDate).format('yyyy-MM-DDTHH:mm');
  const maxDate = moment(selectedDate).add('day', 1).format('yyyy-MM-DDTHH:mm');

  const userOptions = (users ?? []).map(item => ({
      label: item.name,
      value: item.id
    }));

  return (
    <CoreForm footer={null} form={form}>
      <PickerFieldController
        controlName='userId'
        title='Technician'
        required
        defaultFirst={false}
        items={userOptions} />
      <DateInputFieldController
        required
        title='Date'
        controlName='date'
      />
      <DateInputFieldController
        required
        min={minDate}
        max={maxDate}
        type='datetime-local'
        title='Start Time'
        controlName='startDateTime'
      />
      <DateInputFieldController
        required
        min={minDate}
        max={maxDate}
        type='datetime-local'
        title='End Time'
        controlName='endDateTime'
      />
      <TextFieldController
        controlName='hoursWorked'
        title='Hours'
        type='numeric'
        required
      />
    </CoreForm>
  )
}
