import { baseApi } from "./base-api";
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {MaintenancePart, NewMaintenancePart} from "../../data/entities/maintenance-part";

export const maintenancePartApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPartsList: build.query<PagedResponse<MaintenancePart>, Record<string, any>>({
      query: (params) => ({
        url: `maintenanceParts/getPartsList`,
        params: params
      }),
      transformResponse: getPagedResponse
    }),
    createPart: build.mutation<MaintenancePart, NewMaintenancePart>({
      query: (dto) => ({
        url: `maintenanceParts/createPart`,
        method: 'POST',
        body: dto
      })
    }),
    updatePart: build.mutation<MaintenancePart, {id: string, part: NewMaintenancePart }>({
      query: (dto) => ({
        url: `maintenanceParts/updatePart/${dto.id}`,
        method: 'PUT',
        body: dto.part
      })
    }),
    getPart: build.query<MaintenancePart, string>({
      query: (partId) => ({
        url: `maintenanceParts/getPart/${partId}`
      })
    }),
    searchPart: build.query<MaintenancePart[], { searchTerm: string, assetTypeId: string }>({
      query: (params) => {
        const {searchTerm, assetTypeId} = params;
        const queryParams: Record<string, string> = {};

        if (searchTerm) queryParams.searchTerm = searchTerm;
        if (assetTypeId) queryParams.assetTypeId = assetTypeId;

        return {
          url: `maintenanceParts/searchPart`,
          params: queryParams
        };
      }
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetPartsListQuery,
  useCreatePartMutation,
  useUpdatePartMutation,
  useLazyGetPartQuery,
  useLazySearchPartQuery
} = maintenancePartApi;
