import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AssetStackParamList} from "../AssetStack";
import MobilePageLayout from "../../../layouts/MobilePageLayout";
import {useLazyGetAssetQuery} from "../../../../../store/services/api/asset-api";
import {useEffect} from "react";
import {useSetLoading} from "../../../../../hooks/useSetLoading";
import * as React from "react";
import {Card, TextInput} from 'react-native-paper';
import {StyleSheet} from "react-native";

export default function AssetTroubleshootingScreen({route, navigation}: ScreenProps<AssetStackParamList, 'troubleshooting'>) {
  const assetId = route.params?.assetId;
  const [getAsset, {data: asset, isFetching: assetFetching}] = useLazyGetAssetQuery();

  useSetLoading([assetFetching]);

  useEffect(() => {
    if (assetId) {
      getAsset(assetId);
    }
  }, [assetId]);

  return (
    <MobilePageLayout>
      <Card style={styles.card}>
        <Card.Content style={styles.contentContainer}>
          <TextInput
            mode='outlined'
            label='Item Description'
            value={asset?.description ?? ''}
            disabled />

          <TextInput
            multiline
            mode='outlined'
            label='Troubleshooting'
            value={asset?.troubleshootingGuide ?? ''}
            style={{height: 400}}
            disabled />
        </Card.Content>
      </Card>
    </MobilePageLayout>
  )
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white'
  },
  contentContainer: {
    flexDirection: 'column',
    gap: 8,
    borderRadius: 12,
    padding: 16
  }
})
