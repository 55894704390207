import {useSelector} from "react-redux";
import {RootState} from "../store";
import {UserPermission} from "../store/data/users/user-permission";

export default function useHasPermission(permission?: UserPermission) {
  const {permissions} = useSelector((state: RootState) => state.user);
  const _permission = permission;

  const hasPermission = (permission?: UserPermission) => {
    if (!permission && !_permission) return true;
    return permissions.includes(permission ?? (_permission as UserPermission));
  }

  return {
    hasPermission,
  };
}
