import { baseApi } from "./base-api";
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {
  MaintenanceChecklist,
  NewMaintenanceChecklist
} from "../../data/entities/maintanence-checklist";

export const maintenanceChecklistApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getChecklistsList: build.query<PagedResponse<MaintenanceChecklist>, Record<string, any>>({
      query: (params) => ({
        url: `maintenanceChecklists/getChecklistsList`,
        params: params
      }),
      transformResponse: getPagedResponse
    }),
    createChecklist: build.mutation<MaintenanceChecklist, NewMaintenanceChecklist>({
      query: (dto) => ({
        url: `maintenanceChecklists/createChecklist`,
        method: 'POST',
        body: dto
      })
    }),
    updateChecklist: build.mutation<MaintenanceChecklist, {id: string, checklist: NewMaintenanceChecklist }>({
      query: (dto) => ({
        url: `maintenanceChecklists/updateChecklist/${dto.id}`,
        method: 'PUT',
        body: dto.checklist
      })
    }),
    getChecklist: build.query<MaintenanceChecklist, string>({
      query: (checklistId) => ({
        url: `maintenanceChecklists/getChecklist/${checklistId}`
      })
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetChecklistsListQuery,
  useCreateChecklistMutation,
  useUpdateChecklistMutation,
  useLazyGetChecklistQuery
} = maintenanceChecklistApi;
