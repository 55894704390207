import {StyleSheet, View} from "react-native";
import {ReactNode} from "react";

interface TablePageLayoutProps {
  filters?: ReactNode;
  actions: ReactNode;
  children: ReactNode;
}

export default function TablePageLayout({filters, actions, children}: TablePageLayoutProps) {
  return (
    <View style={styles.pageContainer}>
      {filters && (
        <View style={styles.filtersContainer}>
          {filters}
        </View>
      )}
      <View style={styles.tableContainer}>
        <View style={styles.actionsContainer}>
          {actions}
        </View>
        {children}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    maxHeight: '100%',
    alignItems: 'stretch',
  },
  filtersContainer: {
  },
  tableContainer: {
    flex: 1,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    marginBottom: 8
  }
})
