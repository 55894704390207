import {StyleSheet, View} from "react-native";
import React, {useEffect} from "react";
import {useJobControl} from "../../../hooks/useJobControl";
import {FileUpload, FileUploadProps} from "../../file-uploader/FileUpload";
import {pickMedia} from "../../../utils/media-utils";
import {useLazyGetJobMediaForJobQuery, useRemoveJobMediaMutation} from "../../../store/services/api/job-api";
import {ImageApprovalList} from "./components/ImageApprovalList";
import {useToast} from "../../toast/ToastProvider";

interface ImagesControl {
  images: File[];
}

export function JobImagesControl<T extends ImagesControl>() {
  const {form: {watch, setValue}, isApproval, jobId} = useJobControl<ImagesControl>();
  const [getMedia, {data: jobMedia, isFetching: mediaFetching}] = useLazyGetJobMediaForJobQuery();
  const [removeMedia, {isLoading: removeLoading, isSuccess: removeSuccess, isError: removeError}] = useRemoveJobMediaMutation();
  const fileList = watch("images");

  const {show} = useToast();

  useEffect(() => {
    if (removeError) {
      show('Remove Error', 'There was a problem removing this media', 'error');
    }
  }, [removeError]);

  useEffect(() => {
    if (removeSuccess) {
      getMedia(jobId);
    }
  }, [removeSuccess, jobId]);

  useEffect(() => {
    if (jobId) {
      getMedia(jobId);
    }
  }, [jobId])

  const props: FileUploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setValue('images', newFileList);
    },
    onUploadFilesPress: async () => {
      const images = await pickMedia('both', true);
      const newFileList = [...fileList];
      images.forEach((file) => {
        newFileList.push(file);
        setValue('images', newFileList);
      });
    },
    onFilePress: (file) => {
      // todo Add preview
      console.log(file)
    },
    fileList: fileList ?? [],
    buttonText: 'Select Images',
  };
  return (
    <View style={styles.uploadContainer}>
      {isApproval ? (
        <ImageApprovalList
          isLoading={mediaFetching || removeLoading}
          onRemoveMedia={removeMedia}
          media={jobMedia} />
      ) : (
        <FileUpload {...props} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  uploadContainer: {
    marginHorizontal: 4,
    marginTop: 6
  }
})
