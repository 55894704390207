import GlobalStyle from "../../../../constants/GlobalStyle";
import React, {createContext, ReactNode, useContext} from "react";
import {View, Text, TouchableOpacity, StyleSheet, TextInput} from "react-native";
import {SimpleLineIcons} from "@expo/vector-icons";
import {Picker} from "@react-native-picker/picker";
import DateTimePicker from "../navigation/controls/DateTimePicker";
import moment from "moment/moment";

interface FilterContextProps {
  getFilter: (name: string) => any;
  setFilter: (name: string, value: any) => void;
  resetFilters: () => void;
}

export const FilterContext = createContext<FilterContextProps | undefined>(undefined);

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return context;
};

interface SearchFiltersProps {
  children: ReactNode;
  filter: FilterContextProps;
  onSubmit: () => void;
  onResetFilters?: () => void;
}

export function SearchFilters({ children, filter, onSubmit, onResetFilters }: SearchFiltersProps) {
  const handleOnResetFilters = () => {
    onResetFilters?.();
    filter.resetFilters();
  }

  return (
    <FilterContext.Provider value={filter}>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>
        <View style={[GlobalStyle.filter__columns, {display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}]}>
          <View style={{flex: 1}}>
            {children}
          </View>
          <SearchFilterButtons onSubmit={onSubmit} resetFilters={handleOnResetFilters} />
        </View>
      </View>
    </FilterContext.Provider>
  )
}

export function SearchFilterRow({ children }) {
  return (
    <View style={styles.filter__row}>
      {children}
    </View>
  )
}

function SearchFilterButtons({onSubmit, resetFilters}) {
  return (
    <View style={[GlobalStyle.column__flex, styles.filter__buttons__container]}>
      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={onSubmit}>
          <SimpleLineIcons name="magnifier" size={16} color="white" />
        </TouchableOpacity>

        <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={resetFilters}>
          <SimpleLineIcons name="ban" size={16} color="black" />
        </TouchableOpacity>
      </View>
    </View>
  )
}

function FilterOption({ title, children }) {
  return (
    <View style={[GlobalStyle.column__flex, {marginBottom: 12}]}>
      <Text style={GlobalStyle.column__header}>{title}</Text>
      {children}
    </View>
  )
}

interface BaseFilterProps {
  title: string;
  name: string;
}

interface TextInputFilterProps extends BaseFilterProps {
  placeholder?: string | undefined;
}

export function TextInputFilter({ title, name, placeholder }: TextInputFilterProps) {
  const {getFilter, setFilter} = useFilterContext();

  return (
    <FilterOption title={title}>
      <TextInput
        placeholder={placeholder ?? title}
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        value={getFilter(name)}
        onChangeText={(value) => setFilter(name, value)} />
    </FilterOption>
  )
}

export function DateFilter({ title, name }: BaseFilterProps) {
  const {getFilter, setFilter} = useFilterContext();

  return (
    <FilterOption title={title}>
      <DateTimePicker
        style={[GlobalStyle.column, GlobalStyle.column__input,]}
        onChange={(value) => setFilter(name, moment(value).toISOString())}
        value={getFilter(name)}>
      </DateTimePicker>
    </FilterOption>
  )
}

interface DropdownFilterProps<T>  {
  title: string;
  name?: string;
  options?: {label: string, value: T}[];
  children?: ReactNode;
  onValueChange?: (value: string) => void;
  value?: string;
}

export function DropdownFilter<T extends number | string>({ title, options, children, onValueChange, value, name }: DropdownFilterProps<T>) {
  const {getFilter, setFilter} = useFilterContext();

  const handleOnValueChange = (value: string) => {
    onValueChange?.(value);
    if (name) {
      setFilter(name, value);
    }
  }

  return (
    <FilterOption title={title}>
      <Picker
        style={[GlobalStyle.column, GlobalStyle.column__input]}
        selectedValue={value ?? getFilter(name)}
        onValueChange={handleOnValueChange}>
        {children ? children : (options ?? []).map(item =>
          <Picker.Item key={item.value} label={item.label} value={item.value} />
        )}
      </Picker>
    </FilterOption>
  )
}

const styles = StyleSheet.create({
  filter__option: {
    marginBottom: 16,
    flexBasis: 240
  },
  filter__option__checkbox: {
    marginBottom: 16,
    flexBasis: 240
  },
  filter__buttons__container: {
    flexShrink: 1,
    flexGrow: 0,
    marginBottom: 16,
    flexBasis: 140,
  },
  filter__row: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 1,
    flexBasis: '100%',
    flexGrow: 4
  }
})
