import {useForm} from "react-hook-form";
import {
  useCreateLocationMutation,
  useUpdateLocationMutation
} from "../../../../../../../../store/services/api/building-api";
import React, {useEffect} from "react";
import CoreForm from "../../../../../../../web/components/forms/CoreForm";
import {FormButton} from "../../../../../../../web/components/forms/FormButton";
import {TextFieldController} from "../../../../../../../web/components/forms/TextFieldController";

interface SystemLocationsFormProps {
  location: any;
  floorId: string;
  onSubmit: () => void;
  onClearSelection: () => void;
}

export default function SystemLocationsForm({location, floorId, onSubmit, onClearSelection}: SystemLocationsFormProps) {
  const [createLocation] = useCreateLocationMutation();
  const [updateLocation] = useUpdateLocationMutation();

  const form = useForm({
    defaultValues: {
      id: null,
      floorId: floorId,
      name: "",
    }
  });

  const id = form.watch("id");

  useEffect(() => {
    if (location) {
      form.setValue("id", location.id);
      form.setValue("floorId", location.floorId);
      form.setValue("name", location.name);
    }
  }, [location]);

  const handleOnSubmit = async (data) => {
    data.floorId = floorId;

    const performAction = async () => {
      if (data.id != null) {
        return await updateLocation(data).unwrap();
      }
      return await createLocation(data).unwrap();
    }

    const result = await performAction();

    if (result?.error) {
      alert(result.error)
      return;
    }

    form.reset();
    onSubmit();
  }

  const handleOnClear = () => {
    onClearSelection();
    form.reset();
  }

  return (
    <CoreForm
      form={form}
      onSubmit={form.handleSubmit(handleOnSubmit)}
      footer={(
        <>
          <FormButton
            title={id == null ? 'Add' : 'Update'}
            onPress={form.handleSubmit(handleOnSubmit)}
          />
          {id && (
            <FormButton title='Cancel' onPress={handleOnClear}/>
          )}
        </>
      )}
    >
      <CoreForm.Row>
        <TextFieldController
          controlName='name'
          title='Location Name'
          required
        />
      </CoreForm.Row>
    </CoreForm>
  )
}
