import {CoreFilters, CoreFiltersBaseProps} from "../../../../../components/CoreFilters";
import {
  useGetAllAssetTypesQuery,
  useGetAllSubAssetTypesQuery
} from "../../../../../../../store/services/api/asset-type-api";
import {DropdownFilter, TextInputFilter} from "../../../../../../web/components/search-filters/SearchFilters";
import { Picker } from "@react-native-picker/picker";
import React from "react";

export default function JobPlansListScreenFilters({filter, onSubmit}: CoreFiltersBaseProps) {
  const { data: assetTypes } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes } = useGetAllSubAssetTypesQuery(null);
  return (
    <CoreFilters
      filter={filter}
      onSubmit={onSubmit}
    >
      <TextInputFilter title='Reference' name='reference' />
      <DropdownFilter title='Asset Type' name='assetTypeId' >
        <Picker.Item label='All' value='' />
        {(assetTypes ?? []).map(item =>
          <Picker.Item key={item.id} label={item.name} value={item.id} />
        )}
      </DropdownFilter>
      <DropdownFilter title='Sub Asset Type' name='subAssetTypeId' >
        <Picker.Item label='All' value='' />
        {(subAssetTypes ?? []).map(item =>
          <Picker.Item key={item.id} label={item.name} value={item.id} />
        )}
      </DropdownFilter>

      <DropdownFilter title='Status' name='status'>
        <Picker.Item label="All" value='' />
        <Picker.Item label="Awaiting Approval" value='0' />
        <Picker.Item label="Approved" value='1' />
        <Picker.Item label="Rejected" value='2' />
      </DropdownFilter>

    </CoreFilters>
  )
}
