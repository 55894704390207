import MobilePageLayout from "../../../layouts/MobilePageLayout";
import FindAssetForm from "./components/FindAssetForm";
import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AssetStackParamList} from "../AssetStack";
import {
  useLazyGetAssetQuery,
  useLazyGetAssetsListQuery,
  useUpdateAssetMutation
} from "../../../../../store/services/api/asset-api";
import {useModal} from "../../../../../hooks/useModal";
import {FlatList, Image, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {Dialog} from "react-native-paper";
import React from "react";
import {useSetLoading} from "../../../../../hooks/useSetLoading";
import ConfirmModal from "../../../modals/ConfirmModal";

export default function AssetReplaceQRScreen({route, navigation}: ScreenProps<AssetStackParamList, 'replaceQr'>) {
  const uniqueRef = route.params?.uniqueRef;
  const [getAssetsList, {isFetching: assetsFetching}] = useLazyGetAssetsListQuery();
  const [getAsset, {isFetching: assetFetching}] = useLazyGetAssetQuery();
  const [updateAsset] = useUpdateAssetMutation();

  useSetLoading([assetsFetching, assetFetching]);

  const {showModal, hideModal} = useModal();

  const handleOnAssetSearch = (data: any)=> {
    getAssetsList(data)
      .unwrap()
      .then(assets => {
        if (!assets?.list?.length) return;
        showModal(
          <SelectAssetModal
            assets={assets.list}
            onSelect={(asset) => {
              hideModal();
              updateUniqueRef(asset);
            }}
          />
        )
      })
  }

  const updateUniqueRef = async (item: any) => {
    const performUpdate = async () => {
      const assetResponse = await getAsset(item.id);

      if (assetResponse.isError) {
        alert("Error getting asset");
        return;
      }
      const updatedAsset = {
        ...assetResponse.data,
        uniqueReference: uniqueRef
      };

      try {
        await updateAsset(updatedAsset);
      } catch (e) {
        alert("Error updating asset");
      }

      navigation.navigate('view', {uniqueRef});
    }

    showModal(
      <ConfirmModal
        onConfirm={() => {
          hideModal();
          performUpdate();
        }}
        onCancel={hideModal}
      />
    )
  }

  return (
    <MobilePageLayout>
      <FindAssetForm onSubmit={handleOnAssetSearch} />
    </MobilePageLayout>
  )
}

function SelectAssetModal({assets, onSelect}) {
  return (
    <Dialog visible={true}>
      <Dialog.Title>Select Asset</Dialog.Title>
      <Dialog.Content style={{maxHeight: 600}}>
        <FlatList
          style={{maxHeight: '100%'}}
          data={assets}
          renderItem={({item}) => (
            <TouchableOpacity style={styles.assetItem} onPress={() => onSelect(item)}>
              <View>
                <Text>{item?.buildingName}</Text>
                <Text>{item?.floorName}</Text>
                <Text>{item?.locationName}</Text>
                <Text>{item?.assetTypeName}</Text>
                <Text>{item?.subAssetTypeName}</Text>
                <Text>{item?.description}</Text>
                <Text>{item?.code}</Text>
                <Text>{item?.uniqueReference}</Text>
              </View>
              <Image style={styles.image} source={item.imageUri} />
            </TouchableOpacity>
          )}
        />
      </Dialog.Content>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  assetItem: {
    width: '100%',
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1,
    padding: 10,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    flex: 1,
    height: 100,
    resizeMode: 'contain',
  }
})
