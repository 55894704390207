import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import WebPageLayout from "../../../../layouts/WebPageLayout";
import {WorkOrdersStackParamList} from "../../WorkOrdersStack";
import * as React from "react";
import WorkOrderManageForm from "./WorkOrderManageForm";
import {View} from "react-native";
import GlobalStyle from "../../../../../../constants/GlobalStyle";

export default function WorkOrderManageScreen({route, navigation}: ScreenProps<WorkOrdersStackParamList, 'manage'>) {
  const id = route?.params?.id;
  const isApproval = route?.params?.approval ?? false;

  return (
    <WebPageLayout header={{
      previousTab: {title: 'Work Orders', routeName: 'list'},
      tabs: [
        {title: 'Manage', routeName: 'manage', params: {id}}
      ]
    }}>
      <View style={GlobalStyle.formContainer}>
        <WorkOrderManageForm
          onSuccess={() => navigation.navigate("list")}
          workOrderId={id}
          isApproval={isApproval}
        />
      </View>
    </WebPageLayout>
  )
}
