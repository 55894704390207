import {StyleProp, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {DataTable} from "react-native-paper";
import React, {useEffect} from "react";
import {humanizeDate, humanizeEnum} from "../../../../utils/humanizers";
import {CoreTable} from "../../components/core-table/CoreTable";
import {
  DropdownFilter,
  SearchFilterRow,
  SearchFilters,
  TextInputFilter
} from "../../components/search-filters/SearchFilters";
import GlobalStyle from "../../../../constants/GlobalStyle";
import useFilters, {BaseFilters} from "../../hooks/useFilters";
import {useLazyGetJobsListQuery} from "../../../../store/services/api/job-api";
import {Entypo, Ionicons, MaterialIcons} from "@expo/vector-icons";
import {Job, JobPriorities, JobPriority, JobStatus, JobStatuses, JobType} from "../../../../store/data/entities/job";
import {WebScreen} from "../../components/WebScreen";
import {useToast} from "../../../../components/toast/ToastProvider";
import {Picker} from "@react-native-picker/picker";
import {useGetUsersOfRoleQuery} from "../../../../store/services/api/user-api";
import {useIsFocused} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {useSetLoading} from "../../../../hooks/useSetLoading";

interface JobListFilters extends BaseFilters {
  reference: string;
  technicianId: string;
  status: number;
  priority: JobPriority;
}

export function JobsList({navigation}: NativeStackScreenProps<any, any>) {
  const {permissions} = useSelector((state: RootState) => state.user);
  const [getJobsList, {data: jobsListResult, isFetching: jobsFetching, isError}] = useLazyGetJobsListQuery();
  const {data: usersList, isLoading: usersListLoading} = useGetUsersOfRoleQuery('Technician');
  const loadTable = (filters?: Record<string, any>) => getJobsList(filters ?? getFilterValues());

  const filter = useFilters<JobListFilters>({
    reference: "",
    technicianId: "",
    sortedColumn: "",
    status: -1,
    priority: -1,
    sortedColumnAscending: false,
    pageNumber: 1,
    pageSize: 10
  }, loadTable);
  const {getFilterValues, setFilter, filters} = filter;

  const isFocused = useIsFocused()

  useEffect(() => {
    if(isFocused){
      loadTable();
    }
  }, [isFocused]);

  useSetLoading([usersListLoading]);

  useEffect(() => {
    if (isError) {
      showToast('Error getting table', 'There was an error getting job data', 'error');
    }
  }, [isError]);

  const {show: showToast} = useToast();

  const jobStatusCell = (item: Job) => {
    const statusLabel = JobStatuses.find(s => s.value === item.status)?.label ?? 'Unknown';
    const textStyle: StyleProp<any> = item.status === JobStatus.Overdue ? {
      fontWeight: 'bold', color: 'red'
    } : {}

    if (!permissions.includes('CanApproveJobs') || item.status !== JobStatus.AwaitingApproval) {
      return (
        <DataTable.Cell>
          <Text style={textStyle}>{statusLabel}</Text>
        </DataTable.Cell>
      )
    }

    const handleOnJobStatusPress = () => navigation.navigate('approval', {
      jobId: item.id
    });

    return (
      <DataTable.Cell onPress={handleOnJobStatusPress}>
        <View style={styles.approvalWrapper}>
          <Text style={{fontWeight: 'bold'}}>{statusLabel}</Text>
          <MaterialIcons name="approval" size={24} color="black" />
        </View>
      </DataTable.Cell>
    );
  };

  const handleOnJobPress = (item: Job) => {
    navigation.navigate('manage', {jobId: item.id})
  }

  return (
    <WebScreen>
      <SearchFilters filter={filter} onSubmit={() => loadTable()}>
        <SearchFilterRow>
          <TextInputFilter title='Reference' name='reference' />
          <DropdownFilter title='Technician' name='technicianId' >
            <Picker.Item label='All' value='' />
            {(usersList ?? []).map(item =>
              <Picker.Item key={item.id} label={item.name} value={item.id} />
            )}
          </DropdownFilter>
          <DropdownFilter title='Status' name='status' >
            <Picker.Item label='All' value={-1} />
            {JobStatuses.map((item, index) =>
              <Picker.Item key={`${item.value}-${index}`} label={item.label} value={item.value} />
            )}
          </DropdownFilter>
          <DropdownFilter title='Priority' name='priority' >
            <Picker.Item label='All' value={-1} />
            {JobPriorities.map((item, index) =>
              <Picker.Item key={`${item.value}-${index}`} label={item.label} value={item.value} />
            )}
          </DropdownFilter>
        </SearchFilterRow>
      </SearchFilters>

      {permissions.includes('CanManageJobs') && (
        <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
            <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add Work Order</Text>
          </View>
        </TouchableOpacity>
      )}

      <CoreTable
        filters={filters}
        setFilter={setFilter}
        isLoading={jobsFetching}
        data={jobsListResult}
        headers={[
          { text: 'Edit', visible: true, flex: 0.2 },
          { text: 'Job Reference', visible: true, flex: 1.5 },
          { text: 'Priority', visible: true, flex: 0.5, sortedName: 'priority' },
          { text: 'Planned Date', visible: true, sortedName: 'plannedDate', flex: 0.5 },
          { text: 'Job Type', visible: true, flex: 0.5 },
          { text: 'Assigned Technician', visible: true },
          { text: 'Approved By', visible: true },
          { text: 'Status', visible: true },
        ]}
        renderItem={({item}) => (
          <DataTable.Row key={item.id}>
            <DataTable.Cell style={{flex: 0.2}}>
              <TouchableOpacity onPress={() => handleOnJobPress(item)}>
                <Entypo name="pencil" size={24} color="black"/>
              </TouchableOpacity>
            </DataTable.Cell>
            <DataTable.Cell style={{flex: 1.5}}>{item.reference}</DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>{humanizeEnum<JobPriority>(item.priority)}</DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>{humanizeDate(item.plannedDate)}</DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>{JobType[item.type]}</DataTable.Cell>
            <DataTable.Cell>{item.technician?.name ?? 'Not Assigned'}</DataTable.Cell>
            <DataTable.Cell>{item?.approvedByUser?.name}</DataTable.Cell>
            {jobStatusCell(item)}
          </DataTable.Row>
        )}
      />
    </WebScreen>
  )
}


const styles = StyleSheet.create({
  approvalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 12,
    alignItems: 'center',
    width: '100%'
  }
})
