import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, SafeAreaView, Image, } from "react-native";
import { AntDesign, EvilIcons, MaterialIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { TextInput } from "react-native-paper";
import { Controller, useForm } from "react-hook-form";
import { useAddTicketMutation } from "../../../../store/services/api/ticket-api";
import { LoadingIndicator } from "../../../web/components/LoadingIndicator";
import { clearTicket, setTicket } from "../../../../store/slices/ticket-slice";
import * as ImagePicker from "expo-image-picker";
import { compressImage } from "../../../../utils/imageUtils";

export default function AddTicketScreen({ navigation }: { navigation: any }) {
  const asset = useSelector((state: RootState) => state.asset);
  const [createTicket, createTicketResult] = useAddTicketMutation();
  const dispatch = useDispatch();
  const [selectedImages, setSelectedImages] = useState([]);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      description: asset?.description,
      issue: "",
      contactEmail: "",
      assetId: asset?.id,
    },
  });
  const [charCount, setCharCount] = useState(0);

  const onInputChange = (text: string) => {
    setCharCount(text.length);
  };
  const takeImage = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    if (status !== "granted") {
      alert("Permission to access the camera is required!");
      return;
    }

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      base64: true,
    });

    if (!result.canceled) {
      setSelectedImages([...selectedImages, result.assets[0].uri]);
    }
  };

  const pickImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert("Permission to access the photos is required!");
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      base64: true,
      allowsMultipleSelection: true
    });

    if (!result.canceled) {
      setSelectedImages([...selectedImages, result.assets[0].uri]);
    }
  };

  const deleteImage = (index) => {
    setSelectedImages(selectedImages.filter((_, idx) => idx !== index));
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const onSubmit = async (data) => {

    const compressedImages = await Promise.all(
      selectedImages.map(uri => compressImage(uri))
    );
    data.images = compressedImages;

    const result = (await createTicket(data)) as any;
    if (result) {
      dispatch(clearTicket());
      dispatch(setTicket(result.data));
    }

    if (result.error) {
      console.error(result.error);
      return;
    }
    navigation.navigate("ticket-submit-confirmation-screen");
  };

  if (createTicketResult.isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <SafeAreaView style={GlobalStyle.mobile__page__container}>
      <View style={styles.header}>
        <TouchableOpacity
          style={GlobalStyle.sub__title__icon}
          onPress={() => navigation.goBack()}
        >
          <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
      </View>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.section}>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, onBlur, value } }) => (
              <View>
                <Text>Item Description</Text>
                <TextInput
                  style={styles.greyed__out__input}
                  onBlur={onBlur}
                  value={asset ? asset.description : ""}
                  editable={false}
                  multiline
                ></TextInput>
              </View>
            )}
          />
        </View>
        <View style={styles.section}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="issue"
            render={({ field: { onChange, onBlur, value } }) => (
              <View>
                <Text>Enter Issue</Text>
                <TextInput
                  style={styles.input}
                  onBlur={onBlur}
                  onChangeText={(text) => {
                    onChange(text);
                    onInputChange(text);
                  }}
                  value={value}
                  multiline
                  numberOfLines={10}
                  maxLength={200}
                />
                <Text style={styles.characterCount}>{charCount}/200</Text>
                {errors.issue && (
                  <Text style={GlobalStyle.form__input__validation}>
                    Issue is required.
                  </Text>
                )}
              </View>
            )}
          />
        </View>
        <View style={styles.section}>
          <View style={styles.imagesContainer}>
            {selectedImages.length < 5 && (
              <TouchableOpacity onPress={pickImage} style={styles.addButton}>
                <MaterialIcons name="add-to-photos" size={24} color="white" />
              </TouchableOpacity>
            )}
            {selectedImages.length < 5 && (
              <TouchableOpacity onPress={takeImage} style={styles.addButton}>
                <MaterialIcons name="camera-alt" size={24} color="white" />
              </TouchableOpacity>
            )}

            <View
              style={styles.imageScroll}
            >
              {selectedImages.map((uri, index) => (
                <TouchableOpacity
                  key={uri}
                  onPress={() => deleteImage(index)}
                  style={styles.imageWrapper}
                >
                  <Image source={{ uri }} style={styles.image} />
                </TouchableOpacity>
              ))}
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Controller
            control={control}
            rules={{ required: true, validate: isValidEmail }}
            name="contactEmail"
            render={({ field: { onChange, onBlur, value } }) => (
              <View>
                <Text>Contact email</Text>
                <TextInput
                  style={styles.input}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                ></TextInput>
              </View>
            )}
          />
          {errors.contactEmail && (
            <Text style={GlobalStyle.form__input__validation}>
              Email is not valid.
            </Text>
          )}
        </View>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={[GlobalStyle.mobile__button, { backgroundColor: '#69db7c', width: '85%' }]}
            onPress={handleSubmit(onSubmit)}
          >
            <AntDesign name="plus" size={38} color="white" />
            <Text style={styles.buttonText}>Submit ticket</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    alignSelf: "stretch",
    marginRight: 20,
    marginBottom: 10,
    padding: 20,
  },
  title: {
    fontSize: 18,
    marginVertical: 20,
    textAlign: "center",
    paddingHorizontal: "5%",
  },
  buttonContainer: {
    marginVertical: 20,
    width: "100%",
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    marginLeft: 10,
    fontSize: 24
  },
  footer: {
    fontSize: 16,
    color: "#3B3B3B",
    marginBottom: 20,
  },
  section: {
    width: "80%",
    height: "auto",
    alignItems: "stretch",
  },
  input: {
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 5,
    marginBottom: 10,
    backgroundColor: "white",
    width: "100%",
  },
  greyed__out__input: {
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 5,
    padding: 5,
    marginBottom: 10,
    backgroundColor: "#f2f2f2",
    width: "100%",
  },
  characterCount: {
    alignSelf: "flex-end",
  },
  imagesContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  addButtonContainer: {
    alignSelf: "flex-start",
  },
  addButton: {
    marginRight: 10,
    backgroundColor: PRIMARY_COLOUR,
    padding: 8,
    borderRadius: 4,
    justifyContent: "center",
  },
  imageScroll: {
    flexDirection: "row",
  },
  imageWrapper: {
    marginRight: 10,
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 8,
  },
});
