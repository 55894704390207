import {useGetJobPlanCascaderOptionsQuery} from "../../store/services/api/job-plan-api";
import {Cascader, CascaderProps} from "antd";
import React, {useEffect} from "react";
import {JobPlan} from "../../store/data/entities/job-plan";
import {findFullPathByLeafId} from "../../utils/cascader-utils";

type JobPlanCascaderProps = CascaderProps<any, any, any> & {
  initialValues?: JobPlan[] | undefined;
  value?: string[][] | string[] | undefined;
  onChange?: (value: string[][] | string[]) => void;
}

enum JobPlanOptionType {
  AssetType,
  SubAssetType,
  JobPlan
}

export type JobPlanOption = {
  value: string;
  label: string;
  type: JobPlanOptionType;
  children?: JobPlanOption[];
  isLeaf?: boolean;
  selectable?: boolean;
}

export default function JobPlanFormControl(props: JobPlanCascaderProps) {
  const {data: options, isLoading} = useGetJobPlanCascaderOptionsQuery();

  useEffect(() => {
    const initializeOptions = async () => {
      if (props.initialValues) {
        const mappedPaths = props.initialValues
          .map((jobPlan) => findFullPathByLeafId(options, jobPlan.id))
          .filter((path): path is string[] => !!path);

        const value = mappedPaths || [];
        props.onChange?.(value);
      }
    };

    initializeOptions();
  }, [props.initialValues, options]);


  const handleOnChange: CascaderProps<any, any, any>['onChange'] = (selectedItem: string[], selectedOptions: JobPlanOption[][]) => {
    const value = selectedOptions.map((option) => option?.map((o) => o.value));
    props.onChange?.(value);
  };

  return (
    <Cascader
      {...props}
      options={isLoading ? [] : options}
      loading={isLoading || props.loading}
      onChange={handleOnChange}
      changeOnSelect
      showCheckedStrategy="SHOW_CHILD"
      placeholder="Please select"
      multiple={true}
    />
  )
}
