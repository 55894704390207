import {WebScreen} from "../../components/WebScreen";
import {Text, TouchableOpacity, View} from "react-native";
import GlobalStyle, {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import {EvilIcons} from "@expo/vector-icons";
import * as React from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {useGetJobQuery} from "../../../../store/services/api/job-api";
import {JobType} from "../../../../store/data/entities/job";
import {MaintenanceJobApproval} from "./Approvals/MaintenanceJobApproval";
import {CorrectiveJobApproval} from "./Approvals/CorrectiveJobApproval";

export function JobApproval({navigation, route}: NativeStackScreenProps<any, any>) {
  const {jobId} = route.params;
  const {data: jobDetails} = useGetJobQuery(jobId);

  const JobComponentsMap = {
    [JobType.Maintenance]: MaintenanceJobApproval,
    [JobType.Corrective]: CorrectiveJobApproval,
  };

  const jobControl = () => {
    if (!jobDetails) return null;

    const JobComponent = JobComponentsMap[jobDetails.type];
    return JobComponent ? <JobComponent navigation={navigation} route={route}/> : null;
  }

  return (
    <WebScreen>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.navigate("list")}>
          <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{"APPROVE WORK ORDER"}</Text>
      </View>
      {jobControl()}
    </WebScreen>
  )
}
