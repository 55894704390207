import {ActivityIndicator, Image, ScrollView, StyleSheet, View} from "react-native";
import React, {ReactNode} from "react";
import {usePage} from "../../../hooks/usePage";

export default function MobilePageLayout({children}: {children: ReactNode}) {
  const {isLoading} = usePage();

  return (
    <View style={styles.pageContainer}>
      <ScrollView style={styles.contentContainer} automaticallyAdjustKeyboardInsets>
        {children}
      </ScrollView>

      <View style={styles.logoContainer}>
        <Image
          source={require("../../../assets/images/coreLogoSmall.png")}
          style={styles.tinyLogo}
          resizeMode="contain"
        />
        <Image
          source={require("../../../assets/images/pfizerLogoSmall.png")}
          style={styles.tinyLogo}
          resizeMode="contain"
        />
      </View>
      {isLoading && <MobileLoadingIndicator />}
    </View>
  )
}

function MobileLoadingIndicator() {
  return (
    <View style={styles.indicatorContainer}>
      <ActivityIndicator size={48} />
    </View>
  )
}


const styles = StyleSheet.create({
  contentContainer: {
    padding: 4,
    flex: 1
  },
  logoContainer: {
    backgroundColor: 'white',
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderColor: 'rgb(216, 216, 216)',
    borderWidth: 1,
  },
  tinyLogo: {
    width: 90,
    height: 50,
  },
  pageContainer: {
    height: '100%',
    position: 'relative'
  },
  indicatorContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
})
