import {Button, Flex, Form, Skeleton} from "antd";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useGetAssetIdListMutation, useLazyGetAssetOptionsQuery} from "../../../../../../store/services/api/asset-api";
import {
  useCreatePreventativeMaintenanceMutation,
  useLazyGetPreventativeMaintenanceQuery,
  useUpdatePreventativeMaintenanceMutation
} from "../../../../../../store/services/api/preventative-maintenance-api";
import {PreventativeMaintenanceForm} from "../../../../../../store/data/entities/preventative-maintenance";
import PreventativeMaintenanceDetailsForm from "./PreventativeMaintenanceDetailsForm";
import {View} from "react-native";
import JobPlanFormControl from "../../../../../../components/form-controls/JobPlanFormControl";
import PreventativeMaintenanceApprovalModal from "./PreventativeMaintenanceApprovalModal";
import AssetFormControl from "../../../../../../components/form-controls/AssetFormControl";

interface PreventativeMaintenanceManageFormProps {
  onSuccess?: () => void;
  preventativeMaintenanceId?: string | undefined;
  isApproval?: boolean;
}

export default function PreventativeMaintenanceManageForm(props: PreventativeMaintenanceManageFormProps) {
  const [createMaintenance, {isLoading: createLoading}] = useCreatePreventativeMaintenanceMutation();
  const [updateMaintenance, {isLoading: updateLoading}] = useUpdatePreventativeMaintenanceMutation();

  const [getAssetOptions, {data: initialAssetOptions, isFetching: initialAssetOptionsLoading}] = useLazyGetAssetOptionsQuery();
  const [getMaintenance, {data: initialFormData, isFetching: initialFormDataLoading}] = useLazyGetPreventativeMaintenanceQuery();

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [form] = Form.useForm();

  const handleOnFinish = async (data: any) => {
    const formData = convertFormData(data);

    if (props.preventativeMaintenanceId) {
      updateMaintenance(formData)
        .then(() => {
          props.onSuccess?.();
        })
      return;
    }

    createMaintenance(formData)
      .then(() => {
        props.onSuccess?.();
      })
  }

  const convertFormData = (data): PreventativeMaintenanceForm => ({
    ...data,
    jobPlans: data.jobPlans?.map(item => item[item.length - 1]) ?? [],
    assets: data.assets?.map(item => item.value) ?? [],
  });

  useEffect(() => {
    if (!props.preventativeMaintenanceId) return;
    getMaintenance(props.preventativeMaintenanceId)
      .unwrap()
      .then((pm) => {
        getAssetOptions(pm.assets.map(item => item.id))
      });
  }, [props.preventativeMaintenanceId]);

  useEffect(() => {
    if (!initialFormData) return;

    form.setFieldValue('id', initialFormData.id);
    form.setFieldValue('title', initialFormData.title);
    form.setFieldValue('technicianId', initialFormData.technicianId);
    form.setFieldValue('buildingLeadId', initialFormData.buildingLeadId);
    form.setFieldValue('startDate', dayjs(initialFormData.startDate));
    form.setFieldValue('frequency', initialFormData.frequency);
    form.setFieldValue('frequencyPeriod', initialFormData.frequencyPeriod);
    form.setFieldValue('autoGenerateOffset', initialFormData.autoGenerateOffset);
    form.setFieldValue('autoGenerateOffsetPeriod', initialFormData.autoGenerateOffsetPeriod);
  }, [initialFormData]);

  useEffect(() => {
    if (!initialAssetOptions) return;
    form.setFieldValue('assets', initialAssetOptions);
  }, [initialAssetOptions]);

  const handleOnApproval = () => {
    setShowApprovalModal(false);
    props.onSuccess?.();
  }

  return (
    <Skeleton
      loading={initialFormDataLoading || initialAssetOptionsLoading}
      active
    >
      <Form
        onFinish={handleOnFinish}
        form={form}
        layout='vertical'
        disabled={createLoading || updateLoading || props.isApproval}
      >
        <Flex gap={16}>
          <View style={{flex: 1}}>
            <PreventativeMaintenanceDetailsForm form={form} isApproval={props.isApproval} />
          </View>
          <View style={{flex: 2}}>
            <Form.Item label='Assets' name='assets'>
              <AssetFormControl
                disabled={props.isApproval}
                multiple
              />
            </Form.Item>
            <Form.Item name='jobPlans' label='Job Plans'>
              <JobPlanFormControl
                initialValues={initialFormData?.jobPlans}
              />
            </Form.Item>
          </View>
        </Flex>

        {!props.isApproval && (
          <div>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        )}
      </Form>
      {props.isApproval && (
        <div>
          <Button type="primary" onClick={() => setShowApprovalModal(true)}>
            Approve / Reject
          </Button>
        </div>
      )}

      <PreventativeMaintenanceApprovalModal
        id={props.preventativeMaintenanceId}
        onClose={() => setShowApprovalModal(false)}
        onSuccess={handleOnApproval}
        open={showApprovalModal}
      />
    </Skeleton>
  )
}
