import {Controller, useForm} from "react-hook-form";
import MobileForm from "../../../../layouts/MobileForm";
import {TextInput} from "react-native-paper";
import {StyleSheet, View} from "react-native";
import {Picker} from "@react-native-picker/picker";
import React, {useEffect} from "react";
import {
  useGetAllAssetTypesQuery,
  useGetAllSubAssetTypesQuery
} from "../../../../../../store/services/api/asset-type-api";
import {
  useGetAllBuildingsQuery,
  useGetAllFloorsQuery,
  useGetAllLocationsQuery
} from "../../../../../../store/services/api/building-api";
import {PRIMARY_COLOUR} from "../../../../../../constants/GlobalStyle";
import {FileUpload} from "../../../../../../components/file-uploader/FileUpload";
import {getImageBase64, pickMedia} from "../../../../../../utils/media-utils";
import {useModal} from "../../../../../../hooks/useModal";
import ImagePreviewModal from "../../../../modals/ImagePreviewModal";

interface AssetSetupFormProps {
  uniqueRef: string;
  onSubmit: (data: any) => void;
}

export default function AssetSetupForm({uniqueRef, onSubmit}: AssetSetupFormProps) {
  const { data: buildings } = useGetAllBuildingsQuery(null);
  const { data: floors } = useGetAllFloorsQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: assetTypes } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes } = useGetAllSubAssetTypesQuery(null);

  const form = useForm({
    defaultValues: {
      id: null,
      buildingId: "",
      floorId: "",
      code: "",
      locationId: "",
      assetTypeId: "",
      subAssetTypeId: "",
      description: "",
      uniqueReference: uniqueRef,
      images: [],
      priority: 1,
    }
  });
  const assetTypeId = form.watch('assetTypeId');
  const buildingId = form.watch('buildingId');
  const floorId = form.watch('floorId');

  useEffect(() => {
    form.setValue('subAssetTypeId', '');
  }, [assetTypeId])

  useEffect(() => {
    form.setValue('floorId', '');
  }, [buildingId]);

  useEffect(() => {
    form.setValue('locationId', '');
  }, [floorId])

  const handleOnFormSubmit = () => {
    form.handleSubmit(onSubmit)();
  }

  const assetTypeOptions = (assetTypes ?? []).map(item => ({
    label: item.name,
    value: item.id
  }));

  const subAssetTypeOptions = (subAssetTypes ?? [])
    .filter(item => item.assetTypeId === assetTypeId)
    .map(item => ({
      label: item.name,
      value: item.id
  }));

  const buildingOptions = (buildings ?? []).map(item => ({
    label: item.name,
    value: item.id
  }));

  const floorOptions = (floors ?? [])
    .filter(item => item.buildingId === buildingId)
    .map(item => ({
      label: item.name,
      value: item.id
  }));

  const locationOptions = (locations ?? [])
    .filter(item => item.floorId === floorId)
    .map(item => ({
      label: item.name,
      value: item.id
  }));


  return (
    <MobileForm onSubmit={handleOnFormSubmit}>
      <SelectFormControl
        title='Asset Type'
        control={form.control}
        controlName='assetTypeId'
        options={assetTypeOptions}
      />
      <SelectFormControl
        title='Sub Asset Type'
        control={form.control}
        controlName='subAssetTypeId'
        options={subAssetTypeOptions}
      />
      <SelectFormControl
        title='Building'
        control={form.control}
        controlName='buildingId'
        options={buildingOptions}
      />
      <SelectFormControl
        title='Floor'
        control={form.control}
        controlName='floorId'
        options={floorOptions}
      />
      <SelectFormControl
        title='Location'
        control={form.control}
        controlName='locationId'
        options={locationOptions}
      />
      <TextFormControl
        title='Code'
        control={form.control}
        controlName='code'
        multiline={true}
        numberOfLines={2}
      />
      <TextFormControl
        title='Description'
        control={form.control}
        controlName='description'
        multiline={true}
        numberOfLines={3}
      />
      <TextFormControl
        title='CoreRFID QR'
        control={form.control}
        controlName='uniqueReference'
        multiline={true}
        numberOfLines={3}
      />
      <ImageFormControl
        control={form.control}
        controlName='images'
      />
    </MobileForm>
  )
}

function ImageFormControl({control, controlName}) {
  const {showModal, hideModal} = useModal();

  return (
    <Controller
      control={control}
      name={controlName}
      render={({field: {onChange, value}}) => (
        <FileUpload
          buttonText='Add Image'
          fileList={value}
          onRemove={() => onChange([])}
          onUploadFilesPress={async () => {
            const images = await pickMedia('image', false);
            onChange([images[0]]);
          }}
          onFilePress={async (file) => {
            const base64 = await getImageBase64(file);
            showModal(<ImagePreviewModal imageBase64={base64} onClose={hideModal} />);
          }}
        />
      )}
    />
  )
}

export function TextFormControl({title, control, controlName, multiline = false, numberOfLines = 3}) {
  return (
    <Controller
      control={control}
      name={controlName}
      render={({field}) => (
        <TextInput
          {...field}
          label={title}
          mode='outlined'
          multiline={multiline}
          numberOfLines={numberOfLines}
        />
      )}
    />
  )
}

export function SelectFormControl({title, control, controlName, options}) {
  return (
    <Controller
      control={control}
      name={controlName}
      render={({field: {value, onChange}}) => (
        <View style={styles.pickerContainer}>
          <TextInput
            label={title}
            mode="outlined"
            value={options?.find(item => item.value === value)?.label ?? ''}
          />
          <Picker
            style={styles.pickerOverlay}
            selectedValue={value}
            onValueChange={(itemValue) => onChange(itemValue)}
          >
            <Picker.Item label="Please Select" value="" />
            {(options ?? []).map(item => (
              <Picker.Item label={item.label} value={item.value} key={item.value} />
            ))}
          </Picker>
        </View>
      )}
    />
  )
}

const styles = StyleSheet.create({
  pickerContainer: {
    position: 'relative'
  },
  pickerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    zIndex: 9999
  },
  addButton: {
    marginRight: 10,
    backgroundColor: PRIMARY_COLOUR,
    padding: 8,
    borderRadius: 4,
    justifyContent: "center",
  },
  imagesContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  imageWrapper: {
    marginRight: 10,
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 8,
  }
})
