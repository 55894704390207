import {usePage} from "../../../hooks/usePage";
import {useToast} from "../../../components/toast/ToastProvider";
import {useEffect} from "react";
import {useNavigation} from "@react-navigation/native";
import {useSetLoading} from "../../../hooks/useSetLoading";

interface UseEntityManagePageProps<T> {
  entityName: string;
  entityId: string | undefined;
  createMutation: any;
  updateMutation: any;
  lazyGetEntityQuery: any;
}

export default function useEntityManagePage<T>(props: UseEntityManagePageProps<T>) {
  const navigation = useNavigation<any>();
  const [createEntity] = props.createMutation();
  const [updateEntity] = props.updateMutation();
  const [getEntity, {data, isFetching}] = props.lazyGetEntityQuery();

  const {setIsLoading} = usePage();
  const {show: showToast} = useToast();

  useSetLoading([isFetching]);

  useEffect(() => {
    if (props.entityId != null) {
      getEntity(props.entityId);
    }
  }, [props.entityId]);

  const onSubmit = async (data: any) => {
    const performAction = async () => {
      if (props.entityId != null) {
        return updateEntity(data).unwrap();
      }
      return createEntity(data).unwrap();
    }

    setIsLoading(true);
    try {
      const result = await performAction();

      if (result?.error) {
        showToast('Error', `There was an issue updating the ${props.entityName}`, 'error');
        setIsLoading(false);
        return;
      }

      showToast(`Updated ${props.entityName}`, `Successfully updated ${props.entityName}`, 'success');
      navigation.navigate("list");
    }
    catch {
      showToast('Error', `There was an issue updating the ${props.entityName}`, 'error');
    }
    finally {
      setIsLoading(false);
    }
  }


  return {
    entity: data as T,
    onSubmit
  }
}
