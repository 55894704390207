import React, {ReactNode} from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import SystemAssetsStack, {systemAssetsLinkingOptions} from "./assets/SystemAssetsStack";
import SystemAssetTypesStack, {systemAssetTypesLinkingOptions} from "./asset-types/SystemAssetTypesStack";
import SystemBuildingsStack, {
  systemBuildingsLinkingOptions
} from "./buildings/SystemBuildingsStack";
import SystemUsersStack, {systemUsersLinkingOptions} from "./users/SystemUsersStack";
import SystemSettingsStack, {systemSettingsLinkingOptions} from "./settings/SystemSettingsStack";
import useHasPermission from "../../../../hooks/useHasPermission";
import {UserPermission} from "../../../../store/data/users/user-permission";

const Stack = createNativeStackNavigator<SystemStackParamList>();

interface ProtectedRoute {
  requiredPermission: UserPermission;
  screen: ReactNode;
}

export default function SystemStack() {
  const {hasPermission} = useHasPermission();

  const screens: ProtectedRoute[] = [
    {
      requiredPermission: 'CanViewAssets',
      screen: (
        <Stack.Screen
          name='assets'
          component={SystemAssetsStack}
          options={{headerShown: false}}
        />
      )
    },
    {
      requiredPermission: 'CanManageSystem',
      screen: (
        <Stack.Screen
          name='assetTypes'
          component={SystemAssetTypesStack}
          options={{headerShown: false}}
        />
      )
    },
    {
      requiredPermission: 'CanManageSystem',
      screen: (
        <Stack.Screen
          name='buildings'
          component={SystemBuildingsStack}
          options={{headerShown: false}}
        />
      )
    },
    {
      requiredPermission: 'CanManageSystem',
      screen: (
        <Stack.Screen
          name='users'
          component={SystemUsersStack}
          options={{headerShown: false}}
        />
      )
    },
    {
      requiredPermission: 'CanManageSystem',
      screen: (
        <Stack.Screen
          name='settings'
          component={SystemSettingsStack}
          options={{headerShown: false}}
        />
      )
    }
  ];

  const renderScreens = () => {
    return screens.map(screen => {
      if (hasPermission(screen.requiredPermission)) {
        return screen.screen;
      }
    });
  }

  return (
    <Stack.Navigator>
      {renderScreens()}
    </Stack.Navigator>
  );
};

export const systemLinkingOptions = {
  path: 'system',
  screens: {
    assets: systemAssetsLinkingOptions,
    assetTypes: systemAssetTypesLinkingOptions,
    buildings: systemBuildingsLinkingOptions,
    users: systemUsersLinkingOptions,
    settings: systemSettingsLinkingOptions
  },
}

export type SystemStackParamList = ParamListBase & {
  assets: undefined;
  assetTypes: undefined;
  buildings: undefined;
  users: undefined;
  settings: undefined;
};
