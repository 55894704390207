import {useForm} from "react-hook-form";
import * as React from "react";
import {useEffect} from "react";
import CoreForm from "../../../../../../web/components/forms/CoreForm";
import {TextFieldController} from "../../../../../../web/components/forms/TextFieldController";
import {
  AssetManageForm as AssetForm,
  assetPriorityOptions
} from "../../../../../../../store/data/entities/asset";
import {
  useGetAllBuildingsQuery,
  useGetAllFloorsQuery,
  useGetAllLocationsQuery
} from "../../../../../../../store/services/api/building-api";
import {
  useGetAllAssetTypesQuery,
  useGetAllSubAssetTypesQuery
} from "../../../../../../../store/services/api/asset-type-api";
import {useSetLoading} from "../../../../../../../hooks/useSetLoading";
import * as DocumentPicker from "expo-document-picker";
import {PickerFieldController} from "../../../../../../web/components/forms/PickerFieldController";
import {Image, StyleSheet, View} from "react-native";
import {Button} from "react-native-paper";
import {workOrderPriorityOptions} from "../../../../../../../store/data/entities/work-order";

interface AssetManageFormProps {
  asset: any;
  onSubmit: (data: any) => void;
}

export default function AssetManageForm({asset, onSubmit}: AssetManageFormProps) {
  const { data: buildings, isFetching: buildingsFetching } = useGetAllBuildingsQuery(null);
  const { data: floors, isFetching: floorsFetching } = useGetAllFloorsQuery(null);
  const { data: locations, isFetching: locationsFetching } = useGetAllLocationsQuery(null);
  const { data: assetTypes, isFetching: assetTypesFetching } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes, isFetching: subAssetTypesFetching } = useGetAllSubAssetTypesQuery(null);

  useSetLoading([buildingsFetching, floorsFetching, locationsFetching, assetTypesFetching, subAssetTypesFetching]);

  const form = useForm<AssetForm>({
    defaultValues: {
      id: undefined,
      buildingId: "",
      floorId: "",
      locationId: "",
      assetTypeId: "",
      subAssetTypeId: "",
      description: "",
      code: "",
      uniqueReference: "",
      imageBase64: "",
      troubleshootingGuide: "",
      priority: 0,
      supportingInformation: ""
    }
  });
  let imageBase64 = form.watch("imageBase64");

  useEffect(() => {
    if (asset) {
      form.setValue("id", asset.id);
      form.setValue("buildingId", asset.buildingId);
      form.setValue("floorId", asset.floorId);
      form.setValue("locationId", asset.locationId);
      form.setValue("assetTypeId", asset.assetTypeId);
      form.setValue("subAssetTypeId", asset.subAssetTypeId);
      form.setValue("description", asset.description);
      form.setValue("code", asset.code);
      form.setValue("uniqueReference", asset.uniqueReference);
      form.setValue("troubleshootingGuide", asset.troubleshootingGuide);
      form.setValue("priority", asset.priority);
    }
  }, [asset])

  const handleOnSubmit = () => {
    form.handleSubmit(onSubmit)();
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const loadFile = async () => {
    var result = await DocumentPicker.getDocumentAsync({
      type: ".png"
    }) as any;

    if (result.assets.length == 0) return;
    const file = result.assets[0].file;

    var base64 = (await toBase64(file)) as string;

    form.setValue("imageBase64", base64);
  }

  return (
    <CoreForm form={form} onSubmit={handleOnSubmit}>
      <CoreForm.Row>
        <PickerFieldController
          controlName='buildingId'
          title='Building'
          required
          defaultFirst={false}
          items={(buildings ?? []).map(item => ({
            label: item.name,
            value: item.id
          }))} />
        <PickerFieldController
          controlName='floorId'
          title='Floor'
          required
          defaultFirst={false}
          items={(floors ?? []).map(item => ({
            label: item.name,
            value: item.id
          }))} />
        <PickerFieldController
          controlName='locationId'
          title='Location'
          required
          defaultFirst={false}
          items={(locations ?? []).map(item => ({
            label: item.name,
            value: item.id
          }))} />
      </CoreForm.Row>
      <CoreForm.Row>
        <PickerFieldController
          controlName='assetTypeId'
          title='Asset Type'
          required
          defaultFirst={false}
          items={(assetTypes ?? []).map(item => ({
            label: item.name,
            value: item.id
          }))} />
        <PickerFieldController
          controlName='subAssetTypeId'
          title='Sub Asset Type'
          required
          defaultFirst={false}
          items={(subAssetTypes ?? []).map(item => ({
            label: item.name,
            value: item.id
          }))} />
        <TextFieldController controlName='description' title='Description' />
      </CoreForm.Row>
      <CoreForm.Row>
        <TextFieldController required controlName='uniqueReference' title='CoreRFID QR' />
        <TextFieldController required controlName='code' title='Code' />
        <PickerFieldController
          numeric
          controlName='priority'
          title='Priority'
          required
          items={assetPriorityOptions} />
      </CoreForm.Row>
      <CoreForm.Row>
        <TextFieldController
          style={{minHeight: 50}}
          multiline
          controlName='supportingInformation'
          title='Supporting Information' />
      </CoreForm.Row>
      <CoreForm.Row>
        <TextFieldController
          style={{minHeight: 100}}
          multiline
          controlName='troubleshootingGuide'
          title='Troubleshooting Guide' />
      </CoreForm.Row>
      <CoreForm.Row>
        <View style={styles.imageSection}>
          <View style={styles.imageContainer}>
            <Image
              style={{ height: 200, width: 200 }}
              resizeMode='cover'
              source={{
                uri: imageBase64 != "" ? imageBase64 : asset?.imageUri
              }}
            />
          </View>
          <Button
            icon="camera" mode='elevated'
            buttonColor='#FFF'
            textColor='#333'
            style={{marginTop: 12}}
            onPress={() => loadFile()}
          >
            Upload Picture
          </Button>
        </View>
      </CoreForm.Row>
    </CoreForm>
  )
}

const styles = StyleSheet.create({
  imageFormContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: "column"
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: "row",
    minHeight: 200,
  },
  imageSection: {
    borderWidth: 1,
    borderColor: "#d9d9d9",
    borderRadius: 6,
    padding: 12
  }
})
