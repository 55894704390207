import {View, Text, StyleSheet, Animated, Dimensions} from "react-native";
import {Media} from "../../../../store/data/entities/media";
import {CoreList} from "../core-list/CoreList";
import React, {useEffect, useState} from "react";

interface MediaListProps {
  media: Media[];
  isLoading?: boolean;
  emptyListText?: string;
}

export function MediaList({media, isLoading, emptyListText}: MediaListProps) {
  const [selectedMedia, setSelectedMedia] = useState<Media>();
  const [animation] = useState(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(animation, {
      toValue: selectedMedia ? 1 : 0,
      duration: 150,
      useNativeDriver: false,
    }).start();
  }, [selectedMedia]);

  const screenHalfHeight = Dimensions.get('window').height / 2;
  const animatedHeight = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, screenHalfHeight],
  });

  const selectedMediaType: 'video' | 'image' = selectedMedia?.uri.endsWith('.mp4') ? 'video' : 'image';

  return (
    <View style={styles.container}>
      {selectedMedia && (
        <Animated.View style={[styles.mediaContainer, {maxHeight: animatedHeight}]}>
          <View style={styles.mediaHeader}>
            <Text style={styles.mediaTitle}>{selectedMedia.title}</Text>
            <Text style={styles.mediaDescription}>{selectedMedia.description}</Text>
          </View>
          {selectedMediaType === 'video' ? (
            <video
              style={styles.mediaPlayer}
              src={selectedMedia.uri}
              controls
            />
          ) : (
            <img
              alt={selectedMedia.title}
              src={selectedMedia.uri}
              style={styles.image}
            />
          )}
        </Animated.View>
      )}
      <View style={styles.listContainer}>
        <CoreList<Media>
          isLoading={isLoading}
          data={media}
          emptyListText={emptyListText}
          onItemPress={({item}) => setSelectedMedia(item)}
          renderItem={({item}) => (
            <View style={styles.listItemContainer}>
              <Text>{item.title}</Text>
              <Text>{item.description}</Text>
            </View>
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
  },
  mediaContainer: {
    backgroundColor: '#333',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  mediaHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 8,
  },
  mediaTitle: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  mediaDescription: {
    color: '#fff',
    fontSize: 12,
  },
  mediaPlayer: {
    width: '100%',
    height: '100%',
    aspectRatio: 1,
  },
  image: {
    height: '100%',
    aspectRatio: '1:1',
    resizeMode: 'cover',
  },
  listContainer: {
    flex: 1,
    paddingTop: 4
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingVertical: 12,
    paddingHorizontal: 18,
    gap: 4
  },
});
