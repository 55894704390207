import {CoreFilters, CoreFiltersBaseProps} from "../../../../../components/CoreFilters";
import {DropdownFilter, TextInputFilter} from "../../../../../../web/components/search-filters/SearchFilters";
import { Picker } from "@react-native-picker/picker";
import React from "react";

export default function PreventativeMaintenancesListScreenFilters({filter, onSubmit}: CoreFiltersBaseProps) {
  return (
    <CoreFilters
      filter={filter}
      onSubmit={onSubmit}
    >
      <TextInputFilter title='Reference' name='reference' />
      <TextInputFilter title='Description' name='description' />

      <DropdownFilter title='Status' name='status'>
        <Picker.Item label="All" value='' />
        <Picker.Item label="Awaiting Approval" value='0' />
        <Picker.Item label="Approved" value='1' />
        <Picker.Item label="Rejected" value='2' />
      </DropdownFilter>
    </CoreFilters>
  )
}
