import {CoreFilters, CoreFiltersBaseProps} from "../../../../components/CoreFilters";
import {DateFilter, DropdownFilter, TextInputFilter} from "../../../../../web/components/search-filters/SearchFilters";
import React, {useState} from "react";
import {Picker} from "@react-native-picker/picker";
import {WorkOrderPriority, WorkOrderStatus} from "../../../../../../store/data/entities/work-order";
import moment from "moment";

export default function WorkOrdersListScreenFilters({filter, onSubmit}: CoreFiltersBaseProps) {
  const [dateRange, setDateRange] = useState("-1");

  const updateDateRange = (val: string) => {
    setDateRange(val);
    if (!val) {
      filter.setFilter('startDate', '');
      filter.setFilter('endDate', '');
      return;
    }

    const startDate = moment();
    let endDate: moment.MomentInput;

    if (val == "-1") {
      endDate = moment().add(1, 'week');
    } else {
      endDate = moment().add(Number.parseInt(val), 'M');
    }

    filter.setFilter('startDate', moment(startDate).toISOString());
    filter.setFilter('endDate', moment(endDate).toISOString());
  }

  return (
    <CoreFilters
      filter={filter}
      onSubmit={onSubmit}
      onResetFilters={() => updateDateRange("-1")}
    >
      <TextInputFilter title='Reference' name='reference' />
      <TextInputFilter title='Parent WO Reference' name='parentReference' />
      <TextInputFilter title='Description' name='description' />

      <DropdownFilter title='Date Range' value={dateRange} onValueChange={updateDateRange}>
        <Picker.Item label="None" value=""/>
        <Picker.Item label="1 Week" value="-1"/>
        <Picker.Item label="1 Month" value="1"/>
        <Picker.Item label="3 Months" value="3"/>
        <Picker.Item label="6 Months" value="6"/>
        <Picker.Item label="12 Months" value="12"/>
      </DropdownFilter>
      <DateFilter title='Start Date' name='startDate'/>
      <DateFilter title='End Date' name='endDate'/>

      <DropdownFilter title='Priority' name='priority'>
        <Picker.Item label="All" value='' />
        <Picker.Item label="Standard" value={WorkOrderPriority.Standard} />
        <Picker.Item label="Important" value={WorkOrderPriority.Important} />
        <Picker.Item label="Urgent" value={WorkOrderPriority.Urgent} />
        <Picker.Item label="Emergency" value={WorkOrderPriority.Emergency} />
      </DropdownFilter>

      <DropdownFilter title='Status' name='statuses'>
        <Picker.Item label="All" value={[]} />
        <Picker.Item label="Approved" value={[WorkOrderStatus.Approved]} />
        <Picker.Item label="In Progress" value={[WorkOrderStatus.InProgress]} />
        <Picker.Item label="Completed (Pending Approval)" value={[WorkOrderStatus.Completed]} />
        <Picker.Item label="Closed" value={[WorkOrderStatus.Closed]} />
        <Picker.Item label="Cancelled" value={[WorkOrderStatus.Cancelled]} />
      </DropdownFilter>
    </CoreFilters>
  )
}
