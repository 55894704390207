import React, { useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, TextInput, View } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { Feather } from "@expo/vector-icons";
import {debounce} from "../../../../utils/debounce";

interface ComboPickerProps {
  options?: { key: string, value: string, label: string }[];
  onSearch: (value: string) => void;
  onValueChange: (value: string) => void;
  value: string;
  optionsLoading?: boolean;
  disabled?: boolean;
  debounceTime?: number;
}

export const ComboPicker: React.FC<ComboPickerProps> = ({options,onSearch,value,onValueChange,optionsLoading,disabled, debounceTime = 0}) => {
  const [textValue, setTextValue] = useState<string>('');

  useEffect(() => {
    const selectedOption = options?.find(opt => opt.value === value);
    if (!selectedOption) {
      setTextValue('');
      return;
    }

    setTextValue(selectedOption.label);
  }, [value]);

  const handleOnTextChanged = (value: string) => {
    setTextValue(value);
    debouncedSearch(value);
  }

  const debouncedSearch = React.useMemo(
    () => debounce(onSearch, debounceTime),
    [onSearch]
  );

  return (
    <View style={styles.container}>
      <TextInput
        editable={!disabled}
        style={[styles.textBox]}
        value={textValue}
        onChangeText={handleOnTextChanged}
      />
      <Picker
        enabled={!disabled}
        selectedValue={textValue}
        style={styles.picker}
        onValueChange={(itemValue) => onValueChange(itemValue)}
      >
        <Picker.Item key='select' label='Please Select' value='' />
        {options?.map(option => (
          <Picker.Item key={option.key} label={option.label} value={option.value} />
        ))}
      </Picker>
      <View pointerEvents={optionsLoading ? 'box-none' : 'none'} style={styles.downArrowContainer}>
        {optionsLoading ? (
          <ActivityIndicator size='small' />
        ) : (
          <Feather name='chevron-down' size={16}/>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    minHeight: 31,
    marginTop: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
    justifyContent: 'center',
    backgroundColor: '#fff'
  },
  textBox: {
    position: 'absolute',
    padding: 5,
    zIndex: 20,
    right: 32,
    bottom: 0,
    top: 0,
    left: 0,
    fontFamily: 'OpenSans-Regular',
    backgroundColor: 'white'
  },
  picker: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    opacity: 0,
    zIndex: 10,
    borderWidth: 0,
    backgroundColor: '#fff'
  },
  downArrowContainer: {
    pointerEvents: 'none',
    position: 'absolute',
    right: 5,
    top: 0,
    bottom: 0,
    zIndex: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff'
  },
  downArrow: {
    paddingHorizontal: 5
  }
});
