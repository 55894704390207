import {PickerItem} from "../../../screens/web/components/forms/PickerFieldController";
import {User} from "./user";

export interface Job {
  id: string;
  reference: string;
  technicianId: string;
  technician?: User | null;
  plannedDate: string;
  completed: boolean;
  approved: boolean;
  type: JobType;
  priority: JobPriority;
  approvedByUserId?: string;
  approvedByUser?: User;
  status?: JobStatus;
}

export enum JobStatus {
  NotStarted,
  AwaitingApproval,
  Completed,
  Overdue
}

export interface JobManageForm {
  id?: string;
  reference: string;
  plannedDate: string | null;
  type: JobType;
  priority: JobPriority;
  technicianId?: string;
}

export interface NewTicketJob extends JobManageForm {
  issue: string;
  contactEmail: string;
  assetId: string
}

export interface NewMaintenanceJob extends JobManageForm {
  assetId: string
  checklistId: string;
}

export interface NewJobFromTicket extends JobManageForm {
  ticketId: string;
}

export enum JobType {
  Corrective,
  Maintenance
}

export enum JobPriority {
  P1,
  P2,
  P3,
  P4
}

export interface JobMedia {
  url: string;
  contentType: string;
}

export const JobTypes: PickerItem<number>[] = [
  { key: 'corrective', value: JobType.Corrective, label: 'Corrective'},
  { key: 'maintenance', value: JobType.Maintenance, label: 'Maintenance'},
]

export const JobPriorities: PickerItem<number>[] = [
  { value: JobPriority.P1, label: 'EMER (P1)'},
  { value: JobPriority.P2, label: 'ERG (P2)'},
  { value: JobPriority.P3, label: 'IMP (P3)'},
  { value: JobPriority.P4, label: 'STD (P4)'},
]

export const JobStatuses: PickerItem<number>[] = [
  { value: JobStatus.NotStarted, label: 'Not Started'},
  { value: JobStatus.AwaitingApproval, label: 'Awaiting Approval'},
  { value: JobStatus.Completed, label: 'Completed'},
  { value: JobStatus.Overdue, label: 'Overdue'},
]
