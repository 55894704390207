import {PagedResponse} from "../../../utils/helpers";
import {FlatList, ListRenderItem, StyleSheet, View} from "react-native";
import {BaseFilters} from "../../web/hooks/useFilters";
import React from "react";
import {DataTable} from "react-native-paper";
import {EmptyList} from "../../../components/flatlist/EmptyList";
import {Tooltip} from "antd";

interface CoreTableHeader {
  text: string;
  hidden?: boolean;
  flex?: number;
  sortedName?: string | undefined;
}

interface CoreTableProps<T> {
  headers: CoreTableHeader[];
  data: PagedResponse<T>;
  renderItem: ListRenderItem<T>;
  keyExtractor: (data: T) => string;
  isLoading?: boolean;
  filters: BaseFilters;
  setFilter: (key: keyof BaseFilters, value: any) => void;
}

export function CoreTable<T>({headers, data, renderItem, keyExtractor, isLoading, filters, setFilter}: CoreTableProps<T>) {
  const {pageNumber, sortedColumn, sortedColumnAscending} = filters;

  const getPaginationLabel = () => {
    const start = data?.pagination.currentStartIndex;
    const end = data?.pagination.currentEndIndex;
    const total = data?.pagination.totalCount;
    if (!start || !end || !total) return ''

    return `${start}-${end} of ${total}`
  }

  const sortHeader = (header: CoreTableHeader) => {
    const column = header.sortedName;
    if (column == null) return;

    if (sortedColumn.toLowerCase() == column.toLowerCase()) {
      setFilter('sortedColumnAscending', !sortedColumnAscending);
    }

    setFilter('sortedColumn', column);
  }

  const previewData = isLoading ? [] : data?.list;
  const sortHeaderOrder = () => sortedColumnAscending ? 'ascending' : 'descending';

  return (
    <DataTable style={styles.table}>
      <DataTable.Header>
        {(headers ?? []).filter(h => !h?.hidden).map((header, index) => {
          const sortDirection = header.sortedName?.toLowerCase() != sortedColumn?.toLowerCase()
            ? null
            : sortHeaderOrder();

          return (
            <DataTable.Title
              onPress={() => sortHeader(header)}
              key={`${header?.text}-${index}`}
              style={{flex: header?.flex ?? 1}}
              sortDirection={sortDirection}
            >
              {header.text}
            </DataTable.Title>
          )
        })}
      </DataTable.Header>

      <FlatList
        style={{flex: 1}}
        data={previewData}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListEmptyComponent={
          <EmptyList
            isLoading={isLoading}
            emptyText='There are no results. Try changing your search filters' />
        }
      />

      <View style={styles.pagination}>
        <DataTable.Pagination
          page={pageNumber - 1}
          numberOfPages={data?.pagination.totalPages}
          onPageChange={(page) => setFilter('pageNumber', page + 1)}
          label={getPaginationLabel()}
          showFastPaginationControls
          numberOfItemsPerPage={10}
        />
      </View>
    </DataTable>
  )
}

CoreTable.Row = ({ children, style }) => {
  return <View style={[styles.row, style]}>{children}</View>;
};

CoreTable.Cell = ({ children, style, tooltip = true }: {children: any, style?: any, tooltip?: boolean}) => {
  if (!tooltip) return <DataTable.Cell style={style}>{children}</DataTable.Cell>;

  return (
    <Tooltip title={children} style={style}>
      <DataTable.Cell style={style}>
        {children}
      </DataTable.Cell>
    </Tooltip>
  )
};


const styles = StyleSheet.create({
  table: {
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderColor: '#CECECE',
    borderRadius: 6,
    flex: 1
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  row: {
    flexDirection: "row",
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
  },
});
