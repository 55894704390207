import {CoreList} from "../../../../screens/mobile/components/core-list/CoreList";
import React from "react";
import {StyleSheet, View} from "react-native";
import {Button} from "react-native-paper";
import {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import {JobMedia} from "../../../../store/data/entities/job";
import {getMediaType} from "../../../../store/data/entities/media";
import {useModal} from "../../../../hooks/useModal";
import {ConfirmModal} from "../../../modals/ConfirmModal";
import {JobMediaViewModal} from "../../../modals/JobMediaViewModal";

interface ImageApprovalList {
  media: JobMedia[];
  onRemoveMedia: (media: JobMedia) => void;
  isLoading?: boolean;
}

export function ImageApprovalList({media, onRemoveMedia, isLoading}: ImageApprovalList) {
  const {showModal, hideModal} = useModal();

  const showConfirmModal = (media: JobMedia) => {
    showModal(
      <ConfirmModal
        title='Remove item?'
        message='Are you sure you want to remove this item?'
        onConfirm={() => {
          hideModal();
          onRemoveMedia(media);
        }}
        onClose={hideModal} />
    )
  }

  const showMediaModal = (media: JobMedia) => {
    showModal(
      <JobMediaViewModal
        jobMedia={media}
        onClose={() => {
          hideModal();
        }}
      />
    )
  }

  const renderMedia = (media: JobMedia) => {
    const handleDelete = () => {
      showConfirmModal(media);
    }

    const mediaType = getMediaType(media.url);

    return (
      <View style={styles.mediaItem}>
        <View style={styles.mediaContainer}>
          {mediaType == 'video' ? (
            <video
              src={media.url}
              style={{height: '100%', width: '100%', objectFit: 'cover'}}
            />
          ) : (
            <img
              alt='Inspection Image'
              src={media.url}
              style={{height: '100%', width: '100%', objectFit: 'cover'}}
            />
          )}
          <View style={styles.actionsOverlay}>
            <Button
              icon='delete'
              textColor={PRIMARY_COLOUR}
              onPress={handleDelete}
            >
              Remove
            </Button>
          </View>
        </View>
      </View>
    )
  }

  return (
    <CoreList
      onItemPress={({item}) => showMediaModal(item)}
      horizontal
      isLoading={isLoading}
      emptyListText='No media was uploaded'
      data={media}
      renderItem={({item}) => renderMedia(item)}/>
  )
}

const styles = StyleSheet.create({
  mediaItem: {
    margin: 5
  },
  mediaContainer: {
    width: 150,
    height: 150,
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden'
  },
  actionsOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
