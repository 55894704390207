import { TagDescription } from '@reduxjs/toolkit/query';
import {TagType} from "../store/services/api/base-api";

type Tag = TagDescription<TagType>;

/**
 * Helper function to create tags for an entity with an id.
 * @param result - The entity result (e.g., AssetCategory, User, etc.).
 * @param type - The type of the tag (e.g., 'AssetCategory', 'User', etc.).
 * @returns Tags for the single entity or empty tags if result is undefined.
 */
export function buildEntityTags<T extends { id: string }>(
  result: T | undefined,
  type: TagType
): Tag[] {
  return result ? [{ type, id: result.id } as const] : [];
}

export function buildPagedResponseTags<T extends { id: string }>(
  result: { list: T[] } | T[] | undefined,
  type: TagType
): Tag[] {
  const items = Array.isArray(result) ? result : result?.list;

  return items?.length
    ? [
      { type, id: 'LIST' },
      ...items.map(({ id }) => ({ type, id })),
    ]
    : [{ type, id: 'LIST' }];
}

/**
 * Helper function to invalidate tags for an entity with an id or a list.
 * @param id - The id of the entity to invalidate (e.g., specific record), or 'LIST' to invalidate the whole list.
 * @param type - The type of the tag (e.g., 'AssetCategory', 'User').
 * @returns Array containing a single tag to invalidate for the provided type and id.
 */
export function invalidateTags(
  id: string | number | 'LIST' | undefined,
  type: TagType
): Tag[] {
  return [{ type, id }] as const;
}
