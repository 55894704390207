import {DropdownFilter, TextInputFilter} from "../../../../../../../web/components/search-filters/SearchFilters";
import {Picker} from "@react-native-picker/picker";
import React from "react";
import {useGetAllBuildingsQuery, useGetAllFloorsQuery} from "../../../../../../../../store/services/api/building-api";
import {
  useGetAllAssetTypesQuery,
  useGetAllSubAssetTypesQuery
} from "../../../../../../../../store/services/api/asset-type-api";
import {CoreFilters, CoreFiltersBaseProps} from "../../../../../../components/CoreFilters";

export default function SystemBuildingsScreenFilter({filter, onSubmit}: CoreFiltersBaseProps) {
  return (
    <CoreFilters
      filter={filter}
      onSubmit={onSubmit}
    >
      <TextInputFilter title='Name' name='name' />
    </CoreFilters>
  )
}
