export interface ApprovalStatusForm {
  entityId: string;
  isApproved: boolean;
  rejectionReason?: string;
}

export enum ApprovalStatus {
  AwaitingApproval,
  Approved,
  Rejected
}

export const ApprovalStatusNames = {
  [ApprovalStatus.AwaitingApproval]: 'Awaiting Approval',
  [ApprovalStatus.Approved]: 'Approved',
  [ApprovalStatus.Rejected]: 'Rejected'
}

export const getApprovalStatus = (entity: {isRejected: boolean, isApproved: boolean}) => {
  if (entity.isRejected) {
    return ApprovalStatus.Rejected;
  }
  if (entity.isApproved) {
    return ApprovalStatus.Approved;
  }
  return ApprovalStatus.AwaitingApproval;
}
