import {Button} from "react-native-paper";
import {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import {StyleSheet, Text, View} from "react-native";
import {Checkbox} from "expo-checkbox";
import React from "react";

interface ApprovalButtonProps {
  onPress: () => void;
  value: boolean;
  text?: string;
}

export function ApprovalButton({onPress, value, text = 'Approve'}: ApprovalButtonProps) {
  return (
    <Button
      textColor={PRIMARY_COLOUR}
      onPress={onPress}
    >
      <View style={styles.approveCheckbox}>
        <Checkbox
          value={value}
        />
        <Text>{text}</Text>
      </View>
    </Button>
  )
}

const styles = StyleSheet.create({
  approveCheckbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12
  },
})
