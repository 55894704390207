import {ActivityIndicator, StyleSheet, Text, TextInput, View} from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import {Controller, RegisterOptions} from "react-hook-form";
import * as React from "react";
import {useCoreForm} from "./CoreForm";
import {Feather} from "@expo/vector-icons";
import {useEffect, useState} from "react";
import {Picker} from '@react-native-picker/picker';

interface TextFieldControllerProps {
  rules?: RegisterOptions;
  controlName: string;
  title: string;
  options: { key: string, value: string, label: string }[];
  onTextChanged: (value: string) => void;
  optionsLoading: boolean;
  initialSelection: { key: string, value: string, label: string };
  required?: boolean;
  disabled?: boolean;
}

export function ComboTextFieldController(
  {rules, controlName, title, options, onTextChanged, optionsLoading, initialSelection, required = false, disabled = false}: TextFieldControllerProps) {
  const {form: {control}, isLoading} = useCoreForm();
  const [textValue, setTextValue] = useState<string>(initialSelection?.label || "");
  const [initialSelectionSet, setInitialSelectionSet] = useState<boolean>(false);

  if (!control) return null;

  const extendedRules = {
    ...(rules ?? {}),
    required: required ? 'This field is required' : false
  };

  useEffect(() => {
    if (initialSelection?.label && !initialSelectionSet) {
      setTextValue(initialSelection.label);
      setInitialSelectionSet(true);
    }
  }, [initialSelection, initialSelectionSet]);

  const handleOnTextChanged = (value: string) => {
    setTextValue(value);
    onTextChanged(value);
  }

  return (
    <Controller
      control={control}
      rules={extendedRules}
      name={controlName}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <View>
          <Text style={[GlobalStyle.form__column__text]}>{title}</Text>
          <View style={[styles.container, error && {borderColor: 'red'}, disabled && styles.disabledContainer]}>
            <TextInput
              editable={!isLoading && !disabled}
              style={[styles.textBox, disabled && styles.disabledText]}
              value={textValue}
              onChangeText={handleOnTextChanged}
            />
            <Picker
              enabled={!isLoading && !disabled}
              selectedValue={value}
              style={styles.picker}
              onValueChange={(itemValue, itemIndex) => {
                setTextValue(options[itemIndex]?.label);
                onChange(itemValue?.toString());
              }}
            >
              <Picker.Item key='select' label='Please Select' value='' />
              {options.map(option => (
                <Picker.Item key={option.key} label={option.label} value={option.value} />
              ))}
            </Picker>
            <View pointerEvents={optionsLoading ? 'box-none' : 'none'} style={styles.downArrowContainer}>
              {optionsLoading ? (
                <ActivityIndicator size='small' />
              ) : (
                <Feather name='chevron-down' size={16}/>
              )}
            </View>
          </View>
          {error && <Text style={{color: 'red'}}>{error.message}</Text>}
        </View>
      )}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    minHeight: 31,
    marginTop: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  disabledContainer: {
    borderColor: 'rgb(206, 206, 206)'
  },
  disabledText: {
    color: 'rgb(109, 109, 109)'
  },
  textBox: {
    position: 'absolute',
    padding: 5,
    zIndex: 20,
    right: 32,
    bottom: 0,
    top: 0,
    left: 0,
    fontFamily: 'OpenSans-Regular',
    backgroundColor: 'white',
    borderWidth: 0
  },
  picker: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    opacity: 0,  // fully transparent
    zIndex: 10,
    borderWidth: 0,  // removes any border
  },
  inputOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'transparent',
    zIndex: 15
  },
  downArrowContainer: {
    pointerEvents: 'none',
    position: 'absolute',
    right: 5,
    top: 0,
    bottom: 0,
    zIndex: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  downArrow: {
    paddingHorizontal: 5
  }
});
