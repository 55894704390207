import WebPageLayout from "../../../../layouts/WebPageLayout";
import {Button} from "react-native-paper";
import React, {useState} from "react";
import Table from "../../../../components/Table";
import {GetWorkOrderScreenHeader} from "../work-order-screen-headers";
import {
  useGetWorkOrderLogsQuery, useGetWorkOrderQuery
} from "../../../../../../store/services/api/work-order-api";
import {useSetLoading} from "../../../../../../hooks/useSetLoading";
import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {WorkOrdersStackParamList} from "../../WorkOrdersStack";
import {WorkOrderLog} from "../../../../../../store/data/entities/work-order-log";
import {Button as AntButton} from "antd";
import {FileOutlined} from "@ant-design/icons";
import UploadWorkOrderLogDocumentsModal from "../../../../../../components/modals/UploadWorkOrderLogDocumentsModal";
import AddWorkLogModal from "../../../../../../components/modals/New/AddWorkLogModal";
import {WorkOrderStatus} from "../../../../../../store/data/entities/work-order";

export default function WorkOrderWorkLogScreen({route}: ScreenProps<WorkOrdersStackParamList, 'workLog'>) {
  const workOrderId = route?.params?.id;
  const {data: workOrder, isFetching: workOrderFetching} = useGetWorkOrderQuery(workOrderId, {
    skip: !workOrderId,
  });
  const {data, isFetching} = useGetWorkOrderLogsQuery(workOrderId, {
    skip: !workOrderId
  });
  const [selectedWorkOrderLog, setSelectedWorkOrderLog] = useState<WorkOrderLog | undefined>(undefined);
  const [addWorkOrderLogVisible, setAddWorkOrderLogVisible] = useState(false);

  useSetLoading([isFetching || workOrderFetching]);
  const canEdit = workOrder?.status === WorkOrderStatus.Approved
    || workOrder?.status === WorkOrderStatus.Completed
    || workOrder?.status === WorkOrderStatus.InProgress;

  return (
    <WebPageLayout header={GetWorkOrderScreenHeader(workOrderId)}>
      <Table<WorkOrderLog>
        keyExtractor={(_, index) => `wo-log-${index}`}
        data={data ?? []}
        columns={[
          {key: 'comments'},
          {key: 'user'},
          {
            title: 'Documents',
            render: (item) => {
              const documentCount = item.fileURIs.length;
              const showDocumentCount = documentCount > 0;
              return (
                <AntButton
                  disabled={!canEdit}
                  onClick={() => setSelectedWorkOrderLog(item)}
                  icon={<FileOutlined />}
                >
                  Documents{showDocumentCount && ` (${documentCount})`}
                </AntButton>
              )
            }
          }
        ]}
      />

      <Button
        disabled={!canEdit}
        style={{marginTop: 12}}
        icon='plus'
        mode="elevated"
        buttonColor='#fff'
        textColor='#0f0f0f'
        onPress={() => setAddWorkOrderLogVisible(true)}
      >
        Add Work Log
      </Button>

      <UploadWorkOrderLogDocumentsModal
        workOrderId={workOrderId}
        workOrderLogEntryId={selectedWorkOrderLog?.id}
        existingFileURIs={selectedWorkOrderLog?.fileURIs ?? []}
        onClose={() => setSelectedWorkOrderLog(undefined)}
        visible={!!selectedWorkOrderLog}
      />

      <AddWorkLogModal
        open={addWorkOrderLogVisible}
        onClose={() => setAddWorkOrderLogVisible(false)}
        onSuccess={() => setAddWorkOrderLogVisible(false)}
        workOrderId={workOrderId}
      />
    </WebPageLayout>
  )
}
