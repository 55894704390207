import React, {useEffect} from "react";
import WebPageLayout from "../../../../../layouts/WebPageLayout";
import TablePageLayout from "../../../../../layouts/TablePageLayout";
import {Button, DataTable} from "react-native-paper";
import {Entypo} from "@expo/vector-icons";
import {useLazyExportAssetsListQuery, useLazyGetAssetsListQuery} from "../../../../../../../store/services/api/asset-api";
import {BaseFilters} from "../../../../../../web/hooks/useFilters";
import {CoreTable} from "../../../../../components/CoreTable";
import {TouchableOpacity} from "react-native";
import SystemAssetsScreenFilters from "./components/SystemAssetsScreenFilters";
import {convertBase64toBlob} from "../../../../../../../utils/helpers";
import {useToast} from "../../../../../../../components/toast/ToastProvider";
import {useSetLoading} from "../../../../../../../hooks/useSetLoading";
import {getSystemScreenHeaders} from "../../../system-screen-headers";
import useEntityListPage from "../../../../../hooks/useEntityListPage";
import useHasPermission from "../../../../../../../hooks/useHasPermission";

interface AssetListFilters extends BaseFilters {
  buildingId: string;
  floorId: string;
  assetTypeId: string;
  subAssetTypeId: string;
  description: string;
  code: string;
  uniqueReference: string;
  ticketStatus: number;
}

export default function SystemAssetsListScreen() {
  const [exportAssetsList, {data: exportResult, isFetching: exportFetching, isError: exportError}] = useLazyExportAssetsListQuery();
  const exportTable = (filters?: Record<string, any>) => exportAssetsList(filters ?? filter.getFilterValues());
  const {hasPermission} = useHasPermission();

  const {
    navigateToCreate,
    navigateToEdit,
    onSubmitFilter,
    filter,
    tableData,
    isLoading
  } = useEntityListPage<AssetListFilters>({
    entityName: "Asset",
    lazyTableQuery: useLazyGetAssetsListQuery,
    filterDefinition: {
      buildingId: "",
      floorId: "",
      assetTypeId: "",
      subAssetTypeId: "",
      description: "",
      code: "",
      uniqueReference: "",
      ticketStatus: -1,
      sortedColumn: "",
      sortedColumnAscending: false,
      pageNumber: 1,
      pageSize: 10
    },
  });

  useEffect(() => {
    if (exportResult) {
      let blob = convertBase64toBlob(exportResult.base64, "application/vnd.ms-excel");
      const fileSaver = require('file-saver');
      fileSaver(blob, "report.xlsx");
    }
  }, [exportResult]);

  const {show: showToast} = useToast();
  useSetLoading([exportFetching]);

  useEffect(() => {
    if (exportError) {
      showToast('Error getting table', 'There was an error getting building data', 'error');
    }
  }, [exportError]);

  return (
    <WebPageLayout header={getSystemScreenHeaders()}>
      <TablePageLayout
        filters={<SystemAssetsScreenFilters filter={filter} onSubmit={onSubmitFilter} />}
        actions={(
          <>
            {hasPermission('CanManageSystem') && (
              <Button icon='plus' mode="elevated" onPress={navigateToCreate} buttonColor='#fff' textColor='#0f0f0f'>
                Add Asset
              </Button>
            )}

            {tableData?.list.length > 0 &&
              <Button icon='export' mode="elevated" onPress={() => exportTable()} buttonColor='#fff' textColor='#0f0f0f'>
                Export
              </Button>
            }
          </>
        )}
      >
        <CoreTable<any>
          headers={[
            { text: 'Building', sortedName: "buildingName" },
            { text: 'Floor', sortedName: "floorName", flex: 0.5 },
            { text: 'Specific Location', sortedName: "locationName" },
            { text: 'Asset Type', sortedName: "assetTypeName", flex: 0.5 },
            { text: 'Sub Asset Type', sortedName: "subAssetTypeName", flex: 0.5 },
            { text: 'Description', sortedName: "description" },
            { text: 'Code', sortedName: "code"},
            { text: 'CoreRFID QR', sortedName: "uniqueReference" },
            { text: 'Options', flex: 0.5 },
          ]}
          isLoading={isLoading}
          data={tableData}
          keyExtractor={(item) => item.id}
          renderItem={({item}) => (
            <DataTable.Row key={item.id}>
              <DataTable.Cell>{item.buildingName}</DataTable.Cell>
              <DataTable.Cell style={{flex: 0.5}}>{item.floorName}</DataTable.Cell>
              <DataTable.Cell>{item.locationName}</DataTable.Cell>
              <DataTable.Cell style={{flex: 0.5}}>{item.assetTypeName}</DataTable.Cell>
              <DataTable.Cell style={{flex: 0.5}}>{item.subAssetTypeName}</DataTable.Cell>
              <DataTable.Cell>{item.description}</DataTable.Cell>
              <DataTable.Cell>{item.code}</DataTable.Cell>
              <DataTable.Cell>{item.uniqueReference}</DataTable.Cell>
              <DataTable.Cell style={{flex: 0.5}}>
                <TouchableOpacity onPress={() => navigateToEdit(item.id)}>
                  <Entypo name="pencil" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )}
          filters={filter.filters}
          setFilter={filter.setFilter}
        />
      </TablePageLayout>
    </WebPageLayout>
  )
}
