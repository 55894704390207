import React, {useEffect, useState} from "react";
import {View, StyleSheet, Text, TouchableOpacity} from "react-native";
import GlobalStyle, {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import {Picker} from '@react-native-picker/picker';
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {DataTable} from 'react-native-paper';
import {
  useLazyExportTicketsQuery,
  useLazyGetTicketsQuery,
  useUpdateTicketStatusMutation
} from "../../../../store/services/api/ticket-api";
import moment from 'moment';
import {Entypo, MaterialIcons} from '@expo/vector-icons';
import {ImageViewer} from "../../components/ImageViewer";
import {convertBase64toBlob} from "../../../../utils/helpers";
import {
  DateFilter,
  DropdownFilter,
  SearchFilterRow,
  SearchFilters,
  TextInputFilter
} from "../../components/search-filters/SearchFilters";
import {WebScreen} from "../../components/WebScreen";
import useFilters, {BaseFilters} from "../../hooks/useFilters";
import {CoreTable} from "../../components/core-table/CoreTable";
import {TicketViewModel} from "../../../../store/data/models/ticket-view-model";
import {useToast} from "../../../../components/toast/ToastProvider";
import {CreateJobFromTicketModal} from "../../../../components/modals/CreateJobFromTicketModal";
import {useModal} from "../../../../hooks/useModal";
import {humanizeDate} from "../../../../utils/humanizers";
import {useSetLoading} from "../../../../hooks/useSetLoading";
import {TicketUpdatesListModal} from "./components/TicketUpdatesListModal";
import {TicketUpdateModal} from "./components/TicketUpdateModal";

interface TicketsListFilters extends BaseFilters {
  ticketNumber: string;
  startDate: string;
  endDate: string;
  ticketDescription: string;
  contactEmail: string;
  status: string;
  uniqueReference: string;
  hasUpdates: boolean;
}

export default function TicketsListScreen({navigation}) {
  const [getTicketsList, {data: ticketsListResult, isFetching: ticketsFetching, isError: ticketsError}] = useLazyGetTicketsQuery();
  const [exportTicketsList, {data: exportResult, isFetching: exportFetching, isError: exportError}] = useLazyExportTicketsQuery();
  const loadTable = (filters?: Record<string, any>) => getTicketsList(filters ?? getFilterValues());
  const exportTable = (filters?: Record<string, any>) => exportTicketsList(filters ?? getFilterValues());

  const [imagesToView, setImagesToView] = React.useState<any>([]);
  const [dateRange, setDateRange] = useState("");
  const [updateTicketStatus] = useUpdateTicketStatusMutation();

  const {showModal, hideModal} = useModal();

  useSetLoading([exportFetching]);

  const handleOnShowTicketModal = (ticketId: string) => {
    showModal(
      <CreateJobFromTicketModal
        ticketId={ticketId}
        onSuccess={() => {
          hideModal();
          handleOnJobCreated();
        }}
        onClose={hideModal} />
    );
  }

  const handleOnShowTicketUpdatesModal = (ticketId: string) => {
    showModal(
      <TicketUpdatesListModal
        ticketId={ticketId}
        onClose={hideModal} />
    );
  }

  const handleOnShowAddTicketUpdateModal = (ticketId: string) => {
    showModal(
      <TicketUpdateModal
        ticketId={ticketId}
        onClose={hideModal} />
    );
  }

  const {show: showToast} = useToast();

  const filter = useFilters<TicketsListFilters>({
    contactEmail: "",
    endDate: moment().toISOString(),
    startDate: moment().add(-12, 'week').toISOString(),
    status: "All",
    ticketDescription: "",
    ticketNumber: "",
    sortedColumn: "",
    sortedColumnAscending: false,
    uniqueReference: "",
    hasUpdates: "",
    pageNumber: 1,
    pageSize: 10
  }, loadTable);
  const {getFilterValues, setFilter, filters} = filter;

  useEffect(() => {
    loadTable();
  }, []);

  useEffect(() => {
    if (ticketsError) {
      showToast('Error getting table', 'There was an error getting ticket data', 'error');
    }
    if (exportError) {
      showToast('Error exporting table', 'There was an error getting ticket export data', 'error');
    }
  }, [ticketsError, exportError]);

  useEffect(() => {
    if (exportResult) {
      let blob = convertBase64toBlob(exportResult.base64, "application/vnd.ms-excel");
      const fileSaver = require('file-saver');
      fileSaver(blob, "report.xlsx");
    }
  }, [exportResult]);

  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate: moment.MomentInput;
    const endDate = moment();

    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    setFilter('startDate', moment(startDate).toISOString());
    setFilter('endDate', moment(endDate).toISOString());
  }

  const toggleTicketStatus = async (element) => {
    try {
      const newStatus = element.status === "Closed" ? "Open" : "Closed";
      await updateTicketStatus({id: element.id, status: newStatus});
      loadTable(getFilterValues());
    } catch (error) {
      console.error("Error changing ticket status:", error);
    }
  };

  const handleOnJobCreated = () => {
    loadTable();
  }

  const handleOnJobClick = (item) => {
    if (item.latestJobId) {
      // todo currently just navigates into list, maybe do something else?
      navigation.navigate('jobs', {screen: 'list'});
      return;
    }

    handleOnShowTicketModal(item.id);
  }

  return (
    <WebScreen>
      <View style={styles.container}>
        {imagesToView.length > 0 && <ImageViewer images={imagesToView} onClosed={() => setImagesToView([])}/>}
      </View>

      <SearchFilters filter={filter} onSubmit={() => loadTable()} onResetFilters={() => setDateRange("")}>
        <SearchFilterRow>
          <TextInputFilter title='Ticket Number' name='ticketNumber'/>
          <TextInputFilter title='Ticket Description' name='ticketDescription'/>
          <TextInputFilter title='CoreRFID QR' name='uniqueReference'/>
        </SearchFilterRow>
        <SearchFilterRow>
          <DropdownFilter title='Date Range' value={dateRange} onValueChange={updateDateRange}>
            <Picker.Item label="Predefined Date Range" value=""/>
            <Picker.Item label="1 Week" value="-1"/>
            <Picker.Item label="1 Month" value="1"/>
            <Picker.Item label="3 Months" value="3"/>
            <Picker.Item label="6 Months" value="6"/>
            <Picker.Item label="12 Months" value="12"/>
          </DropdownFilter>
          <DateFilter title='Start Date' name='startDate'/>
          <DateFilter title='End Date' name='endDate'/>
        </SearchFilterRow>
        <SearchFilterRow>
          <TextInputFilter title='Contact Email' name='contactEmail'/>
          <DropdownFilter title='Updates' name='hasUpdates'>
            <Picker.Item label="All" value=""/>
            <Picker.Item label="Has Updates" value={true}/>
            <Picker.Item label="No Updates" value={false}/>
          </DropdownFilter>
          <DropdownFilter title='Status' name='status'>
            <Picker.Item label="All" value="All"/>
            <Picker.Item label="Open" value="Open"/>
            <Picker.Item label="Closed" value="Closed"/>
          </DropdownFilter>
        </SearchFilterRow>
      </SearchFilters>

      {ticketsListResult?.list?.length > 0 &&
        <View style={{flexDirection: 'row'}}>
          <TouchableOpacity style={{marginRight: 20}} onPress={() => exportTable()}>
            <Text style={GlobalStyle.add__new__link}>Export</Text>
          </TouchableOpacity>
        </View>
      }

      <CoreTable<TicketViewModel>
        filters={filters}
        setFilter={setFilter}
        isLoading={ticketsFetching}
        data={ticketsListResult}
        headers={[
          {text: 'Edit', visible: true, flex: 0.5},
          {text: 'Ticket', sortedName: "number", visible: true, flex: 0.5},
          {text: 'Job', visible: true},
          {text: 'Building', visible: true},
          {text: 'Floor', visible: true},
          {text: 'Location', visible: true},
          {text: 'Sub Asset Type', visible: true},
          {text: 'Code', visible: true},
          {text: 'Date Submitted', sortedName: "dateSubmittedFull", visible: true},
          {text: 'Ticket Description', sortedName: "description", visible: true},
          {text: 'Contact Email', sortedName: "contactEmail", visible: true},
          {text: 'Status', sortedName: "status", visible: true, flex: 0.5},
          {text: 'Date of Status Change', sortedName: "dateofStatusChangeFull", visible: true},
          {text: 'Photos', visible: true, flex: 0.5},
          {text: 'Change Status', visible: true, flex: 0.5},
          {text: 'Updates', visible: true, flex: 0.5}
        ]}
        renderItem={({item}) => (
          <DataTable.Row key={item.id}>
            <DataTable.Cell style={{flex: 0.5}}>
              <TouchableOpacity onPress={() => handleOnShowAddTicketUpdateModal(item.id)}>
                <Entypo name="pencil" size={24} color="black"/>
              </TouchableOpacity>
            </DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>{item.number}</DataTable.Cell>
            <DataTable.Cell>
              {!item.latestJobId && item.status === 'Open' ? (
                <Text onPress={() => handleOnJobClick(item)} style={{fontWeight: 'bold'}}>Create</Text>
              ) : (
                <Text>{item.latestJobReference}</Text>
              )}
            </DataTable.Cell>
            <DataTable.Cell>{item.building}</DataTable.Cell>
            <DataTable.Cell>{item.floor}</DataTable.Cell>
            <DataTable.Cell>{item.location}</DataTable.Cell>
            <DataTable.Cell>{item.subAssetType}</DataTable.Cell>
            <DataTable.Cell>{item.code}</DataTable.Cell>
            <DataTable.Cell>{item.dateSubmitted}</DataTable.Cell>
            <DataTable.Cell>{item.description}</DataTable.Cell>
            <DataTable.Cell>{item.contactEmail}</DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>{item.status}</DataTable.Cell>
            <DataTable.Cell>{humanizeDate(item.dateOfStatusChangeFull, true)}</DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>
              {item.images != null && item.images.length > 0 ?
                <TouchableOpacity onPress={() => setImagesToView(item.images)}>
                  <MaterialIcons name={item.images.length === 1 ? "photo" : "photo-library"} size={24}
                                 color={PRIMARY_COLOUR}/>
                </TouchableOpacity> : <Text>None</Text>
              }
            </DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>
              <TouchableOpacity onPress={() => toggleTicketStatus(item)}>
                {item.status === "Open" ? <Entypo name="circle-with-cross" size={24} color="black"/> :
                  <Entypo name="cycle" size={24} color="black"/>}
              </TouchableOpacity>
            </DataTable.Cell>
            <DataTable.Cell style={{flex: 0.5}}>
              <TouchableOpacity onPress={() => handleOnShowTicketUpdatesModal(item.id)}>
                <MaterialCommunityIcons name="view-list-outline" size={24} color="black"/>
              </TouchableOpacity>
            </DataTable.Cell>
          </DataTable.Row>
        )}
      />
    </WebScreen>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  }

});
