import {Feather} from "@expo/vector-icons";
import React from "react";
import {HeaderButton} from "./header-button";

export function HelpButton({onPress}: {onPress: () => void}) {
  return (
    <HeaderButton onPress={onPress}>
      <Feather name='help-circle' size={24} />
    </HeaderButton>
  )
}
