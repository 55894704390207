import * as React from "react";
import {useForm} from "react-hook-form";
import {useEffect} from "react";
import {JobPlan, JobPlanDescriptionForm, JobPlanForm} from "../../../../../../store/data/entities/job-plan";
import CoreForm from "../../../../../web/components/forms/CoreForm";
import {TextFieldController} from "../../../../../web/components/forms/TextFieldController";
import {
  useGetAllAssetTypesQuery,
  useGetAllSubAssetTypesQuery
} from "../../../../../../store/services/api/asset-type-api";
import {PickerFieldController} from "../../../../../web/components/forms/PickerFieldController";
import {ScrollView, StyleSheet, View} from "react-native";
import JobPlanItemDescriptionsForm from "./JobPlanItemDescriptionsForm";
import {FormButton} from "../../../../../web/components/forms/FormButton";
import {useSetLoading} from "../../../../../../hooks/useSetLoading";

interface JobPlanManageFormProps {
  jobPlan?: JobPlan | undefined;
  onSubmit: (data: any) => void;
  onApproval: (isApproved?: boolean) => void;
  isApproval: boolean;
}

export default function JobPlanManageForm({jobPlan, onSubmit, onApproval, isApproval}: JobPlanManageFormProps) {
  const { data: assetTypes, isFetching: assetTypesFetching } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes, isFetching: subAssetTypesFetching } = useGetAllSubAssetTypesQuery(null);

  useSetLoading([assetTypesFetching, subAssetTypesFetching]);

  const form = useForm<JobPlanForm>({
    defaultValues: {
      id: null,
      title: "",
      assetTypeId: "",
      subAssetTypeId: "",
      descriptions: []
    }
  });

  useEffect(() => {
    if (jobPlan) {
      form.setValue("id", jobPlan.id);
      form.setValue("title", jobPlan.title);
      form.setValue("assetTypeId", jobPlan.assetTypeId);
      form.setValue("subAssetTypeId", jobPlan.subAssetTypeId);

      const descriptions: JobPlanDescriptionForm[] = jobPlan.descriptions?.map(item => ({
        id: item.id,
        description: item.description,
        imagesBase64: [],
        images: item.imageURIs?.map((image) => ({
          uri: image
        }))
      }))
      form.setValue("descriptions", descriptions);
    }
  }, [jobPlan]);
  const assetTypeId = form.watch('assetTypeId');

  const handleOnSubmit = () => {
    form.handleSubmit((data) => {
      const descriptions = data.descriptions.map((item) => ({
        ...item,
        imagesBase64: item.images.map((image) => image.uri)
      }));

      onSubmit({
        ...data,
        subAssetTypeId: data.subAssetTypeId === "" ? null : data.subAssetTypeId,
        descriptions,
      });
    })();
  }

  const handleOnAssetTypeChange = () => {
    form.setValue('subAssetTypeId', '');
  }

  const assetTypesOptions = (assetTypes ?? [])
    .map(item => ({
      label: item.name,
      value: item.id
    }));

  const subAssetTypeOptions = (subAssetTypes ?? [])
    .filter(st => st.assetTypeId === assetTypeId)
    .map(item => ({
      label: item.name,
      value: item.id
    }));

  return (
    <>
      <View style={styles.formContainer}>
        <CoreForm
          style={styles.container}
          form={form}
          footer={null}
        >
          <TextFieldController
            disabled={isApproval}
            controlName='title'
            title='Title'
            required
          />
          <PickerFieldController
            disabled={isApproval}
            controlName='assetTypeId'
            title='Asset Type'
            required
            defaultFirst={false}
            items={assetTypesOptions}
            onChange={handleOnAssetTypeChange}
          />
          <PickerFieldController
            disabled={isApproval}
            controlName='subAssetTypeId'
            title='Sub Asset Type'
            defaultFirst={false}
            items={subAssetTypeOptions} />
        </CoreForm>

        <CoreForm
          style={styles.container}
          footer={null}
          form={form}
        >
          <ScrollView>
            <JobPlanItemDescriptionsForm
              isApproval={isApproval}
              control={form.control}
            />
          </ScrollView>
        </CoreForm>
      </View>
      <View style={styles.buttonContainer}>
        {isApproval ? (
          <>
            <FormButton title='APPROVE' onPress={() => onApproval(true)}/>
            <FormButton title='REJECT' type='danger' onPress={() => onApproval(false)}/>
          </>
        ) : (
          <FormButton title='SUBMIT' onPress={handleOnSubmit}/>
        )}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    flex: 1,
    maxHeight: 600
  },
  container: {
    gap: 4,
    borderWidth: 1,
    borderColor: "#d9d9d9",
    borderRadius: 6,
    backgroundColor: "#fff",
    overflow: "hidden",
    flex: 1,
  },
  buttonContainer: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    justifyContent: 'flex-start',
    maxWidth: 300,
  }
})
