import { baseApi } from "./base-api";
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {JobPlan, JobPlanForm} from "../../data/entities/job-plan";
import {ApprovalStatusForm} from "../../data/models/approval-status-form";
import {CascaderOption} from "../../../utils/cascader-utils";
import {buildEntityTags, buildPagedResponseTags, invalidateTags} from "../../../utils/api-utils";

export const jobPlanApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getJobPlan: build.query<JobPlan, string>({
      query: (id) => ({
        url: `jobPlans/getJobPlan`,
        params: {
          jobPlanId: id,
        }
      }),
      providesTags: (result) => buildEntityTags(result, 'JobPlan')
    }),
    getJobPlans: build.query<PagedResponse<JobPlan>, Record<string, any>>({
      query: (params) => ({
        url: `jobPlans/getJobPlans`,
        params: params
      }),
      transformResponse: getPagedResponse,
      providesTags: (result) => buildPagedResponseTags(result, 'JobPlan')
    }),
    createJobPlan: build.mutation<JobPlan, JobPlanForm>({
      query: (dto) => ({
        url: `jobPlans/createJobPlan`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: () => invalidateTags('LIST', 'JobPlan')
    }),
    updateJobPlan: build.mutation<JobPlan, JobPlanForm>({
      query: (dto) => ({
        url: `jobPlans/updateJobPlan`,
        method: 'PUT',
        body: dto
      }),
      invalidatesTags: (_result, _error, {id}) => invalidateTags(id, 'JobPlan')
    }),
    deleteJobPlan: build.mutation<void, string>({
      query: (id) => ({
        url: `jobPlans/deleteJobPlan`,
        method: 'DELETE',
        params: {
          jobPlanId: id,
        }
      }),
      invalidatesTags: (_result, _error, id) => invalidateTags(id, 'JobPlan')
    }),
    setJobPlanApprovalStatus: build.mutation<JobPlan, ApprovalStatusForm>({
      query: (dto) => ({
        url: `jobPlans/setJobPlanApproval/${dto.entityId}`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: (_result, _error, {entityId}) => invalidateTags(entityId, 'JobPlan')
    }),
    getJobPlanCascaderOptions: build.query<CascaderOption<JobPlan>[], void>({
      query: () => ({
        url: `jobPlans/getJobPlanCascaderOptions`
      }),
      providesTags: () => [{ type: 'JobPlan'}]
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetJobPlansQuery,
  useLazyGetJobPlanQuery,
  useCreateJobPlanMutation,
  useUpdateJobPlanMutation,
  useDeleteJobPlanMutation,
  useSetJobPlanApprovalStatusMutation,
  useGetJobPlanCascaderOptionsQuery,
} = jobPlanApi;
