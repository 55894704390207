import CoreForm from "../../../../../../../web/components/forms/CoreForm";
import {TextFieldController} from "../../../../../../../web/components/forms/TextFieldController";
import * as React from "react";
import {useForm} from "react-hook-form";
import {useEffect} from "react";

interface AssetTypeManageFormProps {
  assetType: any;
  onSubmit: (data: any) => void;
}

export default function AssetTypeManageForm({assetType, onSubmit}: AssetTypeManageFormProps) {
  const form = useForm({
    defaultValues: {
      id: null,
      name: "",
    }
  });

  useEffect(() => {
    if (assetType) {
      form.setValue("id", assetType.id);
      form.setValue("name", assetType.name);
    }
  }, [assetType])

  const handleOnSubmit = () => {
    form.handleSubmit(onSubmit)();
  }

  return (
    <CoreForm
      form={form}
      onSubmit={handleOnSubmit}
    >
      <CoreForm.Row>
        <TextFieldController
          controlName='name'
          title='Name'
          required
        />
      </CoreForm.Row>
    </CoreForm>
  )
}
