import { baseApi } from './base-api'
import {Asset} from "../../data/entities/asset";
import {MaintenanceChecklist} from "../../data/entities/maintanence-checklist";
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {AssetCascaderOptions} from "../../data/models/asset-cascader-options";
import {AssetIdListForm} from "../../data/models/asset-id-list-form";
import {AssetOption} from "../../../components/forms/controls/AssetSelectionCascader";
import {AssetLogBookEntry} from "../../data/entities/asset-log-book-entry";
import {SelectOption} from "../../data/models/select-option";
import {buildPagedResponseTags, invalidateTags} from "../../../utils/api-utils";

export const assetApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAsset: build.query<any, any>({
      query: (id) => ({
        url: `assets/getasset/${id}`,
      }),
    }),
    getAssetByJobId: build.query<Asset, string>({
      query: (jobId) => ({
        url: `assets/getAssetByJobId/${jobId}`,
      }),
    }),
    getAssetByUniqueRef: build.query<any, any>({
      query: (uniqueRef) => ({
        url: `assets/getassetbyuniqueref/${uniqueRef}`,
      }),
    }),
    getAssetByCode: build.query<any, any>({
      query: (code) => ({
        url: `assets/getassetbycode/${code}`,
      }),
    }),
    searchAsset: build.query<SelectOption[], Record<string, string>>({
      query: (params) => ({
        url: `assets/searchAsset`,
        params: params
      }),
    }),
    getAssetsList: build.query<PagedResponse<any>, Record<string, any>>({
      query: (params) => ({
        url: `assets/getassets`,
        params: params
      }),
      transformResponse: getPagedResponse,
      providesTags: (result) => buildPagedResponseTags(result, 'Asset')
    }),
    exportAssetsList: build.query<any, Record<string, any>>({
      query: (params) => ({
        url: `assets/exportAssets`,
        params: params
      })
    }),
    addAsset: build.mutation<any, any>({
      query: (body) => ({
        url: 'assets/addasset',
        method: 'post',
        body: body
      }),
      invalidatesTags: () => invalidateTags('LIST', 'Asset')
    }),
    updateAsset: build.mutation<any, any>({
      query: (body) => ({
        url: 'assets/updateasset',
        method: 'put',
        body: body
      }),
      invalidatesTags: (_result, _error, {id}) => invalidateTags(id, 'Asset')
    }),
    getChecklistsForAsset: build.query<MaintenanceChecklist[], string>({
      query: (assetId) => ({
        url: `assets/GetMaintenanceChecklistsForAsset/${assetId}`
      }),
    }),
    getCascaderOptions: build.mutation<AssetOption[], AssetCascaderOptions>({
      query: (dto) => ({
        url: `assets/getCascaderOptions`,
        method: 'POST',
        body: {
          initialAssetIds: dto.initialAssetIds,
          assetTypeId: dto.assetTypeId ? dto.assetTypeId : undefined,
          subAssetTypeId: dto.subAssetTypeId ? dto.subAssetTypeId : undefined
        }
      }),
    }),
    getAssetIdList: build.mutation<string[], AssetIdListForm>({
      query: (dto) => ({
        url: `assets/getAssetIdList`,
        method: 'POST',
        body: {
          idPaths: dto.idPaths,
          assetTypeId: dto.assetTypeId ? dto.assetTypeId : undefined,
          subAssetTypeId: dto.subAssetTypeId ? dto.subAssetTypeId : undefined
        }
      }),
    }),
    getAssetLogBookEntries: build.query<PagedResponse<AssetLogBookEntry>, Record<string, any>>({
      query: (params) => ({
        url: `assets/getAssetLogBook`,
        params: params
      }),
      transformResponse: getPagedResponse
    }),
    getAssetOptions: build.query<SelectOption[], string[]>({
      query: (ids) => ({
        url: `assets/getAssetOptions`,
        params: {
          assetIds: ids.join(',')
        }
      })
    }),
  }),
  overrideExisting: true,
})

export const {
  useLazyGetAssetByJobIdQuery,
  useLazyGetAssetQuery,
  useGetAssetByUniqueRefQuery,
  useLazyGetAssetByUniqueRefQuery,
  useAddAssetMutation,
  useUpdateAssetMutation,
  useLazySearchAssetQuery,
  useLazyGetChecklistsForAssetQuery,
  useLazyGetAssetsListQuery,
  useLazyExportAssetsListQuery,
  useGetCascaderOptionsMutation,
  useGetAssetIdListMutation,
  useLazyGetAssetLogBookEntriesQuery,
  useLazyGetAssetOptionsQuery,
} = assetApi;
