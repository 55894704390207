import React, {useEffect} from "react";
import {ListRenderItemInfo, StyleSheet, Text, View} from "react-native";
import {useLazySearchPartQuery} from "../../../../store/services/api/maintenance-part-api";
import {MaintenancePart} from "../../../../store/data/entities/maintenance-part";
import CoreForm from "../../../../screens/web/components/forms/CoreForm";
import {useForm} from "react-hook-form";
import {TextFieldController} from "../../../../screens/web/components/forms/TextFieldController";
import {ComboPickerFieldController} from "../../../../screens/web/components/forms/ComboPickerFieldController";
import {CoreList} from "../../../../screens/mobile/components/core-list/CoreList";
import {useJobControl} from "../../../../hooks/useJobControl";
import {MaintenanceJobControlForm} from "../MaintenanceJobControl";
import {MaintenanceJobPart} from "../../../../store/data/entities/maintenance-job";
import {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import {Button} from "react-native-paper";
import {useLazyGetAssetByJobIdQuery} from "../../../../store/services/api/asset-api";
import {useSetLoading} from "../../../../hooks/useSetLoading";
import {ApprovalButton} from "../../shared/components/ApprovalButton";

const initialValues = {
  partId: '',
  quantity: 1,
}

export function MaintenanceJobPartsControl() {
  const [searchPart, {data: partsList, isLoading: partsLoading}] = useLazySearchPartQuery();
  const [getAsset, {data: asset, isFetching: assetFetching}] = useLazyGetAssetByJobIdQuery();
  const {form: {setValue, getValues, watch}, initialForm, isApproval, jobId} = useJobControl<MaintenanceJobControlForm>();
  const partForm = useForm<{ partId: string, quantity: number }>({
    defaultValues: {...initialValues}
  });
  const {handleSubmit, reset} = partForm;

  const partOptions = partsList?.map((part: MaintenancePart) => {
    return ({
      key: part?.id,
      value: part?.id,
      label: part.title
    })
  }) ?? [];

  useEffect(() => {
    if (jobId) {
      getAsset(jobId);
    }
  }, [jobId]);

  useSetLoading([assetFetching]);

  useEffect(() => {
    if (initialForm) {
      setValue('parts', initialForm.parts);
    }
  }, [initialForm]);

  const selectedParts = watch('parts');

  const handleOnPartSearch = (searchTerm: string) => {
    searchPart({
      assetTypeId: asset?.assetTypeId,
      searchTerm
    })
  }

  const handleOnAddPart = (formData: { partId: string, quantity: number }) => {
    const selectedPart = partOptions.find(opt => opt.value === formData.partId);
    if (!selectedPart) return;

    setValue('parts', [...getValues('parts'), {
      id: undefined,
      maintenancePartId: formData.partId,
      quantity: formData.quantity,
      partTitle: selectedPart.label,
      isApproved: isApproval
    }]);
    reset({...initialValues});
  }

  const renderPartItem = ({item, index}: ListRenderItemInfo<MaintenanceJobPart>) => {
    const handleDelete = () => {
      const parts = getValues('parts');
      setValue('parts', parts.filter((_, i) => i !== index));
    }

    const handleToggleApproved = () => {
      const parts = getValues('parts');
      const updatedParts = parts.map((part, i) =>
        i === index ? {...part, isApproved: !part.isApproved} : part
      );
      setValue('parts', updatedParts);
    }

    return (
      <View style={[styles.listItemContainer, item.isApproved ? styles.lockedItemContainer : {}]}>
        <View style={styles.partInfo}>
          <View style={styles.row}>
            <Text style={styles.label}>Description:</Text>
            <Text style={styles.value}>{item.partTitle}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Quantity:</Text>
            <Text style={styles.value}>{item.quantity}</Text>
          </View>
        </View>
        <View style={styles.actionsContainer}>
          <Button
            disabled={item.isApproved}
            icon='delete'
            textColor={PRIMARY_COLOUR}
            onPress={handleDelete}
          >
            Remove
          </Button>
          {isApproval && (
            <ApprovalButton
              onPress={handleToggleApproved}
              value={item.isApproved}
            />
          )}
        </View>
      </View>
    )
  }

  const handleOnApproveAllChecked = (value: boolean) => {
    const updatedParts = selectedParts.map((response) => ({
      ...response,
      isApproved: value,
    }));
    setValue('parts', updatedParts);
  }

  const allApproved = selectedParts.findIndex(r => !r.isApproved) === -1;

  return (
    <>
      <CoreForm form={partForm} submitText='Add Part' onSubmit={handleSubmit(handleOnAddPart)}>
        <ComboPickerFieldController
          controlName='partId'
          title='Part'
          onSearch={handleOnPartSearch}
          optionsLoading={partsLoading}
          options={partOptions}
          debounceTime={600}
        />
        <TextFieldController
          controlName='quantity'
          title='Quantity'
          type='numeric'
        />
      </CoreForm>

      {isApproval && (
        <View style={styles.approveAllContainer}>
          <ApprovalButton
            onPress={() => handleOnApproveAllChecked(!allApproved)}
            value={allApproved}
            text='Approve All'
          />
        </View>
      )}

      <CoreList
        emptyListText=''
        data={selectedParts}
        renderItem={renderPartItem}
      />
    </>
  )
}


const styles = StyleSheet.create({
  listItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 18
  },
  partInfo: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  label: {
    minWidth: 76,
    textAlign: 'right',
    marginRight: 10,
  },
  value: {
    fontWeight: 'bold',
  },
  lockedItemContainer: {
    backgroundColor: '#e9ecef',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4
  },
  approveAllContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 2
  }
})
