import {StyleProp, Text, View} from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import {Controller, RegisterOptions} from "react-hook-form";
import * as React from "react";
import {useCoreForm} from "./CoreForm";
import {createElement} from "react";
import moment from "moment";

interface DateInputFieldControllerProps {
  controlName: string;
  rules?: RegisterOptions;
  title?: string;
  required?: boolean;
  disabled?: boolean;
  style?: StyleProp<any>;
  control?: any;
  type?: 'date' | 'datetime-local';
  min?: number | string | undefined;
  max?: number | string | undefined;
}

const format = {
  ['date']: 'YYYY-MM-DD',
  ['datetime-local']: 'YYYY-MM-DDTHH:mm',
}

export function DateInputFieldController({rules, controlName, title, required = false, style, control, type = 'date', min, max, disabled}: DateInputFieldControllerProps) {
  const {form: {control: _control}} = useCoreForm();
  if (!_control && !control) return null;

  const extendedRules = {
    ...(rules ?? {}),
    required: required ? 'This field is required' : false
  };

  const handleOnChange = (value: string, onChangeHandler: (value: string) => void) => {
    const date = moment(value, format[type]);
    onChangeHandler(date.toISOString());
  }

  const formatValue = (value: string) => {
    const date = moment(value);
    if (!date.isValid()) return '';

    return date.format(format[type]);
  }

  return (
    <Controller
      control={control ?? _control}
      rules={extendedRules}
      name={controlName}
      render={({field: {onChange, value}, fieldState: {error}}) => {
        const DateInput = () => {
          return createElement('input', {
            type: type ?? 'date',
            min,
            max,
            value: formatValue(value),
            disabled: disabled,
            onChange: (event: any) => handleOnChange(event.target.value, onChange),
            style: {
              ...style,
              ...GlobalStyle.form__column__input,
              ...(error ? {borderColor: 'red'} : {})
            }
          })
        }

        return (
          <View>
            {title && (<Text style={GlobalStyle.form__column__text}>{title} {required ? '*' : null}</Text>)}
            <DateInput />
            {error && <Text style={{color: 'red'}}>{error.message}</Text>}
          </View>
        )
      }}
    />
  );
}
