import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import SystemSettingsPinScreen from "./screens/pin/SystemSettingsPinScreen";

const Stack = createNativeStackNavigator<SystemSettingsStackParamList>();

export default function SystemSettingsStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='pin'
        component={SystemSettingsPinScreen}
        options={{headerShown: false}}
      />
    </Stack.Navigator>
  );
};

export const systemSettingsLinkingOptions = {
  path: 'settings',
  screens: {
    pin: 'pin'
  },
}

export type SystemSettingsStackParamList = ParamListBase & {
  pin: {id: string};
};
