import {Modal, Typography} from "antd";
const {Text} = Typography;

interface ConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
  isLoading?: boolean;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const title = props.title ?? 'Are you sure?';
  const message = props.message ?? 'Are you sure you want to perform this action?';
  return (
    <Modal
      title={title}
      open={props.open}
      onClose={props.onClose}
      onCancel={props.onClose}
      onOk={() => props.onConfirm()}
      loading={props.isLoading}
      destroyOnClose
    >
      <Text>{message}</Text>
    </Modal>
  )
}
