import {StyleSheet, View} from "react-native";
import WebPageLayout from "../../../../layouts/WebPageLayout";
import {GetWorkOrderScreenHeader} from "../work-order-screen-headers";
import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {WorkOrdersStackParamList} from "../../WorkOrdersStack";
import {
  useGetWorkOrderQuery
} from "../../../../../../store/services/api/work-order-api";
import {
  WorkOrderPriority,
  WorkOrderStatus,
  WorkOrderType
} from "../../../../../../store/data/entities/work-order";
import {humanizeDate, humanizeEnum} from "../../../../../../utils/humanizers";
import {useSetLoading} from "../../../../../../hooks/useSetLoading";
import {Button} from "react-native-paper";
import useHasPermission from "../../../../../../hooks/useHasPermission";
import * as React from "react";
import {Descriptions} from "antd";
import {useState} from "react";
import CloseWorkOrderModal from "../../../../../../components/modals/New/CloseWorkOrderModel";

export default function WorkOrderRecordViewScreen({route}: ScreenProps<WorkOrdersStackParamList, 'recordView'>) {
  const workOrderId = route?.params?.id;
  const {data: workOrder, isFetching} = useGetWorkOrderQuery(workOrderId, {
    skip: !workOrderId,
  });
  const {hasPermission} = useHasPermission();
  const [closeWorkOrderModalVisible, setCloseWorkOrderModalVisible] = useState(false);
  const [rejectWork, setRejectWork] = useState(false);

  useSetLoading([isFetching]);

  const handleShowCloseWorkOrderModal = (isReject: boolean) => {
    setRejectWork(isReject);
    setCloseWorkOrderModalVisible(true);
  }

  const handleHideCloseWorkOrderModal = () => {
    setRejectWork(undefined);
    setCloseWorkOrderModalVisible(false);
  }

  return (
    <WebPageLayout header={GetWorkOrderScreenHeader(workOrderId)}>
      <View style={styles.panelContainer}>
        <Descriptions
          bordered
          layout='horizontal'
          styles={{
            content: {
              backgroundColor: 'white'
            },
            label: {
              width: 250,
            }
          }}
          size='small'
        >
          {workOrder?.parentWorkOrderId && (
            <Descriptions.Item label="Parent WO Reference" span={'filled' as any}>{workOrder?.parentWorkOrderReference}</Descriptions.Item>
          )}

          <Descriptions.Item label="Reference" span={'filled' as any}>{workOrder?.reference}</Descriptions.Item>
          <Descriptions.Item label="Description" span={'filled' as any}>{workOrder?.description}</Descriptions.Item>
          <Descriptions.Item label="Scheduled Start" span={'filled' as any}>{humanizeDate(workOrder?.scheduledStartDate)}</Descriptions.Item>
          <Descriptions.Item label="Scheduled Finish" span={'filled' as any}>{humanizeDate(workOrder?.scheduledFinishDate)}</Descriptions.Item>
          <Descriptions.Item label="Actual Start" span={'filled' as any}>{humanizeDate(workOrder?.actualStartDate, true)}</Descriptions.Item>
          <Descriptions.Item label="Actual Finish" span={'filled' as any}>{humanizeDate(workOrder?.actualFinishDate, true)}</Descriptions.Item>
          <Descriptions.Item label="Technician" span={'filled' as any}>{workOrder?.technician}</Descriptions.Item>
          <Descriptions.Item label="Status" span={'filled' as any}>{humanizeEnum(WorkOrderStatus[workOrder?.status])}</Descriptions.Item>
          <Descriptions.Item label="Priority" span={'filled' as any}>{humanizeEnum(WorkOrderPriority[workOrder?.priority])}</Descriptions.Item>
          <Descriptions.Item label="WO Type" span={'filled' as any}>{humanizeEnum(WorkOrderType[workOrder?.type])}</Descriptions.Item>
        </Descriptions>
        {hasPermission('CanCloseCompletedWorkOrders') && (
          <View style={{flexDirection: 'row', gap: 8, marginTop: 8}}>
            <Button
              disabled={workOrder?.status !== WorkOrderStatus.Completed}
              mode='elevated'
              onPress={() => handleShowCloseWorkOrderModal(false)}
            >
              Close Work Order
            </Button>
            <Button
              disabled={workOrder?.status !== WorkOrderStatus.Completed}
              mode='elevated'
              onPress={() => handleShowCloseWorkOrderModal(true)}
            >
              Revert Work Order to Technician
            </Button>
          </View>
        )}
      </View>

      <CloseWorkOrderModal
        open={closeWorkOrderModalVisible}
        id={workOrderId}
        onClose={handleHideCloseWorkOrderModal}
        onSuccess={handleHideCloseWorkOrderModal}
        rejectWork={rejectWork}
      />
    </WebPageLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8
  },
  panelContainer: {
    gap: 4,
    borderWidth: 1,
    borderColor: "#d9d9d9",
    borderRadius: 6,
    backgroundColor: "#fff",
    overflow: "hidden",
    flex: 1,
    padding: 12
  }
});
