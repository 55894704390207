import {MediaManager} from "../../../components/MediaManager";
import {
  useGetMediaQuery,
  useRemoveMediaMutation,
} from "../../../../../store/services/api/media-api";
import {UploadMediaModal} from "../../../../../components/modals/UploadMediaModal";
import React, {useEffect} from "react";
import {useModal} from "../../../../../hooks/useModal";

interface ChecklistMediaManagerProps {
  checklistId: string;
}

export function ChecklistMediaManager({checklistId}: ChecklistMediaManagerProps) {
  const {data: mediaList, isLoading, refetch} = useGetMediaQuery(checklistId);
  const [removeMedia, {isLoading: removeLoading, isSuccess: removeSuccess}] = useRemoveMediaMutation();
  const {showModal, hideModal} = useModal();

  useEffect(() => {
    if (removeSuccess) {
      handleOnMediaSuccess();
    }
  }, [removeSuccess]);

  const handleOnMediaSuccess = () => {
    hideModal();
    refetch();
  }

  const onShowModalPress = () => {
    showModal(<UploadMediaModal
      mediaPath={checklistId}
      onClose={hideModal}
      onSuccess={handleOnMediaSuccess} />
    );
  }

  return (
    <MediaManager
      media={mediaList}
      isLoading={isLoading || removeLoading}
      onShowModalPress={onShowModalPress}
      onRemoveMedia={removeMedia}
    />
  )
}
