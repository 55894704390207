import React from "react";
import {View, StyleSheet, Animated, Dimensions, FlatList} from "react-native";
import { Card } from "react-native-paper";

interface SkeletonProps {
  loading?: boolean;
  children?: React.ReactNode;
  placeholder?: React.ReactNode;
}

const SCREEN_WIDTH = Dimensions.get("window").width;

const Skeleton = ({ loading = true, children, placeholder }: SkeletonProps) => {
  if (loading) {
    return <View style={styles.skeletonWrapper}>{placeholder || <DefaultSkeleton />}</View>;
  }

  return <>{children}</>;
};

function DefaultSkeleton() {
  return (
    <View>
      <ShimmerLine customStyle={styles.skeletonLineShort} />
      <ShimmerLine />
      <ShimmerLine customStyle={styles.skeletonLineLong} />
    </View>
  );
}

Skeleton.Card = function SkeletonCard({rows = 1}: {rows?: number}) {
  const row = (index: number) => (
    <Card style={{backgroundColor: 'white'}} key={`skeleton-card-${index}`}>
      <Card.Content>
        <ShimmerLine customStyle={styles.skeletonLineShort} />
        <ShimmerLine />
        <ShimmerLine customStyle={styles.skeletonLineLong} />
      </Card.Content>
    </Card>
  )

  return (
    <FlatList
      style={{paddingHorizontal: 4, paddingVertical: 6}}
      ItemSeparatorComponent={() => <View style={{height: 6}} />}
      data={Array.from({length: rows})}
      renderItem={({index}) => row(index)}
    />
  );
};

function ShimmerLine({ customStyle }: { customStyle?: object }) {
  const shimmerAnimation = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    const shimmerLoop = Animated.loop(
      Animated.timing(shimmerAnimation, {
        toValue: 1,
        duration: 1500,
        useNativeDriver: true,
      })
    );
    shimmerLoop.start();

    return () => shimmerLoop.stop();
  }, [shimmerAnimation]);

  const translateX = shimmerAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [-SCREEN_WIDTH, SCREEN_WIDTH],
  });

  return (
    <View style={[styles.skeletonLine, customStyle]}>
      <Animated.View
        style={[
          styles.shimmerOverlay,
          {
            transform: [{ translateX }],
          },
        ]}
      />
    </View>
  );
}


export default Skeleton;

const styles = StyleSheet.create({
  skeletonWrapper: {
    flexDirection: "column",
    gap: 10,
  },
  card: {
    marginVertical: 10,
    borderRadius: 8,
    elevation: 1,
    overflow: "hidden",
  },
  cardSkeletonImage: {
    height: 150,
    backgroundColor: "#E0E0E0",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  skeletonLine: {
    backgroundColor: "#E0E0E0",
    height: 14,
    marginBottom: 8,
    borderRadius: 4,
    overflow: "hidden",
  },
  skeletonLineShort: {
    width: "70%",
  },
  skeletonLineLong: {
    width: "100%",
  },
  shimmerOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    width: "50%",
    opacity: 0.7
  },
});
