import CoreForm from "../../../../../../../web/components/forms/CoreForm";
import {TextFieldController} from "../../../../../../../web/components/forms/TextFieldController";
import React, {useEffect} from "react";
import {PickerFieldController} from "../../../../../../../web/components/forms/PickerFieldController";
import {UserRoles} from "../../../../../../../../store/data/users/user-roles";
import {useForm} from "react-hook-form";
import {UserManageForm} from "../../../../../../../../store/data/users/user-manage-form";

interface WebUsersManageForm {
  user: any;
  onSubmit: (data: any) => void;
}

export default function WebUsersManageForm({user, onSubmit}: WebUsersManageForm) {
  const form = useForm<UserManageForm>({
    defaultValues: {
      id: null,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: 'Helpdesk'
    }
  });

  useEffect(() => {
    if (user) {
      form.setValue("id", user.id);
      form.setValue("name", user.name);
      form.setValue("email", user.email);
      form.setValue("role", user.role);
    }
  }, [user]);


  const handleOnSubmit = () => {
    form.handleSubmit(onSubmit)();
  }

  return (
    <CoreForm form={form} onSubmit={handleOnSubmit}>
      <CoreForm.Row>
        <TextFieldController
          controlName='name'
          title='Name'
          required
        />
        <TextFieldController
          controlName='email'
          title='Email'
          required
        />
        <PickerFieldController
          controlName='role'
          title='Role'
          required
          defaultFirst={false}
          items={UserRoles.map(item => ({
            label: item.label,
            value: item.value
          }))}
        />
      </CoreForm.Row>
      <CoreForm.Row>
        <TextFieldController
          placeholder={user ? 'Unchanged': ''}
          controlName='password'
          title='Password'
          required={!user}
        />
        <TextFieldController
          placeholder={user ? 'Unchanged': ''}
          controlName='confirmPassword'
          title='Confirm Password'
          required={!user}
        />
      </CoreForm.Row>
    </CoreForm>
  )
}
