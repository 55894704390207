import {useLazyGetFloorsListForAssetQuery} from "../../../store/services/api/building-api";
import {useGetCascaderOptionsMutation, useLazyGetAssetsListQuery} from "../../../store/services/api/asset-api";
import React, {useEffect, useState} from "react";
import {Asset} from "../../../store/data/entities/asset";
import {Cascader, CascaderProps} from "antd";

interface AssetSelectionCascaderProps {
  initialValues?: Asset[] | undefined;
  value: string[][] | string[] | undefined;
  onValueChange: (value: string[][] | string[]) => void;
  assetTypeId?: string | undefined;
  subAssetTypeId?: string | undefined;
  disabled?: boolean;
  multiple?: boolean;
}

enum AssetOptionType {
  Building,
  Floor,
  Asset,
}

export type AssetOption = {
  value: string;
  label: string;
  type: AssetOptionType;
  children?: AssetOption[];
  isLeaf?: boolean;
  selectable?: boolean;
}

export default function AssetSelectionCascader(props: AssetSelectionCascaderProps) {
  const [getInitialOptions, {isLoading}] = useGetCascaderOptionsMutation();
  const [getFloors] = useLazyGetFloorsListForAssetQuery();
  const [getAssets] = useLazyGetAssetsListQuery();
  const [options, setOptions] = useState<AssetOption[]>([]);

  useEffect(() => {
    const initializeOptions = async () => {
      const initialOptions = await getInitialOptions({
        initialAssetIds: props.initialValues?.map((item) => item.id) ?? [],
        assetTypeId: props.assetTypeId,
        subAssetTypeId: props.subAssetTypeId
      }).unwrap();
      setOptions(initialOptions);

      const value = props.initialValues?.map((item) => [item.buildingId, item.floorId, item.id]) ?? [];
      props.onValueChange(value);
    }

    initializeOptions();
  }, [props.initialValues, props.assetTypeId, props.subAssetTypeId]);

  const fetchFloors = (buildingId: string) =>
    getFloors({
      buildingId,
      assetTypeId: props.assetTypeId ? props.assetTypeId : undefined,
      subAssetTypeId: props.subAssetTypeId ? props.subAssetTypeId : undefined,
    }).unwrap();

  const fetchAssets = (floorId: string) =>
    getAssets({
      pageNumber: 1,
      pageSize: 1000,
      floorId,
      assetTypeId: props.assetTypeId ? props.assetTypeId : undefined,
      subAssetTypeId: props.subAssetTypeId ? props.subAssetTypeId : undefined,

    }).unwrap();

  const onChange: CascaderProps<any>['onChange'] = (selectedItem: string[], selectedOptions: AssetOption[][]) => {
    if (!props.multiple) {
      props.onValueChange(selectedItem);
      return;
    }
    const value = selectedOptions.map((option) => option?.map((o) => o.value));
    props.onValueChange(value);
  };

  const loadData = async (selectedOptions: AssetOption[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption.children) return;

    try {
      let children: AssetOption[] | undefined;

      if (targetOption.type === AssetOptionType.Building) {
        const floors = await fetchFloors(targetOption.value);
        children = floors.map((floor) => ({
          value: floor.id,
          label: floor.name,
          isLeaf: false,
          type: AssetOptionType.Floor,
        }));
      }

      if (targetOption.type === AssetOptionType.Floor) {
        const { list: assets } = await fetchAssets(targetOption.value);
        children = assets.map((asset) => ({
          value: asset.id,
          label: asset.code,
          isLeaf: true,
          selectable: true,
          type: AssetOptionType.Asset,
        }));
      }

      const updateOptionTree = (
        options: AssetOption[],
        newChildren?: AssetOption[]
      ): AssetOption[] => {
        return options.map((option) => {
          if (option.value === targetOption.value) {
            return { ...option, children: newChildren };
          }

          if (option.children) {
            return { ...option, children: updateOptionTree(option.children, newChildren) };
          }

          return option;
        });
      };

      setOptions((currentOptions) =>
        updateOptionTree(currentOptions, children)
      );
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };


  return (
    // @ts-ignore
    <Cascader<any>
      disabled={props.disabled}
      style={{ width: '100%' }}
      options={isLoading ? [] : options}
      loadData={loadData}
      onChange={onChange}
      loading={isLoading}
      value={props.value}
      multiple={props?.multiple}
      changeOnSelect
      showCheckedStrategy="SHOW_PARENT"
      placeholder="Please select"
    />
  )
}
