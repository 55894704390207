import moment from "moment/moment";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {WorkOrderPriority} from "../store/data/entities/work-order";
dayjs.extend(relativeTime);

const dateFormat = 'DD MMM YY'
const dateTimeFormat = 'DD MMM YY HH:mm'
const timeFormat = 'HH:mm'

export function humanizeDate(date: string, showTime?: boolean): string {
  const format = showTime ? dateTimeFormat : dateFormat;
  return (moment(date).isValid() ? moment(date).format(format) : 'N/A').toUpperCase();
}

export function humanizeTime(dateTime: string): string {
  return moment(dateTime).isValid() ? moment(dateTime).format(timeFormat) : 'N/A';
}

export function humanizeBoolean(value: boolean): string {
  return value ? 'Yes' : 'No'
}

export function humanizeCurrency(value: number, locale: Intl.LocalesArgument = 'en-GB', currency: string = 'GBP'): string {
  return value.toLocaleString(locale, {
    style: 'currency',
    currency: currency,
  })
}

export function humanizeString(value: string, casing: 'upper' | 'lower' | 'title'): string {
  switch (casing) {
    case 'upper':
      return value.toUpperCase();
    case 'lower':
      return value.toLowerCase();
    case 'title':
      return value.replace(/\w\S*/g, (txt) =>
        txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
      );
    default:
      return value;
  }
}

export const humanizeEnum = (value: string): string => {
  if (!value) return '';
  return value
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .trim();
};

export const humanizePastDate = (date: string | undefined, fallback: string = 'N/A') => {
  if (!dayjs(date).isValid()) {
    return fallback;
  }

  const now = dayjs().startOf('day');
  const comparisonDate = dayjs(date).startOf('day');

  const dayDifference = comparisonDate.diff(now, 'day');

  if (dayDifference === 0) {
    return 'Today';
  }

  if (dayDifference === 1) {
    return 'Tomorrow';
  }

  if (dayDifference === -1) {
    return 'Yesterday';
  }

  return dayjs().to(dayjs(date));
};


export const humanizeWorkOrderPriority = (value: WorkOrderPriority)=> {
  if (value === WorkOrderPriority.Standard) return 'STD';
  if (value === WorkOrderPriority.Important) return 'IMP';
  if (value === WorkOrderPriority.Urgent) return 'URG';
  if (value === WorkOrderPriority.Emergency) return 'EMER';
  return 'N/A';
}
