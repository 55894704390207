import {StyleSheet} from "react-native";
import {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import * as React from "react";
import {Button} from "react-native-paper";

interface FormSubmitButtonProps {
  title: string;
  onPress: () => void;
  loading?: boolean;
  type?: 'primary' | 'secondary' | 'danger';
  disabled?: boolean;
}

export function FormButton({title, onPress, loading = false, type = 'primary', disabled = false}: FormSubmitButtonProps) {
  const buttonColor = {
    ['primary']: PRIMARY_COLOUR,
    ['secondary']: 'rgba(0,0,0,0)',
    ['danger']: 'rgba(255,62,62,0.05)'
  }

  const buttonTextColor = {
    ['primary']: '#FFF',
    ['secondary']: '#212529',
    ['danger']: '#f03e3e'
  }

  const borderColor = {
    ['primary']: '#868e96',
    ['secondary']: '#868e96',
    ['danger']: '#f03e3e'
  }

  return (
    <Button
      disabled={disabled || loading}
      style={[styles.formButton, {borderColor: borderColor[type]}]}
      mode='outlined'
      onPress={onPress}
      buttonColor={loading ? 'gray' : buttonColor[type]}
      textColor={loading? 'white' : buttonTextColor[type]}
    >
      {title}
    </Button>
  )
}

const styles = StyleSheet.create({
  formButton: {
    paddingHorizontal: 12,
    flexShrink: 0,
    flexGrow: 0
  }
})
