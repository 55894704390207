import React from "react";
import WebPageLayout from "../../../../../layouts/WebPageLayout";
import TablePageLayout from "../../../../../layouts/TablePageLayout";
import {Button, DataTable} from "react-native-paper";
import {Entypo, Ionicons} from "@expo/vector-icons";
import {BaseFilters} from "../../../../../../web/hooks/useFilters";
import {CoreTable} from "../../../../../components/CoreTable";
import {TouchableOpacity} from "react-native";
import {getSystemScreenHeaders} from "../../../system-screen-headers";
import useEntityListPage from "../../../../../hooks/useEntityListPage";
import {useDeleteUserMutation, useLazyGetUsersQuery} from "../../../../../../../store/services/api/user-api";

export default function SystemUsersListScreen() {
  const {
    navigateToCreate,
    navigateToEdit,
    deleteEntity,
    filter,
    tableData,
    isLoading
  } = useEntityListPage<BaseFilters>({
    entityName: "User",
    lazyTableQuery: useLazyGetUsersQuery,
    deleteEntityMutation: useDeleteUserMutation,
    filterDefinition: {
      sortedColumn: "",
      sortedColumnAscending: false,
      pageNumber: 1,
      pageSize: 10
    },
  });

  return (
    <WebPageLayout header={getSystemScreenHeaders()}>
      <TablePageLayout
        actions={(
          <Button icon='plus' mode="elevated" onPress={navigateToCreate} buttonColor='#fff' textColor='#0f0f0f'>
            Add User
          </Button>
        )}
      >
        <CoreTable<any>
          isLoading={isLoading}
          headers={[
            { text: 'Name', sortedName: "name" },
            { text: 'Email', sortedName: "email" },
            { text: 'Actions', flex: 0.5 }
          ]}
          data={tableData}
          keyExtractor={(item) => item.id}
          renderItem={({item}) => (
            <DataTable.Row key={item.id}>
              <DataTable.Cell>{item.name}</DataTable.Cell>
              <DataTable.Cell>{item.email}</DataTable.Cell>
              <DataTable.Cell style={{flex: 0.5}}>
                <TouchableOpacity onPress={() => navigateToEdit(item.id)}>
                  <Entypo name="pencil" size={24} color="black" />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => deleteEntity(item)}>
                  <Ionicons name="ios-remove-circle" size={24} color="red" />
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )}
          filters={filter.filters}
          setFilter={filter.setFilter}
        />
      </TablePageLayout>
    </WebPageLayout>
  )
}
