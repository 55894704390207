import {Text, View} from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import {Controller, RegisterOptions} from "react-hook-form";
import * as React from "react";
import {useCoreForm} from "./CoreForm";
import {Picker} from "@react-native-picker/picker";
import {useEffect} from "react";

interface TextFieldControllerProps {
  rules?: RegisterOptions;
  controlName: string;
  title: string;
  items: PickerItem[];
  required?: boolean;
  defaultFirst?: boolean;
  onChange?: (value: PickerItem) => void;
  hidePleaseSelect?: boolean;
  numeric?: boolean;
  disabled?: boolean;
}

export interface PickerItem {
  key?: any;
  value: any;
  label: string;
}

export function PickerFieldController({rules, controlName, title, items, required = false, defaultFirst = true,
                                        onChange = undefined, hidePleaseSelect = false, numeric = false, disabled = false}: TextFieldControllerProps) {
  const {form: {control, watch, setValue}, isLoading} = useCoreForm();
  if (!control) return null;

  const watchedValue = watch(controlName);
  const hasValue = watchedValue !== undefined && watchedValue !== null && watchedValue !== '';
  const extendedRules = {
    ...(rules ?? {}),
    required: required ? 'This field is required' : false,
  };

  useEffect(() => {
    if (items.length && !hasValue && defaultFirst) {
      setValue(controlName, items[0].value);
    }
  }, [items, watchedValue, setValue, controlName]);

  return (
    <Controller
      control={control}
      rules={extendedRules}
      name={controlName}
      render={({field: {onChange: _onChange, onBlur, value}, fieldState: {error}}) => (
        <View>
          <Text style={GlobalStyle.form__column__text}>{title} {required ? '*' : null}</Text>
          <Picker
            enabled={!isLoading && !disabled}
            onBlur={onBlur}
            onValueChange={(itemValue) => {
              const parsedValue = itemValue === ''
                ? undefined : (isNaN(Number(itemValue)) || !numeric) ? itemValue : Number(itemValue);
              _onChange(parsedValue);
              onChange?.(parsedValue);
            }}
            selectedValue={value}
            style={[GlobalStyle.column, GlobalStyle.form__column__input]}>
              {!hidePleaseSelect && <Picker.Item key='select' value='' label='Please Select' />}
              {items?.map(item => (
                <Picker.Item key={item?.key ?? item.value} value={item.value} label={item.label} />
              ))}
          </Picker>
          {error && <Text style={{color: 'red'}}>{error.message}</Text>}
        </View>
      )}
    />
  )
}
