import React, {useEffect, useState} from "react";
import {Button as AntButton, Form, Modal, Upload, UploadFile, UploadProps} from 'antd';
import {useCamera} from "../../screens/technician-v2/hooks/useCamera";
import {CameraOutlined, UploadOutlined} from "@ant-design/icons";
import {base64toFile} from "../../utils/imageUtils";
import {useAddWorkLogFilesMutation} from "../../store/services/api/work-order-api";

export interface RcFile extends File {
  uid: string;
  lastModifiedDate: Date;
}

interface UploadWorkOrderLogDocumentsModalProps {
  workOrderId: string;
  workOrderLogEntryId: string;
  onClose: () => void;
  onSuccess?: () => void;
  visible: boolean;
  existingFileURIs?: string[];
}

export default function UploadWorkOrderLogDocumentsModal(props: UploadWorkOrderLogDocumentsModalProps) {
  const [uploadMedia, {isLoading}] = useAddWorkLogFilesMutation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const {showCamera} = useCamera();

  const getFileNameFromURI = (uri: string) => {
    try {
      return uri.split("/").pop();
    } catch (error) {
      return "unknown file";
    }
  };

  useEffect(() => {
    if (props.visible) {
      const existingFiles: UploadFile[] = props.existingFileURIs?.map((url, index) => ({
        uid: `existing-${index}`,
        name: getFileNameFromURI(url),
        status: "done",
        url,
      })) || [];
      setFileList(existingFiles);
    }
  }, [props.visible, props.existingFileURIs]);


  const uploadFileToFile = (uploadFile: UploadFile): File => {
    if (uploadFile.originFileObj instanceof File) {
      return uploadFile.originFileObj;
    }
    throw new Error("Invalid file object detected");
  };

  const handleOnSubmit = () => {
    if (!props.workOrderLogEntryId) return;

    const files = fileList
      .filter(file => !file.url)
      .map((uploadFile) => uploadFileToFile(uploadFile))
      .filter(Boolean);

    const filesToRemove = props.existingFileURIs
      .filter(fileUrl => !fileList.find(file => file.url === fileUrl))

    uploadMedia({
      workOrderId: props.workOrderId,
      workLogEntryId: props.workOrderLogEntryId,
      files,
      fileURIsToRemove: filesToRemove,
    })
      .unwrap()
      .then(() => {
        props.onSuccess?.();
      })
      .finally(props.onClose);
  };

  const convertToRcFile = (file: File): RcFile => {
    const rcFile = new File([file], file.name, { type: file.type }) as RcFile;
    rcFile.uid = Date.now().toString();
    return rcFile;
  };

  // todo Check permissions etc...

  const mapFileToUploadFile = (file: File): UploadFile => ({
    uid: Date.now().toString(),
    name: file.name,
    status: "done",
    originFileObj: convertToRcFile(file),
  });

  const handleCameraButtonClick = () => {
    const handleOnResult = (result: string) => {
      const position = fileList.length + 1;
      const file = base64toFile(result, `image-${position}.jpg`);
      if (!(file instanceof File)) {
        return;
      }

      setFileList((curr) => [...curr, mapFileToUploadFile(file)]);
    };

    showCamera({
      mode: "photo",
      onResult: handleOnResult,
    });
  };

  const fileUploadProps: UploadProps = {
    onRemove: (file) => {
      setFileList((currentFileList) =>
        currentFileList.filter((item) => item.uid !== file.uid)
      );
    },
    beforeUpload: (file) => {
      if (!(file instanceof File)) {
        return false;
      }
      const uploadFile = mapFileToUploadFile(file);
      setFileList((currentFileList) => [...currentFileList, uploadFile]);
      return false;
    },
    fileList
  };

  return (
    <Modal
      centered
      loading={isLoading}
      title="Add Media"
      open={props.visible}
      onOk={handleOnSubmit}
      onCancel={props.onClose}
    >
      <Form
        style={{
          maxHeight: '80vh',
          overflowY: 'auto'
        }}
      >
        <Upload {...fileUploadProps}>
          <AntButton icon={<UploadOutlined />}>Click to Upload</AntButton>
        </Upload>
        <AntButton
          icon={<CameraOutlined />}
          style={{ marginTop: 16 }}
          onClick={handleCameraButtonClick}>
          Take Photo
        </AntButton>
      </Form>
    </Modal>
  )
}
