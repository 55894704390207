import {Modal} from "antd";
import WorkOrderManageForm from "../../../screens/web-v2/screens/work-orders/screens/manage/WorkOrderManageForm";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  ticketId?: string | undefined;
}

export default function WorkOrderManageModal(props: ModalProps) {
  return (
    <Modal
      title='Create Work Order'
      centered
      styles={{
        content: {
          maxHeight: '80dvh',
          overflow: 'auto'
        }
      }}
      destroyOnClose
      open={props.open}
      onCancel={props.onClose}
      footer={(_, { CancelBtn }) => (
        <>
          <CancelBtn />
        </>
      )}
    >
      <WorkOrderManageForm
        onSuccess={props.onSuccess}
        ticketId={props.ticketId}
      />
    </Modal>
  );
}
