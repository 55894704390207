import { baseApi } from './base-api'
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {TicketViewModel} from "../../data/models/ticket-view-model";
import {buildPagedResponseTags, invalidateTags} from "../../../utils/api-utils";
import {Update} from "../../data/entities/update";

export const ticketApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getticket: build.query<any, any>({
      query: (id) => ({
        url: `tickets/getticket/${id}`,
      }),
    }),
    
    getTicketByAsset: build.query<any, any>({
      query: (assetId) => ({
        url: `tickets/getticketbyasset/${assetId}`,
      }),
    }),
    exportTickets: build.query<any, Record<string, any>>({
      query: (params) => ({
        url: `tickets/exporttickets`,
        params: params
      }),
    }),
    getTickets: build.query<PagedResponse<TicketViewModel>, Record<string, any>>({
      query: (params) => ({
        url: `tickets/gettickets`,
        params: params
      }),
      transformResponse: getPagedResponse,
      providesTags: result => buildPagedResponseTags(result, 'Ticket')
    }),
    getUpdates: build.query<PagedResponse<Update>, Record<string, any>>({
      query: (params) => ({
        url: `updates/getupdates`,
        params: params
      }),
      transformResponse: getPagedResponse
    }),
    addTicket: build.mutation<any, any>({
      query: (body) => ({
        url: 'tickets/addTicket',
        method: 'post',
        body: body
      }),
      invalidatesTags: invalidateTags('LIST', 'Ticket')
    }),
    addUpdate: build.mutation<any, any>({
      query: (body) => ({
        url: 'updates/addupdate',
        method: 'post',
        body: body
      }),
    }),
    updateTicketStatus: build.mutation<any, { id: string, status: string }>({
      query: ({ id, status }) => ({
        url: `tickets/updateticketstatus/${id}`,
        method: 'put',
        body: { status }
      }),
      invalidatesTags: invalidateTags('LIST', 'Ticket')
    }),
    updateTicketEmail: build.mutation<any, { id: string, contactEmail: string }>({
      query: ({ id, contactEmail }) => ({
        url: `tickets/updateticketemail/${id}`,
        method: 'put',
        body: { contactEmail }
      }),
    }),
    getTicketsByAsset: build.query<any, any>({
      query: (id) => ({
        url: `tickets/getticketsbyasset/${id}`,
      }),
    }),
    getOpenTicketsByAsset: build.query<any, any>({
      query: (id) => ({
        url: `tickets/getopenticketsbyasset/${id}`,
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetTicketsQuery,
  useUpdateTicketStatusMutation,
  useUpdateTicketEmailMutation,
  useAddUpdateMutation,
  useLazyGetUpdatesQuery,
  useAddTicketMutation,
  useGetTicketByAssetQuery,
  useLazyGetTicketByAssetQuery,
  useGetTicketsByAssetQuery,
  useLazyGetTicketsByAssetQuery,
  useGetOpenTicketsByAssetQuery,
  useLazyExportTicketsQuery,
  useLazyGetTicketsQuery
} = ticketApi
