import {StyleSheet} from "react-native";
import {ReactNode} from "react";
import {Button, Card} from "react-native-paper";

export default function MobileForm({children, onSubmit, submitText = 'Submit'}: {children: ReactNode, onSubmit: () => void, submitText?: string}) {
  return (
    <Card style={{backgroundColor: 'white'}}>
      <Card.Content>
        {children}
      </Card.Content>
      <Card.Actions style={styles.footerContainer}>
        <Button mode='elevated' onPress={onSubmit}>{submitText}</Button>
      </Card.Actions>
    </Card>
  )
}

const styles = StyleSheet.create({
  footerContainer: {
    marginTop: 8
  }
})
