import {FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import GlobalStyle, {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import {EvilIcons, MaterialIcons} from "@expo/vector-icons";
import * as React from "react";
import {Controller, useForm} from "react-hook-form";
import {TextFieldController} from "../../components/forms/TextFieldController";
import CoreForm from "../../components/forms/CoreForm";
import {PickerFieldController} from "../../components/forms/PickerFieldController";
import {useEffect, useState} from "react";
import {useToast} from "../../../../components/toast/ToastProvider";
import {WebScreen} from "../../components/WebScreen";
import {useGetAllAssetTypesQuery} from "../../../../store/services/api/asset-type-api";
import {NewMaintenanceChecklist} from "../../../../store/data/entities/maintanence-checklist";
import {FormButton} from "../../components/forms/FormButton";
import {
  useCreateChecklistMutation,
  useLazyGetChecklistQuery, useUpdateChecklistMutation
} from "../../../../store/services/api/maintenance-checklist-api";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {ChecklistMediaManager} from "./components/ChecklistMediaManager";
import {useSetLoading} from "../../../../hooks/useSetLoading";

export default function MaintenanceChecklistManage({navigation, route}: NativeStackScreenProps<any, any>) {
  const {permissions} = useSelector((state: RootState) => state.user);
  const {data: assetTypes, isFetching: isAssetTypesFetching} = useGetAllAssetTypesQuery();
  const [createChecklist, {isLoading: createLoading, isError: createError, isSuccess: createSuccess}] = useCreateChecklistMutation();
  const [updateChecklist, {isLoading: updateLoading, isError: updateError, isSuccess: updateSuccess}] = useUpdateChecklistMutation();
  const [getChecklist, {data: currentChecklist, isLoading: checklistLoading}] = useLazyGetChecklistQuery();
  const [question, setQuestion] = useState('');
  const checklistId = route?.params?.checklistId;
  const isApproval = permissions.includes('CanApproveNewChecklists');

  const form = useForm<NewMaintenanceChecklist>({
    defaultValues: {
      title: '',
      frequency: 12,
      assetTypeId: '',
      questions: []
    },
    mode: "onChange"
  });

  const { watch, setValue } = form;
  const {show} = useToast();
  const navigateBack = () => navigation.navigate('list');

  useEffect(() => {
    if (currentChecklist) {
      form.reset({
        title: currentChecklist.title,
        frequency: currentChecklist.frequency,
        assetTypeId: currentChecklist.assetTypeId,
        questions: currentChecklist.questions.map(q => q.question)
      });
    }
  }, [currentChecklist]);

  useEffect(() => {
    if (checklistId) {
      getChecklist(checklistId);
    }
  }, [checklistId]);

  useSetLoading([isAssetTypesFetching, createLoading, checklistLoading, updateLoading]);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      navigateBack();
      const action = createSuccess ? 'created' : 'updated';
      show('Success', `Successfully ${action} checklist`, 'success');
    }
  }, [createSuccess, updateSuccess]);

  useEffect(() => {
    if (createError || updateError) {
      const action = createSuccess ? 'creating' : 'updating';
      show('Error', `There was an issue ${action} the checklist`, 'error');
    }
  }, [createError, updateError]);

  const questions = watch('questions');

  const onAddQuestion = (question: string) => {
    if (question == '') return;

    const newQuestions = [...questions, question];
    setValue('questions', newQuestions);
    setQuestion('');
  };

  const onRemoveQuestion = (index: number) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setValue('questions', newQuestions);
  };

  const validateQuestions = (value: any[]) => {
    if (!value || value.length === 0) {
      return "At least one question is required";
    }
    return true;
  };

  const handleOnSubmit = async () => {
    const valid = await form.trigger();
    if (!valid) return;
    const formValues = form.getValues();

    if (!checklistId) {
      createChecklist(formValues);
      return;
    }

    updateChecklist({
      id: checklistId,
      checklist: formValues
    });
  }

  return (
    <WebScreen>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.navigate("list")}>
          <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>MANAGE JOB PLAN</Text>
      </View>

      <CoreForm
        form={form}
        onSubmit={handleOnSubmit}
        submitText={isApproval ? 'SUBMIT / APPROVE' : 'SUBMIT'}>
        <CoreForm.Row>
          <TextFieldController
            controlName='title'
            title='Title'
            required
          />
          <TextFieldController
            controlName='frequency'
            title='Frequency'
            type='numeric'
            required
          />
          <PickerFieldController
            controlName='assetTypeId'
            title='Asset Type'
            required
            items={(assetTypes ?? []).map(item => ({
              value: item.id,
              label: item.name
            }))} />
        </CoreForm.Row>
        <CoreForm.Row>
          <View style={styles.questionContainer}>
            <View style={styles.inputWrapper}>
              <Text style={[GlobalStyle.form__column__text, styles.textInput]}>Question</Text>
              <TextInput
                style={GlobalStyle.form__column__input}
                onChangeText={setQuestion}
                value={question}>
              </TextInput>
            </View>
            <FormButton title='Add Question' onPress={() => onAddQuestion(question)} />
          </View>
        </CoreForm.Row>
        <CoreForm.Row>
          <Controller
            control={form.control}
            name="questions"
            rules={{ validate: validateQuestions }}
            render={({fieldState: {error}}) =>
              <View>
                <FlatList
                  data={questions}
                  keyExtractor={(item, index) => index.toString()}
                  renderItem={({item, index}) => (
                    <View style={styles.questionItem}>
                      <Text style={styles.questionText}>{item}</Text>
                      <TouchableOpacity onPress={() => onRemoveQuestion(index)}>
                        <MaterialIcons name="delete" size={24} color="red" />
                      </TouchableOpacity>
                    </View>
                  )}
                />
                {error && <Text style={{color: 'red'}}>{error.message}</Text>}
              </View>}
          />
        </CoreForm.Row>
      </CoreForm>
      {checklistId && (
        <ChecklistMediaManager checklistId={checklistId} />
      )}
    </WebScreen>
  );
}

const styles = StyleSheet.create({
  questionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  inputWrapper: {
    flex: 1, // Flex item to take the available space
    marginRight: 10, // Add some space between input and button
  },
  textInput: {
    minWidth: 300
  },
  questionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between', // Space between text and delete button
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#f9f9f9',
    marginBottom: 5,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 4,
  },
  questionText: {
    flex: 1, // Flex item to take the available space
  }
});
