import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import AssetViewScreen from "./screens/AssetViewScreen";
import AssetFeedbackScreen from "./screens/AssetFeedbackScreen";
import AssetTroubleshootingScreen from "./screens/AssetTroubleshootingScreen";
import AssetCreateTicketScreen from "./screens/AssetCreateTicketScreen";
import AssetOpenTicketsScreen from "./screens/AssetOpenTicketsScreen";
import AssetSetupScreen from "./screens/AssetSetupScreen";
import AssetReplaceQRScreen from "./screens/AssetReplaceQRScreen";
import AssetLogBookScreen from "./screens/AssetLogBookScreen";

const Stack = createNativeStackNavigator<AssetStackParamList>();

export default function AssetStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='asset'
        component={AssetViewScreen}
        options={{title: 'Asset'}}
      />
      <Stack.Screen
        name='feedback'
        component={AssetFeedbackScreen}
        options={{title: 'Feedback'}}
      />
      <Stack.Screen
        name='troubleshooting'
        component={AssetTroubleshootingScreen}
        options={{title: 'Troubleshooting'}}
      />
      <Stack.Screen
        name='createTicket'
        component={AssetCreateTicketScreen}
        options={{title: 'Create Ticket'}}
      />
      <Stack.Screen
        name='openTickets'
        component={AssetOpenTicketsScreen}
        options={{title: 'Open Tickets'}}
      />
      <Stack.Screen
        name='setup'
        component={AssetSetupScreen}
        options={{title: 'Setup Asset'}}
      />
      <Stack.Screen
        name='replaceQr'
        component={AssetReplaceQRScreen}
        options={{title: 'Replace QR'}}
      />
      <Stack.Screen
        name='logBook'
        component={AssetLogBookScreen}
        options={{title: 'Log Book'}}
      />
    </Stack.Navigator>
  );
};

export type AssetStackParamList = ParamListBase & {
  asset: {uniqueRef: string};
  feedback: {assetId: string};
  troubleshooting: {assetId: string};
  createTicket: {assetId: string};
  openTickets: {assetId: string};
  setup: {uniqueRef: string};
  replaceQr: {uniqueRef: string};
  logBook: {assetId: string};
};
