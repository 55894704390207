import React, {useEffect, useState} from "react";
import MobilePageLayout from "../../../layouts/MobilePageLayout";
import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AssetStackParamList} from "../AssetStack";
import {useLazyGetAssetQuery} from "../../../../../store/services/api/asset-api";
import {Controller, useForm} from "react-hook-form";
import {useAddTicketMutation} from "../../../../../store/services/api/ticket-api";
import {useToast} from "../../../../../components/toast/ToastProvider";
import {useSetLoading} from "../../../../../hooks/useSetLoading";
import {FlatList, Image, StyleSheet, TouchableOpacity, View} from "react-native";
import {HelperText, TextInput} from "react-native-paper";
import MobileForm from "../../../layouts/MobileForm";
import * as ImagePicker from "expo-image-picker";
import {MaterialIcons} from "@expo/vector-icons";
import {PRIMARY_COLOUR} from "../../../../../constants/GlobalStyle";
import {compressImage} from "../../../../../utils/imageUtils";

export default function AssetCreateTicketScreen({route, navigation}: ScreenProps<AssetStackParamList, 'createTicket'>) {
  const assetId = route.params?.assetId;
  const [getAsset, { data: asset, isFetching: assetFetching }] = useLazyGetAssetQuery();
  const [createTicket, {isLoading: createLoading}] = useAddTicketMutation();
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  useEffect(() => {
    if (assetId) {
      getAsset(assetId)
        .unwrap()
        .then((asset) => {
          form.setValue('description', asset.description);
        });
    }
  }, [assetId]);

  const {show} = useToast();
  useSetLoading([assetFetching, createLoading]);

  const form = useForm({
    defaultValues: {
      description: asset?.description ?? '',
      issue: "",
      contactEmail: "",
      assetId: assetId ?? '',
    },
  });

  const handleOnSubmit = () => {
    form.handleSubmit(onSubmit)();
  }

  const onSubmit = async (data: any) => {
    data.images = await Promise.all(
      selectedImages.map(uri => compressImage(uri))
    );

    createTicket(data)
      .unwrap()
      .then(() => {
        show('Success', 'Successfully created ticket', 'success');
        navigation.navigate("asset", { uniqueRef: asset.uniqueReference });
      });
  }

  const takeImage = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    if (status !== "granted") {
      alert("Permission to access the camera is required!");
      return;
    }

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      base64: true,
    });

    if (!result.canceled) {
      setSelectedImages([...selectedImages, result.assets[0].uri]);
    }
  };

  const pickImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert("Permission to access the photos is required!");
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      base64: true,
      allowsMultipleSelection: true
    });

    if (!result.canceled) {
      setSelectedImages([...selectedImages, result.assets[0].uri]);
    }
  };

  const deleteImage = (index) => {
    setSelectedImages(selectedImages.filter((_, idx) => idx !== index));
  };

  return (
    <MobilePageLayout>
      <MobileForm onSubmit={handleOnSubmit}>
        <Controller
          control={form.control}
          name='description'
          render={({field: {value}}) => (
            <TextInput
              label='Item Decription'
              mode='outlined'
              value={value}
              disabled
            />
          )}
        />
        <Controller
          control={form.control}
          rules={{ required: true }}
          name='issue'
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <View>
              <TextInput
                numberOfLines={8}
                label='Issue'
                mode='outlined'
                multiline
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                maxLength={200}
                error={!!error}
              />
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <HelperText
                  visible={!!error}
                  type='error'
                >Please enter an issue</HelperText>
                <HelperText
                  type='info'
                >{value?.length ?? 0}/200</HelperText>
              </View>
            </View>
          )}
        />
        <Controller
          control={form.control}
          name='contactEmail'
          render={({field: {onChange, onBlur, value}}) => (
            <TextInput
              label='Contact Email'
              mode='outlined'
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          )}
        />

        <View style={styles.imagesContainer}>
          {selectedImages.length < 5 && (
            <TouchableOpacity onPress={pickImage} style={styles.addButton}>
              <MaterialIcons name="add-to-photos" size={24} color="white" />
            </TouchableOpacity>
          )}
          {selectedImages.length < 5 && (
            <TouchableOpacity onPress={takeImage} style={styles.addButton}>
              <MaterialIcons name="camera-alt" size={24} color="white" />
            </TouchableOpacity>
          )}
          <FlatList
            horizontal
            data={selectedImages}
            renderItem={({item, index}) => (
              <TouchableOpacity
                key={`ticket-images-${index}`}
                onPress={() => deleteImage(index)}
                style={styles.imageWrapper}
              >
                <Image source={{uri: item}} style={styles.image} />
              </TouchableOpacity>
            )}
          />
        </View>
      </MobileForm>
    </MobilePageLayout>
  )
}

const styles = StyleSheet.create({
  addButton: {
    marginRight: 10,
    backgroundColor: PRIMARY_COLOUR,
    padding: 8,
    borderRadius: 4,
    justifyContent: "center",
  },
  imagesContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  imageWrapper: {
    marginRight: 10,
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 8,
  },
})
