import {WorkOrderType} from "./work-order";

export interface Asset {
  id: string;
  buildingId: string;
  buildingName: string;
  floorId: string;
  floorName: string;
  locationId: string;
  locationName: string;
  assetTypeId: string;
  assetTypeName: string;
  subAssetTypeId: string;
  subAssetTypeName: string;
  description: string;
  code: string;
  uniqueReference?: string;
  imageUri?: string;
  ticketCount: number;
  priority: AssetPriority;
  supportingInformation: string;
}

export enum AssetPriority {
  Lead,
  Bronze,
  Silver,
  Gold,
  Platinum
}

export const AssetPriorityLabels = {
  [AssetPriority.Lead]: 'Lead',
  [AssetPriority.Bronze]: 'Bronze',
  [AssetPriority.Silver]: 'Silver',
  [AssetPriority.Gold]: 'Gold',
  [AssetPriority.Platinum]: 'Platinum'
}

export const assetPriorityOptions = [
  {label: "Lead", value: AssetPriority.Lead},
  {label: "Bronze", value: AssetPriority.Bronze},
  {label: "Silver", value: AssetPriority.Silver},
  {label: "Gold", value: AssetPriority.Gold},
  {label: "Platinum", value: AssetPriority.Platinum},
]

export const AssetPriorityIcons = {
  [AssetPriority.Lead]: {name: 'chevrons-down', color: '#247a00'},
  [AssetPriority.Bronze]: {name: 'chevron-down', color: '#00a038'},
  [AssetPriority.Silver]: {name: 'menu', color: '#915100'},
  [AssetPriority.Gold]: {name: 'chevron-up', color: '#ff4b4b'},
  [AssetPriority.Platinum]: {name: 'chevrons-up', color: '#ff8686'}
}

export interface AssetManageForm {
  id: string,
  buildingId: string;
  floorId: string;
  locationId: string;
  assetTypeId: string;
  subAssetTypeId: string;
  description: string;
  code: string;
  uniqueReference: string;
  imageBase64: string;
  troubleshootingGuide: string;
  priority: AssetPriority;
  supportingInformation: string;
}
