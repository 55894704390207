import React from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import WebPageLayout from "../../../../layouts/WebPageLayout";
import TablePageLayout from "../../../../layouts/TablePageLayout";
import {CoreTable} from "../../../../components/CoreTable";
import {Button, DataTable} from "react-native-paper";
import {Feather, MaterialIcons} from "@expo/vector-icons";
import {BaseFilters} from "../../../../../web/hooks/useFilters";
import PreventativeMaintenancesListScreenFilters from "./components/PreventativeMaintenancesListScreenFilters";
import useEntityListPage from "../../../../hooks/useEntityListPage";
import useHasPermission from "../../../../../../hooks/useHasPermission";
import {
  useLazyGetPreventativeMaintenancesQuery
} from "../../../../../../store/services/api/preventative-maintenance-api";
import {
  ApprovalStatus,
  ApprovalStatusNames,
  getApprovalStatus
} from "../../../../../../store/data/models/approval-status-form";
import {Tooltip} from "antd";

interface PreventativeMaintenancesListFilters extends BaseFilters {
  reference: string;
  description: string;
  status: string;
}

export default function PreventativeMaintenancesListScreen() {
  const {hasPermission} = useHasPermission();

  const {
    navigateToCreate,
    navigateToEdit,
    navigateToApproval,
    onSubmitFilter,
    filter,
    tableData,
    isLoading
  } = useEntityListPage<PreventativeMaintenancesListFilters>({
    entityName: "Preventative Maintenances",
    lazyTableQuery: useLazyGetPreventativeMaintenancesQuery,
    filterDefinition: {
      reference: "",
      sortedColumn: "",
      sortedColumnAscending: false,
      pageNumber: 1,
      pageSize: 10,
      description: '',
      status: ''
    },
  });

  return (
    <WebPageLayout
      header={{
        previousTab: {title: 'Dashboard', routeName: 'dashboard', replaceStack: true},
        tabs: [
          {title: 'Preventative Maintenances', routeName: 'list'}
        ]
      }}
    >
      <TablePageLayout
        filters={<PreventativeMaintenancesListScreenFilters filter={filter} onSubmit={onSubmitFilter} />}
        actions={hasPermission('CanCreatePreventativeMaintenances') && (
          <Button icon='plus' mode="elevated" onPress={navigateToCreate} buttonColor='#fff' textColor='#0f0f0f'>
            Create Preventative Maintenance
          </Button>
        )}
      >
        <CoreTable<any>
          headers={[
            {text: 'Reference', sortedName: 'referenceNumber'},
            {text: 'Description', sortedName: 'title'},
            {text: 'Status', sortedName: 'isApproved'},
            {text: 'Edit', flex: 0.5}
          ]}
          data={tableData}
          keyExtractor={(item) => item.id}
          isLoading={isLoading}
          renderItem={({item, index}) => {
            const approvalStatus = getApprovalStatus(item);
            const approvalStatusName = ApprovalStatusNames[approvalStatus];
            const showApprovalButton = hasPermission('CanApprovePreventativeMaintenances') && approvalStatus === ApprovalStatus.AwaitingApproval;
            const showEditButton = hasPermission('CanCreatePreventativeMaintenances') && approvalStatus !== ApprovalStatus.Approved;

            return (
              <DataTable.Row
                style={index % 2 === 0 ? styles.alternateRow : styles.row}
                key={index}
              >
                <DataTable.Cell>{item.reference}</DataTable.Cell>
                <DataTable.Cell>{item.title}</DataTable.Cell>
                <DataTable.Cell>
                  {approvalStatus === ApprovalStatus.Rejected ? (
                    <Tooltip title={item.rejectedReason ?? 'No reason provided'}>
                      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 6 }}>
                        <Text>{approvalStatusName}</Text>
                        <Feather name='alert-circle' size={16} color="#fab005" />
                      </View>
                    </Tooltip>
                  ) : approvalStatusName}
                </DataTable.Cell>
                <DataTable.Cell style={{flex: 0.5}}>
                  {showApprovalButton && (
                    <Tooltip title='View for Approval'>
                      <TouchableOpacity onPress={() => navigateToApproval(item.id)}>
                        <MaterialIcons name="approval" size={24} color="black" />
                      </TouchableOpacity>
                    </Tooltip>
                  )}
                  {showEditButton && (
                    <Tooltip title='Edit'>
                      <TouchableOpacity onPress={() => navigateToEdit(item.id)}>
                        <MaterialIcons name="edit" size={24} color="black" />
                      </TouchableOpacity>
                    </Tooltip>
                  )}
                </DataTable.Cell>
              </DataTable.Row>
            )
          }}
          filters={filter.filters}
          setFilter={filter.setFilter}
        >
        </CoreTable>
      </TablePageLayout>
    </WebPageLayout>
  );
}

const styles = StyleSheet.create({
  row: {
    backgroundColor: 'rgba(255,255,255,1)'
  },
  alternateRow: {
    backgroundColor: 'rgba(245,245,245,1)'
  }
});
