import MobileScreen from "../../../../../components/MobileScreen";
import React, {useEffect} from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {useLazyGetMediaForJobQuery} from "../../../../../../../store/services/api/media-api";
import {MediaList} from "../../../../../components/media/MediaList";


export function AssetMediaHelp({navigation, route}: NativeStackScreenProps<any, any>) {
  const jobId = route?.params?.jobId;
  const [getMedia, {data: mediaList, isFetching: mediaFetching}] = useLazyGetMediaForJobQuery(jobId);

  useEffect(() => {
    if (!jobId) {
      navigation.navigate("help");
      return;
    }

    getMedia(jobId);
  }, [jobId]);

  return (
    <MobileScreen>
      <MediaList
        media={mediaList}
        isLoading={mediaFetching}
        emptyListText='No media has been added for this asset'
      />
    </MobileScreen>
  );
}
