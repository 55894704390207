import {HelperText, TextInput} from "react-native-paper";
import {BaseFormControl, BaseFormControlProps} from "./BaseFormControl";
import {View} from "react-native";

interface TextControlProps<TControl> extends Omit<BaseFormControlProps<TControl>, 'render'> {
  multiline?: boolean;
}

export function TextControl<TControl>(props: TextControlProps<TControl>) {
  return (
    <BaseFormControl
      {...props}
      render={({field: {value, onChange}, fieldState: {error}}) => (
        <View>
          <TextInput
            multiline={props.multiline}
            error={!!error}
            label={props.title}
            mode='outlined'
            value={value as string}
            onChangeText={onChange}
          />
          <HelperText type="error" visible={!!error}>
            {error?.message}
          </HelperText>
        </View>
      )}
    />
  )
}
