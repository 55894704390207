import {StyleSheet, Text, View} from "react-native";
import {FileUpload, FileUploadProps} from "../file-uploader/FileUpload";
import {pickMedia} from "../../utils/media-utils";
import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {NewMedia} from "../../store/data/entities/media";
import {useUploadMediaMutation} from "../../store/services/api/media-api";
import {CoreModal, CoreModalProps} from "../../hooks/useModal";
import {CoreFormContext} from "../../screens/web/components/forms/CoreForm";
import {TextFieldController} from "../../screens/web/components/forms/TextFieldController";

interface UploadMediaModalProps {
  onSuccess: () => void;
  onClose: () => void;
  mediaPath: string;
}

export function UploadMediaModal({onSuccess, onClose, mediaPath} : UploadMediaModalProps) {
  const [uploadMedia, {isLoading: uploadLoading, isSuccess: uploadSuccess}] = useUploadMediaMutation();
  const form = useForm<NewMedia>({
    defaultValues: {
      title: '',
      description: '',
      path: mediaPath,
      file: null
    },
    mode: 'onChange'
  });

  useEffect(() => {
    if (uploadSuccess) onSuccess();
  }, [uploadSuccess]);

  const {control, handleSubmit, setValue, watch} = form;
  const selectedFile = watch('file');

  const props: FileUploadProps = {
    onRemove: () => {
      setValue('file', null);
    },
    onUploadFilesPress: async () => {
      const images = await pickMedia('both', false);
      if (images?.length === 0) return;
      setValue('file', images[0]);
    },
    fileList: selectedFile ? [selectedFile] : [],
    buttonText: 'Select Media',
    isLoading: uploadLoading
  };

  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Cancel', mode: 'text', disabled: uploadLoading, onPress: onClose}
    ],
    rightButtons: [
      {label: 'Finish', mode: 'outlined', disabled: uploadLoading, onPress: handleSubmit(uploadMedia)},
    ]
  }

  return (
    <CoreModal {...modalProps}>
      <View style={styles.container}>
        <Text style={styles.title}>Upload Media</Text>
        <Controller
          control={control}
          rules={{required: 'Please select a file'}}
          name='file'
          disabled={uploadLoading}
          render={({field: {value}, fieldState: {error}}) => (
            <View style={styles.fileUploadButton}>
              <FileUpload {...props} />
              {error && !value && <Text style={{color: 'red'}}>{error.message}</Text>}
            </View>
          )}
        />

        <CoreFormContext.Provider value={{form}}>
          <TextFieldController controlName='title' title='Title' />
          <TextFieldController controlName='description' title='Description' />
        </CoreFormContext.Provider>
      </View>
    </CoreModal>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingBottom: 12,
  },
  title: {
    fontSize: 18,
    marginBottom: 12,
  },
  input: {
    marginBottom: 10,
  },
  button: {
    marginTop: 10,
  },
  fileUploadButton: {
    marginBottom: 12
  }
});
