import { baseApi } from "./base-api";
import {
  MaintenanceChecklist
} from "../../data/entities/maintanence-checklist";
import {MaintenanceJob, MaintenanceJobForm} from "../../data/entities/maintenance-job";

export const maintenanceJobApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    updateMaintenanceJob: build.mutation<void, MaintenanceJobForm>({
      query: (dto) => ({
        url: `maintenanceJobs/updateMaintenanceJob`,
        method: 'POST',
        body: dto
      })
    }),
    getChecklistForJob: build.query<MaintenanceChecklist, string>({
      query: (jobId) => ({
        url: `maintenanceJobs/getChecklistForJob/${jobId}`
      })
    }),
    getMaintenanceJob: build.query<MaintenanceJob, string>({
      query: (jobId) => ({
        url: `maintenanceJobs/getMaintenanceJob/${jobId}`
      })
    }),
  }),
  overrideExisting: true,
});

export const {
  useUpdateMaintenanceJobMutation,
  useLazyGetChecklistForJobQuery,
  useGetMaintenanceJobQuery,
  useLazyGetMaintenanceJobQuery
} = maintenanceJobApi;
