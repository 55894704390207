import {useNavigation} from "@react-navigation/native";
import useFilters, {BaseFilters} from "../../web/hooks/useFilters";
import {useEffect} from "react";
import {useToast} from "../../../components/toast/ToastProvider";

interface UseManagePageProps<T extends BaseFilters> {
  entityName: string;
  lazyTableQuery: any;
  filterDefinition: T;
  deleteEntityMutation?: any;
}

export default function useEntityListPage<T extends BaseFilters>(props: UseManagePageProps<T>) {
  const navigation = useNavigation<any>();

  const [getTableData, {data, isFetching: tableLoading, isError: tableError}] = props.lazyTableQuery();
  const [deleteEntity, deleteResult] = props?.deleteEntityMutation?.() ?? [];
  const loadTable = (filters?: Record<string, any>) => getTableData(filters ?? getFilterValues());

  const {show: showToast} = useToast();

  useEffect(() => {
    loadTable();
  }, []);

  const filter = useFilters<T>(props.filterDefinition, loadTable);
  const {getFilterValues} = filter;

  const navigateToEdit = (id: string) => navigation.navigate('manage', {id});
  const navigateToApproval = (id: string) => navigation.navigate('manage', {id, approval: true});
  const navigateToCreate = () => navigation.navigate('manage');

  useEffect(() => {
    if (tableError) {
      showToast('Error getting table', `There was an error getting ${props.entityName} data`, 'error');
    }
    if (deleteResult?.isError) {
      showToast(`Error deleting ${props.entityName}`, `There was an error deleting ${props.entityName}`, 'error');
    }
  }, [tableError, deleteResult?.isError]);

  const handleOnDeleteEntity = async <K extends {id: string},>(
    data: K,
    condition?: (data: K) => boolean,
    conditionMessage?: string) => {
    if (!confirm(`Are you sure you want to delete this ${props.entityName}?`)) return;
    if (condition !== undefined && !condition(data)) {
      alert(conditionMessage ?? `There was an issue deleting this ${props.entityName}`);
      return;
    }

    await deleteEntity(data.id)
      .unwrap()
      .then(() => loadTable());
  }

  return {
    tableData: data,
    navigateToEdit,
    navigateToCreate,
    navigateToApproval,
    deleteEntity: handleOnDeleteEntity,
    onSubmitFilter: () => loadTable(),
    filter,
    isLoading: tableLoading
  }
}
