import {CoreModal, CoreModalProps} from "../../../../hooks/useModal";
import {View} from "react-native";
import * as React from "react";
import UpdatesList from "../../../web/screens/Updates/UpdatesList";
import {TicketViewModel} from "../../../../store/data/models/ticket-view-model";

export default function TicketUpdatesModal({ticket, onClose}: {ticket: TicketViewModel, onClose: () => void}) {
  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Cancel', onPress: onClose, mode: 'text'},
    ]
  }

  return (
    <CoreModal {...modalProps}>
      <View style={{minWidth: 500}}>
        <UpdatesList ticket={ticket} onClear={onClose} />
      </View>
    </CoreModal>
  )
}
