import {useAddWorkOrderLabourMutation} from "../../../store/services/api/work-order-api";
import React, {useEffect} from "react";
import {DatePicker, Form, InputNumber, Modal, TimePicker} from 'antd';
import UserSelect from "../../../components/forms/controls/UserSelect";
import {WorkOrderLabourForm} from "../../../store/data/entities/work-order-labour";
import {Dayjs} from "dayjs";

interface AddWorkOrderLabourModalProps {
  workOrderId: string;
  technicianId?: string;
  onSuccess?: () => void;
  onClose: () => void;
  visible: boolean;
}

type LabourFormWithDayjs = Omit<WorkOrderLabourForm, 'date' | 'startDateTime' | 'endDateTime'> & {
  date: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
}

const format = 'HH:mm';

export default function AddWorkOrderLabourModal(props: AddWorkOrderLabourModalProps) {
  const [addLabour, {isLoading}] = useAddWorkOrderLabourMutation();

  useEffect(() => {
    form.resetFields();
    form.setFieldValue('userId', props?.technicianId);
  }, [props.visible])

  const [form] = Form.useForm<LabourFormWithDayjs>();

  const calculateHoursWorked = (startTime?: Dayjs, endTime?: Dayjs) => {
    if (startTime && endTime) {
      const duration = endTime.diff(startTime, "minutes");
      return duration > 0 ? (duration / 60).toFixed(2) : 0;
    }
    return 0;
  };

  const handleOnSubmit = async (data: LabourFormWithDayjs) => {
    const startDateTime = data.date
      .hour(data.startTime.hour())
      .minute(data.startTime.minute())
      .second(0);

    const endDateTime = data.date
      .hour(data.endTime.hour())
      .minute(data.endTime.minute())
      .second(0);

    addLabour({
      ...data,
      workOrderId: props.workOrderId,
      date: data.date.format(),
      startDateTime: startDateTime.format(),
      endDateTime: endDateTime.format(),
    }).unwrap()
      .then(props.onSuccess)
      .finally(props.onClose)
  };

  const handleValuesChange = (changedValues: Partial<LabourFormWithDayjs>, allValues: LabourFormWithDayjs) => {
    if (changedValues.startTime || changedValues.endTime) {
      const { startTime, endTime } = allValues;
      const calculatedHours = calculateHoursWorked(startTime, endTime);
      form.setFieldValue("hoursWorked", calculatedHours);
    }
  };


  return (
    <Modal
      centered
      loading={isLoading}
      title="Add Work Labour"
      open={props.visible}
      onOk={() => form.submit()}
      onCancel={props.onClose}
    >
      <Form
        style={{
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
        form={form}
        onFinish={handleOnSubmit}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name='userId'
          label='Technician'
          rules={[{required: true, message: "Technician is required"}]}
        >
          <UserSelect userRoles={['Technician']} disabled={isLoading} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='date'
          label='Date'
          rules={[{required: true, message: "Date is required"}]}
        >
          <DatePicker disabled={isLoading} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='startTime'
          label='Start Time'
          rules={[
            {
              required: true,
              message: "Start time is required",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const endTime = getFieldValue("endTime");
                if (!value || !endTime || value < endTime) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Start time must be earlier than end time")
                );
              },
            }),
          ]}
        >
          <TimePicker format={format} disabled={isLoading} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='endTime'
          label='End Time'
          rules={[{required: true, message: "End Time is required"}]}
        >
          <TimePicker format={format} disabled={isLoading} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='hoursWorked'
          label='Hours'
          rules={[{required: true, message: "Hours are required"}]}
        >
          <InputNumber disabled={isLoading} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
