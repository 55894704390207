import {WorkOrderLabour} from "./work-order-labour";
import {WorkOrderLog} from "./work-order-log";
import {WorkOrderAsset} from "./work-order-asset";
import {BaseAuditEntity} from "./base-audit-entity";
import {Asset} from "./asset";

export interface WorkOrder extends BaseAuditEntity {
  id: string;
  parentWorkOrderId?: string;
  parentWorkOrderReference?: string;

  ticketId?: string;
  technicianId: string;
  buildingLeadId: string;
  technician: string;
  buildingLead: string;
  reference: string;
  description: string;
  locationLabel: string;
  type: WorkOrderType;
  status: WorkOrderStatus;
  priority: WorkOrderPriority;
  priorityScore: number;

  scheduledStartDate: string;
  scheduledFinishDate: string;
  actualStartDate: string;
  actualFinishDate: string;

  closedOn: string;
  reopenedOn: string;
  reopenedReason?: string;

  labour: WorkOrderLabour[];
  logEntries: WorkOrderLog[];
  assets: WorkOrderAsset[];
  assignedAssets: Asset[];

  assetLocations: WorkOrderAssetLocation[];
}

interface WorkOrderAssetLocation {
  building: string;
  floor: string;
  location: string;
}

export enum WorkOrderPriority {
  Standard,
  Important,
  Urgent,
  Emergency
}

export const workOrderPriorityOptions = [
  {label: "Standard", value: WorkOrderPriority.Standard},
  {label: "Important", value: WorkOrderPriority.Important},
  {label: "Urgent", value: WorkOrderPriority.Urgent},
  {label: "Emergency", value: WorkOrderPriority.Emergency}
]

export const WorkOrderPriorityIcons = {
  [WorkOrderPriority.Standard]: {name: 'chevrons-down', color: '#80BAB5'},
  [WorkOrderPriority.Important]: {name: 'chevron-down', color: '#F2CB2E'},
  [WorkOrderPriority.Urgent]: {name: 'chevron-up', color: '#A42821'},
  [WorkOrderPriority.Emergency]: {name: 'chevrons-up', color: '#58181F'}
}

export const WorkOrderPriorityScoreIcons = {
  [10]: {color: '#58181F', name: 'alert-triangle'},
  [9]: {color: '#A42821', name: 'chevrons-up'},
  [8]: {color: '#BC3823', name: 'chevron-up'},
  [7]: {color: '#FF9C00', name: 'minus'},
  [6]: {color: '#F2CB2E', name: 'chevron-down'},
  [5]: {color: '#80BAB5', name: 'chevrons-down'},
}

export enum WorkOrderType {
  Preventative,
  Corrective,
  Improvement
}

export const workOrderOptions = [
  {label: 'Preventative', value: WorkOrderType.Preventative, disabled: true},
  {label: 'Corrective', value: WorkOrderType.Corrective},
  {label: 'Improvement', value: WorkOrderType.Improvement}
];

export enum WorkOrderStatus {
  PendingApproval,
  Approved,
  Rejected,
  InProgress,
  Completed,
  Cancelled,
  Closed
}

export interface WorkOrderForm {
  id?: string;
  parentWorkOrderId?: string;
  reference: string;
  technicianId: string;
  buildingLeadId: string;
  ticketId?: string;
  description: string;
  type: WorkOrderType;
  priority: WorkOrderPriority;

  scheduledStartDate: string;
  scheduledFinishDate: string;

  assets: string[];
  assetCascaderSelections: string[][];
}

export interface WorkOrderUpdateForm {
  id: string;
  buildingLeadId: string;
  technicianId: string;
  scheduledStartDate: string;
  scheduledFinishDate: string;
  assets: string[];
}

export interface WorkOrderStatusForm {
  workOrderId: string;
  status: WorkOrderStatus;
  reason?: string;
}
