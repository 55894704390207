import * as React from 'react';
import {useEffect} from 'react';
import {View, Text, TouchableOpacity, Alert, Platform} from 'react-native';
import {DataTable} from 'react-native-paper';
import {useIsFocused} from "@react-navigation/native";
import {Entypo, Ionicons} from '@expo/vector-icons';
import GlobalStyle from '../../../../constants/GlobalStyle';
import {
  useDeleteUserMutation,
  useLazyGetUsersQuery
} from '../../../../store/services/api/user-api';
import {CoreTable} from "../../components/core-table/CoreTable";
import useFilters, {BaseFilters} from "../../hooks/useFilters";
import {WebScreen} from "../../components/WebScreen";
import {
  SearchFilterRow,
  SearchFilters,
  TextInputFilter
} from "../../components/search-filters/SearchFilters";
import {useToast} from "../../../../components/toast/ToastProvider";
import {User} from "../../../../store/data/entities/user";

interface WebUserListFilters extends BaseFilters {
  name: string;
}

export default function WebUserList({navigation}: { navigation: any }) {
  const [getUsersList, {data: usersList, isFetching: usersListFetching, isError}] = useLazyGetUsersQuery();
  const loadTable = (filters?: Record<string, any>) => getUsersList(filters ?? getFilterValues());

  const [deleteUser, {isError: isDeleteUserError}] = useDeleteUserMutation();
  const isFocused = useIsFocused();

  const filter = useFilters<WebUserListFilters>({
    name: "",
    sortedColumn: "",
    sortedColumnAscending: false,
    pageNumber: 1,
    pageSize: 10
  }, loadTable);
  const {getFilterValues, setFilter, filters} = filter;

  useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [isFocused]);

  const {show: showToast} = useToast();

  useEffect(() => {
    if (isError || isDeleteUserError) {
      showToast('Error getting table', 'There was an error getting user data', 'error');
    }
  }, [isError, isDeleteUserError]);

  const remove = async (entityId) => {
    if (Platform.OS == 'web') {
      if (confirm("Are you sure you want to delete this user?")) {
        var result = await deleteUser(entityId) as any;
        if (result.error) {
          Alert.alert("Error", "Something went wrong. Please try again");
          return;
        }

        loadTable();
      }
    }
  }

  return (
    <WebScreen>
      <SearchFilters filter={filter} onSubmit={() => loadTable()}>
        <SearchFilterRow>
          <TextInputFilter title='Name' name='name' />
        </SearchFilterRow>
      </SearchFilters>

      <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
          <Ionicons name="add" size={24} color="white"/><Text style={GlobalStyle.form__submit__button__text}>Add New User</Text>
        </View>
      </TouchableOpacity>

      <CoreTable<User>
        filters={filters}
        setFilter={setFilter}
        isLoading={usersListFetching}
        data={usersList}
        headers={[
          {text: 'Name', sortedName: 'name', visible: true},
          {text: 'Email', sortedName: 'email', visible: true},
          {text: 'Options', visible: true}
        ]}
        renderItem={({item}) => (
          <DataTable.Row key={item.id}>
            <DataTable.Cell>{item.name}</DataTable.Cell>
            <DataTable.Cell>{item.email}</DataTable.Cell>
            <DataTable.Cell>
              <View style={{flexDirection: 'row'}}>
                <TouchableOpacity onPress={() => navigation.navigate("manage", {userId: item.id})}>
                  <Entypo name="pencil" size={24} color="black"/>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => remove(item.id)} style={{marginLeft: 20}}>
                  <Ionicons name="ios-remove-circle" size={24} color="red"/>
                </TouchableOpacity>
              </View>
            </DataTable.Cell>
          </DataTable.Row>
        )}
      />
    </WebScreen>
  );
}
