import MobilePageLayout from "../../../layouts/MobilePageLayout";
import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AdminStackParamList} from "../AdminStack";
import React, {useEffect} from "react";
import {useLazyGetTicketByAssetQuery} from "../../../../../store/services/api/ticket-api";
import {useSetLoading} from "../../../../../hooks/useSetLoading";
import {Card, TextInput} from "react-native-paper";
import Table from "../../../../web-v2/components/Table";

export default function AdminAssetStatusScreen({route}: ScreenProps<AdminStackParamList, 'assetStatus'>) {
  const assetId = route.params?.assetId;
  const [getTicket, {data: ticket, isFetching}] = useLazyGetTicketByAssetQuery();

  useSetLoading([isFetching]);

  useEffect(() => {
    if (assetId) {
      getTicket(assetId);
    }
  }, [assetId]);

  return (
    <MobilePageLayout>
      <Card style={{backgroundColor: 'white'}}>
        <Card.Content style={{flexDirection: 'column', gap: 12}}>
          <TextInput
            value={ticket?.number ?? ''}
            label='Ticket Number'
            mode='outlined'
            disabled
          />
          <TextInput
            value={ticket?.status ?? ''}
            label='Ticket Status'
            mode='outlined'
            disabled
          />
          <TextInput
            value={ticket?.description ?? ''}
            label='Ticket Description'
            mode='outlined'
            disabled
            multiline
            numberOfLines={3}
          />

          <Table<any>
            columns={[
              {title: 'Date', key: 'dateCreated'},
              {title: 'Update', key: 'text'}
            ]}
            data={ticket?.updates ? ticket.updates : []}
            keyExtractor={(item) => item.id}
          />
        </Card.Content>
      </Card>
    </MobilePageLayout>
  )
}
