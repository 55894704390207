import MobileScreen from "../../../../components/MobileScreen";
import CoreForm from "../../../../../web/components/forms/CoreForm";
import {TextFieldController} from "../../../../../web/components/forms/TextFieldController";
import {PickerFieldController} from "../../../../../web/components/forms/PickerFieldController";
import {
  JobPriority,
  JobType,
  JobTypes,
  NewMaintenanceJob,
  NewTicketJob
} from "../../../../../../store/data/entities/job";
import {DateFieldController} from "../../../../../web/components/forms/DateFieldController";
import {ComboTextFieldController} from "../../../../../web/components/forms/ComboTextFieldController";
import * as React from "react";
import {useForm} from "react-hook-form";
import moment from "moment";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../store";
import {
  useLazyGetAssetQuery,
  useLazyGetChecklistsForAssetQuery,
  useLazySearchAssetQuery
} from "../../../../../../store/services/api/asset-api";
import {useEffect} from "react";
import {useToast} from "../../../../../../components/toast/ToastProvider";
import {debounce} from "../../../../../../utils/debounce";
import {MobileButtons} from "../../../../components/MobileButtons";
import {useCreateAdhocMaintenanceJobMutation} from "../../../../../../store/services/api/job-api";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {useSetLoading} from "../../../../../../hooks/useSetLoading";

export function CreateJob({navigation}: NativeStackScreenProps<any, any>) {
  const {user} = useSelector((state: RootState) => state.user);
  const [getChecklists, {data: checklists, isFetching: checklistsFetching}] = useLazyGetChecklistsForAssetQuery();
  const [searchAssets, {data: assets, isFetching: assetsFetching}] = useLazySearchAssetQuery();
  const [getAsset, {data: selectedAsset, isFetching: selectedAssetFetching}] = useLazyGetAssetQuery();
  const [createMaintenanceJob, {
    data: newMaintenanceJob,
    isLoading: maintenanceJobLoading,
    isSuccess: maintenanceJobSuccess,
    isError: isMaintenanceJobError, }] = useCreateAdhocMaintenanceJobMutation();

  const {show} = useToast();

  useSetLoading([maintenanceJobLoading]);

  useEffect(() => {
    if (maintenanceJobSuccess) {
      navigation.navigate("maintenance-job", {
        screen: 'job',
        params : {jobId: newMaintenanceJob.id}
      });
    }
  }, [maintenanceJobSuccess]);

  useEffect(() => {
    if (isMaintenanceJobError) {
      show('Error creating job', 'There was an error creating the job', 'error');
    }
  }, [isMaintenanceJobError]);

  const form = useForm<NewTicketJob | NewMaintenanceJob>({
    defaultValues: {
      reference: '',
      plannedDate: moment().toISOString(),
      type: JobType.Maintenance,
      priority: JobPriority.P1,
      technicianId: user.id,
      issue: '',
      contactEmail: '',
      assetId: '',
      checklistId: ''
    },
    mode: "onChange"
  });
  const { watch, getValues } = form;

  const selectedJobType = watch('type');
  const selectedAssetId = watch('assetId');

  useEffect(() => {
    if (selectedAssetId) {
      getAsset(selectedAssetId);
      getChecklists(selectedAssetId);
    }
  }, [selectedAssetId]);


  const handleOnFormSubmit = async () => {
    const valid = await form.trigger();
    if (!valid) return;

    if (selectedJobType == JobType.Maintenance) {
      const formData = getValues() as NewMaintenanceJob;
      createMaintenanceJob(formData);
    }
  }

  const debouncedSearchAssets = React.useMemo(
    () => debounce((value) => {
      if (value?.length >= 3) {
        searchAssets(value)
      }
    }, 600),
    [searchAssets]
  );

  const mapAssetToOption = (asset: any) => {
    const label = asset?.uniqueRef ? `(${asset?.uniqueRef}) ${asset?.code}` : asset?.code

    return ({
      key: asset?.id,
      value: asset?.id,
      label: label
    })
  };

  const assetOptions = assets?.map(mapAssetToOption) ?? [];

  return (
    <MobileScreen>
      <CoreForm form={form} footer={null}>
        <CoreForm.Row>
          <TextFieldController required controlName='reference' title='Job Reference' />
          <PickerFieldController
            disabled
            controlName='technicianId'
            title='Technician'
            items={[{
              label: user.name,
              value: user.id,
            }]} />
          <PickerFieldController
            disabled
            controlName='type'
            title='Job Type'
            items={JobTypes.filter(jt => jt.value === JobType.Maintenance)}
          />
          <DateFieldController
            controlName='plannedDate'
            title='Planned Date'
            required
          />
        </CoreForm.Row>
          <CoreForm.Row>
            <ComboTextFieldController
              initialSelection={mapAssetToOption(selectedAsset)}
              optionsLoading={assetsFetching || selectedAssetFetching}
              onTextChanged={debouncedSearchAssets}
              options={assetOptions}
              controlName='assetId'
              title='Asset'
              required
            />
            <PickerFieldController
              required
              controlName='checklistId'
              title='Checklist' items={(checklists ?? []).map(item => ({
                key: item?.id,
                value: item?.id,
                label: item.title
              }))}
            />
          </CoreForm.Row>
      </CoreForm>
      <MobileButtons buttons={[
        {label: 'Finish', onPress: handleOnFormSubmit},
      ]} />
    </MobileScreen>
  )
}
