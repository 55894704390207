import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import { DataTable } from 'react-native-paper';
import Spinner from 'react-native-loading-spinner-overlay';
import { PagedTableDto } from "../../../../store/services/api/base-api";
import { ticketApi } from "../../../../store/services/api/ticket-api";

import { Entypo } from '@expo/vector-icons';

export default function UpdatesListScreen({ ticket, onClear }) {
  const [loading, setLoading] = useState<any>(false);
  const [page, setPage] = React.useState<number>(0);
  const [trigger, updatesResult] = ticketApi.useLazyGetUpdatesQuery();

  useEffect(() => {
    loadTable();
  }, [page, ticket])

  const loadTable = () => {
    trigger(new PagedTableDto(page, {
      ticketId: ticket?.id,
    })).then(() => {
      setLoading(false);
    });
  }
  return (
    <View>
      <Spinner
        visible={loading}
        textContent={'Loading...'}
        textStyle={GlobalStyle.spinnerTextStyle}
      />
      <TouchableOpacity onPress={() => onClear()}>
        <Text style={GlobalStyle.add__new__link}>
          <Entypo name="chevron-left" size={12} color="black" style={{ marginRight: 10 }} />
          Updates for ticket # {ticket?.number}
        </Text>
      </TouchableOpacity>
      <View style={{ flex: 1 }}>
        <DataTable style={GlobalStyle.table}>
          <DataTable.Header>
            <DataTable.Title>Date</DataTable.Title>
            <DataTable.Title>Description</DataTable.Title>
          </DataTable.Header>
          {updatesResult?.data?.list.map((element: any) => {
            return (
              <DataTable.Row key={element.id}>
                <DataTable.Cell>{element.dateCreated}</DataTable.Cell>
                <DataTable.Cell>{element.text}</DataTable.Cell>
              </DataTable.Row>
            )
          })}
        </DataTable>
      </View>
    </View>
  );
}
