import {StyleSheet, View} from "react-native";
import {Badge, Button, ButtonProps} from "react-native-paper";

interface BadgeButtonProps extends ButtonProps {
  badgeValue?: number;
}

export default function BadgeButton(props: BadgeButtonProps) {
  return (
    <View style={styles.buttonContainer}>
      <Button {...props}>{props.children}</Button>
      {props?.badgeValue !== undefined && (
        <Badge style={styles.badge}>{props.badgeValue}</Badge>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    top: -5,
    right: 0
  }
})
