import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import WebPageLayout from "../../../../layouts/WebPageLayout";
import {PreventativeMaintenanceStackParamList} from "../../PreventativeMaintenancesStack";
import * as React from "react";
import PreventativeMaintenanceManageForm from "./PreventativeMaintenanceManageForm";
import {View} from "react-native";
import GlobalStyle from "../../../../../../constants/GlobalStyle";

export default function PreventativeMaintenanceManageScreen({route, navigation}: ScreenProps<PreventativeMaintenanceStackParamList, 'manage'>) {
  const id = route?.params?.id;
  const isApproval = route?.params?.approval ?? false;

  return (
    <WebPageLayout header={{
      previousTab: {title: 'Preventative Maintenances', routeName: 'list'},
      tabs: [
        {title: 'Manage', routeName: 'manage', params: {id}}
      ]
    }}>
      <View style={GlobalStyle.formContainer}>
        <PreventativeMaintenanceManageForm
          onSuccess={() => navigation.navigate("list")}
          isApproval={isApproval}
          preventativeMaintenanceId={id}
        />
      </View>
    </WebPageLayout>
  )
}
