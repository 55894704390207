import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {MaintenanceChecklistList} from "./MaintenanceChecklistList";
import MaintenanceChecklistManage from "./MaintenanceChecklistManage";

const Stack = createNativeStackNavigator();

export default function MaintenanceChecklistNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={MaintenanceChecklistList} options={{ headerShown: false, title: 'Checklists' }} />
      <Stack.Screen name="manage" component={MaintenanceChecklistManage} options={{ headerShown: false, title: 'Manage Checklist' }} />
    </Stack.Navigator>
  )
}
