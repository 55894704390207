import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AssetStackParamList} from "../AssetStack";
import MobilePageLayout from "../../../layouts/MobilePageLayout";
import {useLazyGetAssetQuery} from "../../../../../store/services/api/asset-api";
import {useEffect, useState} from "react";
import {useSetLoading} from "../../../../../hooks/useSetLoading";
import {Controller, useForm} from "react-hook-form";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import GlobalStyle, {PRIMARY_COLOUR, STAR_COLOUR} from "../../../../../constants/GlobalStyle";
import * as React from "react";
import {Ionicons} from "@expo/vector-icons";
import {useAddFeedbackMutation} from "../../../../../store/services/api/feedback-api";
import {useToast} from "../../../../../components/toast/ToastProvider";
import MobileForm from "../../../layouts/MobileForm";
import {TextFormControl} from "./components/AssetSetupForm";
import {TextInput} from "react-native-paper";

export default function AssetFeedbackScreen({route, navigation}: ScreenProps<AssetStackParamList, 'feedback'>) {
  const assetId = route.params?.assetId;
  const [getAsset, { data: asset, isFetching: assetFetching }] = useLazyGetAssetQuery();
  const [addFeedback, {isLoading: feedbackLoading}] = useAddFeedbackMutation();
  const [ratingError, setRatingError] = useState<string | undefined>(undefined);

  const form = useForm({
    defaultValues: {
      assetId: assetId,
      starRating: 0,
      itemDescription: '',
      description: "",
      contactEmail: ""
    },
  });

  const {show} = useToast();
  useSetLoading([assetFetching, feedbackLoading]);

  useEffect(() => {
    if (assetId) {
      getAsset(assetId)
        .unwrap()
        .then((asset) => form.setValue('itemDescription', asset.description));
    }
  }, [assetId]);

  const handleOnSubmit = () => {
    setRatingError(undefined);
    form.handleSubmit(onSubmit)();
  }

  const onSubmit = async (data: any) => {
    if (data.starRating === 0) {
      setRatingError("Please select a star rating");
      return;
    }

    addFeedback(data)
      .unwrap()
      .then(() => {
        show('Success', 'Successfully submitted feedback', 'success');
        navigation.navigate("asset", { uniqueRef: asset.uniqueReference });
      });
  }

  return (
    <MobilePageLayout>
      <MobileForm onSubmit={handleOnSubmit}>
        <TextInput
          label='Item Description'
          mode='outlined'
          multiline
          numberOfLines={3}
          disabled
        />
        <TextFormControl
          title='Feedback'
          control={form.control}
          controlName='description'
          multiline={true}
          numberOfLines={8}
        />
        <RatingsFieldController control={form.control} />
        {ratingError && (
          <Text style={GlobalStyle.form__input__validation}>
            {ratingError}
          </Text>
        )}
        <TextFormControl
          title='Contact Email'
          control={form.control}
          controlName='contactEmail'
        />

      </MobileForm>
    </MobilePageLayout>
  )
}

function RatingsFieldController({control}) {
  return (
    <Controller
      control={control}
      name='starRating'
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <View>
          <Text style={GlobalStyle.form__column__text}>Star Rating</Text>
          <View style={styles.starFieldControl}>
            {Array.from({length: 5}).map((_, index) => (
              <TouchableOpacity
                key={`star-feedback-${index}`}
                onPress={() => {
                  onChange(index + 1);
                }}
              >
                <Ionicons
                  name={(index + 1) <= value ? "star" : "star-outline"}
                  size={30}
                  color={(index + 1) <= value ? STAR_COLOUR : PRIMARY_COLOUR}
                />
              </TouchableOpacity>
            ))}
          </View>
          {error && <Text style={{color: 'red'}}>{error.message}</Text>}
        </View>
      )}
    />
  )
}

const styles = StyleSheet.create({
  starFieldControl: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingHorizontal: 12,
    fontFamily: 'OpenSans-Regular',
    padding: 5,
    marginTop: 5,
  }
})
