import {ScrollView, StyleSheet} from "react-native";
import {ReactNode} from "react";

export default function ManagePageLayout({children}: {children: ReactNode}) {
  return (
    <ScrollView style={styles.container}>
      {children}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: "#d9d9d9",
    borderRadius: 6,
    backgroundColor: "#fff",
    overflow: "hidden",
    flex: 1,
  },
})
