import {CoreFilters, CoreFiltersBaseProps} from "../../../../components/CoreFilters";
import {
  DateFilter,
  DropdownFilter,
  TextInputFilter
} from "../../../../../web/components/search-filters/SearchFilters";
import {Picker} from "@react-native-picker/picker";
import React, {useMemo, useState} from "react";
import moment from "moment/moment";
import {useGetAllBuildingsQuery, useGetAllFloorsQuery} from "../../../../../../store/services/api/building-api";
import {
  useGetAllAssetTypesQuery,
  useGetAllSubAssetTypesQuery
} from "../../../../../../store/services/api/asset-type-api";

export default function TicketsListScreenFilters({filter, onSubmit}: CoreFiltersBaseProps) {
  const { data: buildings } = useGetAllBuildingsQuery(null);
  const { data: floors } = useGetAllFloorsQuery(null);
  const { data: assetTypes } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes } = useGetAllSubAssetTypesQuery(null);

  const selectedBuildingId = filter.getFilter('buildingId');
  const selectedAssetTypeId = filter.getFilter('assetTypeId');

  const filteredFloors = useMemo(() => {
    if (!selectedBuildingId) return [];
    return floors?.filter(item => item.buildingId === selectedBuildingId) ?? [];
  }, [selectedBuildingId]);

  const filteredSubAssetTypes = useMemo(() => {
    if (!selectedAssetTypeId) return [];
    return subAssetTypes?.filter(item => item.assetTypeId === selectedAssetTypeId) ?? [];
  }, [selectedAssetTypeId]);

  const handleOnBuildingChange = (buildingId: string) => {
    filter.setFilter('buildingId', buildingId);
    filter.setFilter('floorId', '');
  }

  const handleOnAssetTypeChange = (assetTypeId: string) => {
    filter.setFilter('assetTypeId', assetTypeId);
    filter.setFilter('subAssetTypeId', '');
  }

  const [dateRange, setDateRange] = useState("3");
  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate: moment.MomentInput;
    const endDate = moment();

    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    filter.setFilter('startDate', moment(startDate).toISOString());
    filter.setFilter('endDate', moment(endDate).toISOString());
  }

  return (
    <CoreFilters
      filter={filter}
      onSubmit={onSubmit}
      onResetFilters={() => updateDateRange("3")}
    >
      <TextInputFilter title='Ticket Number' name='ticketNumber'/>
      <TextInputFilter title='Ticket Description' name='ticketDescription'/>
      <TextInputFilter title='Asset Reference' name='assetReference'/>

      <DropdownFilter title='Building' name='buildingId' onValueChange={handleOnBuildingChange}>
        <Picker.Item label='All' value='' />
        {(buildings ?? []).map(item =>
          <Picker.Item key={item.id} label={item.name} value={item.id} />
        )}
      </DropdownFilter>
      <DropdownFilter title='Floor' name='floorId' >
        <Picker.Item label='All' value='' />
        {filteredFloors.map(item =>
          <Picker.Item key={item.id} label={item.name} value={item.id} />
        )}
      </DropdownFilter>
      <DropdownFilter title='Asset Type' name='assetTypeId' onValueChange={handleOnAssetTypeChange}>
        <Picker.Item label='All' value='' />
        {(assetTypes ?? []).map(item =>
          <Picker.Item key={item.id} label={item.name} value={item.id} />
        )}
      </DropdownFilter>
      <DropdownFilter title='Sub Asset Type' name='subAssetTypeId' >
        <Picker.Item label='All' value='' />
        {filteredSubAssetTypes.map(item =>
          <Picker.Item key={item.id} label={item.name} value={item.id} />
        )}
      </DropdownFilter>

      <DropdownFilter title='Date Range' value={dateRange} onValueChange={updateDateRange}>
        <Picker.Item label="Predefined Date Range" value=""/>
        <Picker.Item label="1 Week" value="-1"/>
        <Picker.Item label="1 Month" value="1"/>
        <Picker.Item label="3 Months" value="3"/>
        <Picker.Item label="6 Months" value="6"/>
        <Picker.Item label="12 Months" value="12"/>
      </DropdownFilter>

      <DateFilter title='Start Date' name='startDate'/>
      <DateFilter title='End Date' name='endDate'/>
      <TextInputFilter title='Contact Email' name='contactEmail'/>

      <DropdownFilter title='Updates' name='hasUpdates'>
        <Picker.Item label="All" value=""/>
        <Picker.Item label="Has Updates" value={true}/>
        <Picker.Item label="No Updates" value={false}/>
      </DropdownFilter>

      <DropdownFilter title='Status' name='status'>
        <Picker.Item label="All" value="All"/>
        <Picker.Item label="Open" value="Open"/>
        <Picker.Item label="Closed" value="Closed"/>
      </DropdownFilter>
    </CoreFilters>
  )
}
