import {Button, DatePicker, Form, Input, Select, Skeleton} from "antd";
import {
  WorkOrderForm,
  workOrderOptions,
  workOrderPriorityOptions, WorkOrderType, WorkOrderUpdateForm
} from "../../../../../../store/data/entities/work-order";
import {useGetUsersOfRoleQuery} from "../../../../../../store/services/api/user-api";
import React, {useEffect, useState} from "react";
import AssetFormControl from "../../../../../../components/form-controls/AssetFormControl";
import {
  useCreateWorkOrderMutation,
  useLazyGetWorkOrderQuery,
  useUpdateWorkOrderMutation
} from "../../../../../../store/services/api/work-order-api";
import dayjs from "dayjs";
import {useLazyGetAssetOptionsQuery} from "../../../../../../store/services/api/asset-api";
import WorkOrderApprovalModal from "./WorkOrderApprovalModal";

interface WorkOrderManageFormProps {
  onSuccess?: () => void;
  workOrderId?: string | undefined;
  ticketId?: string | undefined;
  isApproval?: boolean;
}

export default function WorkOrderManageForm(props: WorkOrderManageFormProps) {
  const [createWorkOrder, {isLoading: createLoading}] = useCreateWorkOrderMutation();
  const [updateWorkOrder, {isLoading: updateLoading}] = useUpdateWorkOrderMutation();

  const [getAssetOptions, {data: initialAssetOptions, isFetching: initialAssetOptionsLoading}] = useLazyGetAssetOptionsQuery();
  const [getWorkOrder, {data: initialFormData, isFetching: initialFormDataLoading}] = useLazyGetWorkOrderQuery();

  const {data: technicians, isFetching: techniciansFetching} = useGetUsersOfRoleQuery(['Technician']);
  const {data: buildingLeads, isFetching: buildingLeadsFetching} = useGetUsersOfRoleQuery(['BuildingLead']);
  const optionsLoading = techniciansFetching || buildingLeadsFetching;

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [form] = Form.useForm();

  const handleOnFinish = (data: any) => {
    const formData = convertFormData(data);

    if (props.workOrderId) {
      updateWorkOrder(formData as WorkOrderUpdateForm)
        .then(() => {
          props.onSuccess?.();
        })
      return;
    }

    createWorkOrder(formData)
      .then(() => {
        props.onSuccess?.();
      })
  }

  const mapUserOptions = (usersList: any) =>
    (usersList ?? []).map(item => ({
      label: item.name,
      value: item.id
    }));

  const convertFormData = (data): WorkOrderForm => ({
    ...data,
    assets: data.assets?.map(item => item.value) ?? [],
  });

  useEffect(() => {
    if (!props.workOrderId) return;
    getWorkOrder(props.workOrderId)
      .unwrap()
      .then((workOrder) => {
        getAssetOptions(workOrder.assignedAssets.map(item => item.id))
      });
  }, [props.workOrderId]);

  useEffect(() => {
    if (!initialFormData) return;

    form.setFieldValue('id', initialFormData.id);
    form.setFieldValue('reference', initialFormData.reference);
    form.setFieldValue('description', initialFormData.description);
    form.setFieldValue('type', initialFormData.type);
    form.setFieldValue('technicianId', initialFormData.technicianId);
    form.setFieldValue('buildingLeadId', initialFormData.buildingLeadId);
    form.setFieldValue('priority', initialFormData.priority);
    form.setFieldValue('scheduledStartDate', dayjs(initialFormData.scheduledStartDate));
    form.setFieldValue('scheduledFinishDate', dayjs(initialFormData.scheduledFinishDate));
  }, [initialFormData]);

  useEffect(() => {
    if (!initialAssetOptions) return;
    form.setFieldValue('assets', initialAssetOptions);
  }, [initialAssetOptions]);

  useEffect(() => {
    if (!props.ticketId) return;
    form.setFieldValue('ticketId', props.ticketId);

  }, [props.ticketId]);

  const handleOnApproval = () => {
    setShowApprovalModal(false);
    props.onSuccess?.();
  }

  const formDisabled = createLoading || updateLoading || props.isApproval;

  return (
    <Skeleton
      loading={optionsLoading || initialFormDataLoading || initialAssetOptionsLoading}
      active
    >
      <Form
        onFinish={handleOnFinish}
        form={form}
        layout='vertical'
        disabled={formDisabled}
      >
        <Form.Item name='id' hidden>
          <Input />
        </Form.Item>
        <Form.Item name='ticketId' hidden>
          <Input />
        </Form.Item>

        <Form.Item name='reference' label='Reference'>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name='description'
          label='Description'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='type'
          label='Type'
          rules={[{ required: true }]}
        >
          <Select
            disabled={initialFormData?.type === WorkOrderType.Preventative || formDisabled}
            options={workOrderOptions}
          />
        </Form.Item>
        <Form.Item
          name='priority'
          label='Priority'
          rules={[{ required: true }]}
        >
          <Select options={workOrderPriorityOptions} />
        </Form.Item>
        <Form.Item
          name='technicianId'
          label='Technician'
          rules={[{ required: true }]}
        >
          <Select options={mapUserOptions(technicians)} />
        </Form.Item>
        <Form.Item
          name='buildingLeadId'
          label='Owner'
          rules={[{ required: true }]}
        >
          <Select options={mapUserOptions(buildingLeads)} />
        </Form.Item>
        <Form.Item
          name='scheduledStartDate'
          label='Scheduled Start Date'
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name='scheduledFinishDate'
          label='Scheduled Finish Date'
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item name='assets' label='Assets'>
          <AssetFormControl
            disabled={!!props.ticketId || props.isApproval}
            multiple
          />
        </Form.Item>
        {!props.isApproval && (
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        )}
      </Form>
      {props.isApproval && (
        <div>
          <Button type="primary" onClick={() => setShowApprovalModal(true)}>
            Approve / Reject
          </Button>
        </div>
      )}

      <WorkOrderApprovalModal
        id={props.workOrderId}
        onClose={() => setShowApprovalModal(false)}
        onSuccess={handleOnApproval}
        open={showApprovalModal}
      />
    </Skeleton>
  )
}
