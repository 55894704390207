import {
  useGetAllBuildingsQuery,
  useGetAllFloorsQuery,
  useGetAllLocationsQuery
} from "../../../../../../store/services/api/building-api";
import {
  useGetAllAssetTypesQuery,
  useGetAllSubAssetTypesQuery
} from "../../../../../../store/services/api/asset-type-api";
import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import MobileForm from "../../../../layouts/MobileForm";
import {SelectFormControl, TextFormControl} from "./AssetSetupForm";

interface FindAssetFormProps {
  onSubmit: (data: any) => void;
}

export default function FindAssetForm({onSubmit}: FindAssetFormProps) {
  const { data: buildings } = useGetAllBuildingsQuery(null);
  const { data: floors } = useGetAllFloorsQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: assetTypes } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes } = useGetAllSubAssetTypesQuery(null);

  const form = useForm({
    defaultValues: {
      assetTypeId: "",
      subAssetTypeId: "",
      buildingId: "",
      floorId: "",
      locationId: "",
      code: "",
    }
  });

  const assetTypeId = form.watch('assetTypeId');
  const buildingId = form.watch('buildingId');
  const floorId = form.watch('floorId');

  useEffect(() => {
    form.setValue('subAssetTypeId', '');
  }, [assetTypeId])

  useEffect(() => {
    form.setValue('floorId', '');
  }, [buildingId]);

  useEffect(() => {
    form.setValue('locationId', '');
  }, [floorId])

  const handleOnFormSubmit = () => {
    form.handleSubmit(onSubmit)();
  }

  const assetTypeOptions = (assetTypes ?? []).map(item => ({
    label: item.name,
    value: item.id
  }));

  const subAssetTypeOptions = (subAssetTypes ?? [])
    .filter(item => item.assetTypeId === assetTypeId)
    .map(item => ({
      label: item.name,
      value: item.id
    }));

  const buildingOptions = (buildings ?? []).map(item => ({
    label: item.name,
    value: item.id
  }));

  const floorOptions = (floors ?? [])
    .filter(item => item.buildingId === buildingId)
    .map(item => ({
      label: item.name,
      value: item.id
    }));

  const locationOptions = (locations ?? [])
    .filter(item => item.floorId === floorId)
    .map(item => ({
      label: item.name,
      value: item.id
    }));

  return (
    <MobileForm onSubmit={handleOnFormSubmit}>
      <SelectFormControl
        title='Asset Type'
        control={form.control}
        controlName='assetTypeId'
        options={assetTypeOptions}
      />
      <SelectFormControl
        title='Sub Asset Type'
        control={form.control}
        controlName='subAssetTypeId'
        options={subAssetTypeOptions}
      />
      <SelectFormControl
        title='Building'
        control={form.control}
        controlName='buildingId'
        options={buildingOptions}
      />
      <SelectFormControl
        title='Floor'
        control={form.control}
        controlName='floorId'
        options={floorOptions}
      />
      <SelectFormControl
        title='Location'
        control={form.control}
        controlName='locationId'
        options={locationOptions}
      />
      <TextFormControl
        title='Code'
        control={form.control}
        controlName='code'
        multiline={true}
        numberOfLines={2}
      />
    </MobileForm>
  )
}
