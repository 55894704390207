import React from "react";
import WorkOrdersList from "./screens/list/WorkOrdersList";
import WorkOrderRecordViewScreen from "./screens/record/WorkOrderRecordViewScreen";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import WorkOrderLabourScreen from "./screens/labour/WorkOrderLabourScreen";
import WorkOrderWorkLogScreen from "./screens/work-log/WorkOrderWorkLogScreen";
import WorkOrderAssetsScreen from "./screens/assets/WorkOrderAssetsScreen";
import {ParamListBase} from "@react-navigation/native";
import WorkOrderManageScreen from "./screens/manage/WorkOrderManageScreen";

const Stack = createNativeStackNavigator<WorkOrdersStackParamList>();

export default function WorkOrdersStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='list'
        component={WorkOrdersList}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='manage'
        component={WorkOrderManageScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='recordView'
        component={WorkOrderRecordViewScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='labour'
        component={WorkOrderLabourScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='workLog'
        component={WorkOrderWorkLogScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='assets'
        component={WorkOrderAssetsScreen}
        options={{headerShown: false}}
      />
    </Stack.Navigator>
  );
};

export type WorkOrdersStackParamList = ParamListBase & {
  list: undefined;
  manage: { id?: string, approval?: boolean };
  recordView: { id: string };
  labour: { id: string };
  workLog: { id: string };
  assets: { id: string };
};
