import React, {
  createContext,
  useContext,
  useState,
  useRef,
  ReactNode, useEffect, useCallback
} from "react";
import {Drawer, notification} from "antd";
import jsQR from "jsqr";

interface CameraOptions {
  mode?: "photo" | "qr";
  onResult: (result: string) => void;
}

interface CameraContextValue {
  isCameraActive: boolean;
  showCamera: (options?: CameraOptions) => void;
  stopCamera: () => void;
}

const CameraContext = createContext<CameraContextValue | undefined>(undefined);

export const CameraProvider: React.FC<{ children: ReactNode }> = ({children}) => {
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [cameraMode, setCameraMode] = useState<"photo" | "qr">("photo");
  const onResultCallbackRef = useRef<((result: string) => void) | undefined>(undefined);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const streamRef = useRef<MediaStream | null>(null);

  const showCamera = (options?: CameraOptions) => {
    const { mode = "photo", onResult } = options || {};
    setCameraMode(mode);
    onResultCallbackRef.current = onResult;

    startCamera();
  };

  const startCamera = async () => {
    setIsCameraActive(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { ideal: "environment" } },
      });
      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

    } catch (err) {
      notification.error({
        message: `Error`,
        description: 'There was an issue accessing the camera.',
        placement: 'bottomRight',
        duration: 3,
      });
      setIsCameraActive(false);
    }
  };

  useEffect(() => {
    if (isCameraActive && cameraMode === "qr") {
      startScanning();
    }
  }, [isCameraActive, cameraMode, canvasRef, videoRef]);

  const stopCamera = () => {
    setIsCameraActive(false);

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  const startScanning = () => {
    if (!canvasRef.current || !videoRef.current) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;

    video.addEventListener("loadedmetadata", () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      requestAnimationFrame(scan);
    });
  };

  const scan = useCallback(() => {
    if (!isCameraActive || !canvasRef.current || !videoRef.current) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;
    const canvasContext = canvas.getContext("2d");
    if (!canvasContext) return;

    canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);

    const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" });

    if (code) {
      if (onResultCallbackRef.current) onResultCallbackRef.current(code.data);
      stopCamera();
      return;
    }

    requestAnimationFrame(scan);
  }, [isCameraActive]);

  const capturePhoto = useCallback(() => {
    if (!canvasRef.current || !videoRef.current) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;
    const canvasContext = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    canvasContext?.drawImage(video, 0, 0, canvas.width, canvas.height);

    const photoData = canvas.toDataURL("image/png");

    if (onResultCallbackRef.current) {
      onResultCallbackRef.current(photoData);
    }
    stopCamera();
  }, [isCameraActive]);

  return (
    <CameraContext.Provider value={{ isCameraActive, showCamera, stopCamera }}>
      {children}

      <Drawer
        title={cameraMode === "photo" ? "Take a Photo" : "Scan QR Code"}
        placement="bottom"
        closable
        onClose={stopCamera}
        open={isCameraActive}
        height="100%"
        zIndex={2000}
        styles={{
          body: {
            padding: 0
          }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "100%",
            height: "100%"
          }}
        >
          <video
            playsInline
            ref={videoRef}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover"
            }}
          />
          <canvas ref={canvasRef} style={{ display: "none" }} />

          {cameraMode === "qr" && (
            <div
              style={{
                position: "absolute",
                border: "4px solid white",
                borderRadius: '18px',
                width: "80%",
                aspectRatio: "1/1",
              }}
            />
          )}

          {cameraMode === "photo" && (
            <button
              onClick={capturePhoto}
              style={{
                position: "absolute",
                bottom: "5%",
                backgroundColor: "white",
                border: "3px solid gray",
                borderRadius: "50%",
                width: "72px",
                height: "72px",
                boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer"
              }}
            >
            </button>
          )}
        </div>
      </Drawer>
    </CameraContext.Provider>
  );
};

export const useCamera = (): CameraContextValue => {
  const context = useContext(CameraContext);
  if (!context) {
    throw new Error("useCamera must be used within a CameraProvider");
  }
  return context;
};
