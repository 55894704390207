import {Text, TouchableOpacity, View} from "react-native";
import GlobalStyle, {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import {EvilIcons} from "@expo/vector-icons";
import * as React from "react";
import {useForm} from "react-hook-form";
import {TextFieldController} from "../../components/forms/TextFieldController";
import CoreForm from "../../components/forms/CoreForm";
import {PickerFieldController} from "../../components/forms/PickerFieldController";
import {useEffect} from "react";
import {useToast} from "../../../../components/toast/ToastProvider";
import {WebScreen} from "../../components/WebScreen";
import {useGetAllAssetTypesQuery} from "../../../../store/services/api/asset-type-api";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {NewMaintenancePart} from "../../../../store/data/entities/maintenance-part";
import {
  useCreatePartMutation,
  useLazyGetPartQuery,
  useUpdatePartMutation
} from "../../../../store/services/api/maintenance-part-api";
import {useSetLoading} from "../../../../hooks/useSetLoading";

export default function MaintenancePartManage({navigation, route}: NativeStackScreenProps<any, any>) {
  const {data: assetTypes, isFetching: isAssetTypesFetching} = useGetAllAssetTypesQuery();
  const [createPart, {isLoading: createLoading, isError: createError, isSuccess: createSuccess}] = useCreatePartMutation();
  const [updatePart, {isLoading: updateLoading, isError: updateError, isSuccess: updateSuccess}] = useUpdatePartMutation();
  const [getPart, {data: currentPart, isLoading: partLoading}] = useLazyGetPartQuery();
  const partId = route?.params?.partId;

  const form = useForm<NewMaintenancePart>({
    defaultValues: {
      title: '',
      description: '',
      unitCost: 0,
      assetTypeId: '',
    },
    mode: "onChange"
  });

  const {show} = useToast();
  const navigateBack = () => navigation.navigate('list');

  useEffect(() => {
    if (currentPart) {
      form.reset({
        title: currentPart.title,
        description: currentPart.description,
        unitCost: currentPart.unitCost,
        assetTypeId: currentPart.assetTypeId
      });
    }
  }, [currentPart]);

  useEffect(() => {
    if (partId) {
      getPart(partId);
    }
  }, [partId]);

  useSetLoading([isAssetTypesFetching, createLoading, partLoading, updateLoading]);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      navigateBack();
      const action = createSuccess ? 'created' : 'updated';
      show('Success', `Successfully ${action} part`, 'success');
    }
  }, [createSuccess, updateSuccess]);

  useEffect(() => {
    if (createError || updateError) {
      const action = createSuccess ? 'creating' : 'updating';
      show('Error', `There was an issue ${action} the part`, 'error');
    }
  }, [createError, updateError]);

  const handleOnSubmit = async () => {
    const valid = await form.trigger();
    if (!valid) return;
    const formValues = form.getValues();

    if (!partId) {
      createPart(formValues);
      return;
    }

    updatePart({
      id: partId,
      part: formValues
    });
  }

  return (
    <WebScreen>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.navigate("list")}>
          <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>MANAGE CHECKLIST</Text>
      </View>

      <CoreForm
        form={form}
        onSubmit={handleOnSubmit}
      >
        <CoreForm.Row>
          <TextFieldController
            controlName='title'
            title='Title'
            required
          />
          <TextFieldController
            controlName='description'
            title='Description'
            required
          />
          <TextFieldController
            controlName='unitCost'
            title='Unit Cost (£)'
            required
            type='numeric'
          />
          <PickerFieldController
            controlName='assetTypeId'
            title='Asset Type'
            required
            items={(assetTypes ?? []).map(item => ({
              value: item.id,
              label: item.name
            }))} />
        </CoreForm.Row>
      </CoreForm>
    </WebScreen>
  );
}
