import {Button, Card} from "react-native-paper";
import {FlatList, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {Feather} from "@expo/vector-icons";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout as logoutUser} from "../../../store/slices/user-slice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {RootState} from "../../../store";
import {useNavigation, useRoute} from "@react-navigation/native";

export interface WebPageHeaderProps {
  previousTab?: RouteTab
  tabs?: RouteTab[]
}

type RouteTab = {
  title: string;
  routeName?: string;
  params?: any;
  disabled?: boolean;
  hidden?: boolean;
  replaceStack?: boolean;
}

interface PageHeaderProps {
  header: WebPageHeaderProps
}

export default function PageHeader({header}: PageHeaderProps) {
  const {user} = useSelector((state: RootState) => state.user);
  const navigation = useNavigation<any>();
  const route = useRoute();
  const {previousTab} = header;

  const dispatch = useDispatch();

  const logout = async () => {
    dispatch(logoutUser());

    try {
      await AsyncStorage.clear();
    } catch (error) {
      console.log("Error clearing AsyncStorage:", error);
    }

    navigation.navigate("login");
  }

  const renderTab = (tab: RouteTab) => {
    const isCurrentTab = tab.routeName === route.name;
    const handleNavigation = () => {
      if (tab?.replaceStack) {
        navigation.replace(tab.routeName, tab?.params ?? {})
        return;
      }
      navigation.navigate(tab.routeName, tab?.params ?? {})
    }

    return (
      <TouchableOpacity
        key={tab.title}
        style={styles.tab}
        onPress={handleNavigation}
        disabled={isCurrentTab || tab.disabled}
      >
        <Text style={styles.tabText}>{tab.title}</Text>
      </TouchableOpacity>
    );
  }

  const renderPreviousTab = (tab: RouteTab) => {
    const handleNavigation = () => {
      if (tab?.replaceStack) {
        navigation.replace(tab.routeName, tab?.params ?? {})
        return;
      }
      navigation.navigate(tab.routeName, tab?.params ?? {})
    }

    return (
      <TouchableOpacity
        style={styles.tab}
        onPress={handleNavigation}
      >
        <Feather name="arrow-left" size={24} />
        <Text style={styles.tabText}>{previousTab.title}</Text>
      </TouchableOpacity>
    )
  }

  return (
    <Card style={styles.headerContainer} >
      <Card.Content style={styles.tabsContainer}>
        {previousTab && renderPreviousTab(previousTab)}

        <FlatList
          ItemSeparatorComponent={() => (
            <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <View style={{ width: 1, backgroundColor: '#9F9F9F', height: 32 }} />
            </View>
          )}
          horizontal
          data={(header?.tabs ?? []).filter(tab => !tab?.hidden)}
          renderItem={({ item }) => renderTab(item)}
          keyExtractor={(item) => item.title}
        />

        <View>
          <Button icon='account' mode="elevated" onPress={logout}>{user?.name}</Button>
        </View>
      </Card.Content>
    </Card>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    marginVertical: 12,
    backgroundColor: '#fff'
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tab: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    paddingHorizontal: 18,
    paddingVertical: 8
  },
  tabText: {
    fontSize: 16,
    fontWeight: '600',
  },
  currentTabText: {
    fontWeight: '600',
    color: '#1a4d78'
  }
})
