import * as DocumentPicker from 'expo-document-picker';

export async function pickMedia(mediaType: 'video' | 'image' | 'both', allowMultiple: boolean = true): Promise<File[]> {
  const result = await DocumentPicker.getDocumentAsync({
    type: mediaType === 'both' ? ['image/*', 'video/*'] : `${mediaType}/*`,
    multiple: allowMultiple
  });

  if (result.canceled) return [];

  const files: File[] = [];

  for (const asset of result.assets) {
    const uri = asset.uri;
    const fileName = asset.name || 'media';
    const fileType = asset.mimeType;

    const response = await fetch(uri);
    const blob = await response.blob();

    const file = new File([blob], fileName, { type: fileType });
    files.push(file);
  }

  return files;
}

export const getImageBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject("Failed to read file as base64");
      }
    };
    reader.onerror = () => {
      reject("File reading failed");
    };
    reader.readAsDataURL(file);
  });
};
