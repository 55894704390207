import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import TechnicianJobPlansList from "./TechnicianJobPlansList";
import TechnicianJobPlanDetails from "./TechnicianJobPlanDetails";

const Stack = createNativeStackNavigator<TechnicianJobPlansStackParamList>();

export default function TechnicianJobPlansStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='list'
        component={TechnicianJobPlansList}
        options={{
          headerShown: true,
          headerLeft: () => null,
          title: 'Job Plans'
        }}
      />
      <Stack.Screen
        name='details'
        component={TechnicianJobPlanDetails}
        options={{
          headerShown: true,
          title: 'Job Plan'
        }}
      />
    </Stack.Navigator>
  );
};

export type TechnicianJobPlansStackParamList = ParamListBase & {
  list: undefined;
  details: { id: string };
};
