import {useWorkOrder} from "../../WorkOrdersStack";
import React, {useEffect, useState} from "react";
import {FlatList, ListRenderItemInfo, View} from "react-native";
import {Button, Card, Text} from "react-native-paper";
import Skeleton from "../../../../../../components/skeletons/Skeleton";
import {WorkOrderLog} from "../../../../../../store/data/entities/work-order-log";
import {useLazyGetWorkOrderLogsQuery} from "../../../../../../store/services/api/work-order-api";
import useUser from "../../../../../../hooks/useUser";
import {FileOutlined} from "@ant-design/icons";
import {Button as AntButton} from "antd";
import UploadWorkOrderLogDocumentsModal from "../../../../../../components/modals/UploadWorkOrderLogDocumentsModal";
import AddWorkLogModal from "../../../../../../components/modals/New/AddWorkLogModal";

export default function TechnicianWorkLog() {
  const [getLogs, {data, isFetching}] = useLazyGetWorkOrderLogsQuery();
  const [workLogModalVisible, setWorkLogModalVisible] = useState(false);
  const [selectedWorkOrderLog, setSelectedWorkOrderLog] = useState<WorkOrderLog | undefined>(undefined);
  const {id} = useWorkOrder();
  const {user} = useUser();

  useEffect(() => {
    if (id) {
      getLogs(id);
    }
  }, [id]);

  const renderWorkLog = ({item}: ListRenderItemInfo<WorkOrderLog>) => {
    const documentCount = item.fileURIs.length;
    const showDocumentCount = documentCount > 0;
    return (
      <Card style={{backgroundColor: 'white'}}>
        <Card.Title title={item.user} />
        <Card.Content>
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <Text variant='bodySmall'>{item.comments}</Text>
            <View>
              <AntButton
                onClick={() => setSelectedWorkOrderLog(item)}
                icon={<FileOutlined />}
              >
                Documents{showDocumentCount && ` (${documentCount})`}
              </AntButton>
            </View>
          </View>
        </Card.Content>
      </Card>
    )
  }

  return (
    <Skeleton
      loading={isFetching}
      placeholder={<Skeleton.Card />}
    >
      <FlatList
        style={{paddingHorizontal: 4, paddingVertical: 6}}
        ItemSeparatorComponent={() => <View style={{height: 6}} />}
        data={data ?? []}
        renderItem={renderWorkLog}
        ListFooterComponent={(
          <Button
            style={{marginTop: 6}}
            icon='plus'
            mode="elevated"
            onPress={() => setWorkLogModalVisible(true)}
            buttonColor='#fff'
            textColor='#0f0f0f'
          >
            Add Work Log
          </Button>
        )}
      />

      <AddWorkLogModal
        workOrderId={id}
        defaultUserId={user.id}
        userRoles={['Technician']}
        onClose={() => setWorkLogModalVisible(false)}
        onSuccess={() => setWorkLogModalVisible(false)}
        open={workLogModalVisible}
      />

      <UploadWorkOrderLogDocumentsModal
        workOrderId={id}
        workOrderLogEntryId={selectedWorkOrderLog?.id}
        existingFileURIs={selectedWorkOrderLog?.fileURIs ?? []}
        onClose={() => setSelectedWorkOrderLog(undefined)}
        visible={!!selectedWorkOrderLog}
      />
    </Skeleton>
  );
}
