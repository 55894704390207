import {ApprovalModal} from "./ApprovalModal";
import {useSetJobPlanApprovalStatusMutation} from "../../store/services/api/job-plan-api";
import {ApprovalStatusForm} from "../../store/data/models/approval-status-form";

interface JobPlanApprovalModalProps {
  id: string;
  isApproved: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export default function JobPlanApprovalModal({id, isApproved, onClose, onSuccess}: JobPlanApprovalModalProps) {
  const [setJobApproval, {isLoading}] = useSetJobPlanApprovalStatusMutation();

  const handleOnSubmit = (status: ApprovalStatusForm) => {
    setJobApproval(status)
      .then(onSuccess)
      .finally(onClose);
  }

  return (
    <ApprovalModal
      loading={isLoading}
      entityName='Job Plan'
      entityId={id}
      isApproved={isApproved}
      onClose={onClose}
      onSubmit={handleOnSubmit}
    />
  )
}
