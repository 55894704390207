import * as React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

import { EvilIcons } from '@expo/vector-icons';
import { useForm } from 'react-hook-form';

import GlobalStyle, { PRIMARY_COLOUR } from '../../../../constants/GlobalStyle';
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useLazyGetUserQuery
} from '../../../../store/services/api/user-api';
import {UserRoles} from "../../../../store/data/users/user-roles";
import { UserManageForm } from '../../../../store/data/users/user-manage-form';
import {WebScreen} from "../../components/WebScreen";
import {useEffect} from "react";
import CoreForm from "../../components/forms/CoreForm";
import {TextFieldController} from "../../components/forms/TextFieldController";
import {PickerFieldController} from "../../components/forms/PickerFieldController";
import {useToast} from "../../../../components/toast/ToastProvider";
import {useSetLoading} from "../../../../hooks/useSetLoading";

export default function WebUserManage({ navigation, route }: { navigation: any, route: any }) {
  const [createUser, {isSuccess: createSuccess, isLoading: createLoading}] = useCreateUserMutation();
  const [updateUser, {isSuccess: updateSuccess, isLoading: updateLoading}] = useUpdateUserMutation();

  const [trigger, {data: userData, isSuccess, isFetching}] = useLazyGetUserQuery();
  const {show} = useToast();

  const form = useForm<UserManageForm>({
    defaultValues: {
      id: null,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: ""
    }
  });
  const { handleSubmit, setValue, watch } = form;

  const userId = watch("id");
  const password = watch("password");
  const navigateBack = () => navigation.navigate('list');

  useSetLoading([isFetching, createLoading, updateLoading]);

  useEffect(() => {
    if (route.params?.userId != null) {
      trigger(route.params?.userId);
    }
  }, []);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      navigateBack();
      show('Updated User', 'Successfully updated user', 'success');
    }
  }, [createSuccess, updateSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setValue("id", userData.id);
      setValue("name", userData.name);
      setValue("email", userData.email);
      setValue("role", userData.role);
    }
  }, [isSuccess]);

  const onSubmit = async (data: UserManageForm) => {
    const mutation = data.id != null ? updateUser : createUser;
    mutation(data);
  };

  return (
    <WebScreen>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
          <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>MANAGE USER</Text>
      </View>

      <CoreForm form={form} onSubmit={handleSubmit(onSubmit)}>
        <CoreForm.Row>
          <TextFieldController required controlName='name' title='Name' />
          <TextFieldController required controlName='email' title='Email' />
          <PickerFieldController required controlName='role' title='Role' items={UserRoles} defaultFirst={false} />
        </CoreForm.Row>
        <CoreForm.Row>
          <TextFieldController
            controlName='password'
            title='Password'
            placeholder={userId != null ? 'Unchanged' : ''}
            secureTextEntry
          />
          <TextFieldController
            controlName='confirmPassword'
            title='Confirm Password'
            placeholder={userId != null ? 'Unchanged' : ''}
            secureTextEntry
            rules={{
              validate: (value) => password === value || 'Passwords must match'
            }}
          />
        </CoreForm.Row>
      </CoreForm>
    </WebScreen>
  );
}
