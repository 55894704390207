import { baseApi } from "./base-api";
import {Media, NewMedia} from "../../data/entities/media";

export const mediaApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMedia: build.query<Media[], string>({
      query: (path) => ({
        url: `media/getMedia`,
        params: { path }
      })
    }),
    getMediaForJob: build.query<Media[], string>({
      query: (jobId) => ({
        url: `media/getMediaForJob/${jobId}`
      })
    }),
    removeMedia: build.mutation<void, Media>({
      query: (dto) => ({
        url: `media/removeMedia`,
        method: 'DELETE',
        body: dto
      })
    }),
    uploadMedia: build.mutation<Media, NewMedia>({
      query: (data) => {
        const formData = new FormData();
        formData.append(`title`, data.title);
        formData.append(`description`, data.description);
        formData.append(`path`, data.path);
        formData.append(`file`, data.file);

        for (const key in data.extras) {
          if (data.extras.hasOwnProperty(key)) {
            formData.append(`extras[${key}]`, data.extras[key]);
          }
        }

        return {
          url: `media/addMedia`,
          method: 'POST',
          body: formData
        }
      },
    })
  }),
  overrideExisting: true,
});

export const {
  useGetMediaQuery,
  useLazyGetMediaForJobQuery,
  useUploadMediaMutation,
  useRemoveMediaMutation
} = mediaApi;
