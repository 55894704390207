import {Text, View} from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import {Controller, RegisterOptions} from "react-hook-form";
import * as React from "react";
import {useCoreForm} from "./CoreForm";
import DateTimePicker from "../navigation/controls/DateTimePicker";

interface DateFieldControllerProps {
  rules?: RegisterOptions;
  controlName: string;
  title: string;
  required?: boolean;
}

export function DateFieldController({rules, controlName, title, required = false}: DateFieldControllerProps) {
  const {form: {control}} = useCoreForm();
  if (!control) return null;

  const extendedRules = {
    ...(rules ?? {}),
    required: required ? 'This field is required' : false
  };

  return (
    <Controller
      control={control}
      rules={extendedRules}
      name={controlName}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <View>
          <Text style={GlobalStyle.form__column__text}>{title} {required ? '*' : null}</Text>
          <DateTimePicker
            style={GlobalStyle.column__input}
            onChange={onChange}
            value={value}>
          </DateTimePicker>
          {error && <Text style={{color: 'red'}}>{error.message}</Text>}
        </View>
      )}
    />
  )
}
