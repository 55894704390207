import React, {useState} from "react";
import {StyleSheet, Text, TouchableOpacity} from "react-native";
import WebPageLayout from "../../../../layouts/WebPageLayout";
import TablePageLayout from "../../../../layouts/TablePageLayout";
import {CoreTable} from "../../../../components/CoreTable";
import {convertBase64toBlob} from "../../../../../../utils/helpers";
import {Button, DataTable} from "react-native-paper";
import {Entypo, MaterialCommunityIcons, MaterialIcons} from "@expo/vector-icons";
import {BaseFilters} from "../../../../../web/hooks/useFilters";
import useEntityListPage from "../../../../hooks/useEntityListPage";
import TicketsListScreenFilters from "./TicketsListScreenFilters";
import {humanizeDate} from "../../../../../../utils/humanizers";
import {
  useLazyExportTicketsQuery,
  useLazyGetTicketsQuery,
  useUpdateTicketStatusMutation
} from "../../../../../../store/services/api/ticket-api";
import moment from "moment/moment";
import {PRIMARY_COLOUR} from "../../../../../../constants/GlobalStyle";
import {useModal} from "../../../../../../hooks/useModal";
import {ImageViewer} from "../../../../../web/components/ImageViewer";
import {TicketViewModel} from "../../../../../../store/data/models/ticket-view-model";
import TicketUpdatesModal from "../../../../components/modals/TicketUpdatesModal";
import UpdateTicketModal from "../../../../components/modals/UpdateTicketModal";
import {useSetLoading} from "../../../../../../hooks/useSetLoading";
import {Tooltip} from "antd";
import CreateTicketModal from "../modals/CreateTicketModal";
import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {TicketsStackParamList} from "../../TicketsStack";
import WorkOrderManageModal from "../../../../../../components/modals/New/WorkOrderManageModal";

interface TicketsListScreenFilters extends BaseFilters {
  ticketNumber: string;
  startDate: string;
  endDate: string;
  ticketDescription: string;
  contactEmail: string;
  status: string;
  assetReference: string;
  hasUpdates: boolean | '';
  buildingId: string;
  floorId: string;
  assetTypeId: string;
  subAssetTypeId: string;
}

export default function TicketsListScreen({navigation}: ScreenProps<TicketsStackParamList, 'list'>) {
  const [updateTicketStatus] = useUpdateTicketStatusMutation();
  const [exportTicketsList, {isFetching: exportFetching}] = useLazyExportTicketsQuery();
  const [createTicketModalOpen, setCreateTicketModalOpen] = useState(false);
  const [workOrderModalVisible, setWorkOrderModalVisible] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState<string | undefined>();

  useSetLoading([exportFetching]);

  const {
    navigateToEdit,
    onSubmitFilter,
    filter,
    tableData,
    isLoading,
  } = useEntityListPage<TicketsListScreenFilters>({
    entityName: "Ticket",
    lazyTableQuery: useLazyGetTicketsQuery,
    deleteEntityMutation: undefined,
    filterDefinition: {
      contactEmail: "",
      endDate: moment().toISOString(),
      startDate: moment().add(-12, 'week').toISOString(),
      status: "All",
      ticketDescription: "",
      ticketNumber: "",
      hasUpdates: '',
      assetReference: "",
      sortedColumn: "",
      buildingId: '',
      floorId: '',
      assetTypeId: '',
      subAssetTypeId: '',
      sortedColumnAscending: false,
      pageNumber: 1,
      pageSize: 10
    },
  });

  const {showModal, hideModal} = useModal();

  const handleOnCreateWorkOrderClick = (item) => {
    setSelectedTicketId(item.id);
    setWorkOrderModalVisible(true);
  }

  const handleOnWorkOrderClick = (item) => {
    if (item.latestWorkOrderId) {
      navigation.navigate('workOrders', {screen: 'recordView', params: {id: item.latestWorkOrderId}});
      return;
    }
  }

  const toggleTicketStatus = async (element) => {
    try {
      const newStatus = element.status === "Closed" ? "Open" : "Closed";
      await updateTicketStatus({id: element.id, status: newStatus});
    } catch (error) {
      console.error("Error changing ticket status:", error);
    }
  };

  const handleOnShowImagesModal = (images: string[]) => {
    showModal(
      <ImageViewer images={images} onClosed={hideModal}/>
    )
  }

  const handleOnShowUpdatesModal = (ticket: TicketViewModel) => {
    showModal(
      <TicketUpdatesModal ticket={ticket} onClose={hideModal}/>
    )
  }

  const handleOnShowTicketUpdateModal = (ticket: TicketViewModel) => {
    showModal(
      <UpdateTicketModal ticket={ticket} onClose={hideModal} />
    )
  }

  const handleOnExportTable = async () => {
    await exportTicketsList(filter.getFilterValues())
      .unwrap()
      .then(response => {
        let blob = convertBase64toBlob(response.base64, "application/vnd.ms-excel");
        const fileSaver = require('file-saver');
        fileSaver(blob, "report.xlsx");
      });
  }

  return (
    <WebPageLayout
      header={{
        previousTab: {title: 'Dashboard', routeName: 'dashboard', replaceStack: true},
        tabs: [
          {title: 'Tickets', routeName: 'list'}
        ]
      }}
    >
      <TablePageLayout
        filters={<TicketsListScreenFilters filter={filter} onSubmit={onSubmitFilter} />}
        actions={(
          <>
            <Button
              icon='plus'
              mode="elevated"
              onPress={() => setCreateTicketModalOpen(true)}
              buttonColor='#fff'
              textColor='#0f0f0f'
            >
              Create Ticket
            </Button>

            <Button
              icon='plus'
              mode="elevated"
              onPress={handleOnExportTable}
              buttonColor='#fff'
              textColor='#0f0f0f'
            >
              Export
            </Button>
          </>
        )}
      >
        <CoreTable<any>
          isLoading={isLoading}
          headers={[
            {text: 'Edit', flex: 0.5},
            {text: 'Ticket', sortedName: "number", flex: 0.5},
            {text: 'Work Order'},
            {text: 'Building'},
            {text: 'Floor'},
            {text: 'Location'},
            {text: 'Sub Asset Type'},
            {text: 'Code'},
            {text: 'Date Submitted', sortedName: "dateSubmitted"},
            {text: 'Ticket Description', sortedName: "description"},
            {text: 'Contact Email', sortedName: "contactEmail"},
            {text: 'Status', sortedName: "status", flex: 0.5},
            {text: 'Closed On', sortedName: "closedOn"},
            {text: 'Closed By', sortedName: "closedBy"},
            {text: 'Photos', flex: 0.5},
            {text: 'Change Status', flex: 0.5},
            {text: 'Updates', flex: 0.5}
          ]}
          data={tableData}
          keyExtractor={(item) => item.id}
          renderItem={({item, index}) => (
            <DataTable.Row
              style={index % 2 === 0 ? styles.alternateRow : styles.row}
              key={index}
              onPress={() => navigateToEdit(item.id)}
            >
              <DataTable.Cell style={{flex: 0.5}}>
                <TouchableOpacity onPress={() => handleOnShowTicketUpdateModal(item)}>
                  <Entypo name="pencil" size={24} color="black"/>
                </TouchableOpacity>
              </DataTable.Cell>
              <CoreTable.Cell style={{flex: 0.5}}>{item.number}</CoreTable.Cell>
              <DataTable.Cell>
                {!item.latestWorkOrderId && item.status === 'Open' ? (
                  <Text onPress={() => handleOnCreateWorkOrderClick(item)} style={{fontWeight: 'bold'}}>Create</Text>
                ) : (
                  <Tooltip title={item.latestWorkOrderReference}>
                    <Text onPress={() => handleOnWorkOrderClick(item)}>{item.latestWorkOrderReference}</Text>
                  </Tooltip>
                )}
              </DataTable.Cell>
              <CoreTable.Cell>{item.building}</CoreTable.Cell>
              <CoreTable.Cell>{item.floor}</CoreTable.Cell>
              <CoreTable.Cell>{item.location}</CoreTable.Cell>
              <CoreTable.Cell>{item.subAssetType}</CoreTable.Cell>
              <CoreTable.Cell>{item.code}</CoreTable.Cell>
              <CoreTable.Cell>{humanizeDate(item.dateSubmitted)}</CoreTable.Cell>
              <CoreTable.Cell>{item.description}</CoreTable.Cell>
              <CoreTable.Cell>{item.contactEmail}</CoreTable.Cell>
              <CoreTable.Cell style={{flex: 0.5}}>{item.status}</CoreTable.Cell>
              <CoreTable.Cell>{humanizeDate(item.closedOn, true)}</CoreTable.Cell>
              <CoreTable.Cell>{item?.closedBy ?? 'N/A'}</CoreTable.Cell>
              <DataTable.Cell style={{flex: 0.5}}>
                {item.images != null && item.images.length > 0 ?
                  <TouchableOpacity onPress={() => handleOnShowImagesModal(item.images)}>
                    <MaterialIcons name={item.images.length === 1 ? "photo" : "photo-library"} size={24}
                                   color={PRIMARY_COLOUR}/>
                  </TouchableOpacity> : <Text>None</Text>
                }
              </DataTable.Cell>
              <DataTable.Cell style={{flex: 0.5}}>
                <TouchableOpacity onPress={() => toggleTicketStatus(item)}>
                  {item.status === "Open" ? <Entypo name="circle-with-cross" size={24} color="black"/> :
                    <Entypo name="cycle" size={24} color="black"/>}
                </TouchableOpacity>
              </DataTable.Cell>
              <DataTable.Cell style={{flex: 0.5}}>
                <TouchableOpacity onPress={() => handleOnShowUpdatesModal(item)}>
                  <MaterialCommunityIcons name="view-list-outline" size={24} color="black"/>
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )}
          filters={filter.filters}
          setFilter={filter.setFilter}
        >
        </CoreTable>
        <CreateTicketModal
          open={createTicketModalOpen}
          onClose={() => setCreateTicketModalOpen(false)}
        />
      </TablePageLayout>

      <WorkOrderManageModal
        open={workOrderModalVisible}
        onClose={() => setWorkOrderModalVisible(false)}
        onSuccess={() => setWorkOrderModalVisible(false)}
        ticketId={selectedTicketId}
      />
    </WebPageLayout>
  );
}

const styles = StyleSheet.create({
  row: {
    backgroundColor: 'rgba(255,255,255,1)'
  },
  alternateRow: {
    backgroundColor: 'rgba(245,245,245,1)'
  }
});
