import {StyleSheet, View} from "react-native";
import {CoreModal, CoreModalProps} from "../../hooks/useModal";
import React from "react";
import {JobMedia} from "../../store/data/entities/job";
import {getMediaType} from "../../store/data/entities/media";

interface JobMediaViewModalProps {
  jobMedia: JobMedia;
  onClose: () => void;
}

export function JobMediaViewModal({jobMedia, onClose}: JobMediaViewModalProps) {
  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Close', onPress: onClose, mode: 'text'},
    ],
  }

  const mediaType = getMediaType(jobMedia.url);

  return (
    <CoreModal {...modalProps}>
      <View style={styles.container}>
        {mediaType == 'video' ? (
          <video
            controls
            src={jobMedia.url}
            style={styles.media}
          />
        ) : (
          <img
            alt='Inspection Image'
            src={jobMedia.url}
            style={styles.media}
          />
        )}
      </View>
    </CoreModal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingBottom: 12,
    width: '100%'
  },
  media: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
  },
});
