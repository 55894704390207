import {StyleSheet, Switch, Text, View} from "react-native";
import GlobalStyle, {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import {Controller, RegisterOptions} from "react-hook-form";
import * as React from "react";
import {useCoreForm} from "./CoreForm";
import {Button} from "react-native-paper";

interface SwitchFieldControllerProps {
  rules?: RegisterOptions;
  controlName: string;
  title: string;
  required?: boolean;
  onButtonText?: string;
  offButtonText?: string;
  disabled?: boolean
}

export function ButtonSwitchFieldController({rules, controlName, title, required = false, onButtonText, offButtonText, disabled}: SwitchFieldControllerProps) {
  const {form: {control}} = useCoreForm();
  if (!control) return null;

  const extendedRules = {
    ...(rules ?? {}),
    required: required ? 'This field is required' : false
  };

  const handleOnChange = (value: boolean, onChangeHandler: (value: boolean) => void) => {
    if (disabled) return;
    onChangeHandler(value);
  }

  return (
    <Controller
      control={control}
      rules={extendedRules}
      name={controlName}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <View>
          <Text style={GlobalStyle.form__column__text}>{title}</Text>
          <View style={styles.buttonContainer}>
            <Button
              style={styles.button}
              mode='outlined'
              compact
              textColor={value ? PRIMARY_COLOUR : '#fff'}
              buttonColor={value ? '#fff' : PRIMARY_COLOUR}
              onPress={() => handleOnChange(false, onChange)}>{offButtonText ?? 'OFF'}</Button>
            <Button
              style={styles.button}
              mode='outlined'
              compact
              textColor={value ? '#fff' : PRIMARY_COLOUR}
              buttonColor={value ? PRIMARY_COLOUR : '#fff'}
              onPress={() => handleOnChange(true, onChange)}>{onButtonText ?? 'ON'}</Button>
          </View>
          {error && <Text style={{color: 'red'}}>{error.message}</Text>}
        </View>
      )}
    />
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  button: {
    flexBasis: 72,
    borderRadius: 4,
  }
})
