import {useAddWorkOrderAssetMutation} from "../../../store/services/api/work-order-api";
import React from "react";
import AssetSelectionCascader from "../../../components/forms/controls/AssetSelectionCascader";
import {Button as AntButton, Form, Modal} from 'antd';
import {useCamera} from "../hooks/useCamera";
import {useLazyGetAssetByUniqueRefQuery} from "../../../store/services/api/asset-api";
import {QrcodeOutlined} from "@ant-design/icons";
import {getUniqueReferenceFromUrl} from "../../../utils/qr-utils";

interface AddWorkOrderAssetModalProps {
  workOrderId: string;
  assetTypeId?: string;
  subAssetTypeId?: string;
  onClose: () => void;
  onSuccess?: (workOrderAssetId: string) => void;
  visible: boolean;
}

export default function AddWorkOrderAssetModal(props: AddWorkOrderAssetModalProps) {
  const [getAssetByQr, {isLoading: isAssetLoading}] = useLazyGetAssetByUniqueRefQuery();
  const [addAsset, {isLoading}] = useAddWorkOrderAssetMutation();
  const [cascaderValue, setCascaderValue] = React.useState<string[]>([]);

  const {showCamera} = useCamera();

  const handleOnSubmit = (assetId: string) => {
    if (!props.workOrderId) return;

    addAsset({
      assetId: assetId,
      workOrderId: props.workOrderId
    }).unwrap()
      .then((workOrderAsset) => {
        props.onClose();
        props?.onSuccess(workOrderAsset.id);
      })
  }

  const handleOnValueChange = (value: string[]) => {
    if (value.length !== 3) return;
    setCascaderValue(value);
  }

  const handleCameraButtonClick = () => {
    const handleOnResult = (url: string) => {
      const uniqueRef = getUniqueReferenceFromUrl(url);
      getAssetByQr(uniqueRef)
        .unwrap()
        .then(asset => handleOnSubmit(asset.id));
    }
    showCamera({
      mode: 'qr',
      onResult: handleOnResult
    });
  }

  return (
    <Modal
      centered
      loading={isLoading || isAssetLoading}
      title="Add Asset"
      open={props.visible}
      onOk={() => handleOnSubmit(cascaderValue[cascaderValue.length - 1])}
      onCancel={props.onClose}
    >
      <Form
        style={{
          maxHeight: '80vh',
          overflowY: 'auto'
        }}
      >
        <AssetSelectionCascader
          assetTypeId={props?.assetTypeId}
          subAssetTypeId={props?.subAssetTypeId}
          disabled={isLoading}
          value={cascaderValue}
          onValueChange={handleOnValueChange}
        />
        <AntButton
          icon={<QrcodeOutlined />}
          style={{ marginTop: 16 }}
          onClick={handleCameraButtonClick}>
          Scan QR Code
        </AntButton>
      </Form>
    </Modal>
  )
}
