import React, {createContext, useContext, useState} from "react";
import {ParamListBase} from "@react-navigation/native";
import {BottomTabBarButtonProps, createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {Feather} from "@expo/vector-icons";
import TechnicianWorkOrderDetails from "./screens/details/TechnicianWorkOrderDetails";
import TechnicianJobPlansStack from "./screens/job-plans/TechnicianJobPlansStack";
import TechnicianWorkOrderAssetsStack from "./screens/assets/TechnicianWorkOrderAssetsStack";
import TechnicianWorkLog from "./screens/work-log/TechnicianWorkLog";
import TechnicianLabour from "./screens/labour/TechnicianLabour";
import {WorkOrderStatus, WorkOrderType} from "../../../../store/data/entities/work-order";
import {TouchableOpacity} from "react-native";
import {ScreenProps} from "../../../../utils/screen-props-helper";
import {TechnicianNavigatorParamList} from "../../TechnicianNavigator";
import {ArrowLeftOutlined} from "@ant-design/icons";

const Tab = createBottomTabNavigator<TechnicianWorkOrdersStackParamList>();

interface WorkOrderContextProps {
  id: string | null;
  setUnseenAssetCount: (value: number) => void;
  setWorkOrderType: (type: WorkOrderType) => void;
  setWorkOrderStatus: (type: WorkOrderStatus) => void;
  workOrderType: WorkOrderType | undefined;
}

const WorkOrderContext = createContext<WorkOrderContextProps>(null);
export const useWorkOrder = () => useContext(WorkOrderContext);

export default function TechnicianWorkOrdersStack({navigation, route}: ScreenProps<TechnicianNavigatorParamList, 'workOrders'>) {
  const id = route?.params?.id;
  const [unseenAssetCount, setUnseenAssetCount] = useState<number | undefined>(undefined);
  const [workOrderType, setWorkOrderType] = useState<WorkOrderType | undefined>(undefined);
  const [workOrderStatus, setWorkOrderStatus] = useState<WorkOrderStatus | undefined>(undefined);

  if (!id) {
    navigation.navigate('workOrdersList');
  }

  const disableTabs = workOrderStatus !== WorkOrderStatus.InProgress

  const DynamicTabBarButton = ({ isTabDisabled, requiredType, ...props }: { isTabDisabled: boolean, requiredType?: WorkOrderType } & BottomTabBarButtonProps) => {
    if (isTabDisabled) return null;
    if (requiredType !== undefined && workOrderType !== requiredType) return null;
    return <TouchableOpacity {...props} />;
  };

  const HeaderLeft = () => (
    <TouchableOpacity
      onPress={() => navigation.goBack()}
      style={{ marginHorizontal: 20 }}
    >
      <ArrowLeftOutlined style={{fontSize: 18}} />
    </TouchableOpacity>
  )

  return (
    <WorkOrderContext.Provider value={{ id, setUnseenAssetCount, setWorkOrderType, setWorkOrderStatus, workOrderType }}>
      <Tab.Navigator>
        <Tab.Screen
          name='details'
          component={TechnicianWorkOrderDetails}
          options={{
            title: 'Details',
            tabBarIcon: ({color, size}) => (
              <Feather name='align-left' color={color} size={size} />
            ),
            headerLeft: HeaderLeft
          }}
        />
        <Tab.Screen
          name='assets'
          component={TechnicianWorkOrderAssetsStack}
          options={{
            tabBarBadge: unseenAssetCount,
            title: 'Assets',
            headerShown: false,
            tabBarIcon: ({color, size}) => (
              <Feather name='list' color={color} size={size} />
            ),
            tabBarButton: (props) => <DynamicTabBarButton isTabDisabled={disableTabs} {...props} />
          }}
        />
        <Tab.Screen
          name='workLog'
          component={TechnicianWorkLog}
          options={{
            title: 'Work Log',
            tabBarIcon: ({color, size}) => (
              <Feather name='book-open' color={color} size={size} />
            ),
            tabBarButton: (props) => <DynamicTabBarButton isTabDisabled={disableTabs} {...props} />
          }}
        />
        <Tab.Screen
          name='labour'
          component={TechnicianLabour}
          options={{
            title: 'Labour',
            tabBarIcon: ({color, size}) => (
              <Feather name='clock' color={color} size={size} />
            ),
            tabBarButton: (props) => <DynamicTabBarButton isTabDisabled={disableTabs} {...props} />
          }}
        />
        <Tab.Screen
          name='jobPlans'
          component={TechnicianJobPlansStack}
          options={{
            title: 'Job Plans',
            headerShown: false,
            tabBarIcon: ({color, size}) => (
              <Feather name='book' color={color} size={size} />
            ),
            tabBarButton: (props) => (
              <DynamicTabBarButton
                isTabDisabled={disableTabs}
                requiredType={WorkOrderType.Preventative}
                {...props}
              />
            )
          }}
        />
      </Tab.Navigator>
    </WorkOrderContext.Provider>
  );
};

export type TechnicianWorkOrdersStackParamList = ParamListBase & {
  assets: undefined;
  jobPlans: undefined;
  details: undefined;
  workLog: undefined;
  labour: undefined;
};
