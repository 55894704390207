import React, { useState } from "react";
import { Entypo, Ionicons } from "@expo/vector-icons";
import { PagedTableDto } from "../../../../store/services/api/base-api";
import { useForm, Controller } from "react-hook-form";
import { View, TouchableOpacity, TextInput, Text, StyleSheet } from "react-native";
import Spinner from "react-native-loading-spinner-overlay";
import { DataTable } from "react-native-paper";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { DataTableHeader } from "../../components/DataTableHeader";
import PaginationComponent from "../../components/PaginationComponent";
import { assetTypeApi, useCreateSubAssetTypeMutation, useDeleteSubAssetTypeMutation, useUpdateSubAssetTypeMutation } from "../../../../store/services/api/asset-type-api";
import DataTableCell from "../../components/DataTableCell";

export function SubAssetTypesList({ assetType, onClear }) {
    const [page, setPage] = useState<number>(0);
    const [sortedColumn, setSortedColumn] = React.useState<string>("name");
    const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
    const [loading, setLoading] = useState<any>(false);

    const [trigger, subAssetTypesResult] = assetTypeApi.useLazyGetSubAssetTypesQuery();
    const [createSubAssetType, createSubAssetTypeResult] = useCreateSubAssetTypeMutation();
    const [updateSubAssetType, updateSubAssetTypeResult] = useUpdateSubAssetTypeMutation();
    const [deleteSubAssetType, deleteSubAssetTypeResult] = useDeleteSubAssetTypeMutation();

    const { control, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            assetTypeId: assetType?.id,
            name: "",
        }
    });

    const id = watch("id");

    React.useEffect(() => {
        loadTable();
    }, [page, assetType, sortedColumn, sortedColumnAscending]);

    const loadTable = () => {
        trigger(new PagedTableDto(page, {
            assetTypeId: assetType?.id,
            pageSize: numberOfItemsPerPage
        }, sortedColumn, sortedColumnAscending)).then(() => {
            setLoading(false);
        });
    }

    const onSubmit = async (data) => {
        data.assetTypeId = assetType.id;
        console.log("data", data);

        var result = null;
        if (data.id != null) {
            result = await updateSubAssetType(data) as any;
        } else {
            result = await createSubAssetType(data) as any;
        }

        if (result.error) {
            alert(result.error)
            return;
        }

        reset();
        loadTable();
    }


    const edit = (subAssetType) => {
        setValue("id", subAssetType.id);
        setValue("assetTypeId", subAssetType.assetTypeId);
        setValue("name", subAssetType.name);
    }

    const remove = async (subAssetType) => {
        if (confirm("Are you sure you want to delete this sub asset type?")) {
            if (subAssetType.assetCount !== 0) {
                alert(`Can't delete asset type, there are ${subAssetType.assetCount} assets associated with it.`)
                return;
            }
            var result = await deleteSubAssetType(subAssetType.id) as any;

            if (result.error) {
                alert("Error deleting sub asset type. Please try again.")
                return;
            }

            loadTable();
        }
    }

    return (
        <View>
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                textStyle={GlobalStyle.spinnerTextStyle}
            />
            <TouchableOpacity onPress={() => onClear()}>
                <Text style={GlobalStyle.add__new__link}>
                    <Entypo name="chevron-left" size={12} color="black" style={{ marginRight: 10 }} />
                    Sub Asset Types for {assetType.name}
                </Text>
            </TouchableOpacity>

            <View style={[GlobalStyle.form, styles.form]}>
                <View style={GlobalStyle.form__row}>
                    <View style={[GlobalStyle.form__column, GlobalStyle.form__column__full]}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="name"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Sub AssetType Name (*)</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />

                        {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
                    </View>

                    <View style={styles.form__buttons}>
                        <TouchableOpacity style={styles.form__add__btn} onPress={handleSubmit(onSubmit)}>
                            <Text style={styles.form__add__btn__text}>{id == null ? 'Add' : 'Update'}</Text>
                        </TouchableOpacity>

                        {id != null &&
                            <TouchableOpacity style={styles.form__add__btn} onPress={() => reset()}>
                                <Text style={styles.form__add__btn__text}>Clear</Text>
                            </TouchableOpacity>
                        }
                    </View>
                </View>
            </View>

            <DataTable style={GlobalStyle.table}>
                <DataTableHeader
                    sortedColumn={sortedColumn}
                    sortedColumnAscending={sortedColumnAscending}
                    onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
                    headers={[
                        { text: 'Name', sortedName: "name", visible: true },
                        { text: 'Actions', visible: true },
                    ]}
                />

                {subAssetTypesResult?.data?.list.map((element: any) => {
                    return (
                        <DataTable.Row key={element.id}>
                            <DataTableCell>{element.name}</DataTableCell>
                            <DataTableCell>
                                <View style={styles.table__actions}>
                                    <TouchableOpacity style={styles.action} onPress={() => edit(element)}><Entypo name="pencil" size={24} color="black" /></TouchableOpacity>
                                    <TouchableOpacity style={styles.action} onPress={() => remove(element)}><Ionicons name="ios-remove-circle" size={24} color="red" /></TouchableOpacity>
                                </View>
                            </DataTableCell>
                        </DataTable.Row>
                    )
                })}
            </DataTable>

            <PaginationComponent
                numberOfItemsPerPage={numberOfItemsPerPage}
                setNumberOfItemsPerPage={setNumberOfItemsPerPage}
                page={page}
                setPage={setPage}
                fetchResult={subAssetTypesResult}
                setLoading={setLoading}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    },

    form: {
        borderBottomWidth: 1,
        borderColor: '#CECECE',
        paddingBottom: 20,
        marginBottom: 10
    },

    form__buttons: {
        width: 150,
        flexDirection: 'row',
    },

    form__add__btn: {
        backgroundColor: PRIMARY_COLOUR,
        flex: 1,
        height: 31,
        alignSelf: 'flex-end',
        textAlign: 'center',
        marginLeft: 10,
        marginBottom: 5,

        alignItems: 'center',
        justifyContent: 'center',
    },

    form__add__btn__text: {
        color: '#FFF',
    },

    table__actions: {
        flexDirection: 'row',
    },

    action: {
        paddingLeft: 5,
        paddingRight: 5,
    }
});
