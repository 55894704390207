import {useForm} from "react-hook-form";
import {NewJobFromTicket} from "../../store/data/entities/job";
import {useCreateJobFromTicketMutation} from "../../store/services/api/job-api";
import CoreForm from "../../screens/web/components/forms/CoreForm";
import {TextFieldController} from "../../screens/web/components/forms/TextFieldController";
import * as React from "react";
import {View} from "react-native";
import {DateFieldController} from "../../screens/web/components/forms/DateFieldController";
import {useToast} from "../toast/ToastProvider";
import {useEffect} from "react";
import moment from "moment/moment";
import {CoreModal, CoreModalProps} from "../../hooks/useModal";
import {PickerFieldController, PickerItem} from "../../screens/web/components/forms/PickerFieldController";
import {useGetUsersOfRoleQuery} from "../../store/services/api/user-api";

interface CreateJobFromTicketModalProps {
  ticketId: string;
  onClose?: () => void;
  onSuccess?: () => void;
}

export function CreateJobFromTicketModal({ticketId, onClose, onSuccess}: CreateJobFromTicketModalProps) {
  const [createTicketJob, {
    isLoading: ticketJobLoading,
    isSuccess: ticketJobSuccess,
    isError: isTicketJobError }] = useCreateJobFromTicketMutation();
  const {data: technicians, isLoading: techniciansLoading} = useGetUsersOfRoleQuery('Technician');

  const {show} = useToast();

  const form = useForm<NewJobFromTicket>({
    defaultValues: {
      reference: '',
      plannedDate: moment().toISOString(),
      ticketId: null,
      technicianId: ''
    }
  });
  const { getValues } = form;

  useEffect(() => {
    if (ticketJobSuccess) {
      onSuccess();
      form.reset();
      show('Created Job', 'Successfully created job', 'success');
    }
  }, [ticketJobSuccess]);

  useEffect(() => {
    if (isTicketJobError) {
      show('Error', 'There was an issue creating the job', 'error');
    }
  }, [isTicketJobError]);

  const handleOnFormSubmit = async () => {
    const formData = getValues() as NewJobFromTicket;
    createTicketJob({...formData, ticketId});
  }

  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Cancel', onPress: onClose, mode: 'text', disabled: ticketJobLoading},
    ],
    rightButtons: [
      {label: 'Submit', onPress: handleOnFormSubmit, mode: 'contained', disabled: ticketJobLoading},
    ]
  }

  const technicianOptions: PickerItem[] = (technicians ?? []).map((item) => ({
    label: item.name,
    value: item.id
  }))

  return (
    <CoreModal {...modalProps}>
      <View style={{minWidth: 500}}>
        <CoreForm
          isLoading={ticketJobLoading || techniciansLoading}
          footer={null}
          form={form}
          onSubmit={handleOnFormSubmit}>
          <CoreForm.Row>
            <TextFieldController controlName='reference' title='Job Reference' />
            <DateFieldController controlName='plannedDate' title='Planned Date' />
            <PickerFieldController controlName='technicianId' title='Technician' items={technicianOptions} />
          </CoreForm.Row>
        </CoreForm>
      </View>
    </CoreModal>
  )
}
