import WebPageLayout from "../../../../../layouts/WebPageLayout";
import ManagePageLayout from "../../../../../layouts/ManagePageLayout";
import React from "react";
import {ScreenProps} from "../../../../../../../utils/screen-props-helper";
import {SystemBuildingsStackParamList} from "../../SystemBuildingsStack";
import {
  useCreateBuildingMutation, useLazyGetBuildingQuery,
  useUpdateBuildingMutation
} from "../../../../../../../store/services/api/building-api";
import useEntityManagePage from "../../../../../hooks/useEntityManagePage";
import BuildingManageForm from "./components/BuildingManageForm";

export default function SystemBuildingsManageScreen({route}: ScreenProps<SystemBuildingsStackParamList, 'manage'>) {
  const id = route?.params?.id;

  const {
    entity,
    onSubmit
  } = useEntityManagePage({
    entityName: "Building",
    entityId: id,
    createMutation: useCreateBuildingMutation,
    updateMutation: useUpdateBuildingMutation,
    lazyGetEntityQuery: useLazyGetBuildingQuery
  })

  return (
    <WebPageLayout header={{
      previousTab: {title: 'Buildings', routeName: 'list'},
      tabs: [
        {title: 'Manage', disabled: true}
      ]
    }}>
      <ManagePageLayout>
        <BuildingManageForm building={entity} onSubmit={onSubmit} />
      </ManagePageLayout>
    </WebPageLayout>
  )
}
