import {Card} from "react-native-paper";
import React from "react";
import {Props} from "react-native-paper/lib/typescript/components/Card/Card";
import {ActivityIndicator, StyleSheet} from "react-native";

type OutlinedCardProps = {
  mode: 'outlined';
  elevation?: never;
};

type ElevatedCardProps = {
  mode?: 'elevated';
  elevation?: number;
};

type ContainedCardProps = {
  mode?: 'contained';
  elevation?: never;
};

type LoadingCardProps = (OutlinedCardProps | ElevatedCardProps | ContainedCardProps) & Props & {
  loading?: boolean;
};

export default function LoadingCard(props: LoadingCardProps) {
  return (
    <Card
      {...props}
      style={{backgroundColor: 'white', margin: 4, position: 'relative'}}
    >
      {props.children}
      {props.loading && (
        <ActivityIndicator size="large" style={styles.spinner} />
      )}
    </Card>
  )
}

const styles = StyleSheet.create({
  spinner: {
    borderRadius: 12,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255, 0.75)'
  }
})
