import {Checkbox, Text} from "react-native-paper";
import {BaseFormControl, BaseFormControlProps} from "./BaseFormControl";
import {TouchableOpacity} from "react-native";

interface CheckboxControlProps<TControl> extends Omit<BaseFormControlProps<TControl>, 'render'> {
  onChange?: (value: boolean) => void;
}

export function CheckboxControl<TControl>(props: CheckboxControlProps<TControl>) {
  const handleOnChange = (value: any, onChangeHandler: any) => {
    if (props.disabled) return;
    if (props.onChange) {
      props.onChange?.(value);
      return;
    }
    onChangeHandler(value);
  }

  return (
    <BaseFormControl
      {...props}
      render={({field: {value, onChange}}) => (
        <TouchableOpacity
          style={{flexDirection: 'row', gap: 4, alignItems: 'center', minHeight: 32, marginVertical: 6}}
          onPress={() => handleOnChange(!value, onChange)}
        >
          <Checkbox
            disabled={props.disabled}
            status={value ? "checked" : "unchecked"}
            onPress={() => handleOnChange(!value, onChange)}
          />
          <Text>{props.title}</Text>
        </TouchableOpacity>
      )}
    />
  )
}
