import React from "react";
import {ToastProvider} from "../../components/toast/ToastProvider";
import {UserPermission} from "../../store/data/users/user-permission";
import {ModalProvider} from "../../hooks/useModal";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import AssetStack from "./screens/asset/AssetStack";
import AdminStack from "./screens/system/AdminStack";

const Stack = createNativeStackNavigator();

export default function MobileNavigator() {
  const renderScreens = () => {
    return protectedScreens.map((s, i) => (
      <Stack.Screen
        key={`${s.name}-${i}`}
        name={s.name}
        component={s.component}
        options={{ title: s.title }}
      />
    ));
  }

  return (
    <ToastProvider>
      <ModalProvider>
        <Stack.Navigator screenOptions={{headerShown: false}}>
          {renderScreens()}
        </Stack.Navigator>
      </ModalProvider>
    </ToastProvider>
  );
}

interface ProtectedDrawerScreen {
  component: any;
  name: string;
  title: string;
  requiredPermission?: UserPermission;
}

const protectedScreens: ProtectedDrawerScreen[] = [
  {name: 'asset-screen', title: 'Asset', component: AssetStack},
  {name: 'admin', title: 'Admin', component: AdminStack},
]
