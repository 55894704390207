import {StyleSheet, View} from "react-native";
import {Button} from "react-native-paper";
import {PRIMARY_COLOUR} from "../../../constants/GlobalStyle";

interface MobileButtonsProps {
  buttons: MobileButton[];
}

export interface MobileButton {
  label: string,
  onPress: () => void,
  icon?: string,
  buttonColor?: string,
  mode?: 'text' | 'outlined' | 'contained' | 'elevated' | 'contained-tonal';
  disabled?: boolean;
}

export function MobileButtons({buttons}: MobileButtonsProps) {
  return (
    <View style={styles.container}>
      {buttons.map(({onPress, label, icon, mode, buttonColor, disabled}, index) => (
        <Button
          disabled={disabled}
          key={index}
          style={styles.button}
          mode={mode as any ?? 'contained'}
          onPress={onPress}
          icon={icon}
          buttonColor={buttonColor ?? PRIMARY_COLOUR}
        >{label}</Button>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    bottom: 0,
    left: 0,
    right: 0,
    gap: 6,
    padding: 4,
  },
  button: {
    borderRadius: 4,
    flex: 1
  }
})
