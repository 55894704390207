import {PickerItem} from "../../../screens/web/components/forms/PickerFieldController";

export enum Periodicity {
  Daily,
  Weekly,
  Monthly,
  Yearly
}

export const PeriodicityOptions: PickerItem[] = [
  { label: "Days", value: Periodicity.Daily },
  { label: "Weeks", value: Periodicity.Weekly },
  { label: "Months", value: Periodicity.Monthly },
  { label: "Years", value: Periodicity.Yearly },
]
