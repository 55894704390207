import WebPageLayout from "../../../../layouts/WebPageLayout";
import React from "react";
import {GetWorkOrderScreenHeader} from "../work-order-screen-headers";
import {WorkOrdersStackParamList} from "../../WorkOrdersStack";
import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {
  useLazyGetWorkOrderAssetsQuery
} from "../../../../../../store/services/api/work-order-api";
import {WorkOrderAsset} from "../../../../../../store/data/entities/work-order-asset";
import useEntityListPage from "../../../../hooks/useEntityListPage";
import {CoreTable} from "../../../../components/CoreTable";
import {DataTable} from "react-native-paper";
import {BaseFilters} from "../../../../../web/hooks/useFilters";
import UpdateWorkOrderAssetModal from "./components/UpdateWorkOrderAssetModal";
import {TouchableOpacity} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

interface WorkOrderAssetsFilters extends BaseFilters {
  workOrderId: string;
  showChecked: boolean;
}

export default function WorkOrderAssetsScreen({route}: ScreenProps<WorkOrdersStackParamList, 'assets'>) {
  const workOrderId = route?.params?.id;
  const [selectedWorkOrderAssetId, setSelectedWorkOrderAssetId] = React.useState<string | undefined>(undefined);

  const {
    isLoading,
    filter,
    tableData
  } = useEntityListPage<WorkOrderAssetsFilters>({
    entityName: "Asset",
    lazyTableQuery: useLazyGetWorkOrderAssetsQuery,
    filterDefinition: {
      workOrderId,
      showChecked: true,
      pageNumber: 1,
      pageSize: 10,
      sortedColumn: '',
      sortedColumnAscending: false,
    },
  });

  return (
    <WebPageLayout header={GetWorkOrderScreenHeader(workOrderId)}>
      <CoreTable<WorkOrderAsset>
        isLoading={isLoading}
        headers={[
          {text: 'Asset Code'},
          {text: 'Description'},
          {text: 'Checked Completed', flex: 0.5},
          {text: 'QR Tagged', flex: 0.5},
          {text: 'Follow-up Required', flex: 0.5},
          {text: 'Log Book Entry'},
          {text: 'Action', flex: 0.5}
        ]}
        data={tableData}
        keyExtractor={(item) => item.id}
        renderItem={({item, index}) => {
          return (
            <DataTable.Row key={index}>
              <CoreTable.Cell>{item.assetCode}</CoreTable.Cell>
              <CoreTable.Cell>{item.assetDescription}</CoreTable.Cell>
              <CoreTable.Cell style={{flex: 0.5}}>{item.isChecksCompleted ? 'Y' : 'N'}</CoreTable.Cell>
              <CoreTable.Cell style={{flex: 0.5}}>{item.isQRScanned ? 'Y' : 'N'}</CoreTable.Cell>
              <CoreTable.Cell style={{flex: 0.5}}>{item.isFollowUpRequired ? 'Y' : 'N'}</CoreTable.Cell>
              <CoreTable.Cell>{item.comments}</CoreTable.Cell>
              <CoreTable.Cell style={{flex: 0.5}} tooltip={false}>
                <TouchableOpacity onPress={() => setSelectedWorkOrderAssetId(item.id)}>
                  <MaterialIcons name="edit" size={24} color="black" />
                </TouchableOpacity>
              </CoreTable.Cell>
            </DataTable.Row>
          )
        }}
        filters={filter.filters}
        setFilter={filter.setFilter}
      >
      </CoreTable>

      <UpdateWorkOrderAssetModal
        workOrderAssetId={selectedWorkOrderAssetId}
        workOrderId={workOrderId}
        open={!!selectedWorkOrderAssetId}
        onClose={() => setSelectedWorkOrderAssetId(undefined)}
      />
    </WebPageLayout>
  )
}
