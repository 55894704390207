import {Button, Dialog} from "react-native-paper";
import {Image, StyleSheet} from "react-native";
import React from "react";


interface ImagePreviewModalProps {
  imageBase64: string;
  onClose: () => void;
}

export default function ImagePreviewModal({imageBase64, onClose}: ImagePreviewModalProps) {
  return (
    <Dialog visible={true} onDismiss={onClose}>
      <Dialog.Title>Image Preview</Dialog.Title>
      <Dialog.Content>
        <Image
          style={styles.imagePreview}
          source={{uri: imageBase64}}
          resizeMode={'cover'}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button onPress={onClose}>Done</Button>
      </Dialog.Actions>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  imagePreview: {
    width: '100%',
      maxHeight: 400,
      aspectRatio: 1,
      borderRadius: 4,
  }
})
