import {FlatList, ListRenderItem, StyleSheet, Text, View} from "react-native";
import {DataTable} from "react-native-paper";
import GlobalStyle from "../../../../constants/GlobalStyle";
import {DataTableHeader} from "../DataTableHeader";
import {EmptyList} from "../../../../components/flatlist/EmptyList";
import React from "react";
import {PagedResponse} from "../../../../utils/helpers";
import {BaseFilters} from "../../hooks/useFilters";
import {Picker} from "@react-native-picker/picker";

interface CoreTableHeader {
  text: string;
  sortedName?: string | undefined;
  visible: boolean;
  flex?: number;
}

interface CoreTableProps<T> {
  headers: CoreTableHeader[];
  data: PagedResponse<T>;
  renderItem: ListRenderItem<T>;
  isLoading?: boolean;
  filters: BaseFilters;
  setFilter: (key: keyof BaseFilters, value: any) => void;
  emptyListText?: string;
}

export function CoreTable<T>(props: CoreTableProps<T>) {
  const {
    headers,
    data,
    renderItem,
    isLoading,
    filters,
    setFilter,
    emptyListText = 'There are no results. Try changing your search filters'
  } = props;
  const {pageNumber, sortedColumn, sortedColumnAscending, pageSize} = filters;

  const getPaginationLabel = () => {
    const start = data?.pagination.currentStartIndex;
    const end = data?.pagination.currentEndIndex;
    const total = data?.pagination.totalCount;
    if (!start || !end || !total) return ''

    return `${start}-${end} of ${total}`
  }

  // todo Create a better way of displaying table loading
  const previewData = isLoading ? [] : data?.list;

  return (
    <DataTable style={GlobalStyle.table}>
      <DataTableHeader
        sortedColumn={sortedColumn}
        sortedColumnAscending={sortedColumnAscending}
        onSorted={(column, ascending) => {
          setFilter('sortedColumn', column);
          setFilter('sortedColumnAscending', ascending);
        }}
        headers={headers}
      />

      <FlatList
        data={previewData}
        renderItem={renderItem}
        ListEmptyComponent={
          <EmptyList
            isLoading={isLoading}
            emptyText={emptyListText} />
        }
      />

      <View style={styles.pagination}>
        <View style={styles.pagination__dropdown}>
          <Text style={styles.pagination__dropdown__header}>Rows per page</Text>
          <Picker
            style={styles.picker}
            selectedValue={pageSize}
            onValueChange={(value) => setFilter('pageSize', value)}>

            {[10, 25, 50].map(value => (
              <Picker.Item key={value} label={value.toString()} value={value} />
            ))}
          </Picker>
        </View>

        <DataTable.Pagination
          page={pageNumber - 1}
          numberOfPages={data?.pagination.totalPages}
          onPageChange={(page) => setFilter('pageNumber', page + 1)}
          label={getPaginationLabel()}
          showFastPaginationControls
          numberOfItemsPerPage={pageSize}
        />
      </View>
    </DataTable>
  )
}


const styles = StyleSheet.create({
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pagination__dropdown: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  pagination__dropdown__header: {
    paddingRight: 5,
    fontFamily: 'OpenSans-Light',
  },
  picker: {
    borderWidth: 1,
    borderRadius: 10,
    overflow: 'hidden',
    width: 40,
    height: 20,
  },
});
