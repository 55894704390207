export type UserRole =
  | "Admin"
  | "Helpdesk"
  | "BuildingLead"
  | "Technician";

interface UserRoleItem {
  value: UserRole;
  label: string;
}

export const UserRoles: UserRoleItem[] = [
  {
    value: 'Admin',
    label: 'Admin',
  },
  {
    value: 'Helpdesk',
    label: 'Helpdesk',
  },
  {
    value: 'BuildingLead',
    label: 'Building Lead',
  },
  {
    value: 'Technician',
    label: 'Technician',
  }
];
