import {NativeStackScreenProps} from "@react-navigation/native-stack";
import React, {useEffect} from "react";
import {MobileButton, MobileButtons} from "../../../../components/MobileButtons";
import {useToast} from "../../../../../../components/toast/ToastProvider";
import {
  MaintenanceJobControl,
  MaintenanceJobControlForm
} from "../../../../../../components/job-controls/maintenance-job/MaintenanceJobControl";
import {useJobControl} from "../../../../../../hooks/useJobControl";
import {HelpButton} from "../../../../components/header/help-button";
import {useForm} from "react-hook-form";
import MobileScreen from "../../../../components/MobileScreen";

export function MaintenanceJob({navigation, route}: NativeStackScreenProps<any, any>) {
  const {show: showToast} = useToast();
  const jobId = route.params?.jobId;

  const handleOnSuccess = () => {
    showToast('Successfully completed job', 'Successfully completed maintenance checklist', 'success');
    returnToJobsList();
  }

  const handleOnHelpPress = () => {
    navigation.navigate('help', {
      jobId: jobId
    })
  }

  const returnToJobsList = () => {
    navigation.navigate('jobs', {
      screen: 'jobs-list'
    });
  }

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <HelpButton onPress={handleOnHelpPress} />
    })
  }, []);

  const handleOnError = (message?: string) => {
    showToast('Error', message ?? 'There was an error', 'error');
  }

  const NavigationButtons = () => {
    const { handleOnFinish, handleOnNext, handleOnBack, isLastControl, isFirstControl } = useJobControl();
    const buttons: MobileButton[] = [
      {label: 'Back', onPress: handleOnBack, disabled: isFirstControl},
      {label: isLastControl ? 'Finish' : 'Next', onPress: isLastControl ? handleOnFinish : handleOnNext},
    ]

    return (
      <MobileButtons buttons={buttons}/>
    );
  };

  const form = useForm<MaintenanceJobControlForm>({
    defaultValues: {
      jobId,
      checklistResponses: [],
      parts: [],
      images: []
    }
  });

  useEffect(() => {
    if (!jobId) {
      returnToJobsList();
      return;
    }
    form.setValue('jobId', jobId);
  }, [jobId]);

  return (
    <MobileScreen>
      <MaintenanceJobControl
        jobId={jobId}
        onError={handleOnError}
        onSuccess={handleOnSuccess} >
        <NavigationButtons />
      </MaintenanceJobControl>
    </MobileScreen>
  )
}
