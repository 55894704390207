import {createNativeStackNavigator, NativeStackScreenProps} from "@react-navigation/native-stack";
import React from "react";
import {BackButton} from "../../../../components/header/back-button";
import {useModal} from "../../../../../../hooks/useModal";
import {ConfirmModal} from "../../../../../../components/modals/ConfirmModal";
import {CorrectiveJob} from "./CorrectiveJob";
import {MaintenanceJobHelp} from "../MaintenanceJob/MaintenanceJobHelp";
import {HelpButton} from "../../../../components/header/help-button";
import {CorrectiveJobHelp} from "./CorrectiveJobHelp";

const Stack = createNativeStackNavigator();

export function CorrectiveJobNavigator({navigation, route}: NativeStackScreenProps<any, any>) {
  const {showModal, hideModal} = useModal();
  const jobId = route.params?.jobId;

  const handleOnBackPress = () => {
    showModal(
      <ConfirmModal
        title='Are you sure?'
        message='Are you sure you want to go back? You will loose all your progress.'
        onConfirm={() => {
          hideModal();
          navigation.navigate('jobs', { screen: 'jobs-list'})
        }}
        onClose={hideModal} />
    )
  }

  return (
    <>
      <Stack.Navigator initialRouteName='job'>
        <Stack.Screen
          name="job"
          component={CorrectiveJob}
          initialParams={{jobId}}
          options={{
            headerShown: true,
            title: 'Corrective Works',
            headerLeft: () => <BackButton onPress={handleOnBackPress} />
          }}
        />
        <Stack.Screen
          name="help"
          component={CorrectiveJobHelp}
          initialParams={{jobId}}
          options={{
            headerShown: true,
            title: 'Help'
          }}
        />
      </Stack.Navigator>
    </>
  )
}
