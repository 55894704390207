import {Checkbox, Form, Input, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {WorkOrderAssetForm} from "../../../../../../../store/data/entities/work-order-asset";
import {
  useFindWorkOrderAssetQuery, useUpdateWorkOrderAssetMutation
} from "../../../../../../../store/services/api/work-order-api";
import FollowUpWorkOrderModal from "../../../../../../technician-v2/modals/FollowUpWorkOrderModal";

interface UpdateWorkOrderAssetModalProps {
  workOrderAssetId: string;
  workOrderId: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export default function UpdateWorkOrderAssetModal(props: UpdateWorkOrderAssetModalProps) {
  const [form] = Form.useForm<WorkOrderAssetForm>();
  const [updateWorkOrderAsset, {isLoading}] = useUpdateWorkOrderAssetMutation();
  const {data: asset, isFetching, isError} = useFindWorkOrderAssetQuery({
    workOrderId: props.workOrderId,
    workOrderAssetId: props.workOrderAssetId
  }, {
    skip: !props.workOrderAssetId || !props.workOrderAssetId
  });

  const [workOrderModalVisible, setWorkOrderModalVisible] = useState(false);

  const handleOnFinish = (data: WorkOrderAssetForm) => {
    if (!asset) return;

    if (data.isFollowUpRequired && !data.followUpWorkOrderId) {
      setWorkOrderModalVisible(true);
      return;
    }

    updateWorkOrderAsset({
      ...data,
      isFollowUpRequired: data.isFollowUpRequired,
      isQRScanned: data.isQRScanned
    }).unwrap()
      .then(() => props.onSuccess?.())
      .finally(() => props.onClose());
  }

  const handleWorkOrderCreation = (workOrderId: string) => {
    form.setFieldValue('followUpWorkOrderId', workOrderId);
    form.submit();
  }

  useEffect(() => {
    if (asset) {
      form.setFieldValue('id', asset.id);
      form.setFieldValue('isFollowUpRequired', asset.isFollowUpRequired);
      form.setFieldValue('followUpWorkOrderId', asset.followUpWorkOrderId);
      form.setFieldValue('isChecksCompleted', asset.isChecksCompleted);
      form.setFieldValue('isQRScanned', asset.isQRScanned);
      form.setFieldValue('comments', asset.comments);
    }
  }, [asset]);

  useEffect(() => {
    form.resetFields();
  }, [props.open]);

  useEffect(() => {
    props.onClose();
  }, [isError]);

  return (
    <>
      <Modal
        title='Update Asset Details'
        open={props.open}
        onClose={props.onClose}
        onCancel={props.onClose}
        onOk={() => form.submit()}
        loading={isFetching || isLoading}
      >
        <Form<WorkOrderAssetForm>
          form={form}
          layout='vertical'
          onFinish={handleOnFinish}
        >
          <Form.Item name='id' hidden />
          <Form.Item name='followUpWorkOrderId' hidden />


          <Form.Item name='isChecksCompleted' valuePropName="checked">
            <Checkbox>Checks Completed</Checkbox>
          </Form.Item>

          <Form.Item name='isQRScanned' valuePropName="checked">
            <Checkbox disabled>QR Tagged</Checkbox>
          </Form.Item>

          <Form.Item name='isFollowUpRequired' valuePropName="checked">
            <Checkbox>Follow-up Required</Checkbox>
          </Form.Item>

          <Form.Item name='comments' label='Logbook Entry'>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>

      <FollowUpWorkOrderModal
        parentWorkOrderId={props.workOrderId}
        onSuccess={handleWorkOrderCreation}
        assetId={asset?.assetId}
        assetLabel={asset?.assetDescription}
        visible={workOrderModalVisible}
        onClose={() => setWorkOrderModalVisible(false)}
      />
    </>
  )
}
