import WebPageLayout from "../../../../../layouts/WebPageLayout";
import ManagePageLayout from "../../../../../layouts/ManagePageLayout";
import React from "react";
import {ScreenProps} from "../../../../../../../utils/screen-props-helper";
import {SystemAssetsStackParamList} from "../../SystemAssetsStack";
import {compressImage, PlatformType} from "../../../../../../../utils/imageUtils";
import useEntityManagePage from "../../../../../hooks/useEntityManagePage";
import {
  useAddAssetMutation,
  useLazyGetAssetQuery,
  useUpdateAssetMutation
} from "../../../../../../../store/services/api/asset-api";
import AssetManageForm from "./AssetManageForm";

export default function SystemAssetsManageScreen({route}: ScreenProps<SystemAssetsStackParamList, 'manage'>) {
  const {
    entity,
    onSubmit
  } = useEntityManagePage({
    entityName: "Asset",
    entityId: route?.params?.id,
    createMutation: useAddAssetMutation,
    updateMutation: useUpdateAssetMutation,
    lazyGetEntityQuery: useLazyGetAssetQuery
  })

  const handleOnSubmit = async (data: any) => {
    if (data.imageBase64) {
      data.imageBase64 = await compressImage(data.imageBase64, PlatformType.Web);
    }
    await onSubmit(data);
  }

  return (
    <WebPageLayout header={{
      previousTab: {title: 'Assets', routeName: 'list'},
      tabs: [
        {title: 'Manage Asset', routeName: 'manage'}
      ]
    }}>
      <ManagePageLayout>
        <AssetManageForm asset={entity} onSubmit={handleOnSubmit} />
      </ManagePageLayout>
    </WebPageLayout>
  )
}
