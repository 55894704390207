import WebPageLayout from "../../../../../layouts/WebPageLayout";
import ManagePageLayout from "../../../../../layouts/ManagePageLayout";
import React from "react";
import {ScreenProps} from "../../../../../../../utils/screen-props-helper";
import {SystemAssetTypesStackParamList} from "../../SystemAssetTypesStack";
import {
  useCreateAssetTypeMutation, useLazyGetAssetTypeQuery,
  useUpdateAssetTypeMutation
} from "../../../../../../../store/services/api/asset-type-api";
import useEntityManagePage from "../../../../../hooks/useEntityManagePage";
import AssetTypeManageForm from "./components/AssetTypeManageForm";

export default function SystemAssetTypesManageScreen({route}: ScreenProps<SystemAssetTypesStackParamList, 'manage'>) {
  const id = route?.params?.id;
  const {
    entity,
    onSubmit
  } = useEntityManagePage({
    entityName: "Asset Type",
    entityId: id,
    createMutation: useCreateAssetTypeMutation,
    updateMutation: useUpdateAssetTypeMutation,
    lazyGetEntityQuery: useLazyGetAssetTypeQuery
  })

  return (
    <WebPageLayout header={{
      previousTab: {title: 'Asset Types', routeName: 'list'},
      tabs: [
        {title: 'Manage', routeName: 'manage', params: {id}}
      ]
    }}>
      <ManagePageLayout>
        <AssetTypeManageForm
          assetType={entity}
          onSubmit={onSubmit} />
      </ManagePageLayout>
    </WebPageLayout>
  )
}
