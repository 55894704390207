import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import JobPlansListScreen from "./screens/list/JobPlansListScreen";
import JobPlanManageScreen from "./screens/manage/JobPlanManageScreen";

const Stack = createNativeStackNavigator<JobPlansStackParamList>();

export default function JobPlansStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='list'
        component={JobPlansListScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='manage'
        component={JobPlanManageScreen}
        options={{headerShown: false}}
      />
    </Stack.Navigator>
  );
};

export type JobPlansStackParamList = ParamListBase & {
  list: undefined;
  manage: { id: string, approval?: boolean };
};
