import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import TechnicianWorkOrderAssetsList from "./TechnicianWorkOrderAssetsList";
import TechnicianWorkOrderAssetDetails from "./TechnicianWorkOrderAssetDetails";

const Stack = createNativeStackNavigator<TechnicianWorkOrderAssetsStackParamList>();

export default function TechnicianWorkOrderAssetsStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='list'
        component={TechnicianWorkOrderAssetsList}
        options={{
          headerShown: true,
          headerLeft: () => null,
          title: 'Assets'
        }}
      />
      <Stack.Screen
        name='details'
        component={TechnicianWorkOrderAssetDetails}
        options={{
          headerShown: true,
          title: 'Asset'
        }}
      />
    </Stack.Navigator>
  );
};

export type TechnicianWorkOrderAssetsStackParamList = ParamListBase & {
  list: undefined;
  details: { id: string, qrScanned?: boolean | undefined };
};
