import MobilePageLayout from "../../../layouts/MobilePageLayout";
import {useLazyGetAssetQuery} from "../../../../../store/services/api/asset-api";
import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AdminStackParamList} from "../AdminStack";
import React, {useEffect} from "react";
import {useLazyGetTicketsByAssetQuery} from "../../../../../store/services/api/ticket-api";
import {useSetLoading} from "../../../../../hooks/useSetLoading";
import {Card, TextInput} from "react-native-paper";
import {FlatList, View} from "react-native";
import Descriptions from "../../../../web-v2/components/Descriptions";

export default function AdminAssetDetailsScreen({route}: ScreenProps<AdminStackParamList, 'assetDetails'>) {
  const assetId = route.params?.assetId;
  const [getAsset, { data: asset, isFetching: assetFetching }] = useLazyGetAssetQuery();
  const [getTickets, {data: tickets, isFetching: ticketsFetching}] = useLazyGetTicketsByAssetQuery();

  useSetLoading([assetFetching, ticketsFetching]);

  useEffect(() => {
    if (assetId) {
      getAsset(assetId)
        .unwrap()
        .then((asset) => getTickets(asset.id))
    }
  }, [assetId]);

  return (
    <MobilePageLayout>
      <Card style={{backgroundColor: 'white'}}>
        <Card.Content style={{flexDirection: 'column', gap: 12}}>
          <TextInput
            value={asset?.description ?? ''}
            label='Item Description'
            mode='outlined'
            multiline
            numberOfLines={3}
            disabled
          />

          <FlatList
            ItemSeparatorComponent={() => <View style={{height: 12}}/>}
            data={tickets ?? []}
            keyExtractor={(item) => item.id}
            renderItem={({item}) => (
              <Descriptions
                data={item}
                columns={[
                  {title: 'Ticket Number', key: 'number'},
                  {title: 'Issue', key: 'description'},
                  {title: 'Latest Update', key: 'latestUpdate'},
                ]}
              />
            )}
          />
        </Card.Content>
      </Card>
    </MobilePageLayout>
  )
}
