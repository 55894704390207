import {NativeStackScreenProps} from "@react-navigation/native-stack";
import React, {useEffect} from "react";
import {MobileButton, MobileButtons} from "../../../../components/MobileButtons";
import {useToast} from "../../../../../../components/toast/ToastProvider";
import {
  MaintenanceJobControlForm
} from "../../../../../../components/job-controls/maintenance-job/MaintenanceJobControl";
import {useJobControl} from "../../../../../../hooks/useJobControl";
import {useForm} from "react-hook-form";
import MobileScreen from "../../../../components/MobileScreen";
import {CorrectiveJobControl} from "../../../../../../components/job-controls/corrective-job/CorrectiveJobControl";
import {HelpButton} from "../../../../components/header/help-button";

export function CorrectiveJob({navigation, route}: NativeStackScreenProps<any, any>) {
  const {show: showToast} = useToast();
  const jobId = route.params?.jobId;

  const handleOnSuccess = () => {
    showToast('Successfully completed job', 'Successfully completed corrective job', 'success');
    navigation.navigate('jobs', {
      screen: 'jobs-list'
    });
  }

  const handleOnHelpPress = () => {
    navigation.navigate('help', {
      jobId: jobId
    })
  }

  const handleOnError = (message?: string) => {
    showToast('Error', message ?? 'There was an error', 'error');
  }

  const NavigationButtons = () => {
    const { handleOnFinish } = useJobControl();
    const buttons: MobileButton[] = [
      {label: 'Finish', onPress: handleOnFinish},
    ]

    return (
      <MobileButtons buttons={buttons}/>
    );
  };

  const form = useForm<MaintenanceJobControlForm>({
    defaultValues: {
      jobId,
      checklistResponses: [],
      parts: [],
      images: []
    }
  });

  useEffect(() => {
    form.setValue('jobId', jobId);
  }, [jobId]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <HelpButton onPress={handleOnHelpPress} />
    })
  }, []);

  return (
    <MobileScreen>
      <CorrectiveJobControl
        jobId={jobId}
        onSuccess={handleOnSuccess}
        onError={handleOnError}
      >
        <NavigationButtons />
      </CorrectiveJobControl>
    </MobileScreen>
  )
}
