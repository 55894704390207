import {FlatList, ListRenderItemInfo, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import React, {useEffect, useState} from "react";
import {Feather} from "@expo/vector-icons";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {useLazyGetWorkOrdersQuery} from "../../../../store/services/api/work-order-api";
import {
  WorkOrder,
  WorkOrderPriorityScoreIcons,
  WorkOrderStatus,
  WorkOrderType
} from "../../../../store/data/entities/work-order";
import Skeleton from "../../../../components/skeletons/Skeleton";
import {Button, Card} from "react-native-paper";
import {TechnicianNavigatorParamList} from "../../TechnicianNavigator";
import {ScreenProps} from "../../../../utils/screen-props-helper";
import {Flex} from "antd";
import {Text as PaperText} from 'react-native-paper';
import {humanizeDate} from "../../../../utils/humanizers";

export function TechnicianWorkOrdersList({navigation}: ScreenProps<TechnicianNavigatorParamList, 'workOrdersList'>) {
  const {user} = useSelector((state: RootState) => state.user);
  const [getWorkOrders, {data: latestPage, isFetching}] = useLazyGetWorkOrdersQuery();
  const [listData, setListData] = useState<WorkOrder[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedPriorityScore, setSelectedPriorityScore] = useState<number | undefined>(undefined);
  const canLoadMore = !isFetching && (latestPage?.pagination.hasNext);

  useEffect(() => {
    fetchInitialPage();
  }, [selectedPriorityScore]);

  useEffect(() => {
    navigation.setOptions({
      title: 'Work Orders',
      headerRight: () => (
        <TouchableOpacity
          disabled={isFetching}
          onPress={fetchInitialPage}
          style={{paddingHorizontal: 14, paddingVertical: 6}}>
          <Feather name="refresh-cw" size={24} color={isFetching ? 'gray' : 'black'} />
        </TouchableOpacity>
      )
    });
  }, [isFetching, navigation]);

  const fetchInitialPage = () => fetchWorkOrders(true);

  const fetchWorkOrders = (isInitialPage: boolean = false) => {
    if (!isInitialPage && !canLoadMore) return;

    const pageNumber = isInitialPage ? 1 : currentPage + 1;

    getWorkOrders({
      pageNumber,
      pageSize: 10,
      technicianId: user.id,
      isApproved: true,
      statuses: [
        WorkOrderStatus.InProgress,
        WorkOrderStatus.Approved,
      ],
      priorityScore: selectedPriorityScore,
    }).unwrap()
      .then((page) => {
        setListData((prev) => isInitialPage ? page.list : [...prev, ...page.list]);
        setCurrentPage((curr) => curr + 1);
      });
  };

  const renderItem = ({item}: ListRenderItemInfo<WorkOrder>) => {
    const handleOnItemPress = () => {
      navigation.navigate("workOrders", {id: item.id});
    }

    const icons = {
      [WorkOrderType.Preventative]: 'calendar',
      [WorkOrderType.Corrective]: 'tool',
      [WorkOrderType.Improvement]: 'trending-up',
    }

    const icon = WorkOrderPriorityScoreIcons[item.priorityScore];

    return (
      <Card style={{backgroundColor: 'white'}} onPress={handleOnItemPress}>
        <Card.Content style={styles.listItemContainer}>
          {/*@ts-ignore*/}
          <Flex justify='space-between' align='center' gap={6} style={styles.icon}>
            <View style={[styles.priorityIcon, {backgroundColor: icon?.color}]}>
              <Feather name={icon?.name as any} color='white' size={20} />
            </View>
            <Feather name={icons[item.type] as any} size={20}/>
          </Flex>
          <View style={styles.itemDetails}>
            <Flex justify='space-between' align='center'>
              <Text style={styles.itemReference}>{item.reference}</Text>
              <Text style={styles.listItemDateText}>{humanizeDate(item.scheduledStartDate)}</Text>
            </Flex>
            <Text style={styles.listItemDateText}>{item.description}</Text>
            <Text style={styles.listItemDateText}>{item.locationLabel}</Text>
          </View>
          <View style={styles.icon}>
            <Feather name='chevron-right' size={20}/>
          </View>
        </Card.Content>
      </Card>
    )
  }

  const PriorityHeader = () => {
    const handleOnSelectScore = (value: number) => {
      const nextValue = selectedPriorityScore === value ? undefined : value;
      setSelectedPriorityScore(nextValue);
    }

    const getPriorityCount = (scoreValue: string) => latestPage?.priorityCounts[scoreValue] ?? 0;

    return (
      <View style={styles.container}>
        {Object.keys(WorkOrderPriorityScoreIcons).map((key) => {
          const value = WorkOrderPriorityScoreIcons[key];
          const isSelected = selectedPriorityScore === Number(key);
          const textColor = value.textColor ?? 'white';

          return (
            <TouchableOpacity
              key={key}
              onPress={() => handleOnSelectScore(Number(key))}
              style={[
                styles.circle,
                { backgroundColor: value.color },
                isSelected && styles.selectedSquare,
              ]}
            >
              <PaperText variant="titleMedium" style={{textAlign: 'center', color: textColor}}>
                {getPriorityCount(key)}
              </PaperText>
            </TouchableOpacity>
          );
        })}
      </View>
    )
  };


  return (
    <>
      <PriorityHeader />
      <FlatList<WorkOrder>
        style={{padding: 4}}
        ItemSeparatorComponent={() => <View style={{height: 6}} />}
        keyExtractor={(workOrder: WorkOrder) => workOrder.id}
        data={listData}
        renderItem={renderItem}
        ListFooterComponent={isFetching ? <Skeleton.Card rows={1} /> : (
          <Button
            disabled={!canLoadMore}
            style={{marginVertical: 6}}
            mode='elevated'
            onPress={() => fetchWorkOrders()}
            loading={isFetching}
          >
            Load More
          </Button>
        )}
      />
    </>
  )
}

const styles = StyleSheet.create({
  listItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 18,
    marginVertical: 4
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemDetails: {
    flex: 1,
  },
  itemReference: {
    fontSize: 16,
    fontWeight: '600',
    flexWrap: 'nowrap',
    //@ts-ignore
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  listItemDateText: {
    fontWeight: '400',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 8,
    marginHorizontal: 4,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    borderBottomWidth: 1,
    borderColor: 'rgb(216, 216, 216)'
  },
  circle: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    aspectRatio: 1,
    //@ts-ignore
    borderRadius: '35%',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 6,
    maxWidth: 64
  },
  selectedSquare: {
    borderWidth: 2,
    borderColor: 'black',
  },
  priorityIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 999,
    width: 32,
    height: 32,
  }
})
