import {useSetWorkOrderStatusMutation} from "../../../store/services/api/work-order-api";
import React from "react";
import {Modal} from 'antd';
import {WorkOrderStatus} from "../../../store/data/entities/work-order";
import {Text} from 'react-native';

interface AddWorkOrderAssetModalProps {
  workOrderId: string;
  visible: boolean;
  targetStatus: WorkOrderStatus.InProgress | WorkOrderStatus.Completed;
  onClose: () => void;
  onSuccess?: (newStatus: WorkOrderStatus) => void;
}

export default function WorkOrderSetStatusModal(props: AddWorkOrderAssetModalProps) {
  const [setStatus, {isLoading}] = useSetWorkOrderStatusMutation();

  const handleSetWorkOrderStatus = () => {
    setStatus({
      workOrderId: props.workOrderId,
      status: props.targetStatus
    }).unwrap()
      .then(() => props.onSuccess?.(props.targetStatus))
      .finally(props.onClose)
  }

  const title = props.targetStatus === WorkOrderStatus.Completed
    ? 'Complete Work Order'
    : 'Begin Work Order';

  const message = props.targetStatus === WorkOrderStatus.Completed
    ? 'Are you sure you want to complete this work order?'
    : 'Are you sure you want to begin work on this work order?';

  const messageDescription = props.targetStatus === WorkOrderStatus.Completed
    ? "Once completed, you won't be able to update any details within this work order unless reopened."
    : '';

  return (
    <Modal
      centered
      loading={isLoading}
      title={title}
      open={props.visible}
      onOk={handleSetWorkOrderStatus}
      onCancel={props.onClose}
    >
      <Text>{message}</Text>
      <br/>
      <Text>{messageDescription}</Text>
    </Modal>
  )
}
