import React from "react";
import {FlatList, TouchableOpacity, StyleSheet, View, Text, ActivityIndicator} from "react-native";
import {Feather} from "@expo/vector-icons";
import {PRIMARY_COLOUR} from "../../../constants/GlobalStyle";
import {getMediaType, Media} from "../../../store/data/entities/media";
import {humanizeString} from "../../../utils/humanizers";

interface MediaManagerProps {
  media: Media[];
  isLoading?: boolean;
  onShowModalPress?: () => void;
  onRemoveMedia?: (media: Media) => void;
  onEditMedia?: (media: Media) => void;
}

export function MediaManager({media, isLoading = false, onShowModalPress, onRemoveMedia, onEditMedia}: MediaManagerProps) {
  const renderItem = (item: Media) => {
    const mediaType = getMediaType(item?.uri);

    return (
      <View style={styles.card}>
        <Text style={styles.mediaType}>{humanizeString(mediaType ?? 'Unknown', 'title')}</Text>
        <Text style={styles.title}>{item?.title}</Text>
        <Text style={styles.description}>{item?.description}</Text>
        <View style={styles.manageOptionContainer}>
          {onEditMedia && (
            <TouchableOpacity onPress={() => onEditMedia(item)}>
              <Feather color='#444' size={20} name='edit'/>
            </TouchableOpacity>
          )}
          {onRemoveMedia && (
            <TouchableOpacity onPress={() => onRemoveMedia(item)}>
              <Feather color='#f03e3e' size={20} name='x-circle'/>
            </TouchableOpacity>
          )}
        </View>
      </View>
    )
  }

  return (
    <>
      <View style={styles.sectionContainer}>
        {onShowModalPress && (
          <TouchableOpacity
            style={styles.addButton}
            onPress={onShowModalPress}>
            <Feather name="plus" size={24} color="#fff"/>
          </TouchableOpacity>
        )}
        <FlatList
          data={media}
          renderItem={({item}) => renderItem(item)}
          contentContainerStyle={styles.container}
          keyExtractor={(item, index) => index.toString()}
          ListEmptyComponent={() => <EmptyListItem isLoading={isLoading} />}
          horizontal>
        </FlatList>
      </View>
    </>
  )
}

function EmptyListItem({isLoading}: {isLoading: boolean}) {
  return (
    <View style={styles.emptyCard}>
      {isLoading ? (
        <ActivityIndicator animating={true} color={PRIMARY_COLOUR} />
      ): (
        <Text>There is currently no media</Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  sectionContainer: {
    marginTop: 12,
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: 4,
    minHeight: 150,
    overflow: 'hidden'
  },
  addButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    backgroundColor: PRIMARY_COLOUR,
    borderRadius: 9999,
    padding: 8,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 15,
    zIndex: 10
  },
  container: {
    paddingBottom: 12
  },
  emptyCard: {
    padding: 10,
    width: '100%',
    height: 150,
    margin: 10,
  },
  card: {
    position: 'relative',
    backgroundColor: '#f1f1f1',
    borderRadius: 4,
    shadowColor: '#000',
    shadowOffset: {width: 1, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 5,
    padding: 10,
    minWidth: 150,
    maxWidth: 200,
    height: 150,
    margin: 10,
  },
  title: {
    fontSize: 18,
    marginTop: 4
  },
  description: {
    fontSize: 12,
  },
  mediaType: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  manageOptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: 12
  }
});
