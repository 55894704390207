import {CoreModal, CoreModalProps} from "../../../../../hooks/useModal";
import {View, Text, StyleSheet} from "react-native";
import {useLazyGetUpdatesQuery} from "../../../../../store/services/api/ticket-api";
import useFilters, {BaseFilters} from "../../../hooks/useFilters";
import React, {useEffect} from "react";
import {DataTable} from "react-native-paper";
import {humanizeDate} from "../../../../../utils/humanizers";
import {CoreTable} from "../../../components/core-table/CoreTable";
import {Update} from "../../../../../store/data/entities/update";

interface TicketUpdatesModalProps {
  ticketId: string;
  onClose: () => void;
}

interface TicketsListFilters extends BaseFilters {
  ticketId: string;
}

export function TicketUpdatesListModal({ticketId, onClose}: TicketUpdatesModalProps) {
  const [getUpdates, {data, isFetching}] = useLazyGetUpdatesQuery();
  const loadTable = (filters?: Record<string, any>) => getUpdates(filters ?? getFilterValues());

  const filter = useFilters<TicketsListFilters>({
    ticketId: ticketId,
    pageNumber: 1,
    pageSize: 10,
    sortedColumn: "",
    sortedColumnAscending: false
  }, loadTable);
  const {getFilterValues, filters, setFilter} = filter;

  useEffect(() => {
    loadTable();
  }, [ticketId]);

  const modalProps: CoreModalProps = {
    leftButtons: [
      {label: 'Close', onPress: onClose, mode: 'text'},
    ],
  }

  return (
    <CoreModal {...modalProps}>
      <View style={styles.container}>
        <Text style={styles.title}>Ticket Updates</Text>
        <CoreTable<Update>
          filters={filters}
          setFilter={setFilter}
          isLoading={isFetching}
          emptyListText='There are no updates for this ticket.'
          data={data}
          headers={[
            {text: 'Date', visible: true, flex: 0.2},
            {text: 'Description', visible: true},
          ]}
          renderItem={({item}) => (
            <DataTable.Row key={item.id}>
              <DataTable.Cell style={{flex: 0.2}}>{humanizeDate(item.dateCreated)}</DataTable.Cell>
              <DataTable.Cell>{item.text}</DataTable.Cell>
            </DataTable.Row>
          )}
        />
      </View>
    </CoreModal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingBottom: 12,
    minWidth: 600,
    maxWidth: 1024
  },
  title: {
    fontSize: 18,
    marginBottom: 18
  }
})
