import WebPageLayout from "./layouts/WebPageLayout";
import React from "react";
import {Card, Flex, Image, Button} from "antd";

export default function DashboardView() {
  return (
    <WebPageLayout
      header={{
        tabs: [
          {title: 'Work Orders', routeName: 'workOrders', replaceStack: true},
          {title: 'Tickets', routeName: 'tickets', replaceStack: true},
          {title: 'Job Plans', routeName: 'jobPlans', replaceStack: true},
          {title: 'Preventative Maintenance', routeName: 'preventativeMaintenances', replaceStack: true},
          {title: 'System', routeName: 'system', replaceStack: true},
        ]
      }}
    >
      <Flex style={{paddingBlock: 12}}>
        <Card
          title='Pfizer Grange Castle'
          variant='outlined'
          style={{maxWidth: '768px'}}
          styles={{
            header: {
              textAlign: 'center',
              fontSize: 28,
              paddingBlock: 12
            },
            body: {
              padding: 0,
            }
          }}
        >
          <Image.PreviewGroup>
            <Image
              src={require('../../assets/images/pfizer-grange.png')}
            />
          </Image.PreviewGroup>
        </Card>
      </Flex>
      <Flex style={{paddingBlock: 12}}>
        <Button color='primary' variant='solid' size='large'>Training Guides</Button>
      </Flex>
    </WebPageLayout>
  )
}
