import React, {ReactNode} from "react";
import {ActivityIndicator, SafeAreaView, StyleSheet, View} from "react-native";
import {usePage} from "../../../hooks/usePage";

export default function MobileScreen({children}: {children: ReactNode}) {
  const {isLoading} = usePage();

  return (
    <SafeAreaView style={styles.mobilePageContainer}>
      {children}
      {isLoading && <MobileLoadingIndicator />}
    </SafeAreaView>
  )
}

function MobileLoadingIndicator() {
  return (
    <View style={styles.indicatorContainer}>
      <ActivityIndicator size={48} />
    </View>
  )
}

const styles = StyleSheet.create({
  indicatorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  mobilePageContainer: {
    flex: 1,
    backgroundColor: "#f1f3f5",
    position: 'relative',
    marginHorizontal: 2,
    paddingBottom: 48
  }
})
