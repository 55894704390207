import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, SafeAreaView, } from "react-native";
import { Ionicons, EvilIcons, AntDesign } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import GlobalStyle, { PRIMARY_COLOUR, STAR_COLOUR } from "../../../../constants/GlobalStyle";
import { TextInput } from "react-native-paper";
import { Controller, useForm } from "react-hook-form";
import { LoadingIndicator } from "../../../web/components/LoadingIndicator";
import { useAddFeedbackMutation } from "../../../../store/services/api/feedback-api";

export default function AssetFeedbackScreen({ navigation }: { navigation: any; }) {
    const asset = useSelector((state: RootState) => state.asset);
    const [addFeedback, addFeedbackResult] = useAddFeedbackMutation();
    const [rating, setRating] = useState(0);
    const [ratingError, setRatingError] = useState("");

    const { control, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: {
            assetId: asset.id,
            starRating: 0,
            itemDescription: asset.description,
            description: "",
            contactEmail: ""
        },
    });

    const stars = [1, 2, 3, 4, 5];

    const onSubmit = async (data) => {
        if (rating === 0) {
            setRatingError("Please provide a star rating");
            return;
        }

        const result = (await addFeedback(data)) as any;

        if (result.error) {
            console.error(result.error);
            return;
        }
        navigation.navigate("asset-feedback-confirmation-screen");
    };

    if (addFeedbackResult.isLoading) {
        return <LoadingIndicator />;
    }

    const renderStars = (control, ratingFieldName) => {
        return stars.map((index) => (
            <Controller
                key={index}
                control={control}
                name={ratingFieldName}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                    <TouchableOpacity
                        onPress={() => {
                            onChange(index);
                            setRating(index);
                            setRatingError("");
                        }}
                    >
                        <Ionicons
                            name={index <= value ? "star" : "star-outline"}
                            size={30}
                            color={index <= value ? STAR_COLOUR : PRIMARY_COLOUR}
                        />
                    </TouchableOpacity>
                )}
            />
        ));
    };

    return (
        <SafeAreaView style={GlobalStyle.mobile__page__container}>
            <View style={styles.header}>
                <TouchableOpacity
                    style={GlobalStyle.sub__title__icon}
                    onPress={() =>
                        navigation.navigate("asset", { uniqueRef: asset.uniqueReference })
                    }
                >
                    <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
            </View>
            <ScrollView contentContainerStyle={styles.scrollViewContent}>
                <View style={styles.section}>
                    <Controller
                        control={control}
                        name="itemDescription"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <View>
                                <Text style={styles.title}>Item Description</Text>
                                <TextInput
                                    style={styles.greyed__out__input}
                                    onBlur={onBlur}
                                    value={asset ? asset.description : ""}
                                    editable={false}
                                ></TextInput>
                            </View>
                        )}
                    />
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>Rating</Text>
                    <View style={styles.ratingContainer}>
                        {renderStars(control, "starRating")}
                    </View>
                    {ratingError && (
                        <Text style={GlobalStyle.form__input__validation}>
                            {ratingError}
                        </Text>
                    )}
                </View>
                <View style={styles.section}>
                    <Controller
                        control={control}
                        name="description"
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <View>
                                <Text style={styles.title}>Feedback</Text>
                                <TextInput
                                    style={styles.input}
                                    onBlur={onBlur}
                                    onChangeText={onChange}
                                    value={value}
                                    multiline
                                    numberOfLines={7}
                                ></TextInput>
                            </View>
                        )}
                    />
                    {errors.description && (
                        <Text style={GlobalStyle.form__input__validation}>
                            Feedback is required
                        </Text>
                    )}
                </View>
                <View style={styles.section}>
                    <Controller
                        control={control}
                        name="contactEmail"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <View>
                                <Text style={styles.title}>Contact Email (Optional)</Text>
                                <TextInput
                                    style={styles.input}
                                    onBlur={onBlur}
                                    onChangeText={onChange}
                                    value={value}
                                ></TextInput>
                            </View>
                        )}
                    />
                </View>
                <View style={styles.buttonContainer}>
                    <TouchableOpacity
                        style={[GlobalStyle.mobile__button, { backgroundColor: '#69db7c', width: '80%', marginVertical: 5 }]}
                        onPress={handleSubmit(onSubmit)}
                    >
                        <AntDesign name="plus" size={38} color="white" />
                        <Text style={styles.buttonText}>Submit feedback</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    scrollViewContent: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: 'flex-start',
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
        alignSelf: "stretch",
        marginRight: 20,
        marginBottom: 5,
        padding: 15,
    },
    title: {
        fontSize: 18,
        marginVertical: 7,
        textAlign: "center",
        paddingHorizontal: "5%",
        alignSelf: "flex-start",
    },
    buttonContainer: {
        marginVertical: 10,
        width: "100%",
        alignItems: "center",
    },
    buttonText: {
        color: "white",
        marginLeft: 10,
        fontSize: 22
    },
    footer: {
        fontSize: 16,
        color: "#3B3B3B",
        marginBottom: 20,
    },
    section: {
        width: "80%",
        height: "auto",
        alignItems: "stretch",
    },
    input: {
        borderWidth: 1,
        borderColor: "black",
        borderRadius: 5,
        marginBottom: 10,
        backgroundColor: "white",
        width: "100%",
    },
    greyed__out__input: {
        borderWidth: 1,
        borderColor: "black",
        borderRadius: 5,
        padding: 5,
        marginBottom: 10,
        backgroundColor: "#f2f2f2",
        width: "100%",
    },
    addButtonContainer: {
        alignSelf: "center"
    },
    addButton: {
        marginRight: 10,
        backgroundColor: PRIMARY_COLOUR,
        padding: 8,
        borderRadius: 4,
        justifyContent: "center",
    },
    ratingContainer: {
        flexDirection: "row",
        alignSelf: 'center',
        justifyContent: "space-between",
        marginBottom: 5,
        width: '90%'
    },
});
