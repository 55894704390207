import {StyleSheet, View} from "react-native";
import React, {ReactNode} from "react";

export default function SubManagePageLayout({form, table}: {form: ReactNode, table: ReactNode}) {
  return (
    <View style={styles.pageContainer}>
      <View style={styles.container}>
        {form}
      </View>
      <View style={styles.tableContainer}>
        {table}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: "#d9d9d9",
    borderRadius: 6,
    backgroundColor: "#fff",
    overflow: "hidden"
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    maxHeight: '100%',
    alignItems: 'stretch',
  },
  tableContainer: {
    flex: 1,
  }
})
