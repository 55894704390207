import WebPageLayout from "../../../../layouts/WebPageLayout";
import Table from "../../../../components/Table";
import {GetWorkOrderScreenHeader} from "../work-order-screen-headers";
import {
  useGetWorkOrderLabourQuery,
  useGetWorkOrderQuery
} from "../../../../../../store/services/api/work-order-api";
import {useSetLoading} from "../../../../../../hooks/useSetLoading";
import React from "react";
import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {WorkOrdersStackParamList} from "../../WorkOrdersStack";
import {WorkOrderLabour} from "../../../../../../store/data/entities/work-order-labour";
import {Button} from "react-native-paper";
import {useModal} from "../../../../../../hooks/useModal";
import AddLabourFormModal from "../../../../components/modals/AddLabourFormModal";
import {WorkOrderStatus} from "../../../../../../store/data/entities/work-order";

export default function WorkOrderLabourScreen({route}: ScreenProps<WorkOrdersStackParamList, 'labour'>) {
  const workOrderId = route?.params?.id;
  const {data: workOrder, isFetching: workOrderFetching} = useGetWorkOrderQuery(workOrderId, {
    skip: !workOrderId,
  });
  const {data, isFetching} = useGetWorkOrderLabourQuery(workOrderId, {
    skip: !workOrderId,
  });
  useSetLoading([isFetching || workOrderFetching]);

  const {showModal, hideModal} = useModal();
  const canEdit = workOrder?.status === WorkOrderStatus.Approved
    || workOrder?.status === WorkOrderStatus.Completed
    || workOrder?.status === WorkOrderStatus.InProgress;

  const handleAddLabour = () => {
    showModal(
      <AddLabourFormModal
        workOrderId={workOrderId}
        onSuccess={hideModal}
        onClose={hideModal}
      />
    )
  }

  return (
    <WebPageLayout header={GetWorkOrderScreenHeader(workOrderId)}>
      <Table<WorkOrderLabour>
        keyExtractor={(_, index) => `wo-labour-${index}`}
        data={data ?? []}
        columns={[
          {title: 'Date', key: 'date'},
          {title: 'Employee', key: 'user'},
          {title: 'Start Time', key: 'startDateTime'},
          {title: 'End Time', key: 'endDateTime'},
          {title: 'Hours', key: 'hoursWorked'}
        ]}
      />

      <Button
        disabled={!canEdit}
        style={{marginTop: 12}}
        icon='plus'
        mode="elevated"
        onPress={handleAddLabour}
        buttonColor='#fff'
        textColor='#0f0f0f'
      >
        Add Labour
      </Button>
    </WebPageLayout>
  )
}
