import { baseApi } from "./base-api";
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {WorkOrder, WorkOrderForm, WorkOrderStatusForm, WorkOrderUpdateForm} from "../../data/entities/work-order";
import {WorkOrderLabour, WorkOrderLabourForm} from "../../data/entities/work-order-labour";
import {buildEntityTags, buildPagedResponseTags, invalidateTags} from "../../../utils/api-utils";
import {UpdateWorkOrderLogFiles, WorkOrderLog, WorkOrderLogForm} from "../../data/entities/work-order-log";
import {JobPlan} from "../../data/entities/job-plan";
import {WorkOrderAsset, WorkOrderAssetForm} from "../../data/entities/work-order-asset";
import {ApprovalStatusForm} from "../../data/models/approval-status-form";

export const workOrderApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkOrder: build.query<WorkOrder, string>({
      query: (id) => ({
        url: `workOrders/getWorkOrder`,
        params: {
          workOrderId: id,
        }
      }),
      providesTags: (result) => buildEntityTags(result, 'WorkOrder')
    }),
    getWorkOrderChild: build.query<WorkOrder, string>({
      query: (id) => ({
        url: `workOrders/getWorkOrderChild`,
        params: {
          workOrderId: id,
        }
      }),
      providesTags: (_result, _error, workOrderId) => [{type: 'WorkOrderChild', id: workOrderId}]
    }),
    getWorkOrders: build.query<PagedResponse<WorkOrder> & {priorityCounts: Record<string, number>}, Record<string, any>>({
      query: (params) => ({
        url: `workOrders/getWorkOrders`,
        params: params
      }),
      transformResponse: (response: any, meta: any) => {
        const pagination = JSON.parse(meta.response.headers.get("X-Pagination") as string);
        const priorityCounts = JSON.parse(meta.response.headers.get("PriorityCounts") as string);
        return {
          list: response,
          pagination: pagination,
          priorityCounts: priorityCounts
        };
      },
      providesTags: (result) => buildPagedResponseTags(result, 'WorkOrder')
    }),
    createWorkOrder: build.mutation<WorkOrder, WorkOrderForm>({
      query: (dto) => ({
        url: `workOrders/createWorkOrder`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: (_result, _error, {ticketId}) => [
        ...invalidateTags(ticketId, 'Ticket'),
        ...invalidateTags(_result.parentWorkOrderId, 'WorkOrderChild'),
        ...invalidateTags('LIST', 'WorkOrder')
      ]
    }),
    updateWorkOrder: build.mutation<WorkOrder, WorkOrderUpdateForm>({
      query: (dto) => ({
        url: `workOrders/updateWorkOrder`,
        method: 'PUT',
        body: dto
      }),
      invalidatesTags: (_result, _error, {id}) => invalidateTags(id, 'WorkOrder')
    }),
    deleteWorkOrder: build.mutation<void, string>({
      query: (id) => ({
        url: `workOrders/deleteWorkOrder`,
        method: 'DELETE',
        params: {
          workOrderId: id,
        }
      }),
      invalidatesTags: invalidateTags('LIST', 'WorkOrder')
    }),
    addWorkOrderLabour: build.mutation<WorkOrderLabour, WorkOrderLabourForm & {workOrderId: string}>({
      query: (dto) => ({
        url: `workOrders/addWorkOrderLabour/${dto.workOrderId}`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: () => invalidateTags(undefined, 'WorkOrderLabour')
    }),
    addWorkOrderLog: build.mutation<WorkOrderLog, WorkOrderLogForm & {workOrderId: string}>({
      query: (dto) => ({
        url: `workOrders/addWorkOrderLog/${dto.workOrderId}`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: () => invalidateTags(undefined, 'WorkOrderLog')
    }),
    addWorkOrderAsset: build.mutation<WorkOrderAsset, {workOrderId: string; assetId: string}>({
      query: (dto) => ({
        url: `workOrders/addWorkOrderAsset/${dto.workOrderId}`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: () => invalidateTags(undefined, 'WorkOrderAsset')
    }),
    getWorkOrderJobPlans: build.query<JobPlan[], string>({
      query: (id) => ({
        url: `workOrders/getWorkOrderJobPlans`,
        params: {
          workOrderId: id,
        }
      }),
      providesTags: (result) => buildPagedResponseTags(result, 'WorkOrder')
    }),
    getWorkOrderAssets: build.query<PagedResponse<WorkOrderAsset>, Record<string, any>>({
      query: (params) => ({
        url: `workOrders/getWorkOrderAssets`,
        params
      }),
      transformResponse: getPagedResponse,
      providesTags: (result) => buildPagedResponseTags(result, 'WorkOrderAsset')
    }),
    findWorkOrderAsset: build.query<WorkOrderAsset | null, {workOrderId: string, qrCode?: string, searchTerm?: string, workOrderAssetId?: string}>({
      query: (params) => ({
        url: `workOrders/findWorkOrderAsset`,
        params
      }),
      providesTags: (result) => buildEntityTags(result, 'WorkOrderAsset')
    }),
    updateWorkOrderAsset: build.mutation<WorkOrderAsset, WorkOrderAssetForm>({
      query: (body) => ({
        url: `workOrders/updateWorkOrderAsset`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (_result, _error, {id}) => invalidateTags(id, 'WorkOrderAsset')
    }),
    getWorkOrderLogs: build.query<WorkOrderLog[], string>({
      query: (id) => ({
        url: `workOrders/getWorkOrderLogs`,
        params: {
          workOrderId: id,
        }
      }),
      providesTags: (result) => buildPagedResponseTags(result, 'WorkOrderLog')
    }),
    getWorkOrderLabour: build.query<WorkOrderLabour[], string>({
      query: (id) => ({
        url: `workOrders/getWorkOrderLabour`,
        params: {
          workOrderId: id,
        }
      }),
      providesTags: (result) => buildPagedResponseTags(result, 'WorkOrderLabour')
    }),
    setWorkOrderStatus: build.mutation<void, WorkOrderStatusForm>({
      query: (body) => ({
        url: `workOrders/setWorkOrderStatus`,
        method: 'POST',
        body
      }),
      invalidatesTags: (_result, _error, {workOrderId}) => invalidateTags(workOrderId, 'WorkOrder')
    }),
    setWorkOrderApprovalStatus: build.mutation<void, ApprovalStatusForm>({
      query: (dto) => ({
        url: `workOrders/setApprovalStatus/${dto.entityId}`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: (_result, _error, {entityId}) => invalidateTags(entityId, 'WorkOrder')
    }),
    addWorkLogFiles: build.mutation<void, UpdateWorkOrderLogFiles>({
      query: (data) => {
        const formData = new FormData();
        formData.append(`workOrderId`, data.workOrderId);
        formData.append(`workLogEntryId`, data.workLogEntryId);

        for (let i = 0; i < data.files.length; i++) {
          formData.append('Files', data.files[i]);
        }

        for (let i = 0; i < data.fileURIsToRemove.length; i++) {
          formData.append('FileURIsToRemove', data.fileURIsToRemove[i]);
        }

        return {
          url: `workOrders/addWorkLogFiles`,
          method: 'POST',
          body: formData
        }
      },
      invalidatesTags: (_result, _error, {workLogEntryId}) => invalidateTags(workLogEntryId, 'WorkOrderLog')
    })
  }),
  overrideExisting: true,
});

export const {
  useLazyGetWorkOrdersQuery,
  useLazyGetWorkOrderQuery,
  useCreateWorkOrderMutation,
  useUpdateWorkOrderMutation,
  useDeleteWorkOrderMutation,
  useAddWorkOrderLabourMutation,
  useAddWorkOrderLogMutation,
  useAddWorkOrderAssetMutation,
  useLazyGetWorkOrderJobPlansQuery,
  useLazyGetWorkOrderAssetsQuery,
  useUpdateWorkOrderAssetMutation,
  useLazyGetWorkOrderLogsQuery,
  useLazyGetWorkOrderLabourQuery,
  useSetWorkOrderStatusMutation,
  useSetWorkOrderApprovalStatusMutation,
  useAddWorkLogFilesMutation,
  useLazyFindWorkOrderAssetQuery,
  useFindWorkOrderAssetQuery,
  useGetWorkOrderQuery,
  useGetWorkOrderLabourQuery,
  useGetWorkOrderLogsQuery,
  useGetWorkOrderChildQuery
} = workOrderApi;
