import WebPageLayout from "../../../../../layouts/WebPageLayout";
import {ScreenProps} from "../../../../../../../utils/screen-props-helper";
import {SystemBuildingsStackParamList} from "../../SystemBuildingsStack";
import {
  useGetFloorQuery,
  useGetLocationsQuery
} from "../../../../../../../store/services/api/building-api";
import SystemLocationsList from "./components/SystemLocationsList";
import SubManagePageLayout from "../../../../../layouts/SubManagePageLayout";
import {useState} from "react";
import SystemLocationsForm from "./components/SystemLocationsForm";
import {useSetLoading} from "../../../../../../../hooks/useSetLoading";

export default function SystemLocationsScreen({route}: ScreenProps<SystemBuildingsStackParamList, 'locations'>) {
  const floorId = route?.params?.floorId;
  const {data: floor, isLoading} = useGetFloorQuery(floorId);
  const {data: locations, refetch, isFetching} = useGetLocationsQuery({
    floorId: floorId,
    pageNumber: 1,
    pageSize: 1000
  })

  useSetLoading([isFetching, isLoading]);

  const [selectedLocation, setSelectedLocation] = useState();

  return (
    <WebPageLayout header={{
      previousTab: {title: 'Floors', routeName: 'floors', params: {buildingId: floor?.buildingId} },
      tabs: [
        {title: floor?.name, hidden: !floor?.name, disabled: true},
        {title: 'Locations', disabled: true}
      ]
    }}>
      <SubManagePageLayout
        form={(
          <SystemLocationsForm
            location={selectedLocation}
            floorId={floorId}
            onSubmit={refetch}
            onClearSelection={() => setSelectedLocation(null)}
          />
        )}
        table={(
          <SystemLocationsList
            locations={locations?.list}
            onLocationSelected={setSelectedLocation}
            onLocationDeleted={refetch}
          />
        )}
      />
    </WebPageLayout>
  )
}
