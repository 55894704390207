import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {useToast} from "../../../../../components/toast/ToastProvider";
import React, {useEffect} from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import GlobalStyle from "../../../../../constants/GlobalStyle";
import {useJobControl} from "../../../../../hooks/useJobControl";
import {useForm} from "react-hook-form";
import {useGetCorrectiveJobQuery} from "../../../../../store/services/api/corrective-job-api";
import {
  CorrectiveJobControl,
  CorrectiveJobControlForm
} from "../../../../../components/job-controls/corrective-job/CorrectiveJobControl";
import {useSetLoading} from "../../../../../hooks/useSetLoading";

export function CorrectiveJobApproval({navigation, route}: NativeStackScreenProps<any, any>) {
  const jobId = route.params.jobId;
  const {data: currentData, isLoading: currentDataLoading} = useGetCorrectiveJobQuery(jobId);
  const {show: showToast} = useToast();

  useSetLoading([currentDataLoading]);

  const handleOnSuccess = () => {
    showToast('Successfully completed job', 'Successfully completed corrective job', 'success');
    navigation.navigate('list');
  }

  const handleOnError = (message?: string) => {
    showToast('Error completing job', message ?? 'There was an error submitting job', 'error');
  }

  const FinishButton = () => {
    const { handleOnFinish } = useJobControl();

    return (
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={GlobalStyle.add__button} onPress={handleOnFinish}>
          <View style={styles.navigationButton}>
            <Text style={GlobalStyle.form__submit__button__text}>Finish</Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const form = useForm<CorrectiveJobControlForm>({
    defaultValues: {
      jobId,
      updates: [],
      images: []
    }
  });

  useEffect(() => {
    form.setValue('jobId', jobId);
  }, [jobId]);

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <CorrectiveJobControl
          initialForm={currentData as unknown as CorrectiveJobControlForm}
          jobId={jobId}
          onError={handleOnError}
          onSuccess={handleOnSuccess}>
          <FinishButton />
        </CorrectiveJobControl>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
    backgroundColor: '#FFF'
  },
  innerContainer: {
    maxWidth: 1024
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 8,
    paddingHorizontal: 4,
    gap: 6
  },
  navigationButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10
  }
})
