import {ListRenderItemInfo, StyleSheet, Text, View} from "react-native";
import React, {useEffect} from "react";
import {useJobControl} from "../../../../hooks/useJobControl";
import {CorrectiveJobControlForm} from "../CorrectiveJobControl";
import CoreForm from "../../../../screens/web/components/forms/CoreForm";
import {TextFieldController} from "../../../../screens/web/components/forms/TextFieldController";
import {useForm} from "react-hook-form";
import {CoreList} from "../../../../screens/mobile/components/core-list/CoreList";
import {Button} from "react-native-paper";
import {PRIMARY_COLOUR} from "../../../../constants/GlobalStyle";
import {CorrectiveJobUpdate} from "../../../../store/data/entities/corrective-job-update";
import {Checkbox} from "expo-checkbox";

export function CorrectiveJobUpdatesControl() {
  const {form: {setValue, getValues, watch}, initialForm, isApproval} = useJobControl<CorrectiveJobControlForm>();
  const updatesForm = useForm<CorrectiveJobUpdate>({
    defaultValues: {
      text: ''
    }
  });
  const {handleSubmit, reset} = updatesForm;

  useEffect(() => {
    if (initialForm) {
      setValue('updates', initialForm.updates);
    }
  }, [initialForm]);

  const updatesList = watch('updates');

  const handleOnAddComment = (formData: { text: string }) => {
    const newComment: CorrectiveJobUpdate = {
      text: formData.text,
      isApproved: isApproval
    }

    setValue('updates', [...getValues('updates'), newComment]);
    reset({
      text: ''
    });
  }

  const renderUpdateItem = ({item, index}: ListRenderItemInfo<CorrectiveJobUpdate>) => {
    const handleDelete = () => {
      const updates = getValues('updates');
      setValue('updates', updates.filter((_, i) => i !== index));
    }

    const handleSetApproved = (value: boolean) => {
      const updates = getValues('updates');
      const updatedUpdates = updates.map((update, i) =>
        i === index ? {...update, isApproved: value} : update
      );
      setValue('updates', updatedUpdates);
    }

    const handleToggleApproved = () => {
      const updates = getValues('updates');
      const updatedUpdates = updates.map((update, i) =>
        i === index ? {...update, isApproved: !update.isApproved} : update
      );
      setValue('updates', updatedUpdates);
    }

    return (
      <View style={[styles.listItemContainer, item.isApproved ? styles.lockedItemContainer : {}]}>
        <View style={styles.updateInfo}>
          <Text style={styles.updateText}>{item.text}</Text>
        </View>
        <View style={styles.actionsContainer}>
          <Button
            disabled={item.isApproved}
            icon='delete'
            textColor={PRIMARY_COLOUR}
            onPress={handleDelete}
          >
            Remove
          </Button>
          {isApproval && (
            <View style={styles.approveCheckbox}>
              <Checkbox
                value={item.isApproved}
                onValueChange={handleSetApproved}
              />
              <Button
                textColor={PRIMARY_COLOUR}
                onPress={handleToggleApproved}
              >
                Approve
              </Button>
            </View>
          )}
        </View>
      </View>
    )
  }

  return (
    <View style={styles.formContainer}>
      <CoreForm form={updatesForm} submitText='Add Update' onSubmit={handleSubmit(handleOnAddComment)}>
        <TextFieldController
          required
          multiline
          controlName='text'
          title='Update'/>
      </CoreForm>

      <CoreList
        emptyListText=''
        data={updatesList}
        renderItem={renderUpdateItem}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  formContainer: {
    marginHorizontal: 4,
    marginTop: 6
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 18,
    flexWrap: 'wrap',
  },
  lockedItemContainer: {
    backgroundColor: '#e9ecef',
  },
  updateInfo: {
    flexDirection: 'column',
    flex: 1
  },
  updateText: {
    flex: 1,
    flexWrap: 'wrap',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4
  },
  approveCheckbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4
  }
})
