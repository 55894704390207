import {ScreenProps} from "../../../../../utils/screen-props-helper";
import {AssetStackParamList} from "../AssetStack";
import {useLazyGetAssetLogBookEntriesQuery} from "../../../../../store/services/api/asset-api";
import React, {useEffect, useState} from "react";
import Skeleton from "../../../../../components/skeletons/Skeleton";
import {FlatList, ListRenderItemInfo, View} from "react-native";
import {Button, Card, Text} from "react-native-paper";
import {AssetLogBookEntry} from "../../../../../store/data/entities/asset-log-book-entry";
import {Descriptions} from "antd";
import {humanizeDate, humanizeEnum} from "../../../../../utils/humanizers";
import {WorkOrderType} from "../../../../../store/data/entities/work-order";

export default function AssetLogBookScreen({route}: ScreenProps<AssetStackParamList, 'logBook'>) {
  const assetId = route?.params?.assetId;
  const [getLogBookPage, {data: latestPage, isFetching}] = useLazyGetAssetLogBookEntriesQuery();
  const [listData, setListData] = useState<AssetLogBookEntry[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const canLoadMore = !isFetching && (latestPage?.pagination.hasNext);
  const pageSize = 5;

  useEffect(() => {
    fetchInitialPage();
  }, [])

  const fetchInitialPage = () => {
    getLogBookPage({
      pageNumber: 1,
      pageSize,
      assetId
    }).unwrap()
      .then((page) => {
        setListData(page.list);
        setCurrentPage(curr => curr + 1);
      })
  }

  const fetchWorkOrders = () => {
    if (!canLoadMore) return;

    getLogBookPage({
      pageNumber: currentPage + 1,
      pageSize,
      assetId
    }).unwrap()
      .then((page) => {
        setListData(prev => [...prev, ...page.list]);
        setCurrentPage(curr => curr + 1);
      })
  }

  const renderItem = ({item}: ListRenderItemInfo<AssetLogBookEntry>) => {
    const comments = item.comments ? item.comments : 'None';

    return (
      <Card style={{backgroundColor: 'white'}}>
        <Card.Content>
          <Descriptions
            size='small'
            layout='horizontal'
            styles={{
              label: {
                width: 150
              }
            }}
          >
            <Descriptions.Item label="WO Number">{item.workOrderReference}</Descriptions.Item>
            <Descriptions.Item label="Date">{humanizeDate(item.workCompletedTime)}</Descriptions.Item>
            <Descriptions.Item label="WO Type">{humanizeEnum(WorkOrderType[item.workOrderType])}</Descriptions.Item>
            <Descriptions.Item label="WO Description">{item.workOrderDescription}</Descriptions.Item>
            <Descriptions.Item label="Checks Completed">{item.checkCompleted ? 'Y' : 'N'}</Descriptions.Item>
            <Descriptions.Item label="Logbook Update">{comments}</Descriptions.Item>
          </Descriptions>
        </Card.Content>
      </Card>
    )
  }

  return (
    <FlatList<AssetLogBookEntry>
      style={{padding: 4}}
      ItemSeparatorComponent={() => <View style={{height: 6}} />}
      keyExtractor={(_, index) => `logbook-entry-${index}`}
      data={listData}
      renderItem={renderItem}
      ListEmptyComponent={isFetching ? null : <Text variant='bodyMedium'>No logbook entries</Text>}
      ListFooterComponent={isFetching ? <Skeleton.Card rows={1} /> : (
        <Button
          disabled={!canLoadMore}
          style={{marginVertical: 6}}
          mode='elevated'
          onPress={fetchWorkOrders}
          loading={isFetching}
        >
          Load More
        </Button>
      )}
    />
  )
}
