import { createNativeStackNavigator } from '@react-navigation/native-stack';
import TicketStatusScreen from './TicketStatusScreen';
import AddTicketScreen from './AddTicketScreen';
import TicketSubmitConfirmationScreen from './TicketSubmitConfirmationScreen';
import UpdateTicketScreen from './UpdateTicketScreen';
import OpenTicketsScreen from './OpenTicketsScreen';

const Stack = createNativeStackNavigator();

export default function TicketScreen({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="ticket" component={AddTicketScreen} options={{ headerShown: false, title: 'Ticket' }} />
      <Stack.Screen name="ticket-status-screen" component={TicketStatusScreen} options={{ headerShown: false, title: 'Status' }} />
      <Stack.Screen name="ticket-submit-confirmation-screen" component={TicketSubmitConfirmationScreen} options={{ headerShown: false, title: 'Confirmation' }} />
      <Stack.Screen name="update-ticket-screen" component={UpdateTicketScreen} options={{ headerShown: false, title: 'Update' }} />
      <Stack.Screen name="open-tickets-screen" component={OpenTicketsScreen} options={{ headerShown: false, title: 'Open Tickets' }} />
    </Stack.Navigator>
  )
}
