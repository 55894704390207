import React, {useEffect} from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import MobileScreen from "../../../../components/MobileScreen";
import {StyleSheet, Text, View} from "react-native";
import {CoreList} from "../../../../components/core-list/CoreList";
import {useLazyGetCorrectiveJobQuery} from "../../../../../../store/services/api/corrective-job-api";
import {useSetLoading} from "../../../../../../hooks/useSetLoading";

export function CorrectiveJobHelp({route}: NativeStackScreenProps<any, any>) {
  const jobId = route?.params?.jobId;
  const [getCorrectiveJob, {data: correctiveJob, isFetching: jobFetching}] = useLazyGetCorrectiveJobQuery();

  useSetLoading([jobFetching]);

  useEffect(() => {
    if (jobId) {
      getCorrectiveJob(jobId);
    }
  }, [jobId])

  return (
    <MobileScreen>
      <View style={styles.updatesListContainer}>
        <Text style={styles.title}>Issue</Text>
        <Text>{correctiveJob?.ticket?.issue}</Text>
      </View>

      <View style={styles.updatesListContainer}>
        <Text style={styles.title}>Updates</Text>
        <CoreList
          data={correctiveJob?.ticket?.updates}
          itemWrapper={null}
          renderItem={({item}) => (
            <View>
              <Text>{item.text}</Text>
            </View>
          )} />
      </View>
    </MobileScreen>
  );
}

const styles = StyleSheet.create({
  updatesListContainer: {
    marginVertical: 4,
    borderRadius: 4,
    backgroundColor: '#fff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
    paddingVertical: 12,
    paddingHorizontal: 18
  },
  title: {
    fontSize: 18,
    marginBottom: 4
  }
})
