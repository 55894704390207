import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {ParamListBase} from "@react-navigation/native";
import SystemBuildingsListScreen from "./screens/list/SystemBuildingsListScreen";
import SystemBuildingsManageScreen from "./screens/manage/SystemBuildingsManageScreen";
import SystemFloorsScreen from "./screens/floors/SystemFloorsScreen";
import SystemLocationsScreen from "./screens/locations/SystemLocationsScreen";

const Stack = createNativeStackNavigator<SystemBuildingsStackParamList>();

export default function SystemBuildingsStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='list'
        component={SystemBuildingsListScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='manage'
        component={SystemBuildingsManageScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='floors'
        component={SystemFloorsScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name='locations'
        component={SystemLocationsScreen}
        options={{headerShown: false}}
      />
    </Stack.Navigator>
  );
};

export const systemBuildingsLinkingOptions = {
  path: 'buildings',
  screens: {
    list: 'list',
    manage: 'manage'
  },
}

export type SystemBuildingsStackParamList = ParamListBase & {
  list: undefined;
  manage: {id: string};
  floors: {buildingId: string};
  locations: {floorId: string};
};
