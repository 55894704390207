import {createNativeStackNavigator, NativeStackScreenProps} from "@react-navigation/native-stack";
import React from "react";
import {BackButton} from "../../../../components/header/back-button";
import {useModal} from "../../../../../../hooks/useModal";
import {ConfirmModal} from "../../../../../../components/modals/ConfirmModal";
import {CreateJob} from "../CreateJob/CreateJob";
import {TechnicianJobsList} from "./TechnicianJobsList";
import {useDispatch} from "react-redux";
import {logout as logoutUser} from "../../../../../../store/slices/user-slice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {HeaderButton} from "../../../../components/header/header-button";
import {Feather} from "@expo/vector-icons";

const Stack = createNativeStackNavigator();

export function TechnicianJobsNavigator({navigation}: NativeStackScreenProps<any, any>) {
  const {showModal, hideModal} = useModal();

  const handleOnBackPress = (route: string, ask: boolean = false) => {
    if (ask) {
      showModal(
        <ConfirmModal
          title='Are you sure?'
          message='Are you sure you want to go back? You will loose all your progress.'
          onConfirm={() => {
            hideModal();
            navigation.navigate(route)
          }}
          onClose={hideModal} />
      )
      return;
    }

    navigation.navigate(route);
  }

  const LogoutButton = () => {
    const dispatch = useDispatch();

    const handleOnLogout = () => {
      showModal(
        <ConfirmModal
          title='Are you sure?'
          message='Are you sure you want to logout?'
          onConfirm={async () => {
            hideModal();
            await logout();
            navigation.navigate("login");
          }}
          onClose={hideModal} />
      )
    }

    const logout = async () => {
      dispatch(logoutUser());

      try {
        await AsyncStorage.clear();
      } catch (error) {
        console.log("Error clearing AsyncStorage:", error);
      }
    }

    return (
      <HeaderButton onPress={handleOnLogout}>
        <Feather name='log-out' size={24} />
      </HeaderButton>
    );
  };

  const AddJobButton = () => {
    return (
      <HeaderButton onPress={() => navigation.navigate("create-job")}>
        <Feather name='plus-circle' size={24} />
      </HeaderButton>
    );
  }

  return (
    <>
      <Stack.Navigator initialRouteName='jobs-list'>
        <Stack.Screen
          name="jobs-list"
          component={TechnicianJobsList}
          options={{
            headerShown: true,
            title: 'Work Orders',
            headerLeft: () => <LogoutButton />,
            headerRight: () => <AddJobButton />
          }}
        />
        <Stack.Screen
          name="create-job"
          component={CreateJob}
          options={{
            headerShown: true,
            title: 'Create Work Order',
            headerLeft: () => <BackButton onPress={() => handleOnBackPress('jobs-list')} />
          }}
        />
      </Stack.Navigator>
    </>
  )
}
