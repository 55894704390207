import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {MaintenancePartsList} from "./MaintenancePartsList";
import MaintenancePartManage from "./MaintenancePartManage";

const Stack = createNativeStackNavigator();

export default function MaintenancePartsNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={MaintenancePartsList} options={{ headerShown: false, title: 'Parts' }} />
      <Stack.Screen name="manage" component={MaintenancePartManage} options={{ headerShown: false, title: 'Manage Parts' }} />
    </Stack.Navigator>
  )
}
