import {Button, Dialog, Text} from "react-native-paper";

interface ConfirmModalProps {
  title?: string;
  message?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export default function ConfirmModal({title, message, onConfirm, onCancel}: ConfirmModalProps) {
  return (
    <Dialog visible={true}>
      <Dialog.Title>{title ?? 'Confirm'}</Dialog.Title>
      <Dialog.Content>
        <Text variant='bodySmall'>{message ?? 'Are you sure?'}</Text>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onPress={onCancel}>Cancel</Button>
        <Button onPress={onConfirm}>Confirm</Button>
      </Dialog.Actions>
    </Dialog>
  )
}
