import { baseApi } from "./base-api";
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {Job, JobManageForm, JobMedia, NewJobFromTicket, NewMaintenanceJob, NewTicketJob} from "../../data/entities/job";
import {invalidateTags} from "../../../utils/api-utils";

export const jobApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getJobsList: build.query<PagedResponse<Job>, Record<string, any>>({
      query: (params) => ({
        url: `jobs/getJobsList`,
        params: params
      }),
      transformResponse: getPagedResponse
    }),
    createTicketJob: build.mutation<Job, NewTicketJob>({
      query: (dto) => ({
        url: `jobs/CreateTicketJob`,
        method: 'POST',
        body: dto
      })
    }),
    updateJob: build.mutation<Job, Omit<JobManageForm, 'type'>>({
      query: (dto) => ({
        url: `jobs/UpdateJob`,
        method: 'PUT',
        body: dto
      })
    }),
    createJobFromTicket: build.mutation<Job, NewJobFromTicket>({
      query: (dto) => ({
        url: `jobs/CreateJobFromTicket/${dto.ticketId}`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: invalidateTags('LIST', 'Ticket')
    }),
    createMaintenanceJob: build.mutation<Job, NewMaintenanceJob>({
      query: (dto) => ({
        url: `jobs/CreateMaintenanceJob`,
        method: 'POST',
        body: dto
      })
    }),
    createAdhocMaintenanceJob: build.mutation<Job, NewMaintenanceJob>({
      query: (dto) => ({
        url: `jobs/CreateAdhocMaintenanceJob`,
        method: 'POST',
        body: dto
      })
    }),
    getJob: build.query<Job, string>({
      query: (jobId) => ({
        url: `jobs/GetJob/${jobId}`
      })
    }),
    getJobMediaForJob: build.query<JobMedia[], string>({
      query: (jobId) => ({
        url: `jobs/getJobMediaForJob/${jobId}`
      })
    }),
    removeJobMedia: build.mutation<void, JobMedia>({
      query: (media) => ({
        url: `jobs/removeJobMedia`,
        method: 'POST',
        body: {
          url: media.url
        }
      })
    }),
    addJobMediaForJob: build.mutation<void, {images: File[], jobId: string}>({
      query: (data) => {
        const formData = new FormData();
        data.images.forEach((blob) => {
          formData.append(`images`, blob);
        });

        return {
          url: `jobs/addJobMediaForJob/${data.jobId}`,
          method: 'POST',
          body: formData
        }
      },
    })
  }),
  overrideExisting: true,
});

export const {
  useGetJobQuery,
  useLazyGetJobQuery,
  useAddJobMediaForJobMutation,
  useRemoveJobMediaMutation,
  useLazyGetJobsListQuery,
  useCreateTicketJobMutation,
  useCreateJobFromTicketMutation,
  useCreateMaintenanceJobMutation,
  useCreateAdhocMaintenanceJobMutation,
  useLazyGetJobMediaForJobQuery,
  useUpdateJobMutation,
} = jobApi;
