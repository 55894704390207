import { baseApi } from "./base-api";
import {getPagedResponse, PagedResponse} from "../../../utils/helpers";
import {PreventativeMaintenance, PreventativeMaintenanceForm} from "../../data/entities/preventative-maintenance";
import {ApprovalStatusForm} from "../../data/models/approval-status-form";
import {buildEntityTags, buildPagedResponseTags, invalidateTags} from "../../../utils/api-utils";

export const preventativeMaintenanceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPreventativeMaintenance: build.query<PreventativeMaintenance, string>({
      query: (id) => ({
        url: `preventativeMaintenance/get`,
        params: {
          preventativeMaintenanceId: id,
        }
      }),
      providesTags: (result) => buildEntityTags(result, 'PreventativeMaintenance')
    }),
    getPreventativeMaintenances: build.query<PagedResponse<PreventativeMaintenance>, Record<string, any>>({
      query: (params) => ({
        url: `preventativeMaintenance/getList`,
        params: params
      }),
      transformResponse: getPagedResponse,
      providesTags: (result) => buildPagedResponseTags(result, 'PreventativeMaintenance')
    }),
    createPreventativeMaintenance: build.mutation<PreventativeMaintenance, PreventativeMaintenanceForm>({
      query: (dto) => ({
        url: `preventativeMaintenance/create`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: () => invalidateTags('LIST', 'PreventativeMaintenance')
    }),
    updatePreventativeMaintenance: build.mutation<PreventativeMaintenance, PreventativeMaintenanceForm>({
      query: (dto) => ({
        url: `preventativeMaintenance/update`,
        method: 'PUT',
        body: dto
      }),
      invalidatesTags: (_result, _error, {id}) => invalidateTags(id, 'PreventativeMaintenance')
    }),
    archivePreventativeMaintenance: build.mutation<void, string>({
      query: (id) => ({
        url: `preventativeMaintenance/archive`,
        method: 'POST',
        params: {
          preventativeMaintenanceId: id,
        }
      }),
      invalidatesTags: (_result, _error, id) => invalidateTags(id, 'PreventativeMaintenance')
    }),
    setPreventativeMaintenanceApprovalStatus: build.mutation<void, ApprovalStatusForm>({
      query: (dto) => ({
        url: `preventativeMaintenance/setApprovalStatus/${dto.entityId}`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: (_result, _error, {entityId}) => invalidateTags(entityId, 'PreventativeMaintenance')
    }),
    generateWorkOrderFromPreventativeMaintenance: build.mutation<void, string>({
      query: (id) => ({
        url: `preventativeMaintenance/generateWorkOrder/${id}`,
        method: 'POST'
      })
    })
  }),
  overrideExisting: true,
});

export const {
  useLazyGetPreventativeMaintenancesQuery,
  useLazyGetPreventativeMaintenanceQuery,
  useCreatePreventativeMaintenanceMutation,
  useUpdatePreventativeMaintenanceMutation,
  useArchivePreventativeMaintenanceMutation,
  useSetPreventativeMaintenanceApprovalStatusMutation,
  useGenerateWorkOrderFromPreventativeMaintenanceMutation,
} = preventativeMaintenanceApi;
