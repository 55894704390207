import WebPageLayout from "../../../../../layouts/WebPageLayout";
import {SystemAssetTypesStackParamList} from "../../SystemAssetTypesStack";
import {ScreenProps} from "../../../../../../../utils/screen-props-helper";
import {useGetAssetTypeQuery, useGetSubAssetTypesQuery} from "../../../../../../../store/services/api/asset-type-api";
import SubManagePageLayout from "../../../../../layouts/SubManagePageLayout";
import SystemSubAssetTypesList from "./components/SystemSubAssetTypesList";
import {useSetLoading} from "../../../../../../../hooks/useSetLoading";
import {useState} from "react";
import SystemSubAssetTypesForm from "./components/SystemSubAssetTypesForm";

export default function SystemSubAssetTypesScreen({route}: ScreenProps<SystemAssetTypesStackParamList, 'subAssetType'>) {
  const assetTypeId = route?.params?.assetTypeId;
  const {data: assetType, isLoading} = useGetAssetTypeQuery(assetTypeId);
  const {data: subAssetTypes, refetch, isFetching} = useGetSubAssetTypesQuery({
    assetTypeId: assetTypeId,
    pageNumber: 1,
    pageSize: 1000
  })

  useSetLoading([isFetching, isLoading]);

  const [selectedSubAssetType, setSelectedSubAssetType] = useState();

  return (
    <WebPageLayout header={{
      previousTab: {title: 'Asset Types', routeName: 'list'},
      tabs: [
        {title: assetType?.name, hidden: !assetType?.name, disabled: true},
        {title: 'Sub Asset Types', disabled: true}
      ]
    }}>
      <SubManagePageLayout
        form={(
          <SystemSubAssetTypesForm
            subAssetType={selectedSubAssetType}
            assetTypeId={assetTypeId}
            onClearSelection={() => setSelectedSubAssetType(null)}
            onSubmit={refetch}
          />
        )}
        table={(
          <SystemSubAssetTypesList
            data={subAssetTypes?.list}
            onSelected={setSelectedSubAssetType}
            onDeleted={refetch}
          />
        )}
      />
    </WebPageLayout>
  )
}
