import {ListRenderItemInfo, StyleSheet, Text, TextInput, View} from "react-native";
import {Controller} from "react-hook-form";
import RadioGroup, {RadioButtonProps} from "react-native-radio-buttons-group";
import React, {useEffect} from "react";
import {CoreList} from "../../../../screens/mobile/components/core-list/CoreList";
import {useLazyGetChecklistForJobQuery} from "../../../../store/services/api/maintenance-job-api";
import {useJobControl} from "../../../../hooks/useJobControl";
import {MaintenanceJobControlForm} from "../MaintenanceJobControl";
import {MaintenanceJobResponse} from "../../../../store/data/entities/maintenance-job";
import {ApprovalButton} from "../../shared/components/ApprovalButton";

export function MaintenanceJobChecklistControl() {
  const {form, jobId, onError, initialForm, isApproval} = useJobControl<MaintenanceJobControlForm>();
  const {control, watch, setValue} = form;

  const [getChecklist, {
    data: checklistDetails,
    isLoading: checklistLoading,
    isError: isChecklistError
  }] = useLazyGetChecklistForJobQuery();

  const responsesList = watch('checklistResponses');

  useEffect(() => {
    if (jobId) {
      getChecklist(jobId);
    }
  }, [jobId]);

  useEffect(() => {
    if (isChecklistError) {
      onError('There was an error getting checklist');
    }
  }, [isChecklistError]);

  useEffect(() => {
    if (!checklistDetails) return;
    const initialResponses = initialForm?.checklistResponses ?? [];
    const initialResponsesMap = initialResponses.reduce((map, response) => {
      map[response.maintenanceChecklistQuestionId] = {
        id: response.id,
        isApproved:response.isApproved,
        response: response.response,
        comments: response.comments
      };
      return map;
    }, {});
    const updatedResponses = checklistDetails.questions.map((question) => ({
      id: initialResponsesMap[question.id]?.id ?? undefined,
      maintenanceChecklistQuestionId: question.id,
      response: initialResponsesMap[question.id]?.response ?? '',
      comments: initialResponsesMap[question.id]?.comments ?? '',
      isApproved: initialResponsesMap[question.id]?.isApproved ?? false,
    }));
    form.setValue('checklistResponses', updatedResponses);
  }, [checklistDetails, initialForm]);

  const renderQuestion = ({item, index}: ListRenderItemInfo<MaintenanceJobResponse>) => {
    const question = checklistDetails?.questions?.find(q => q.id === item.maintenanceChecklistQuestionId)?.question;

    const radioButtons: RadioButtonProps[] = [
      {id: '0', label: 'Yes', value: '0', disabled: item.isApproved},
      {id: '1', label: 'No', value: '1', disabled: item.isApproved},
      {id: '2', label: 'N/A', value: '2', disabled: item.isApproved},
    ];

    const handleOnFieldChange = (value: any, onChangeHandler: (value: any) => void) => {
      onChangeHandler(value);
      setValue(`checklistResponses.${index}.id`, undefined);
    }

    const responseController = () => (
      <Controller
        control={control}
        rules={{required: 'Please provide a response'}}
        name={`checklistResponses.${index}.response`}
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <View>
            <RadioGroup
              layout='row'
              radioButtons={radioButtons}
              selectedId={value?.toString()}
              onPress={(val) => handleOnFieldChange(Number.parseInt(val), onChange)}
              containerStyle={styles.radioGroupContainer}
            />
            {error && <Text style={{color: 'red', marginLeft: 12}}>{error.message}</Text>}
          </View>
        )}
      />
    )

    const commentsController = () => (
      <Controller
        control={control}
        name={`checklistResponses.${index}.comments`}
        render={({field: {onChange, onBlur, value}}) => (
          <TextInput
            editable={!item.isApproved}
            multiline
            placeholder="Add comments"
            onBlur={onBlur}
            onChangeText={(val) => handleOnFieldChange(val, onChange)}
            value={value}
            style={styles.commentInput}
          />
        )}
      />
    )

    const approvalController = () => (
      <Controller
        control={control}
        name={`checklistResponses.${index}.isApproved`}
        render={({field: {onChange, value}}) => (
          <ApprovalButton
            onPress={() => onChange(!value)}
            value={value}
          />
        )}
      />
    )

    return (
      <View style={[styles.listItemContainer, item.isApproved ? styles.lockedItemContainer : {}]}>
        <View style={[styles.innerContainer]}>
          <View style={styles.questionContainer}>
            <View style={styles.questionTextContainer}>
              <Text style={styles.questionText}>{question}</Text>
            </View>
            {responseController()}
          </View>
          {commentsController()}
        </View>
        {isApproval && approvalController()}
      </View>
    )
  }

  const handleOnApproveAllChecked = (value: boolean) => {
    const updatedResponses = responsesList.map((response) => ({
      ...response,
      isApproved: value,
    }));
    form.setValue('checklistResponses', updatedResponses);
  }

  const allApproved = responsesList.findIndex(r => !r.isApproved) === -1;

  return (
    <>
      {isApproval && (
        <View style={styles.approveAllContainer}>
          <ApprovalButton
            onPress={() => handleOnApproveAllChecked(!allApproved)}
            value={allApproved}
            text='Approve All'
          />
        </View>
      )}
      <CoreList
        isLoading={checklistLoading}
        data={responsesList}
        itemWrapper={null}
        renderItem={renderQuestion}
        keyExtractor={(item, index) => `${item.id}${index}`}
      />
    </>
  )
}

const styles = StyleSheet.create({
  listItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 12,
    paddingVertical: 12,
    paddingHorizontal: 18,
    marginVertical: 4,
    marginHorizontal: 4,
    borderRadius: 4,
    backgroundColor: '#fff',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5
  },
  questionContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  questionTextContainer: {
    flex: 1,
    flexBasis: 320,
  },
  questionText: {
    marginHorizontal: 10,
    fontSize: 18,
    marginBottom: 12,
  },
  commentInput: {
    marginHorizontal: 10,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 4,
    marginTop: 8,
    fontSize: 14,
    minWidth: 0
  },
  radioGroupContainer: {
    marginBottom: 12,
  },
  lockedItemContainer: {
    backgroundColor: '#e9ecef',
  },
  updateInfo: {
    flexDirection: 'column',
    flex: 1
  },
  updateText: {
    flex: 1,
    flexWrap: 'wrap',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  approveAllContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 2
  }
})

