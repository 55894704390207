import { baseApi } from "./base-api";
import {TroubleshootingGuide} from "../../data/entities/troubleshooting-guide";

export const troubleshootingGuideApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGuideForJob: build.query<TroubleshootingGuide, string>({
      query: (jobId) => ({
        url: `troubleshootingGuides/getGuideForJob/${jobId}`
      })
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetGuideForJobQuery
} = troubleshootingGuideApi;
