import {WebPageHeaderProps} from "../../components/PageHeader";
import useHasPermission from "../../../../hooks/useHasPermission";

export const getSystemScreenHeaders = () : WebPageHeaderProps => {
  const {hasPermission} = useHasPermission();

  return {
    previousTab: {title: 'Dashboard', routeName: 'dashboard', replaceStack: true},
    tabs: [
      {title: 'Assets', routeName: 'assets', hidden: !hasPermission('CanViewAssets')},
      {title: 'Asset Types', routeName: 'assetTypes', hidden: !hasPermission('CanManageSystem')},
      {title: 'Buildings', routeName: 'buildings', hidden: !hasPermission('CanManageSystem')},
      {title: 'Users', routeName: 'users', hidden: !hasPermission('CanManageSystem')},
      {title: 'Settings', routeName: 'settings', hidden: !hasPermission('CanManageSystem')},
    ]
  }
}
