import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {useAddJobMediaForJobMutation} from "../../../store/services/api/job-api";
import {usePage} from "../../../hooks/usePage";
import {JobControlContext, JobControlContextType} from "../../../hooks/useJobControl";
import {View, Text, StyleSheet, ScrollView} from "react-native";
import {CorrectiveJobForm} from "../../../store/data/entities/corrective-job";
import {CorrectiveJobUpdatesControl} from "./components/CorrectiveJobUpdatesControl";
import {CoreModal, CoreModalProps, useModal} from "../../../hooks/useModal";
import {useUpdateCorrectiveJobMutation} from "../../../store/services/api/corrective-job-api";
import {JobImagesControl} from "../shared/JobImagesControl";
import {JobControlProps} from "../shared/JobControlProps";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {CoreFormContext} from "../../../screens/web/components/forms/CoreForm";
import {ButtonSwitchFieldController} from "../../../screens/web/components/forms/ButtonSwitchFieldController";

export interface CorrectiveJobControlForm extends CorrectiveJobForm {
  images: File[]
}

export function CorrectiveJobControl({jobId, onSuccess, onError, children, initialForm}: JobControlProps<CorrectiveJobControlForm>) {
  const {permissions} = useSelector((state: RootState) => state.user);
  const isApproval = permissions.includes('CanApproveJobs');
  const [submitForm] = useUpdateCorrectiveJobMutation();
  const [uploadImages] = useAddJobMediaForJobMutation();

  const form = useForm<CorrectiveJobControlForm>({
    defaultValues: {
      jobId,
      updates: [],
      images: [],
      hasResolvedTicket: false
    }
  });

  const {setIsLoading} = usePage();
  const {showModal, hideModal} = useModal();

  useEffect(() => {
    form.setValue('jobId', jobId);
  }, [jobId]);

  useEffect(() => {
    form.setValue('hasResolvedTicket', initialForm?.hasResolvedTicket);
  }, [initialForm]);

  const handleOnFinish = async () => {
    hideModal();
    setIsLoading(true);
    try {
      const formValid = await form.trigger();
      if (!formValid) return;
      const formData = form.getValues();

      if (formData.images?.length) {
        const response = await uploadImages({
          jobId,
          images: formData.images
        });
        if ((response as any)?.error) {
          onError('There was an error sending images');
          return;
        }
      }
      const formResponse = await submitForm(formData);

      if ((formResponse as any)?.error) {
        onError('There was an error submitting job');
        return;
      }
      onSuccess();
    } catch (error) {
      onError('There was an unexpected error');
    } finally {
      setIsLoading(false);
    }
  }

  const ConfirmModal = () => {
    const modalProps: CoreModalProps = {
      leftButtons: [
        {label: 'Cancel', onPress: hideModal, mode: 'text'},
      ],
      rightButtons: [
        {label: 'Finish', onPress: handleOnFinish, mode: 'contained'}
      ]
    }

    return (
      <CoreModal {...modalProps}>
        <View style={styles.container}>
          <Text style={styles.title}>Finish Job</Text>
          <Text style={styles.message}>Would you like to mark the issue as resolved?</Text>

          <CoreFormContext.Provider value={{form}}>
            <ButtonSwitchFieldController
              onButtonText='Yes'
              offButtonText='No'
              controlName='hasResolvedTicket'
              title='Ticket Resolved?' />
          </CoreFormContext.Provider>
        </View>
      </CoreModal>
    )
  }

  const showFinalizeModal = () => {
    showModal(
      <ConfirmModal />
    )
  }

  const providerValue: JobControlContextType<CorrectiveJobControlForm> = {
    form,
    handleOnFinish: showFinalizeModal,
    jobId,
    onError,
    initialForm,
    isApproval
  }

  return (
    <JobControlContext.Provider value={providerValue}>
      <ScrollView>
        <JobImagesControl />
        <CorrectiveJobUpdatesControl />
      </ScrollView>
      {children}
    </JobControlContext.Provider>
  )
}
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingBottom: 12,
  },
  title: {
    fontSize: 18,
    marginBottom: 6
  },
  message: {
    fontSize: 14,
  },
  modalFormItem: {
    marginBottom: 18
  }
})
