import {StyleSheet, View} from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import * as React from "react";
import {ReactNode, FC, createContext, useContext} from "react";
import {UseFormReturn} from "react-hook-form/dist/types";
import {FormButton} from "./FormButton";

type CoreFormProps = {
  form?: UseFormReturn<any>;
  children: ReactNode;
  footer?: ReactNode;
  onSubmit?: () => void;
  submitText?: string;
  isLoading?: boolean;
  style?: any;
};

const CoreForm: FC<CoreFormProps> & { Row: FC<CoreFormRowProps> } = ({form,children,onSubmit,footer,submitText,isLoading, style}) => {
  const renderFooter = () => {
    const content = footer !== undefined ? footer : (
      <FormButton title={submitText ?? 'SUBMIT'} onPress={onSubmit}/>
    )
    return footer === null ? null : (
      <View style={styles.footerContainer}>
        {content}
      </View>
    )
  }

  return (
    <CoreFormContext.Provider value={{form, isLoading}}>
      <View style={{...styles.form, ...style}}>
        {children}
        {renderFooter()}
      </View>
    </CoreFormContext.Provider>
  )
}

const styles = StyleSheet.create({
  form: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: 18,
    gap: 12,
    flex: 1,
    justifyContent: 'flex-start'
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 18,
    gap: 8
  }
})

type CoreFormRowProps = {
  children: ReactNode;
};

const CoreFormRow: FC<CoreFormRowProps> = ({children}) => {
  const wrappedChild = (item: ReactNode) => (
    <View style={GlobalStyle.form__column}>
      {item}
    </View>
  )

  return (
    <View style={GlobalStyle.form__row}>
      {React.Children.map(children, wrappedChild)}
    </View>
  )
}

export const CoreFormContext = createContext<{ form?: UseFormReturn<any, any>, isLoading?: boolean }>({});

export const useCoreForm = () => {
  const context = useContext(CoreFormContext);
  if (context === undefined) {
    throw new Error('useControl must be used within a ControlProvider');
  }
  return context;
};

CoreForm.Row = CoreFormRow;
export default CoreForm;
