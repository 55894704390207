import {FlatList, Image, StyleSheet, TouchableOpacity, View} from "react-native";
import {Button} from "react-native-paper";
import * as React from "react";
import {useFieldArray} from "react-hook-form";
import {getImageBase64, pickMedia} from "../../../utils/media-utils";
import {useModal} from "../../../hooks/useModal";
import {ConfirmModal} from "../../../components/modals/ConfirmModal";
import {AlertModal} from "../../../components/modals/AlertModal";

interface ImageListFormControlProps<T> {
  control: any;
  name: string;
  maxImages?: number;
  isApproval?: boolean;
}

export default function ImageListFormControl<T>({control, name, maxImages, isApproval}: ImageListFormControlProps<T>) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  const {showModal, hideModal} = useModal();

  const pickImage = async () => {
    if (fields.length >= maxImages) {
      showModal(
        <AlertModal
          title='Image Limit Reached'
          message='You can only upload up to 5 images.'
          onClose={hideModal}
        />
      )
      return;
    }

    const images = await pickMedia('image', false);
    if (images.length === 0) return;

    const imagePromises = images.map(async (file) => {
      const base64 = await getImageBase64(file);
      return {
        uri: base64
      }
    })
    const data = await Promise.all(imagePromises);
    data.forEach((image) => {
      append(image)
    });
  }

  const handleOnDelete = (index: number) => {
    const onConfirm = () => {
      remove(index);
      hideModal();
    }

    showModal(
      <ConfirmModal
        title='Remove Image'
        message='Are you sure you want to remove this image?'
        onConfirm={onConfirm}
        onClose={hideModal}
      />
    )
  }

  return (
    <>
      <View style={fields.length ? styles.imageSection : {}}>
        <FlatList
          data={fields}
          horizontal
          renderItem={({item, index}) => (
            <TouchableOpacity
              disabled={isApproval}
              style={styles.imageContainer} onPress={() => handleOnDelete(index)}
            >
              <Image
                style={{ height: 150, width: 150 }}
                resizeMode='cover'
                source={{ uri: (item as any).uri }}
              />
            </TouchableOpacity>
          )}
        />
      </View>

      <Button
        disabled={isApproval}
        style={styles.button}
        icon="camera" mode='elevated'
        buttonColor='#FFF'
        textColor='#333'
        onPress={() => pickImage()}
      >
        Upload Images
      </Button>
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    marginTop: 6
  },
  imageFormContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: "column"
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: "row",
    minHeight: 150,
    margin: 4
  },
  imageSection: {
    borderWidth: 1,
    borderColor: "#d9d9d9",
    borderRadius: 6,
    padding: 12
  }
})
