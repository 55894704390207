import {ScreenProps} from "../../../../../../utils/screen-props-helper";
import {useWorkOrder} from "../../WorkOrdersStack";
import {FlatList, ListRenderItemInfo, TouchableOpacity, View} from "react-native";
import React, {ReactNode, useEffect, useState} from "react";
import {
  useLazyFindWorkOrderAssetQuery,
  useLazyGetWorkOrderAssetsQuery
} from "../../../../../../store/services/api/work-order-api";
import {Button, Card, Text} from "react-native-paper";
import {WorkOrderAsset} from "../../../../../../store/data/entities/work-order-asset";
import {TechnicianWorkOrderAssetsStackParamList} from "./TechnicianWorkOrderAssetsStack";
import Skeleton from "../../../../../../components/skeletons/Skeleton";
import {EmptyList} from "../../../../../../components/flatlist/EmptyList";
import AddWorkOrderAssetModal from "../../../../modals/AddWorkOrderAssetModal";
import {WorkOrderType} from "../../../../../../store/data/entities/work-order";
import {useCamera} from "../../../../hooks/useCamera";
import {SearchOutlined} from "@ant-design/icons";
import {
  Drawer,
  Input,
  Button as AntButton,
  Flex,
  notification,
  Checkbox,
  Descriptions,
  Tooltip,
  Collapse
} from "antd";
import {QrcodeOutlined} from '@ant-design/icons';
import {getUniqueReferenceFromUrl} from "../../../../../../utils/qr-utils";
import {AssetPriorityIcons, AssetPriorityLabels} from "../../../../../../store/data/entities/asset";
import {Feather, MaterialIcons} from "@expo/vector-icons";
const {Search} = Input;
const {Panel} = Collapse;

export default function TechnicianWorkOrderAssetsList({navigation}: ScreenProps<TechnicianWorkOrderAssetsStackParamList, 'list'>) {
  const {id, workOrderType} = useWorkOrder();
  const [getWorkOrderAssets, {data, isFetching}] = useLazyGetWorkOrderAssetsQuery();
  const [findWorkOrderAsset, {isFetching: isFetchingFind}] = useLazyFindWorkOrderAssetQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [showCheckedAssets, setShowCheckedAssets] = useState<boolean>(false);
  const {showCamera} = useCamera();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  useEffect(() => {
    setCurrentPage(1);
    fetchAssets(1);
  }, [searchQuery, showCheckedAssets]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          onPress={() => setSearchVisible(true)}
          style={{ marginRight: 16 }}
        >
          <SearchOutlined size={24} />
        </TouchableOpacity>
      )
    })
  }, [navigation]);

  const fetchAssets = (pageNumber: number) => {
    getWorkOrderAssets({
      pageNumber,
      pageSize: 10,
      workOrderId: id,
      searchTerm: searchQuery,
      showChecked: showCheckedAssets,
    });
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchAssets(newPage);
  };

  const navigateToAsset = (id: string, qrScanned?: boolean) => navigation.navigate('details', {id, qrScanned});

  const getAssetIdByQr = async (qrCode: string): Promise<string | undefined> => {
    const asset = await findWorkOrderAsset({
      workOrderId: id,
      qrCode
    }).unwrap();
    return asset?.id;
  }

  const handleOnSearch = (search: string | undefined) => {
    setSearchQuery(search);
    setSearchVisible(false);
  }

  const handleOnToggleShowCheckedAssets = (value: boolean) => {
    setShowCheckedAssets(value);
    setSearchVisible(false);
  }

  const handleCameraButtonClick = () => {
    showCamera({
      mode: 'qr',
      onResult: async (url: string) => {
        setSearchVisible(false);
        const uniqueRef = getUniqueReferenceFromUrl(url);
        const assetId = await getAssetIdByQr(uniqueRef);
        if (assetId) {
          navigateToAsset(assetId, true);
          return;
        }

        notification.error({
          message: 'Warning',
          description: 'Asset not found on this Work Order.',
          placement: 'bottomRight',
          duration: 3,
        });
      }
    })
  }

  const renderWorkOrderAsset = ({item}: ListRenderItemInfo<WorkOrderAsset>) => {
    const handleOnViewPress = () => navigateToAsset(item.id);
    const getValue = (value: boolean) => value ? 'Y' : 'N';

    const noticeText = !item.isQRScanned ? 'QR not scanned'
      : !item.isChecksCompleted ? 'Checks not completed' : 'Completed';
    const noticeColor = item.isQRScanned && item.isChecksCompleted ? 'green' : 'red';

    const icon = AssetPriorityIcons[item.assetPriority];

    const DescriptionItem = (props: {label: string, children: ReactNode}) => (
      <Descriptions.Item
        {...props}
        span={'filled' as any}
      />
    )

    return (
      <Card style={{backgroundColor: 'white'}}>
        <Card.Title
          title={(
            <Flex gap={4} align="center" style={{ flex: 1, overflow: "hidden" }}>
              <MaterialIcons name="location-on" size={18} />
              <Tooltip title={item.assetLocation}>
                <Text
                  variant="bodySmall"
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={{ flex: 1, overflow: "hidden" }}
                >
                  {item.assetLocation}
                </Text>
              </Tooltip>
              <Flex gap={4} align="center" style={{ flexShrink: 0 }}>
                <Text variant="bodySmall" style={{ color: icon.color }}>
                  {AssetPriorityLabels[item.assetPriority]}
                </Text>
                <Feather name={icon.name as any} color={icon.color} size={18} />
              </Flex>
            </Flex>
          )}
        />
        <Card.Content>
          <Collapse defaultActiveKey={['2']}>
            <Panel header="Asset Details" key="1">
              <Descriptions
                size='small'
                layout='horizontal'
                styles={{
                  label: {
                    width: 120
                  }
                }}
              >
                {item.subAssetType && (
                  <DescriptionItem label='Sub-Asset Type'>{item.subAssetType}</DescriptionItem>
                )}
                <DescriptionItem label='Code'>{item.assetCode}</DescriptionItem>
                <DescriptionItem label='Description'>{item.assetDescription}</DescriptionItem>
              </Descriptions>
            </Panel>
            <Panel header="Progress" key="2">
              <Descriptions
                size='small'
                layout='horizontal'
                styles={{
                  label: {
                    width: 150
                  }
                }}
              >
                <DescriptionItem label='Checks Completed'>{getValue(item.isChecksCompleted)}</DescriptionItem>
                <DescriptionItem label='QR Tagged'>{getValue(item.isQRScanned)}</DescriptionItem>
                <DescriptionItem label='Follow-Up Required'>{getValue(item.isFollowUpRequired)}</DescriptionItem>
              </Descriptions>
            </Panel>
          </Collapse>
        </Card.Content>
        <Card.Actions >
          <View style={{marginRight: 'auto'}}>
            <Text variant='labelSmall' style={{color: noticeColor}}>
              {noticeText}
            </Text>
          </View>
          <Button onPress={handleOnViewPress}>View</Button>
        </Card.Actions>
      </Card>
    )
  };

  const showAddButton = workOrderType !== undefined && workOrderType !== WorkOrderType.Preventative;

  return (
    <View style={{position: 'relative', flex: 1}}>
      <Skeleton
        loading={isFetching || isFetchingFind}
        placeholder={<Skeleton.Card />}
      >
        <FlatList
          style={{padding: 4}}
          ItemSeparatorComponent={() => <View style={{height: 6}} />}
          keyExtractor={item => item.id}
          data={data?.list}
          renderItem={renderWorkOrderAsset}
          ListEmptyComponent={
            <EmptyList
              isLoading={isFetching}
              emptyText="Could not find any assets" />
          }
          ListFooterComponent={showAddButton ? (
            <Button
              style={{marginTop: 6}}
              icon='plus'
              mode="elevated"
              onPress={() => setModalVisible(true)}
              buttonColor='#fff'
              textColor='#0f0f0f'
            >
              Add Asset
            </Button>
          ) : null}
        />
      </Skeleton>

      {data?.pagination?.hasNext && (
        <Flex justify='space-around' gap={8} style={{margin: 6}}>
          <Button
            disabled={isFetching || currentPage === 1}
            style={{flex: 1}}
            mode="elevated"
            onPress={() => handlePageChange(currentPage - 1)}
            buttonColor='#fff'
            textColor='#0f0f0f'
          >
            Previous
          </Button>
          <Button
            disabled={!data?.pagination?.hasNext || isFetching}
            style={{flex: 1}}
            mode="elevated"
            onPress={() => handlePageChange(currentPage + 1)}
            buttonColor='#fff'
            textColor='#0f0f0f'
          >
            Next
          </Button>
        </Flex>
      )}

      <AddWorkOrderAssetModal
        visible={modalVisible}
        workOrderId={id}
        onClose={() => setModalVisible(false)}
        onSuccess={(id) => navigateToAsset(id, true)}
      />

      <Drawer
        placement="top"
        onClose={() => setSearchVisible(false)}
        open={searchVisible}
        getContainer={false}
        closeIcon={null}
        style={{height: 'auto'}}
        styles={{
          body: {
            paddingBlock: '24px',
            paddingInline: '12px'
          },
          content: {
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px'
          }
        }}
        height='auto'
      >
        <Flex justify='center' gap={8}>
          <Search
            allowClear
            placeholder="Search Assets"
            onSearch={handleOnSearch}
            onClear={() => handleOnSearch(undefined)}
            value={searchQuery}
          />
          <AntButton
            style={{ width: 80 }}
            onClick={handleCameraButtonClick}>
            <QrcodeOutlined />
          </AntButton>
        </Flex>
        <Checkbox
          style={{marginTop: 16}}
          value={showCheckedAssets}
          onChange={(e) => handleOnToggleShowCheckedAssets(e.target.checked)}
        >
          Show Checked Assets
        </Checkbox>
      </Drawer>
    </View>
  );
}
