import { View, Text, StyleSheet } from "react-native";
import { MaterialIcons, Feather, FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import {UserPermission} from "../../../../store/data/users/user-permission";
import React from "react";

var logo = require('../../../../assets/corerfidlogo.png');

interface MenuItemProps {
  children: React.ReactNode;
  url: string;
  requiredPermission?: UserPermission;
}

export const DrawerContent = ({props, permissions = []}: {props: any, permissions: UserPermission[]}) => {

  const MenuItem: React.FC<MenuItemProps> = ({ children, url, requiredPermission }) => {
    if (requiredPermission && !permissions.includes(requiredPermission)) return null;

    return (
      <View>
        <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate(url)}>
          {children}
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.navigation}>
      <View style={styles.logo__container}>
        <img alt='CoreRFID Logo' src={logo} style={styles.logo} />
      </View>

      <MenuItem url='assets' requiredPermission='CanManageAssets'>
        <MaterialIcons style={styles.link__icon} name="web-asset" size={18} color="black" />
        Assets
      </MenuItem>

      <MenuItem url='assetTypes' requiredPermission='CanManageAssetTypes'>
        <MaterialCommunityIcons style={styles.link__icon} name="format-list-bulleted-type" size={18} color="black" />
        Asset Types
      </MenuItem>

      <MenuItem url='buildings' requiredPermission='CanManageBuildings'>
        <FontAwesome5 style={styles.link__icon} name="building" size={18} color="black" />
        Buildings
      </MenuItem>

      <MenuItem url='tickets' requiredPermission='CanManageTickets'>
        <MaterialCommunityIcons style={styles.link__icon} name="book-multiple" size={18} color="black" />
        Tickets
      </MenuItem>

      <MenuItem url='feedback'>
        <MaterialIcons style={styles.link__icon} name="chat" size={18} color="black" />
        Feedback
      </MenuItem>

      <MenuItem url='jobs' requiredPermission='CanApproveJobs'>
        <MaterialCommunityIcons style={styles.link__icon} name="account-hard-hat" size={18} color="black" />
        Work Orders
      </MenuItem>

      <MenuItem url='maintenance-checklists' requiredPermission='CanManageMaintenanceChecklists'>
        <MaterialCommunityIcons style={styles.link__icon} name="format-list-bulleted-type" size={18} color="black" />
        Job Plans
      </MenuItem>

      <MenuItem url='maintenance-parts' requiredPermission='CanManageMaintenanceParts'>
        <MaterialCommunityIcons style={styles.link__icon} name="cogs" size={18} color="black" />
        Parts
      </MenuItem>

      <MenuItem url='users' requiredPermission='CanManageUsers'>
        <Feather style={styles.link__icon} name="users" size={18} color="black" />
        Users
      </MenuItem>

      <MenuItem url='system-settings'>
        <FontAwesome style={styles.link__icon} name="cog" size={18} color="black" />
        System Settings
      </MenuItem>
    </View>
  );
}

const styles = StyleSheet.create({
  navigation: {
    height: '100%',
    overflowY: 'scroll',
    paddingBottom: 10
  },

  sub__menu: {
    paddingLeft: 20,
  },

  title: {
    fontFamily: 'OpenSans-ExtraBold',
    padding: 10,
  },

  link: {
    padding: 10,
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'OpenSans-Regular'
  },

  link__icon: {
    paddingRight: 10,
  },

  logo__container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10
  },

  logo: {
    height: 60,
    resizeMode: 'contain',
    marginTop: 10,
  },
});
